import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// eslint-disable-next-line object-curly-newline
import { useForm, Controller } from 'react-hook-form';
import { Button, Card, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

const ContactSite = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);

  useEffect(() => {
    dispatch(actions.getInformation());
    return () => {};
  }, []);

  console.log('Information', information);

  // Setting React hook form
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const onSubmit = async (data, event) => {
    try {
      const preprocessedData = {
        contact: {
          location: {
            address: data.address,
            keyword: data.keyword,
          },
          tel_1: data.tel_1,
          tel_2: data.tel_2,
          tel_3: data.tel_3,
          email1: data.email1,
          email2: data.email2,
          facebook: data.facebook,
          instagram: data.instagram,
          line: data.line,
        },
      };
      console.log(preprocessedData);
      event.preventDefault();
      await dispatch(
        // eslint-disable-next-line no-underscore-dangle
        actions.editOneInformation(information?._id, preprocessedData),
      );
      alert('สำเร็จ');
      await dispatch(actions.getInformation());
    } catch (error) {
      console.error(error);
    }
  };

  if (information.isLoading) {
    return <Loading />;
  }

  if (!information.isLoading && information.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          {/* <div className="mt-6">{renderAddButton()}</div> */}
        </div>
        <Card className="p-6">
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* <MainSiteForm /> */}
            <div className="flex flex-row flex-wrap">
              <div className="w-full px-1 py-2 md:w-1/2">
                <div className="py-2">เบอร์โทรศัพท์</div>
                <div className="w-full  px-1 py-2">
                  <Controller
                    name={'tel_1'}
                    control={control}
                    defaultValue={information?.contact?.tel_1 || ''}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="เบอร์โทรศัพท์ที่ 1"
                        fullWidth
                        size={'small'}
                        helperText={errors.name && 'กรุณาใส่ข้อมูล'}
                      />
                    )}
                  />
                </div>
                <div className="w-full  px-1 py-2">
                  <Controller
                    name={'tel_2'}
                    control={control}
                    defaultValue={information?.contact?.tel_2 || ''}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="เบอร์โทรศัพท์ที่ 2"
                        fullWidth
                        size={'small'}
                        helperText={errors.name && 'กรุณาใส่ข้อมูล'}
                      />
                    )}
                  />
                </div>
                <div className="w-full  px-1 py-2">
                  <Controller
                    name={'tel_3'}
                    control={control}
                    defaultValue={information?.contact?.tel_3 || ''}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="เบอร์โทรศัพท์ที่ 3"
                        fullWidth
                        size={'small'}
                        helperText={errors.name && 'กรุณาใส่ข้อมูล'}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="w-full px-1 py-2 md:w-1/2">
                <div className="py-2">อีเมลสำหรับติดต่อ</div>
                <div className="w-full  px-1 py-2">
                  <Controller
                    name={'email1'}
                    control={control}
                    defaultValue={information?.contact?.email1 || ''}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="อีเมลที่ 1 (ใช้สำหรับรับคำขอใบเสนอราคา)"
                        fullWidth
                        size={'small'}
                        helperText={errors.name && 'กรุณาใส่ข้อมูล'}
                      />
                    )}
                  />
                </div>
                <div className="w-full  px-1 py-2">
                  <Controller
                    name={'email2'}
                    control={control}
                    defaultValue={information?.contact?.email2 || ''}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="อีเมลที่ 2"
                        fullWidth
                        size={'small'}
                        helperText={errors.name && 'กรุณาใส่ข้อมูล'}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="w-full px-1 py-2 md:w-1/2">
                <div className="py-2">โซเซียลมีเดีย</div>
                <div className="w-full  px-1 py-2">
                  <Controller
                    name={'facebook'}
                    control={control}
                    defaultValue={information?.contact?.facebook || ''}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="facebook link"
                        fullWidth
                        size={'small'}
                        helperText={errors.name && 'กรุณาใส่ข้อมูล'}
                      />
                    )}
                  />
                </div>
                <div className="w-full  px-1 py-2">
                  <Controller
                    name={'instagram'}
                    control={control}
                    defaultValue={information?.contact?.instagram || ''}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="instagram"
                        fullWidth
                        size={'small'}
                        helperText={errors.name && 'กรุณาใส่ข้อมูล'}
                      />
                    )}
                  />
                </div>
                <div className="w-full  px-1 py-2">
                  <Controller
                    name={'line'}
                    control={control}
                    defaultValue={information?.contact?.line || ''}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="line id"
                        fullWidth
                        size={'small'}
                        helperText={errors.name && 'กรุณาใส่ข้อมูล'}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="w-full px-1 py-2 md:w-1/2">
                <div className="py-2">ตำแหน่งที่ตั้ง</div>
                <div className="w-full  px-1 py-2">
                  <Controller
                    name={'address'}
                    control={control}
                    defaultValue={information?.contact?.location?.address || ''}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="สถานที่ตั้ง"
                        multiline={true}
                        fullWidth
                        size={'small'}
                        helperText={errors.name && 'กรุณาใส่ข้อมูล'}
                      />
                    )}
                  />
                </div>
                <div className="w-full  px-1 py-2">
                  <Controller
                    name={'keyword'}
                    control={control}
                    defaultValue={information?.contact?.location?.keyword || ''}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="คำค้นหาในแผนที่"
                        multiline={true}
                        fullWidth
                        size={'small'}
                        helperText={errors.name && 'กรุณาใส่ข้อมูล'}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-end gap-1 py-4">
              <Button
                variant="contained"
                type="submit"
                startIcon={<SaveIcon />}
              >
                บันทึก
              </Button>
            </div>
          </form>
        </Card>
      </div>
    );
  }
  return <Error message={information?.message} />;
};

ContactSite.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ContactSite.defaultProps = {
  title: '',
  subtitle: '',
};

export default ContactSite;
