import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import {
  Backdrop,
  Fade,
  Modal,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  Paper,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

export default function DetailExport({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const product = useSelector((state) => state.product);
  const productTransaction = useSelector((state) => state.productTransaction);
  const [selectSerial, setSelectSerial] = useState([]);
  const [openSerial, setOpenSerial] = useState(false);

  const handleOpenSerial = () => setOpenSerial(true);
  const handleCloseSerial = () => setOpenSerial(false);

  console.log('productTransaction', productTransaction);
  const { rows } = product;
  console.log('product', rows);

  useEffect(() => {
    const fetchProductTransaction = async () => {
      try {
        await dispatch(actions.productTransactionGet(id));
        await dispatch(actions.productAll(''));
      } catch (error) {
        console.error(error);
      }
    };
    const fetchSerialNumber = async () => {
      try {
        await dispatch(actions.getAllSerial(''));
      } catch (error) {
        console.error(error);
      }
    };
    fetchProductTransaction();
    fetchSerialNumber();

    return () => {};
  }, []);

  const { order } = productTransaction;
  // eslint-disable-next-line consistent-return
  function checkSerial(data) {
    handleOpenSerial();
    const findSerial = _.filter(order, {
      product: data,
    });
    setSelectSerial(findSerial);
  }

  function findProductName(data) {
    // console.log('data', data);
    const findName = _.find(rows, {
      id: data,
    });
    // console.log('findName', findName);
    return findName?.name;
  }

  const renderModalSerialNumber = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openSerial}
      onClose={handleCloseSerial}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openSerial}>
        <Card sx={style} className="max-w-xl">
          <div className="pb-2 font-bold">รายการ Serial Number</div>
          <div className="max-h-80 overflow-y-auto border-2">
            <Paper>
              <TableContainer component={Paper}>
                <Table size="small" className="border-lg">
                  <TableHead className="bg-yellow-100">
                    <TableRow>
                      <TableCell>
                        <div className="font-bold">ลำดับที่</div>
                      </TableCell>
                      <TableCell>
                        <div className="font-bold"> Serial Number</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectSerial.map(
                      (eachSerial) =>
                        // eslint-disable-next-line implicit-arrow-linebreak
                        eachSerial.serial.map((eachSerialName, index) => (
                          // eslint-disable-next-line react/jsx-key
                          <TableRow
                            key={index}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                            className={index % 2 === 0 ? 'bg-gray-100' : ''}
                          >
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{eachSerialName.name}</TableCell>
                          </TableRow>
                        )),
                      // eslint-disable-next-line function-paren-newline
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const displayProduct = () => (
    <div className="">
      <Paper>
        <TableContainer>
          <Table size="small" className="border-lg">
            <TableHead className="bg-yellow-100">
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> จำนวน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ราคาต่อหน่วย</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(productTransaction) ? (
                order.map((_order, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <div className="flex">
                        <div className="my-auto">
                          {findProductName(_order?.product)}
                        </div>
                        {_order?.serial.length === 0 ? (
                          <div className="py-5"></div>
                        ) : (
                          <div className="my-auto">
                            <Tooltip
                              title="ดู serial number"
                              placement="right-start"
                            >
                              <IconButton
                                color="primary"
                                aria-label="more detail product"
                                component="span"
                                // eslint-disable-next-line no-underscore-dangle
                                onClick={() => checkSerial(_order?.product)}
                              >
                                <MoreHorizIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    </TableCell>
                    <TableCell>
                      {_order?.amount} {_order?.product?.unit}
                    </TableCell>
                    <TableCell>
                      {`${parseInt(_order?.price, 10 || 0, 10).toLocaleString(
                        undefined,
                      )}`}{' '}
                      {'บาท'}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow key={''}>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีรายการ</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );

  const renderShowProductList = () => (
    <Card className="p-6 ">{displayProduct()}</Card>
  );

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (productTransaction.isLoading || productTransaction.rows) {
    return <Loading />;
  }
  if (!productTransaction.isLoading && productTransaction.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderModalSerialNumber()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
          <div className="text-lg font-semibold ml-6">รายละเอียดการส่งออก</div>
        </div>
        <div>
          <Card className="p-4 ">
            <div className="flex flex-wrap divide-y">
              <div className="w-full py-4 text-lg font-semibold ">
                {'รายละเอียด'}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold ">{'คู่ค้า'}</div>
              <div className="w-1/2 py-4 ">{`${productTransaction?.customer?.name}`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'ประเภทคู่ค้า'}
              </div>
              <div className="w-1/2 py-4 ">{`${productTransaction?.customer?.type?.name}`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'ผู้ดำเนินการ'}
              </div>
              <div className="w-1/2 py-4 ">
                {`${productTransaction?.employee?.firstname}` || '-'}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'หมายเลขบิล'}
              </div>
              <div className="w-1/2 py-4 ">
                {`${productTransaction?.bill_no}` || '-'}
              </div>
              {/* {productTransaction?.detail ? (
                <>
                  <div className="w-1/2 py-4 px-2 font-semibold ">
                    {'รายละเอียด'}
                  </div>
                  <div className="w-1/2 py-4 ">{`${productTransaction?.detail}`}</div>
                </>
              ) : (
                <></>
              )} */}
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'เวลาดำเนินการ'}
              </div>
              <div className="w-1/2 py-4 ">{`${dayjs(
                productTransaction?.date,
              ).format('D MMM BBBB ')}`}</div>
              {productTransaction?.remark ? (
                <>
                  <div className="w-1/2 py-4 px-2 font-semibold ">
                    {'หมายเหตุ'}
                  </div>
                  <div className="w-1/2 py-4 ">{`${productTransaction?.remark}`}</div>
                </>
              ) : (
                <></>
              )}
            </div>
          </Card>
          {productTransaction?.order ? (
            <>
              <div className="w-full py-4 px-2 font-semibold ">
                {'รายการสินค้า'}
              </div>
              {renderShowProductList()}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailExport.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailExport.defaultProps = {
  title: '',
  subtitle: '',
};
