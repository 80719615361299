/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Button,
  Card,
  TextField,
  Checkbox,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Backdrop,
  Modal,
  Fade,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import { styled } from '@mui/material/styles';

import dayjs from 'dayjs';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { Trash2 as Delete } from 'react-feather';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { CreateCustomer, CreateProduct } from '../../components/Dialogs';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { BackButton } from '../../components/Button';
import { ViewTitle } from '../../components/ViewTitle';

import thLocale from 'date-fns/locale/th';
const localeMap = {
  th: thLocale,
};
const maskMap = {
  th: '__/__/____',
};

import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

const filter = createFilterOptions();

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

function CreateStocktakingInvoice({ title, subtitle }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const stocktakingInvoice = useSelector((state) => state.stocktakingInvoice);
  const customer = useSelector((state) => state.customer);
  const productStockLot = useSelector((state) => state.productStockLot);
  const pallet = useSelector((state) => state.pallet);

  const [locale, setLocale] = useState('th');
  const me = useSelector((state) => state.me);
  const product = useSelector((state) => state.product);
  const [stocktakingInvoiceType, setStocktakingInvoiceType] = useState('');
  const [customerData, setCustomerData] = useState();
  const [productData, setProductData] = useState(productStockLot?.rows?.[0]);

  const [openAddCustomer, toggleOpenAddCustomer] = useState(false);
  const [openAddProduct, toggleOpenAddProduct] = useState(false);
  const [dialogCustomer, setDialogCustomer] = useState({
    name: '',
  });
  const [dialogProduct, setDialogProduct] = useState({
    name: '',
    // tag: '',
    unit: '',
  });
  const [selectStatus, setSelectStatus] = useState('');
  const [selectProductStockLot, setSelectProductStockLot] = useState();
  const [selectPallet, setSelectPallet] = useState();
  const [listProductStockLot, setListProductStockLot] = useState([]);

  const [openModalSelectPallet, setOpenModalSelectPallet] = useState(false);

  const handleOpenModalSelectPallet = (data) => {
    setOpenModalSelectPallet(true);
    console.log('data', data);
    const findPallet = _.find(pallet?.rows, (row) => row?.id === data?._id);
    setSelectPallet(findPallet);
  };
  const handleCloseModalSelectPallet = () => setOpenModalSelectPallet(false);

  // const NewProduct = _.map(product?.rows, (eachProduct, index) => ({
  //   ...eachProduct,
  //   index: index + 1,
  // }));

  const NewProductStockLot = _.map(
    productStockLot?.rows,
    (eachProductStockLot, index) => ({
      ...eachProductStockLot,
      index: index + 1,
    }),
  );

  const NewCustomer = _.map(customer?.rows, (eachCustomer, index) => ({
    ...eachCustomer,
    index: index + 1,
  }));

  console.log('NewProductStockLot', NewProductStockLot);
  console.log('selectProductStockLot', selectProductStockLot);
  console.log('listProductStockLot', listProductStockLot);
  console.log('pallet', pallet);
  console.log('selectPallet', selectPallet);

  // console.log('NewProduct', NewProduct);

  const handleCloseAddCustomer = () => {
    setDialogCustomer({
      name: '',
    });
    toggleOpenAddCustomer(false);
  };

  const handleCloseAddProduct = () => {
    setDialogProduct({
      name: '',
      // tag: '',
      unit: '',
    });
    toggleOpenAddProduct(false);
  };

  console.log('customerData', customerData);

  const { register, control, handleSubmit, setValue } = useForm({
    mode: 'onBlur',
  });

  const {
    register: registerAddStock,
    control: controlAddStock,
    handleSubmit: handleSubmitAddStock,
    setValue: setValueAddStock,
  } = useForm({
    mode: 'onBlur',
  });

  const { fields, append, remove } = useFieldArray({
    control: controlAddStock,
    name: 'itemList',
  });

  useEffect(() => {
    dispatch(actions.customerAll({}));
    dispatch(actions.stocktakingInvoiceAll({}));
    dispatch(actions.productAllStock());
    dispatch(
      actions.productStockLotAll({
        selectStatus,
        customerData: customerData?.id,
      }),
    );
    dispatch(actions.palletAll({}));
    dispatch(actions.meGet());
    setSelectProductStockLot();
    return () => {};
  }, [customerData]);

  useEffect(() => {
    if (!pallet.isLoading) {
      setValueAddStock(
        'itemList',
        _.map(selectPallet?.products, (eachPallet) => {
          const payload = {
            product: eachPallet,
            quantity: 0,
          };
          return payload;
        }),
      );
    }
    return () => {};
  }, [selectPallet]);

  // const handleAddItem = () => {
  //   append({
  //     product: product.rows[0].id,
  //     tag: product.rows[0]?.tag,
  //     weight: product.rows[0]?.weight || '',
  //   });
  // };

  const handleDeleteItem = (index) => {
    const NewArray = [...listProductStockLot];
    NewArray.splice(index, 1);
    setListProductStockLot(NewArray);
  };

  const onSubmitAddStock = async (data) => {
    try {
      console.log('data?.itemList', data?.itemList);
      const newList = _.filter(data?.itemList, (item) => item?.quantity !== 0);
      console.log('newList', newList);

      if (
        parseFloat(data?.itemList[0]?.quantity) >
        data?.itemList[0]?.product?.inventory /
          data?.itemList[0]?.product?.product?.weight
      ) {
        alert('สินค้ามีไม่พอ');
      } else {
        const NewArray = [...listProductStockLot];
        _.map(newList, (item) =>
          NewArray.push({
            product_stock_lot: item?.product,
            quantity: parseFloat(item?.quantity),
            pallet: selectPallet,
          }),
        );
        console.log('NewArray', NewArray);
        setListProductStockLot(NewArray);
        setOpenModalSelectPallet(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (data) => {
    try {
      const DataOfInvoice = {
        ...data,
        name: data.name,
        description: data.description,
        productList: listProductStockLot,
        consignment_invoice_type: stocktakingInvoiceType,
        employee: me?.userData?.id,
        customer: customerData?.id,
      };

      console.log('DataOfInvoice', DataOfInvoice);
      await dispatch(actions.stocktakingInvoiceCreate(DataOfInvoice));
      alert('สำเร็จ');
      history.push('/doc/stocktaking-invoice');
    } catch (error) {
      console.error(error);
    }
  };

  console.log('fields', fields);

  const renderModalSelectPallet = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModalSelectPallet}
      onClose={handleCloseModalSelectPallet}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModalSelectPallet}>
        <Card sx={style} className="max-w-5xl my-4">
          <div className=" max-h-screen overflow-y-auto">
            <div className="py-2 text-lg text-center underline">
              รายละเอียดพาเลท
            </div>
            {/* <div>
              {_.map(fields, (eachProduct, index) => (
                <div className="p-2 flex justify-between">
                  <div className="my-auto">
                    {index + 1} :{' '}
                    <a className="font-bold">
                      {eachProduct?.product?.product?.tag}
                    </a>{' '}
                    {eachProduct?.product?.product_type?.name} ({' '}
                    {eachProduct?.product?.product?.weight} kg )
                  </div>
                  <div>ช่อง</div>
                </div>
              ))}
            </div> */}
            <form onSubmit={handleSubmitAddStock(onSubmitAddStock)}>
              <div className="my-4">
                <Paper>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size={'small'}>
                      <colgroup>
                        <col width="10%" />
                        <col width="20%" />
                        <col width="30%" />
                        <col width="15%" />
                        <col width="25%" />
                      </colgroup>
                      <TableHead>
                        <TableRow className="bg-green-100">
                          <TableCell>
                            <div className="font-bold py-1">ลำดับที่</div>
                          </TableCell>
                          <TableCell>
                            <div className="font-bold py-1">รหัสสินค้า</div>
                          </TableCell>
                          <TableCell>
                            <div className="font-bold py-1">ชื่อสินค้า</div>
                          </TableCell>
                          <TableCell>
                            <div className="font-bold py-1">จำนวนที่มี</div>
                          </TableCell>
                          <TableCell>
                            <div className="font-bold py-1">จำนวนที่เบิก</div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!_.isEmpty(fields) ? (
                          _.map(fields, (item, index) => (
                            <TableRow
                              key={item.id}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                <div className="py-1">{`${index + 1}`}</div>
                              </TableCell>

                              <TableCell component="th" scope="row">
                                <div className="py-1">
                                  {item?.product?.product?.tag}
                                </div>
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <div className="py-1">
                                  {item?.product?.product_type?.name} ({' '}
                                  {item?.product?.product?.weight} kg )
                                  <div className="text-xs text-red-600">
                                    ลูกค้า : {item?.product?.customer?.name}
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <div className="py-1">
                                  {item?.product?.inventory /
                                    item?.product?.product?.weight}{' '}
                                  {item?.product?.product_type?.unit}
                                </div>
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <div className="py-1">
                                  <Controller
                                    name={`itemList.${index}.quantity`}
                                    control={controlAddStock}
                                    render={({ field }) => (
                                      <TextField
                                        size="small"
                                        fullWidth
                                        required
                                        type="number"
                                        label={
                                          item?.product?.product_type?.unit
                                        }
                                        disabled={
                                          selectProductStockLot
                                            ?.consignment_invoice?.customer
                                            ?._id !==
                                          item?.product?.customer?._id
                                        }
                                        {...field}
                                      />
                                    )}
                                  />
                                </div>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={6}>
                              <div className="text-center">ไม่มีข้อมูล</div>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </div>
              <div className="flex justify-end">
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  บันทึก
                </Button>
              </div>
            </form>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const rendInfomationTextField = () => (
    <div>
      <div className="my-2 w-full">
        <div className="my-3 w-full">
          <TextField
            size="small"
            fullWidth
            required
            label="เลขใบเบิกสินค้า"
            defaultValue=""
            {...register('name', { required: true })}
          />
        </div>
        <div className="my-3 w-full">
          <Autocomplete
            onChange={(event, newValue) => {
              if (typeof newValue === 'string') {
                // timeout to avoid instant validation of the dialog's form.
                setTimeout(() => {
                  toggleOpenAddCustomer(true);
                  setDialogCustomer({
                    name: newValue,
                  });
                });
              } else if (newValue && newValue?.inputValue) {
                toggleOpenAddCustomer(true);
                setDialogCustomer({
                  name: newValue?.inputValue,
                });
              } else {
                setCustomerData(newValue);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              if (params?.inputValue !== '') {
                filtered.push({
                  inputValue: params?.inputValue,
                  name: `เพิ่ม "${params?.inputValue}"  ลงในระบบ`,
                });
              }

              return filtered;
            }}
            handleHomeEndKeys
            value={customerData}
            options={NewCustomer}
            getOptionLabel={(option) => {
              // e.g value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              if (option?.inputValue) {
                return option?.inputValue;
              }
              return option?.name;
            }}
            required
            renderOption={(props, option) => (
              <li {...props}>
                {option?.index} : {option?.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="ชื่อลูกค้า" />
            )}
          />
        </div>
        <div className="my-3 w-full">
          <TextField
            size="small"
            fullWidth
            label="ทะเบียนรถ"
            defaultValue=""
            {...register('car_registration', { required: false })}
          />
        </div>
        <div className="my-3 w-full">
          <Controller
            name={'date'}
            control={control}
            defaultValue={dayjs(new Date()).locale('th').format('YYYY-MM-DD')}
            rules={{ required: true }}
            render={({ field }) => (
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={localeMap[locale]}
              >
                <DateTimePicker
                  {...field}
                  sx={{
                    width: '100%',
                  }}
                  ampm={false}
                  fullWidth
                  required={true}
                  mask={maskMap[locale]}
                  label="เลือกวันและเวลา ที่เบิกสินค้า"
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            )}
          />
        </div>

        <div className="my-3 w-full">
          <TextField
            label="หมายเหตุ"
            fullWidth
            multiline
            required={stocktakingInvoiceType === 'other'}
            rows={2}
            defaultValue=""
            {...register('description')}
          />
        </div>
      </div>
    </div>
  );

  const renderTable = () => (
    <div>
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size={'small'}>
            {/* <colgroup>
              <col width="8%" />
              <col width="20%" />
              <col width="32%" />
              <col width="15%" />
              <col width="15%" />
              <col width="10%" />
            </colgroup> */}
            <TableHead>
              <TableRow className="bg-green-100">
                <TableCell>
                  <div className="font-bold py-1">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">สินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">จำนวน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ใบรับฝาก</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ทะเบียนรถ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ตำแหน่งสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(listProductStockLot) ? (
                _.map(listProductStockLot, (item, index) => (
                  <TableRow
                    key={item.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="py-1">{`${index + 1}`}</div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="pt-1">
                        {item?.product_stock_lot?.product?.tag ||
                          item?.product_stock_lot?.product_type?.tag}{' '}
                        : {item?.product_stock_lot?.product_type?.name} ( ก้อนละ
                        {item?.product_stock_lot?.product?.weight} kg )
                      </div>
                      <div className="pb-1">
                        พาเลท :{' '}
                        {item?.pallet?.name ||
                          item?.pallet?.prefix + item?.pallet?.running_number}
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div>
                        {item?.quantity}{' '}
                        {item?.product_stock_lot?.product_type?.unit}
                      </div>
                      <div>
                        ({' '}
                        {item?.product_stock_lot?.product?.weight *
                          item?.quantity}{' '}
                        kg )
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div>
                        {item?.product_stock_lot?.consignment_invoice?.name}
                      </div>
                      <div>
                        ({' '}
                        {dayjs(
                          item?.product_stock_lot?.consignment_invoice
                            ?.deposit_start,
                        ).format('D MMM BBBB')}{' '}
                        )
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div>
                        {
                          item?.product_stock_lot?.consignment_invoice
                            ?.car_registration
                        }
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div>คลัง {item?.pallet?.warehouse?.name}</div>
                      <div>แถวที่ {item?.pallet?.row}</div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Button
                        size="sm"
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          handleDeleteItem(index);
                        }}
                      >
                        <Delete size={16} color="red" />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );

  const renderAddButton = () => (
    <Button
      size="small"
      variant="outlined"
      color="success"
      onClick={() => {
        handleAddItem();
      }}
    >
      เพิ่ม
    </Button>
  );

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  // console.log('selectCustomer', selectCustomer);

  if (
    stocktakingInvoice.isLoading ||
    product.isLoading ||
    customer.isLoading ||
    me.isLoading
  ) {
    return <Loading />;
  }
  if (
    !stocktakingInvoice.isLoading &&
    stocktakingInvoice.isCompleted &&
    !product.isLoading &&
    product.isCompleted &&
    !customer.isLoading &&
    customer.isCompleted &&
    !me.isLoading &&
    me.isCompleted
  ) {
    return (
      <div>
        {renderTitle()}
        {renderModalSelectPallet()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <CreateCustomer
          openAddCustomer={openAddCustomer}
          handleCloseAddCustomer={handleCloseAddCustomer}
          dialogCustomer={dialogCustomer}
          setCustomerData={setCustomerData}
          setDialogCustomer={setDialogCustomer}
        />
        <CreateProduct
          openAddProduct={openAddProduct}
          handleCloseAddProduct={handleCloseAddProduct}
          dialogProduct={dialogProduct}
          setProductData={setProductData}
          setDialogProduct={setDialogProduct}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="m-1 flex justify-end">
            {/* {renderAddButton()} */}
            <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
              บันทึก
            </Button>
          </div>
          <div className="flex flex-wrap gap-y-2">
            <div className="w-full px-1 min-h-full">
              <Card className="p-4 ">
                <div className="flex flex-wrap">
                  <div className="w-full">{rendInfomationTextField()}</div>
                </div>
              </Card>
            </div>
          </div>
          {customerData && (
            <div className="flex flex-wrap my-2">
              <div className="w-full text-center underline text-xl my-4">
                เลือกล็อตของสินค้า
              </div>
              <Card className="w-full mx-1">
                <div className="w-full  px-1 min-h-full">
                  <div className="flex flex-wrap">
                    <div className="w-full flex rounded-lg px-2 my-2">
                      <div className="w-1/2 px-1 py-2">
                        <Autocomplete
                          size={'small'}
                          onChange={(e, newValue) => {
                            setSelectProductStockLot(newValue);
                          }}
                          options={NewProductStockLot}
                          getOptionLabel={(option) =>
                            `${option?.product_type?.tag || option?.tag} : ${
                              option?.product_type?.name
                            } ( ก้อนละ ${option?.weight} kg )`
                          }
                          renderOption={(props, option) => (
                            <li {...props} key={option.index}>
                              ( {option?.index} ) {option?.product_type?.tag} :{' '}
                              {option?.product_type?.name} ( คงเหลือ{' '}
                              {option?.product_in_pallet_inventory} kg )
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} label="เลือกล็อตสินค้า" />
                          )}
                        />
                      </div>
                      <div className="w-1/2 px-1 py-2 my-auto text-center">
                        {selectProductStockLot && (
                          <>
                            <div>
                              จากใบรับฝาก{' '}
                              <a className="text-red-600">
                                {
                                  selectProductStockLot?.consignment_invoice
                                    ?.name
                                }{' '}
                              </a>
                            </div>
                            <div>
                              คงเหลือในคลัง{' '}
                              <a className="text-red-600">
                                {
                                  selectProductStockLot?.product_in_pallet_inventory
                                }{' '}
                              </a>
                              กิโลกรัม หรือ{' '}
                              <a className="text-red-600">
                                {selectProductStockLot?.product_in_pallet_inventory /
                                  selectProductStockLot?.weight}{' '}
                              </a>
                              {selectProductStockLot?.product_type?.unit}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    {selectProductStockLot && (
                      <div className="w-full rounded-md border-2 mb-2 pt-2">
                        <div className="text-center underline text-lg">
                          เลือกพาเลทที่ต้องการเบิก
                        </div>
                        <div className="grid grid-cols-3 gap-2 pb-2">
                          {_.map(
                            selectProductStockLot?.product_in_pallet,
                            (eachPallet, index) => (
                              <div
                                key={index}
                                onClick={() =>
                                  handleOpenModalSelectPallet(eachPallet)
                                }
                                className="m-2 py-2 cursor-pointer  border-1 bg-gray-200 rounded-md "
                              >
                                <div className="text-center">
                                  {eachPallet?.name ||
                                    eachPallet?.prefix +
                                      eachPallet?.running_number}
                                </div>
                              </div>
                            ),
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Card>
            </div>
          )}
        </form>

        {customerData && (
          <div className="flex flex-wrap my-2">
            <Card className="w-full mx-1">
              <div className="w-full">{renderTable()}</div>
            </Card>
          </div>
        )}
      </div>
    );
  }
  return <Error />;
}

CreateStocktakingInvoice.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateStocktakingInvoice.defaultProps = {
  title: '',
  subtitle: '',
};

export default CreateStocktakingInvoice;
