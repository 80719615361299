import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import {
  Card,
  TextField,
  Autocomplete,
  Collapse,
  IconButton,
  CardActionArea,
  Tooltip,
  Button,
  Fade,
  Modal,
  Backdrop,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import CloseIcon from '@mui/icons-material/Close';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';

// eslint-disable-next-line import/no-duplicates
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { PalletForm } from '../../components/Forms';

import * as constants from '../../utils/constants';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

const defaultValues = {
  name: '',
  pallet_type: {},
};

const defaultValuesProduct = {
  quantity: '',
};

export default function CreateProductTransactionInvoice({ title, subtitle }) {
  const dispatch = useDispatch();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const consignmentInvoice = useSelector((state) => state.consignmentInvoice);
  const productStockLot = useSelector((state) => state.productStockLot);
  const palletType = useSelector((state) => state.palletType);
  const pallet = useSelector((state) => state.pallet);
  const customer = useSelector((state) => state.customer);

  const NewCustomer = _.map(customer?.rows, (row, index) => ({
    index: index + 1,
    ...row,
  }));

  const NewConsignmentInvoice = _.map(
    consignmentInvoice?.rows,
    (row, index) => ({
      index: index + 1,
      ...row,
    }),
  );

  // console.log('NewCustomer', NewCustomer);

  const [selected, setSelected] = useState();
  const [inputValue, setInputValue] = useState('');
  const filterProduct = selected?.id;
  const [reload, setReload] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openModalAddProduct, setOpenModalAddProduct] = useState(false);
  const [palletAndProduct, setPalletAndProduct] = useState();
  const [selectStatus, setSelectStatus] = useState('');

  const handleChangeStatus = (event) => {
    setSelectStatus(event.target.value);
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleOpenModalAddProduct = () => setOpenModalAddProduct(true);
  const handleCloseModalAddProduct = () => setOpenModalAddProduct(false);

  // console.log('palletType ', palletType?.rows);
  console.log('palletAndProduct', palletAndProduct);
  console.log('selectedProduct', selectedProduct);
  // const history = useHistory();

  useEffect(() => {
    dispatch(actions.productStockLotAll({ selectStatus, filterProduct }));
    dispatch(actions.palletTypeAll({}));
    dispatch(actions.palletAll({}));
    dispatch(actions.consignmentInvoiceAll({}));
    dispatch(actions.customerAll({}));
    setReload(true);
    return () => {};
  }, [selected, reload, selectStatus]);

  const newProductStockLot = _.filter(
    productStockLot?.rows,
    // eslint-disable-next-line consistent-return
    (eachProductStockLot) => {
      if (eachProductStockLot?.status !== 'PACKED_SUCCESS_STATUS') {
        return eachProductStockLot;
      }
    },
  );

  const newPallet = _.filter(
    pallet?.rows,
    // eslint-disable-next-line consistent-return
    (eachPallet) => {
      if (eachPallet?.used_capacity !== eachPallet?.pallet_type?.capacity) {
        return eachPallet;
      }
    },
  );

  const handleSelect = (newValue) => {
    setSelected(newValue);
    setOpenCollapse(false);
    setReload(false);
  };

  const checkFreeSpace = (data) => {
    if (palletAndProduct) {
      const calculate = parseInt(
        (data?.palletData?.pallet_type?.capacity -
          data?.palletData?.used_capacity) /
          data?.productData?.weight,
        10,
      );
      return calculate;
    }
    return 0;
  };

  const handleClickOpen = (product) => {
    if (product?.status === 'PACKED_SUCCESS_STATUS') {
      alert('สินค้าลงพาเลทเรียบร้อยเเล้ว');
    } else {
      setSelectedProduct(product);
      if (!openCollapse || selectedProduct?.id === product?.id) {
        setOpenCollapse(!openCollapse);
      }
    }
  };

  const handleDeletePallet = async (data) => {
    if (data?.used_capacity !== 0) {
      alert('ไม่สามารถลบพาเลทได้ เนื่องจากมีสินค้าอยู่');
    } else {
      const confirm = window.confirm('ยืนยันการลบพาเลท');
      if (confirm) {
        try {
          await dispatch(actions.palletDelete(data?.id));
          await dispatch(actions.palletAll({}));
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  const AddProductToPallet = (palletData, productData) => {
    // console.log('pallet on add product', palletData);
    // console.log('product on add product', productData);
    const data = { palletData, productData };
    setPalletAndProduct(data);
    if (palletData?.pallet_type?.capacity === palletData?.used_capacity) {
      alert('พาเลทถูกบรรจุเต็มเเล้ว');
    } else {
      handleOpenModalAddProduct();
    }
  };

  const onSubmitAddProduct = async (data) => {
    if (
      data.quantity >
      selectedProduct?.quantity -
        selectedProduct?.product_in_pallet_inventory / selectedProduct?.weight
    ) {
      alert('มีสินค้าไม่พอ');
    } else if (
      parseInt(data?.quantity, 10) > checkFreeSpace(palletAndProduct)
    ) {
      alert('มีพื้นที่ในพาเลทไม่พอ');
    } else {
      try {
        const payload = {
          products: {
            product: selectedProduct?.id,
            inventory: parseInt(data?.quantity, 10) * selectedProduct?.weight,
          },
        };
        console.log('payload on submit', payload);
        reset(defaultValuesProduct);
        handleCloseModalAddProduct();
        await dispatch(
          actions.palletPut(palletAndProduct?.palletData?.id, payload),
        );
        await dispatch(actions.palletAll({}));
        await dispatch(actions.productStockLotAll({}));
        // setSelectedProduct(undefined);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const onSubmitCreatePallet = async (data, event) => {
    try {
      const payload = {
        name: data.name,
        pallet_type: data.pallet_type.id,
      };
      console.log('payload on submit', payload);
      event.preventDefault();
      reset(defaultValues);
      // alert('สำเร็จ');
      handleCloseModal();
      await dispatch(actions.palletCreate(payload));
      await dispatch(actions.palletAll({}));
    } catch (error) {
      console.error(error);
    }
  };

  const renderModalAddProductToPallet = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModalAddProduct}
      onClose={handleCloseModalAddProduct}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 100,
      }}
    >
      <Fade in={openModalAddProduct}>
        <Card sx={style} className="max-w-xl">
          <div className="py-2 text-lg font-semibold">
            เพิ่มสินค้าลงพาเลท{' '}
            {palletAndProduct?.palletData?.name ||
              palletAndProduct?.palletData?.name_running}
          </div>
          <div className="py-2">
            <form onSubmit={handleSubmit(onSubmitAddProduct)}>
              <div className="flex flex-row flex-wrap">
                <div className="w-full px-1 py-2">
                  {/* เหลือสินค้าที่ยังไม่ลงพาเลทอีก{' '}
                  {selectedProduct?.quantity -
                    selectedProduct?.product_in_pallet_inventory /
                      (selectedProduct?.product_type?.weight ||
                        selectedProduct?.weight)}{' '}
                  {selectedProduct?.product_type?.unit} */}
                  <small className="text-red-600">
                    ( เหลือที่ว่างอีก {checkFreeSpace(palletAndProduct)}{' '}
                    {selectedProduct?.product_type?.unit} )
                  </small>
                </div>
                <div className="w-full px-1 py-2">
                  <Controller
                    name={'quantity'}
                    control={control}
                    defaultValue={''}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="จำนวนสินค้า"
                        fullWidth
                        required
                        type="number"
                        size={'small'}
                        helperText={errors.name && 'กรุณาใส่ข้อมูล'}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button variant="contained" type="submit">
                  ยืนยัน
                </Button>
                <Button variant="outlined" onClick={handleCloseModalAddProduct}>
                  ยกเลิก
                </Button>
              </div>
            </form>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModal}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 100,
      }}
    >
      <Fade in={openModal}>
        <Card sx={style} className="max-w-4xl">
          <div className="py-2">เพิ่มข้อมูล</div>
          <div className="py-2">
            <form onSubmit={handleSubmit(onSubmitCreatePallet)}>
              <PalletForm
                control={control}
                Controller={Controller}
                errors={errors}
                palletType={palletType?.rows}
              />
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<SaveIcon />}
                >
                  บันทึก
                </Button>
                <Button variant="outlined" onClick={handleCloseModal}>
                  ยกเลิก
                </Button>
              </div>
            </form>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const managePallet = () => (
    <div className="col-span-1 md:col-span-2 lg:col-span-2 xl:col-span-3">
      <Collapse in={openCollapse} timeout="auto" unmountOnExit>
        <Card
          className=""
          style={{
            backgroundColor: '#ffffff',
            border: 'solid',
          }}
        >
          <div className="flex justify-between">
            <div className="w-full p-4  text-lg font-semibold ">
              <p>
                จัดสินค้าลงพาเลท ( {selectedProduct?.product_type?.name}{' '}
                {selectedProduct?.consignment_invoice?.name} )
              </p>
              <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-3 gap-1 overflow-y-auto max-h-96">
                {_.map(newPallet, (eachPallet, index) => (
                  <div className="pr-2 pt-2" key={index}>
                    <Card
                      className="cursor-pointer"
                      style={{
                        backgroundColor: '#e8e8e8',
                        border: '',
                      }}
                    >
                      <div className="w-full text-base flex justify-between">
                        <CardActionArea>
                          <div className="p-4">
                            <div
                              onClick={() =>
                                // eslint-disable-next-line implicit-arrow-linebreak
                                AddProductToPallet(eachPallet, selectedProduct)
                              }
                            >
                              <div className="font-semibold flex flex-wrap">
                                <div>
                                  {eachPallet?.name ||
                                    eachPallet?.prefix +
                                      eachPallet?.running_number}{' '}
                                  ( {eachPallet?.pallet_type?.name} ){' '}
                                </div>
                                {eachPallet?.used_capacity ===
                                eachPallet?.pallet_type?.capacity ? (
                                  <p className="text-red-500 pl-2">เต็มเเล้ว</p>
                                ) : (
                                  <p className="text-green-500 pl-2">
                                    ยังไม่เต็ม
                                  </p>
                                )}
                              </div>
                              <div>
                                ความจุที่ใช้ไป {eachPallet?.used_capacity}{' '}
                                {eachPallet?.pallet_type?.capacity_unit}
                              </div>
                              <div>
                                ความจุสูงสุด {eachPallet?.pallet_type?.capacity}{' '}
                                {eachPallet?.pallet_type?.capacity_unit}
                              </div>
                            </div>

                            <div className="absolute top-1 right-1">
                              <IconButton
                                onClick={() => handleDeletePallet(eachPallet)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          </div>
                        </CardActionArea>
                        {/* <div className="mt-1">
                          <IconButton
                          // onClick={() => handleDeletePallet(eachPallet)}
                          >
                            <EditIcon />
                          </IconButton>
                        </div>` */}
                      </div>
                    </Card>
                  </div>
                ))}

                <div>
                  <Tooltip title="สร้างพาเลทใหม่">
                    <IconButton
                      aria-label="delete"
                      size="large"
                      onClick={handleOpenModal}
                    >
                      <AddBoxRoundedIcon
                        color="primary"
                        sx={{ fontSize: 80 }}
                      />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="mt-1 mr-1">
              <IconButton
                aria-label="delete"
                size="large"
                onClick={() => setOpenCollapse(false)}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </Card>
      </Collapse>
    </div>
  );

  const checkStatusProduct = (status) => {
    if (status === constants.TRANSACTION_STATUS.UNPACKED_STATUS.status_code) {
      return (
        <p className="text-red-500">
          {constants.TRANSACTION_STATUS.UNPACKED_STATUS.description}
        </p>
      );
    }
    if (
      status ===
      constants.TRANSACTION_STATUS.PACKED_NOT_SUCCESS_STATUS.status_code
    ) {
      return (
        <p className="text-yellow-500">
          {constants.TRANSACTION_STATUS.PACKED_NOT_SUCCESS_STATUS.description}
        </p>
      );
    }
    return (
      <p className="text-green-500">
        {constants.TRANSACTION_STATUS.PACKED_SUCCESS_STATUS.description}
      </p>
    );
  };

  console.log('selected', selected);

  const renderHead = () => (
    <div className="">
      <Card className="p-4">
        <div className="md:flex md:justify-between">
          <div className="w-full md:w-1/2">
            <div className="w-full py-4 text-lg font-semibold ">
              หมายเลขใบรับฝาก {selected?.name || 'ทั้งหมด'}
            </div>
          </div>
          <div className="w-full md:w-1/2 my-auto flex md:justify-end gap-1">
            <div className="w-full md:w-1/3 ">
              <FormControl fullWidth>
                <InputLabel id="type">สถานะสินค้า</InputLabel>
                <Select
                  label="ประเภทสินค้า"
                  value={selectStatus}
                  onChange={handleChangeStatus}
                >
                  <MenuItem value={''}>แสดงทั้งหมด</MenuItem>
                  <MenuItem value={'PACKED_NOT_SUCCESS_STATUS'}>
                    ลงพาเลทยังไม่ครบ
                  </MenuItem>
                  <MenuItem value={'UNPACKED_STATUS'}>ยังไม่ลงพาเลท</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="w-full md:w-2/3">
              <Autocomplete
                value={selected}
                onChange={(event, newValue) => {
                  handleSelect(newValue);
                }}
                getOptionLabel={(type) =>
                  `${type?.index} : ${type?.name} ( ${type?.customer?.name} )`
                }
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                id="controllable-states-demo"
                options={NewConsignmentInvoice}
                renderInput={(params) => (
                  <TextField {...params} label="เลือกใบรับฝาก or ลูกค้า" />
                )}
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (productStockLot.isLoading || !productStockLot.rows) {
    return <Loading />;
  }
  if (!productStockLot.isLoading && productStockLot.isCompleted) {
    return (
      <>
        {renderModal()}
        {renderModalAddProductToPallet()}
        <div className="flex justify-between">{renderTitle()}</div>
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
          <div className="text-lg font-semibold ml-6">ข้อมูลสินค้า</div>
        </div>
        {renderHead()}
        {_.isEmpty(newProductStockLot) && (
          <div className="w-full text-2xl text-center mt-10">
            สินค้าลงพาเลทครบหมดเเล้ว
          </div>
        )}
        <div className="w-full grid grid-cols-1 md:grid-cols-2  lg:grid-cols-2 xl:grid-cols-3 gap-2 pt-2">
          {managePallet()}
          {_.map(newProductStockLot, (row, index) => (
            <>
              <div key={index} className="">
                <Card
                  className="cursor-pointer"
                  onClick={() => handleClickOpen(row)}
                  style={{
                    backgroundColor: `${
                      selectedProduct?.id === row?.id && openCollapse
                        ? '#bfe6ac'
                        : '#ffffff'
                    }`,
                  }}
                >
                  <CardActionArea>
                    <div className="w-full flex justify-between">
                      <div>
                        <p className="pt-4 pl-4 text-base">
                          {row?.consignment_invoice?.name}
                        </p>
                        <p className="pl-4 text-base font-semibold">
                          {row?.product_type?.name}
                        </p>
                        <p className="pl-4 text-base font-semibold">
                          ( {row?.tag} )
                        </p>
                        <p className="pl-4 text-lg">
                          {row?.quantity} x {row?.weight}
                        </p>
                      </div>
                      <div className="pt-4 pr-2 text-center">
                        <p>{checkStatusProduct(row?.status)}</p>
                        {row?.status ===
                          constants.TRANSACTION_STATUS.PACKED_NOT_SUCCESS_STATUS
                            .status_code && (
                          <>
                            <p>
                              ลงเเล้ว{' '}
                              {parseInt(row?.product_in_pallet_inventory, 10) /
                                parseInt(row?.weight, 10)}{' '}
                              {row?.product_type?.unit}
                            </p>
                            <p>
                              เหลืออีก{' '}
                              {parseInt(row?.quantity, 10) -
                                parseInt(row?.product_in_pallet_inventory, 10) /
                                  parseInt(row?.weight, 10)}{' '}
                              {row?.product_type?.unit}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="flex justify-end p-1 px-2 text-gray-500">
                      <small>คลิกเพื่อเลือกสินค้าลงพาเลท</small>
                    </div>
                  </CardActionArea>
                </Card>
              </div>
            </>
          ))}
        </div>
      </>
    );
  }
  return <Error />;
}

CreateProductTransactionInvoice.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateProductTransactionInvoice.defaultProps = {
  title: '',
  subtitle: '',
};
