import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { Provider } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import Home from 'views/Home';
import { ThemeProvider } from '@mui/material/styles';
import configureStore from './redux/configureStore';
import {
  Auth,
  CRM,
  HRMS,
  IMS,
  WMS,
  MMS,
  SPM,
  Landing,
  CMS,
  Profile,
  Dashboard,
  DOC,
  Setting,
} from './layouts';
import PrivateRoute from './contexts/PrivateRoute';
import AuthProvider from './contexts/AuthContext';
import theme from './theme';

import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/styles/index.css';

const store = configureStore();
if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
}

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <div className="bg-gray-100">
            <Switch>
              <Route path="/auth" component={Auth} />
              <PrivateRoute>
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route path="/auth" component={Auth} />
                  <Route path="/crm" component={CRM} />
                  <Route path="/hrms" component={HRMS} />
                  <Route path="/ims" component={IMS} />
                  <Route path="/wms" component={WMS} />
                  <Route path="/cms" component={CMS} />
                  <Route path="/mms" component={MMS} />
                  <Route path="/profile" component={Profile} />
                  <Route path="/spm" component={SPM} />
                  <Route path="/doc" component={DOC} />
                  <Route path="/dashboard" component={Dashboard} />
                  <Route path="/setting" component={Setting} />
                  <Route path="*" component={Landing} />
                </Switch>
              </PrivateRoute>
              <Redirect from="*" to="/auth" />
            </Switch>
          </div>
        </ThemeProvider>
      </AuthProvider>
    </Provider>
  </Router>,
  document.getElementById('root'),
);
