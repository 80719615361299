import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardContent, TextField, Snackbar } from '@mui/material';
import PropTypes from 'prop-types';

import { useForm, Controller } from 'react-hook-form';

import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { ViewTitle } from '../../components/ViewTitle';
import api from '../../utils/functions/api';

export default function PalletMove({ title, subtitle }) {
  const dispatch = useDispatch();
  const { control, handleSubmit, setValue, reset } = useForm();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };
  const pallet = useSelector((state) => state.pallet);
  const me = useSelector((state) => state.me);

  const [isAllowScanPallet, setIsAllowScanPallet] = useState(true);

  const handleGetPallet = async (data) => {
    try {
      await dispatch(actions.palletGetByPalletNumber(data.pallet));
      setIsAllowScanPallet(false);
    } catch (error) {
      alert(`ไม่พบพาเลทที่ต้องการ ${error?.response?.data?.error?.message}`);
      reset({ pallet: '' });
    }
  };

  const handleMovePallet = async (data) => {
    const selectedPallet = pallet?._id;
    try {
      const { data: responseData } = await api.get(
        `${process.env.REACT_APP_API_URL}/position-simple/${data?.position}`,
      );

      if (!responseData) {
        throw new Error('ไม่พบตำแหน่งปลายทาง');
      }
      console.log('Response', responseData);
      const payload = {
        update_type: 'movePallet',
        pallet_id: selectedPallet,
        new_position_id: responseData?._id,
        old_position_id: pallet?.position?.id,
        new_row: responseData?.row,
        old_row: pallet?.row,
        new_warehouse_id: responseData?.warehouse_id?._id,
        old_warehouse_id: pallet?.warehouse?.id,
        employee_id: me?.userData?.id,
      };

      console.log('payload on move pallet', payload);

      await dispatch(actions.palletPut(selectedPallet, payload));

      dispatch(actions.palletReset());
      setIsAllowScanPallet(true);
      setSnackbarOpen(true);
      reset({ position: '', pallet: '' });
    } catch (error) {
      alert(`ไม่สามารถย้ายพาเลทได้ ${error?.message}`);
      await dispatch(actions.palletGet(selectedPallet));
      reset({ position: '', pallet: '' });
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  return (
    <div>
      {renderTitle()}
      <div className="flex flex-row justify-start pb-4">
        <div>
          <BackButton />
        </div>
      </div>
      <div>
        {' '}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleClose}
          message="ย้ายพาเลทสำเร็จ"
          severity="success"
          vertical="top"
          horizontal="center"
        />
        {isAllowScanPallet ? (
          <Card>
            <CardContent>
              <div className="justify-center text-xl text-center">
                สแกนพาเลทที่ต้องการย้าย
              </div>
              <form onSubmit={handleSubmit(handleGetPallet)}>
                <div className="w-full my-2">
                  <Controller
                    control={control}
                    name="pallet"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="medium"
                        fullWidth
                        autoFocus
                        label="รหัสพาเลท"
                      />
                    )}
                  />
                </div>
              </form>
            </CardContent>
          </Card>
        ) : (
          <Card>
            <CardContent>
              {' '}
              <div className="text-2xl text-center text-red-500">ต้นทาง </div>
              <div className="text-2xl text-center">
                พาเลทเลขที่ {pallet?.running_number}
              </div>
              <div className="text-2xl text-center">
                แถวที่ {pallet?.position?.row || '-'} คลัง{' '}
                {pallet?.warehouse?.name || '-'}
              </div>
              <div className="flex justify-end">
                <Button
                  size="large"
                  onClick={() => {
                    dispatch(actions.palletReset());
                    setValue('pallet', '');
                    setIsAllowScanPallet(true);
                  }}
                >
                  เลือกพาเลทอื่น
                </Button>
              </div>
            </CardContent>
          </Card>
        )}
        {!isAllowScanPallet && !pallet?.warehouse_status && (
          <div className="my-2">
            <Card>
              <CardContent>
                <div className="text-center">พาเลทยังไม่เข้าคลัง</div>
              </CardContent>
            </Card>
          </div>
        )}
        {!isAllowScanPallet && pallet?.warehouse_status && (
          <div className="my-2">
            <Card>
              <CardContent>
                <div className="justify-center text-xl text-center">
                  สแกนตำแหน่งที่ต้องการนำพาเลทไปลง
                </div>
                <form onSubmit={handleSubmit(handleMovePallet)}>
                  <div className="w-full my-2">
                    <Controller
                      control={control}
                      name="position"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="medium"
                          fullWidth
                          autoFocus
                          label="รหัสตำแหน่ง"
                        />
                      )}
                    />
                  </div>
                </form>
              </CardContent>
            </Card>
          </div>
        )}
      </div>
    </div>
  );
}

PalletMove.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

PalletMove.defaultProps = {
  title: '',
  subtitle: '',
};
