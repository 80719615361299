import {
  CONSIGNMENT_INVOICE_ALL,
  CONSIGNMENT_INVOICE_GET,
  CONSIGNMENT_INVOICE_DEL,
  CONSIGNMENT_INVOICE_PUT,
  CONSIGNMENT_INVOICE_POST,
  CONSIGNMENT_INVOICE_LOADING,
  CONSIGNMENT_INVOICE_ERROR,
  CONSIGNMENT_INVOICE_RESET,
} from '../types';

import api from '../../../utils/functions/api';

export const consignmentInvoiceCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: CONSIGNMENT_INVOICE_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/consignment-invoice`,
      payload,
    );
    dispatch({ type: CONSIGNMENT_INVOICE_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: CONSIGNMENT_INVOICE_ERROR });
    throw new Error(error);
  }
};

export const consignmentInvoiceAll = (params) => async (dispatch) => {
  try {
    const {
      name = '',
      size = '',
      page = 1,
      startDate = '',
      endDate = '',
      selectDate = '',
      orderByField = 'deposit_start',
      orderBy = 'asc',
      selectProduct = '',
      selectYear = '',
      selectEndYear = '',
    } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/consignment-invoice?name=${name}&size=${size}&page=${page}&startDate=${startDate}&endDate=${endDate}&selectDate=${selectDate}&orderByField=${orderByField}&orderBy=${orderBy}&selectProduct=${selectProduct}&selectYear=${selectYear}&selectEndYear=${selectEndYear}`,
    );
    if (status === 200) {
      dispatch({ type: CONSIGNMENT_INVOICE_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: CONSIGNMENT_INVOICE_ERROR });
    throw new Error(error);
  }
};

export const consignmentInvoiceGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/consignment-invoice/${id}`,
    );
    if (status === 200) {
      dispatch({ type: CONSIGNMENT_INVOICE_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: CONSIGNMENT_INVOICE_ERROR });
    throw new Error(error);
  }
};

export const consignmentInvoicePut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: CONSIGNMENT_INVOICE_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/consignment-invoice/${id}`,
      payload,
    );
    dispatch({ type: CONSIGNMENT_INVOICE_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: CONSIGNMENT_INVOICE_ERROR });
    throw new Error(error);
  }
};
export const consignmentInvoiceDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: CONSIGNMENT_INVOICE_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/consignment-invoice/${id}`,
    );
    dispatch({ type: CONSIGNMENT_INVOICE_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: CONSIGNMENT_INVOICE_ERROR });
    throw new Error(error);
  }
};

export const consignmentInvoiceReset = () => async (dispatch) => {
  try {
    dispatch({ type: CONSIGNMENT_INVOICE_RESET });
  } catch (error) {
    console.error(error);
    dispatch({ type: CONSIGNMENT_INVOICE_ERROR });
    throw new Error(error);
  }
};
