import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

import accessRight from '../../utils/functions/accessRight';
import { NotAuthorized } from '../../components/Error';
import { CustomerTypes, EditCustomerType } from '../../views/CRM';

export function SettingSPM() {
  const module = 'CRM';
  const prefix = '/setting/crm';
  const name = 'ตั้งค่าระบบ';
  const me = useSelector((state) => state.me);
  const availableModule = useSelector((state) => state.availableModule);

  if (accessRight(me, module, 1, availableModule)) {
    return (
      <div>
        <Route exact path={`${prefix}/customer-types`}>
          <CustomerTypes title="จัดการประเภทลูกค้า" subtitle={name} />
        </Route>
        <Route exact path={`${prefix}/customer-type/edit/:id`}>
          <EditCustomerType title="แก้ไขประเภทลูกค้า" subtitle={name} />
        </Route>
      </div>
    );
  }
  return (
    <div>
      <Route path="*">
        <NotAuthorized />
      </Route>
    </div>
  );
}

export default SettingSPM;
