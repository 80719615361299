/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

// eslint-disable-next-line object-curly-newline
import {
  Backdrop,
  Button,
  Card,
  Fade,
  Modal,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
} from '@mui/material';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import AddBoxIcon from '@mui/icons-material/AddBox';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import { styled } from '@mui/material/styles';

import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

import * as constants from '../../utils/constants';

import { Link } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

const MainProductStock = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const product = useSelector((state) => state.product);
  const productType = useSelector((state) => state.productType);
  const productStockLot = useSelector((state) => state.productStockLot);

  const [selectProduct, setSelectProduct] = useState('');
  const [filterProduct, setFilterProduct] = useState(true);
  const [openModalPosition, setOpenModalPosition] = useState(false);
  const [selectProductStockLot, setSelectProductStockLot] = useState();
  const [selectArrayPosition, setSelectArrayPosition] = useState([]);

  console.log('selectArrayPosition', selectArrayPosition);
  console.log('selectProductStockLot', selectProductStockLot);

  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);
  const [total, setTotal] = useState(undefined);

  const newProduct = _.map(
    product?.rows,
    (item) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      _.merge(
        { ...item, array_product_stock_lot: [] },
        {
          array_product_stock_lot: _.filter(
            productStockLot?.rows,
            (each) => item?.id === each?.product_type?._id,
          ),
        },
      ),
    // eslint-disable-next-line function-paren-newline
  );

  console.log('newProduct', newProduct);

  const NewProductStockLot = _.filter(productStockLot?.rows, (e) => {
    if (!_.isEmpty(e?.product_in_pallet)) {
      return e;
    }
  });

  console.log('NewProductStockLot', NewProductStockLot);

  const handleOpenModalPosition = () => setOpenModalPosition(true);
  const handleCloseModalPosition = () => setOpenModalPosition(false);

  useEffect(() => {
    dispatch(
      actions.productAll({ name, page, size, selectProduct, filterProduct }),
    );
    dispatch(actions.productStockLotAll({}));
    dispatch(actions.productTypeAll(''));
    dispatch(actions.meGet());
    return () => {};
  }, [name, page, size, selectProduct, filterProduct]);

  useEffect(() => {
    setTotal(product?.total);
    return () => {};
  }, [product]);

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeType = (event) => {
    console.log('type product', event.target);
    setSelectProduct(event.target.value);
  };

  const checkStatusProduct = (status) => {
    if (status === constants.TRANSACTION_STATUS.UNPACKED_STATUS.status_code) {
      return (
        <p className="text-red-500">
          {constants.TRANSACTION_STATUS.UNPACKED_STATUS.description}
        </p>
      );
    }
    if (
      status ===
      constants.TRANSACTION_STATUS.PACKED_NOT_SUCCESS_STATUS.status_code
    ) {
      return (
        <p className="text-yellow-500">
          {constants.TRANSACTION_STATUS.PACKED_NOT_SUCCESS_STATUS.description}
        </p>
      );
    }
    return (
      <p className="text-green-500">
        {constants.TRANSACTION_STATUS.PACKED_SUCCESS_STATUS.description}
      </p>
    );
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderTablePosition = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow className="bg-green-100">
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">รหัสสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อสินค้า/เลขใบรับฝาก</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> จำนวน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> น้ำหนักรวม</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> สถานะ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> วันที่รับสินค้าเข้า</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(selectArrayPosition) ? (
                selectArrayPosition.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>
                      <div>{`${row?.product_type?.tag || row?.tag}`}</div>
                      <div>( {row?.consignment_invoice?.customer?.name} )</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">{`${row?.product_type?.name}`}</div>
                      <div>{`( ${row?.consignment_invoice?.name} )`}</div>
                    </TableCell>
                    <TableCell>
                      <a className="font-bold">{`${row?.quantity} `}</a>
                      <a className="font-bold">x</a>
                      <a className="font-bold">{` ${
                        row?.weight || row?.product_type?.weight
                      }`}</a>
                      {/* <a>{` ${row?.product_type?.unit}`}</a> */}
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">
                        {row?.total_weight} กิโลกรัม
                      </div>
                      <div>{row?.scrap && '( เศษ )'}</div>
                    </TableCell>
                    <TableCell>
                      <p>{checkStatusProduct(row?.status)}</p>
                      <div className="max-h-40 overflow-y-auto">
                        {_.map(row?.product_in_pallet, (eachPallet) => (
                          <ul className="list-disc mt-1">
                            <Link
                              to={`/doc/pallet-transaction/${eachPallet?._id}`}
                            >
                              <li className="cursor-pointer hover:underline flex">
                                <p className="pr-1">
                                  {eachPallet?.name ||
                                    eachPallet?.prefix +
                                      eachPallet?.running_number}
                                </p>
                                <p>({eachPallet?.used_capacity} กิโลกรัม)</p>
                              </li>
                            </Link>
                          </ul>
                        ))}
                      </div>
                    </TableCell>
                    <TableCell>{`${dayjs(row?.createdAt).format(
                      'D MMM BBBB HH:mm',
                    )}`}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );

  const renderModalPosition = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModalPosition}
      onClose={handleCloseModalPosition}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModalPosition}>
        <Card sx={style} className="max-w-5xl my-4">
          <div className=" max-h-screen overflow-y-auto">
            <div className="py-2 text-lg">
              ตำแหน่งของ {selectProductStockLot?.name} ({' '}
              {selectProductStockLot?.tag} )
            </div>
            {renderTablePosition()}
            <div className="flex flex-row justify-end gap-1 py-4">
              <Button variant="outlined" onClick={handleCloseModalPosition}>
                ยกเลิก
              </Button>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 md:flex md:flex-row gap-2">
        <div className="w-full md:w-1/2 pt-2 md:pt-1">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full md:w-1/2 pt-2 md:pt-1">
          <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
            <InputLabel id="type" size={'small'}>
              ประเภทสินค้า
            </InputLabel>
            <Select
              label="ประเภทสินค้า"
              size={'small'}
              value={selectProduct}
              onChange={handleChangeType}
              fullWidth
            >
              <MenuItem value={''}>แสดงทั้งหมด</MenuItem>
              {_.size(productType.rows) ? (
                _.map(productType.rows, (row) => (
                  <MenuItem key={row.id} value={row.name}>
                    {row.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem>
                  <MenuItem disabled value="">
                    <em>ไม่มีข้อมูล</em>
                  </MenuItem>
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-2 ">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ชื่อสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">รหัสสินค้า</div>
                </TableCell>
                {/* <TableCell>
                  <div className="font-bold flex justify-end">ยอดรับ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold flex justify-end">ยอดจ่าย</div>
                </TableCell> */}
                <TableCell>
                  <div className="font-bold flex justify-end">
                    คงเหลือ (ก้อน)
                  </div>
                </TableCell>
                <TableCell>
                  <div className="font-bold flex justify-end">
                    น้ำหนัก (กิโลกรัม)
                  </div>
                </TableCell>
                <TableCell>
                  <div className="font-bold text-center">คำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(newProduct) ? (
                newProduct.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>

                    <TableCell>
                      <div className="font-bold text-lg">{`${row?.name}`}</div>
                      <div className="">{`( ${row?.type?.name} )`}</div>
                    </TableCell>

                    <TableCell>
                      {row?.weight_self ? (
                        <div className="font-bold">
                          {_.map(
                            row?.array_product_stock_lot,
                            (each, index) => (
                              <div key={index}>{each?.tag}</div>
                            ),
                          )}
                        </div>
                      ) : (
                        <div className="font-bold">{row?.tag}</div>
                      )}
                    </TableCell>

                    {/* <TableCell>
                      <div className="font-bold"></div>
                    </TableCell>

                    <TableCell>
                      <div></div>
                    </TableCell> */}

                    <TableCell>
                      {row?.weight_self ? (
                        <div className="font-bold">
                          {_.map(
                            row?.array_product_stock_lot,
                            (each, index) => (
                              <div
                                key={index}
                                className="flex justify-end font-bold"
                              >
                                {each?.quantity}
                              </div>
                            ),
                          )}
                        </div>
                      ) : (
                        <div className="flex justify-end font-bold">
                          {row?.product_in_warehouse}
                        </div>
                      )}
                    </TableCell>

                    <TableCell>
                      {row?.weight ? (
                        <div className="flex justify-end font-bold">
                          {(row?.product_in_warehouse * row?.weight)
                            .toFixed(1)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </div>
                      ) : (
                        <div className="flex justify-end">
                          <div className="font-bold">
                            {_.map(
                              row?.array_product_stock_lot,
                              (each, index) => (
                                <div key={index}>
                                  {' '}
                                  {(each?.quantity * each?.weight)
                                    .toFixed(1)
                                    .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                </div>
                              ),
                            )}
                          </div>
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      <div>
                        <Button
                          variant="contained"
                          color={'info'}
                          size={'small'}
                          fullWidth
                          startIcon={<DescriptionIcon />}
                          onClick={() => {
                            setSelectProductStockLot(row);
                            setSelectArrayPosition(
                              row?.array_product_stock_lot,
                            );
                            handleOpenModalPosition();
                          }}
                        >
                          ดูตำแหน่ง
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูลที่ต้องการ</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (me.isLoading && product?.isLoading) {
    return <Loading />;
  }
  if (!me.isLoading && me.isCompleted) {
    return (
      <div>
        {renderModalPosition()}
        {/* <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={!loading}
          onClose={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop> */}
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error message={me?.message} />;
};

MainProductStock.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MainProductStock.defaultProps = {
  title: '',
  subtitle: '',
};

export default MainProductStock;
