/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';

import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
} from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';

import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';

export default function CreateProduct({
  handleCloseAddProduct,
  openAddProduct,
  dialogProduct,
  setDialogProduct,
}) {
  const dispatch = useDispatch();
  const productType = useSelector((state) => state.productType);

  const { handleSubmit } = useForm();

  const [productTypeData, setProductTypeData] = useState();

  console.log('dialogProduct', dialogProduct);
  console.log('productType', productType);

  useEffect(() => {
    dispatch(actions.productTypeAll(''));
    return () => {};
  }, []);

  useEffect(() => {
    if (!productType?.isLoading) {
      setProductTypeData(productType?.rows[0]);
    }
    return () => {};
  }, [productType]);

  const handleAddProduct = async (data) => {
    // const newProductData = await dispatch(
    //   actions.customerCreate({
    //     name: dialogProduct.name,
    //   }),
    // );
    const payload = {
      ...dialogProduct,
      type: productTypeData?.id,
      weight_self: true,
      images: [],
    };

    console.log('data on submit', data);

    await dispatch(actions.productCreate(payload));
    alert('สำเร็จ');
    // setCustomerData(newCustomerData);
    handleCloseAddProduct();
    await dispatch(actions.productAll({}));
  };

  return (
    <Dialog open={openAddProduct} onClose={handleCloseAddProduct}>
      <form onSubmit={handleSubmit(handleAddProduct)}>
        <DialogTitle>เพิ่มสินค้าลงในระบบ</DialogTitle>
        {/* <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            value={dialogProduct.tag}
            onChange={(event) =>
              setDialogProduct({
                ...dialogProduct,
                tag: event.target.value,
              })
            }
            label="รหัสสินค้า"
            type="text"
            variant="standard"
          />
        </DialogContent> */}
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            value={dialogProduct.name}
            onChange={(event) =>
              setDialogProduct({
                ...dialogProduct,
                name: event.target.value,
              })
            }
            label="ชื่อสินค้า"
            type="text"
            variant="standard"
          />
        </DialogContent>

        <DialogContent>
          <Autocomplete
            variant="standard"
            size="small"
            // value={productType?.rows}
            onChange={(event, newValue) => {
              setProductTypeData(newValue);
            }}
            id="controllable-states-demo"
            options={productType?.rows}
            defaultValue={productType?.rows ? productType.rows[0] : ''}
            getOptionLabel={(type) => `${type?.name}`}
            renderInput={(params) => <TextField {...params} label="ประเภท" />}
          />
        </DialogContent>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="unit"
            value={dialogProduct.unit}
            onChange={(event) =>
              setDialogProduct({
                ...dialogProduct,
                unit: event.target.value,
              })
            }
            label="หน่วยสินค้า"
            type="text"
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddProduct}>ยกเลิก</Button>
          <Button type="submit" startIcon={<SaveIcon />}>
            บันทึก
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

CreateProduct.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  openAddProduct: PropTypes.bool,
  handleCloseAddProduct: PropTypes.func,
  dialogProduct: PropTypes.object,
  setCustomerData: PropTypes.func,
  setDialogProduct: PropTypes.func,
};

CreateProduct.defaultProps = {
  name: '',
};
