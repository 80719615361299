/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Checkbox,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  FormControlLabel,
  Box,
  Typography,
} from '@mui/material';

import { Link, Element } from 'react-scroll';

import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';

import DescriptionIcon from '@mui/icons-material/Description';

import { styled } from '@mui/material/styles';

// eslint-disable-next-line import/no-duplicates
import { useHistory } from 'react-router';
// eslint-disable-next-line import/no-duplicates
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import _ from 'lodash';
// import dayjs from 'dayjs';
// import buddhistEra from 'dayjs/plugin/buddhistEra';

import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: '90vw',
//   boxShadow: 24,
//   p: 4,
// };

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 12,
  borderRadius: 6,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 6,
    // backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

export default function DetailWarehouse({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const warehouse = useSelector((state) => state.warehouse);
  const pallet = useSelector((state) => state.pallet);
  const palletType = useSelector((state) => state.palletType);
  const positionSimple = useSelector((state) => state.positionSimple);
  // const productStockLot = useSelector((state) => state.productStockLot);

  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [selectFloor, setSelectFloor] = useState();
  const [selectFloorAndPosition, setSelectFloorAndPosition] = useState();
  const [selectPallet, setSelectPallet] = useState();
  const [selectPalletArray, setSelectPalletArray] = useState();
  const [allFloor, setAllFloor] = useState(false);

  const [filterByWarehouse, setFilterByWarehouse] = useState(id);
  const [selectPalletArrayButton, setSelectPalletArrayButton] = useState();

  console.log('pallet', pallet);
  console.log('warehouse', warehouse);
  console.log('positionSimple', positionSimple);
  console.log('selectPalletArray', selectPalletArray);

  const NewPalletInWarehouse = _.filter(
    warehouse?.pallet_in_warehouse,
    (piw) => {
      if (!selectPalletArray) {
        return piw;
      }
      return piw?.row === selectPalletArray;
    },
  );

  const calPalletInRow = (rowNumber) => {
    const NewPalletInWarehouseEach = _.filter(
      warehouse?.pallet_in_warehouse,
      (piw) => piw?.row === rowNumber,
    );
    return _.size(NewPalletInWarehouseEach);
  };

  // console.log('calPalletInRow', calPalletInRow(10));

  useEffect(() => {
    dispatch(actions.palletAll({ name, page, size }));
    dispatch(actions.positionSimpleAll({ filterByWarehouse }));
    dispatch(actions.palletTypeAll({}));
    dispatch(actions.warehouseGet(id));
    return () => {};
  }, [id, name, page, size]);

  useEffect(() => {
    const positionOfBenz = _.groupBy(warehouse?.positions, 'floor');
    const arrayOfPosition = _.values(positionOfBenz);
    const positionOfWarehouse = _.map(arrayOfPosition, (eachArray, index) => ({
      floor: index + 1,
      available_position: eachArray,
    }));

    setFilterByWarehouse(id);
    setSelectFloor(positionOfWarehouse);
    setSelectFloorAndPosition(positionOfWarehouse[0]);
    return () => {};
  }, [warehouse]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {
    setTotal(NewPalletInWarehouse?.length);
    return () => {};
  }, [selectPalletArray]);

  const handleChangeSelect = (event) => {
    setAllFloor(event.target.checked);
  };

  const handleChangeFloor = (event) => {
    setAllFloor(false);
    setSelectFloorAndPosition(event.target.value);
  };

  // eslint-disable-next-line no-shadow
  // const handlePushToEditWarehouse = async (id) => {
  //   history.push(`/wms/warehouse/edit/${id}`);
  // };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const checkPalletType = (palletId) => {
    const findPalletType = _.find(palletType?.rows, { id: palletId });
    return findPalletType;
  };

  const progressBarValue = (value, maxValue) => {
    const NewValue = (value * 100) / maxValue;
    return NewValue;
  };

  const LinearProgressWithLabel = (value, maxValue) => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <BorderLinearProgress
          variant="determinate"
          value={progressBarValue(value, maxValue)}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">
          {value}/{maxValue}
        </Typography>
      </Box>
    </Box>
  );

  const handlePushToDetailPallet = async (palletId) => {
    history.push(`/doc/pallet-transaction/${palletId}`);
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2 my-auto flex">
          <div className="font-semibold  text-lg">รายการพาเลท</div>
          {selectPalletArray && (
            <div className="font-semibold ml-2">
              <Button
                variant="contained"
                color={'info'}
                size={'small'}
                onClick={() => {
                  setSelectPalletArray();
                  setSelectPalletArrayButton();
                }}
              >
                แสดงทั้งหมด
              </Button>
            </div>
          )}
        </div>
        <div className="w-full md:w-1/2 my-auto">
          {selectPalletArrayButton && (
            <>รายการพาเลท ในแถวที่ {selectPalletArrayButton?.row}</>
          )}
        </div>
        {/* <div className="w-full md:w-1/2">
          <TextField 
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div> */}
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อ/รหัสพาเลท</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ความจุ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> รายละเอียดสินค้า</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(NewPalletInWarehouse) ? (
                NewPalletInWarehouse.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={
                      index % 2 === 0
                        ? `bg-gray-100 ${
                            // eslint-disable-next-line no-underscore-dangle
                            row?._id === selectPallet?.pallet_id?._id &&
                            'bg-green-400'
                          }`
                        : `${
                            // eslint-disable-next-line no-underscore-dangle
                            row?._id === selectPallet?.pallet_id?._id &&
                            'bg-green-400'
                          }`
                    }
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">
                        <p>{row?.name || row?.prefix + row?.running_number}</p>
                        <p>( {checkPalletType(row?.pallet_type)?.name} )</p>
                      </div>
                    </TableCell>
                    <TableCell>
                      <p className="font-bold">{`${row?.used_capacity}`}</p>
                      <p>กิโลกรัม</p>
                    </TableCell>
                    <TableCell>
                      <div className="flex flex-row flex-wrap gap-1">
                        <Button
                          variant="contained"
                          color={'info'}
                          size={'small'}
                          startIcon={<DescriptionIcon />}
                          // eslint-disable-next-line no-underscore-dangle
                          onClick={() => handlePushToDetailPallet(row._id)}
                        >
                          รายละเอียด
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 0}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (
    warehouse.isLoading ||
    positionSimple.isLoading ||
    pallet.isLoading ||
    palletType.isLoading
  ) {
    return <Loading />;
  }
  if (
    !warehouse.isLoading &&
    warehouse.isCompleted &&
    !positionSimple.isLoading &&
    positionSimple.isCompleted &&
    !pallet.isLoading &&
    pallet.isCompleted &&
    !palletType.isLoading &&
    palletType.isCompleted
  ) {
    return (
      <div>
        <div className="flex justify-between">{renderTitle()}</div>
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
          <div className="text-lg font-semibold ml-6">ข้อมูลสินค้า</div>
        </div>
        <div className="pb-2">
          <Card className="p-4 ">
            <div className="flex flex-wrap divide-y">
              <div className="w-full py-4 text-lg font-semibold ">
                {'รายละเอียด'}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold ">{'ชื่อคลัง'}</div>
              <div className="w-1/2 py-4 ">
                <p>{`${warehouse?.name}`}</p>
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'จำนวนพาเลทในคลัง'}
              </div>
              <div className="w-1/2 py-4 ">
                <p>{`${warehouse?.pallet_in_warehouse?.length} พาเลท / ${warehouse?.maximum_capacity} พาเลท`}</p>
              </div>
            </div>
          </Card>
        </div>
        <div className="pb-2">
          <Card className="p-4 ">
            <div className="w-full">
              <div className="w-full py-2 text-lg font-semibold text-center">
                {'ตำแหน่งในคลัง'}
              </div>
              {warehouse?.type === 'complex' ? (
                <>
                  <div className="w-full md:flex px-1 py-2">
                    <div className="w-full md:w-1/2">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          เลือกชั้นของคลัง
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectFloorAndPosition}
                          label="เลือกชั้นของคลัง"
                          onChange={handleChangeFloor}
                        >
                          {_.map(selectFloor, (eachFloor, index) => (
                            <MenuItem key={index} value={eachFloor}>
                              {eachFloor?.floor}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="w-full md:w-1/2 md:ml-2 my-auto">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={allFloor}
                            onChange={handleChangeSelect}
                            name="allFloor"
                          />
                        }
                        label="แสดงแผนภาพทุกชั้น"
                      />
                    </div>
                  </div>
                  <div className="overflow-auto">
                    {allFloor ? (
                      <>
                        {_.map(selectFloor, (eachFloor, index2) => (
                          <div kay={index2}>
                            <div className="w-full text-xl px-2 text-center mt-2 min-w-max">
                              ชั้นที่ {eachFloor?.floor}
                            </div>

                            <div className="flex justify-center pt-2 overflow-x-auto min-w-max">
                              <div className="overflow-auto">
                                <div
                                  className={`grid grid-cols-${
                                    warehouse?.row + 1
                                  } grid-rows-${warehouse?.col}`}
                                >
                                  <div
                                    className={`row-span-${
                                      warehouse?.col + 1
                                    } bg-green-500`}
                                  >
                                    {Array.from(
                                      Array(warehouse?.col + 1),
                                      (e, i) => (
                                        <div className="text-white text-center py-1">
                                          {i}
                                        </div>
                                      ),
                                    )}
                                  </div>
                                  <div
                                    className={`col-span-${warehouse?.row} bg-green-500`}
                                  >
                                    <div
                                      className={`grid grid-cols-${warehouse?.row} text-gray-200`}
                                    >
                                      {Array.from(
                                        Array(warehouse?.row),
                                        (e, i) => (
                                          <div className="text-center">
                                            {i + 1}
                                          </div>
                                        ),
                                      )}
                                    </div>
                                  </div>
                                  {_.map(
                                    eachFloor?.available_position,
                                    (eachPosition, index) => (
                                      <>
                                        {eachPosition?.available ? (
                                          <Button
                                            key={index}
                                            variant="outlined"
                                            size={'small'}
                                            disabled
                                          >
                                            <div className="pl-1">ว่าง</div>
                                          </Button>
                                        ) : (
                                          <Button
                                            key={index}
                                            variant={
                                              // eslint-disable-next-line no-underscore-dangle
                                              selectPallet?._id ===
                                              // eslint-disable-next-line no-underscore-dangle
                                              eachPosition?._id
                                                ? 'contained'
                                                : 'outlined'
                                            }
                                            // variant="outlined"
                                            size={'small'}
                                            onClick={() =>
                                              // eslint-disable-next-line implicit-arrow-linebreak
                                              setSelectPallet(eachPosition)
                                            }
                                          >
                                            <div className="pl-1">มีพาเลท</div>
                                          </Button>
                                        )}
                                      </>
                                    ),
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <>
                        <div className="w-full text-xl px-2 text-center mt-2  min-w-max">
                          ชั้นที่ {selectFloorAndPosition?.floor}
                        </div>

                        {selectFloorAndPosition && (
                          <>
                            <div className="flex justify-center pt-2 overflow-x-auto min-w-max">
                              <div className="overflow-auto">
                                <div
                                  className={`grid grid-cols-${
                                    warehouse?.row + 1
                                  } grid-rows-${warehouse?.col}`}
                                >
                                  <div
                                    className={`row-span-${
                                      warehouse?.col + 1
                                    } bg-green-500`}
                                  >
                                    {Array.from(
                                      Array(warehouse?.col + 1),
                                      (e, i) => (
                                        <div className="text-white text-center py-1">
                                          {i}
                                        </div>
                                      ),
                                    )}
                                  </div>
                                  <div
                                    className={`col-span-${warehouse?.row} bg-green-500`}
                                  >
                                    <div
                                      className={`grid grid-cols-${warehouse?.row} text-gray-200`}
                                    >
                                      {Array.from(
                                        Array(warehouse?.row),
                                        (e, i) => (
                                          <div className="text-center">
                                            {i + 1}
                                          </div>
                                        ),
                                      )}
                                    </div>
                                  </div>
                                  {_.map(
                                    selectFloorAndPosition?.available_position,
                                    (eachPosition, index) => (
                                      <>
                                        {eachPosition?.available ? (
                                          <Button
                                            key={index}
                                            variant="outlined"
                                            size={'small'}
                                            disabled
                                          >
                                            <div className="pl-1">ว่าง</div>
                                          </Button>
                                        ) : (
                                          <Button
                                            key={index}
                                            variant={
                                              // eslint-disable-next-line no-underscore-dangle
                                              selectPallet?._id ===
                                              // eslint-disable-next-line no-underscore-dangle
                                              eachPosition?._id
                                                ? 'contained'
                                                : 'outlined'
                                            }
                                            // variant="outlined"
                                            size={'small'}
                                            onClick={() =>
                                              // eslint-disable-next-line implicit-arrow-linebreak
                                              setSelectPallet(eachPosition)
                                            }
                                          >
                                            <div className="pl-1">มีพาเลท</div>
                                          </Button>
                                        )}
                                      </>
                                    ),
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="pt-2 overflow-x-auto min-w-max">
                    <div className="w-full md:flex md:justify-center">
                      <div className="md:w-1/3 rounded-lg border-2 max-h-96 overflow-y-auto">
                        <div className="text-center text-xl p-2 pt-4">
                          ทางเข้า
                        </div>
                        <div
                          className={`grid grid-flow-col grid-cols-2 grid-rows-${parseInt(
                            warehouse?.row / 2,
                            10,
                          )} gap-x-12 gap-y-1 p-2`}
                        >
                          {_.map(
                            positionSimple?.rows,
                            (eachPosition, index) => (
                              <div
                                className={
                                  // eslint-disable-next-line no-underscore-dangle
                                  selectPalletArrayButton?._id ===
                                  eachPosition?._id
                                    ? 'rounded-md border-4 border-gray-500 px-2 cursor-pointer text-white bg-green-300'
                                    : 'rounded-md border-4 border-gray-500 px-2 cursor-pointer'
                                }
                                onClick={() => {
                                  setSelectPalletArray(eachPosition?.row);
                                  setSelectPalletArrayButton(eachPosition);
                                }}
                              >
                                <div className="text-center text-base">
                                  แถว {index + 1}
                                </div>
                                <div>
                                  {LinearProgressWithLabel(
                                    calPalletInRow(eachPosition?.row),
                                    eachPosition?.max_capacity,
                                  )}
                                </div>
                              </div>
                            ),
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            {selectPallet && (
              <div className="flex justify-center pt-4">
                <div>
                  <div className="text-xl">เเสดงรายละเอียดพาเลท</div>
                  <div>
                    ชื่อพาเลท{' '}
                    {selectPallet?.pallet_id?.name ||
                      selectPallet?.pallet_id?.prefix +
                        selectPallet?.pallet_id?.running_number}
                  </div>
                </div>
              </div>
            )}
          </Card>
        </div>

        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error />;
}

DetailWarehouse.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailWarehouse.defaultProps = {
  title: '',
  subtitle: '',
};
