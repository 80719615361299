/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import {
  BarChart as BarChartIcon,
  Users as UsersIcon,
  User as UserIcon,
  Box as BoxIcon,
  // FileText as FileTextIcon,
  Sliders as SlidersIcon,
  Archive as ArchiveIcon,
  UserPlus as UserPlusIcon,
  FilePlus as FilePlusIcon,
  Repeat as MoveIcon,
} from 'react-feather';

import { NavbarHome } from '../components/Navbars';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import { Loading } from '../components/Loading';
import { NotAuthorized } from '../components/Error';
import accessRight from '../utils/functions/accessRight';
import * as actions from '../redux/actions';

// import SetMealIcon from '@mui/icons-material/SetMeal';

export default function Home() {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const availableModule = useSelector((state) => state.availableModule);

  console.log('availableModule', availableModule);
  console.log('me', me);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    const fetchMe = async () => {
      try {
        dispatch(actions.meGet());
      } catch (error) {
        console.error('Fetch Me Error', error);
      }
    };

    const fetchAvailableModule = async () => {
      try {
        dispatch(actions.availableModuleAll());
      } catch (error) {
        console.error('Fetch Available Module Error', error);
      }
    };

    fetchMe();
    fetchAvailableModule();

    return () => {};
  }, []);

  if (!me?.userData) {
    return <Loading />;
  }

  const menuList = [
    {
      moduleName: 'PROFILE',
      link: '/profile',
      icon: <UserIcon className="text-white" size={48} />,
      title: 'โปรไฟล์',
      extendedStyle: 'bg-gray-400',
    },
    {
      moduleName: 'DASHBOARD',
      link: '/dashboard/product-stock',
      icon: <BarChartIcon className="text-white" size={48} />,
      title: 'รายงานสรุป',
      extendedStyle: 'bg-gray-600 ',
    },
    {
      moduleName: 'DOC',
      link: '/doc/consignment-invoice',
      icon: <FilePlusIcon className="text-white" size={48} />,
      title: 'จัดการใบรับ/ฝาก',
      extendedStyle: 'bg-blue-400 ',
    },
    {
      moduleName: 'DOC',
      link: '/doc/stocktaking-invoice',
      icon: <FilePlusIcon className="text-white" size={48} />,
      title: 'จัดการใบเบิก',
      extendedStyle: 'bg-blue-400 ',
    },
    {
      moduleName: 'DOC',
      link: '/doc/product-transaction',
      icon: <i className="fas fa-fish text-white text-4xl my-2"></i>,
      title: 'จัดการล๊อตสินค้า',
      extendedStyle: 'bg-pink-600',
    },
    {
      moduleName: 'DOC',
      link: '/doc/pallet-transaction',
      icon: <BoxIcon className="text-white" size={48} />,
      title: 'จัดการพาเลท',
      extendedStyle: 'bg-pink-600',
    },
    {
      moduleName: 'WMS',
      link: '/wms/warehouses',
      icon: <ArchiveIcon className="text-white" size={48} />,
      title: 'คลังสินค้า',
      extendedStyle: 'bg-pink-600',
    },
    {
      moduleName: 'DOC',
      link: '/doc/warehouse-input',
      icon: <ArchiveIcon className="text-white" size={48} />,
      title: 'นำสินค้าเข้าคลัง',
      extendedStyle: 'bg-pink-600',
    },
    {
      moduleName: 'DOC',
      link: '/doc/pallet-move',
      icon: <MoveIcon className="text-white" size={48} />,
      title: 'ย้ายพาเลท',
      extendedStyle: 'bg-pink-600',
    },
    {
      moduleName: 'DOC',
      link: '/doc/product-move',
      icon: <MoveIcon className="text-white" size={48} />,
      title: 'ย้ายสินค้า',
      extendedStyle: 'bg-pink-600',
    },
    {
      moduleName: 'CRM',
      link: '/crm/customers',
      icon: <UsersIcon className="text-white" size={48} />,
      title: 'ลูกค้าสัมพันธ์',
      extendedStyle: 'bg-green-600 ',
    },
    {
      moduleName: 'HRMS',
      link: '/hrms/employee',
      icon: <UserPlusIcon className="text-white" size={48} />,
      title: 'ทรัพยากรบุคคล',
      extendedStyle: 'bg-yellow-500 ',
    },
    {
      moduleName: 'SETTING',
      link: '/setting',
      icon: <SlidersIcon className="text-white" size={48} />,
      title: 'การตั้งค่า',
      extendedStyle: 'bg-black',
    },
  ];

  if (!accessRight(me)) {
    return (
      <div>
        <div className="min-h-screen">
          <NavbarHome />
          <div className="min-h-screen ">
            <div className="container pt-40 mr-auto ml-auto">
              <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 ">
                {_.map(menuList, (_menu, index) => {
                  if (accessRight(me, _menu.moduleName, 0, availableModule)) {
                    return (
                      <div className="my-4  px-4" key={index}>
                        <Link to={_menu.link}>
                          <div
                            className={`  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105 ${_menu.extendedStyle}`}
                          >
                            <div className="flex justify-center">
                              {_menu.icon}
                            </div>
                            <p className=" text-white mt-4 ">{_menu.title}</p>
                          </div>
                        </Link>
                      </div>
                    );
                    // eslint-disable-next-line no-else-return
                  } else {
                    return <></>;
                  }
                })}
              </div>
            </div>
          </div>

          <div className="w-full">
            <MainFooter />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <NavbarHome />
        <NotAuthorized />
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    </div>
  );
}
