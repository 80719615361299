/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line object-curly-newline
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Backdrop,
  CircularProgress,
  TextField,
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
// import ProductGraph from '../../components/Graph/ProductGraph';
// import ProductDonut from '../../components/Graph/ProductDonut';
// import ProductGraphProfit from '../../components/Graph/ProductGraphProfit';
import StatusCards from '../../components/Graph/StatusCards';
import TableStatus from '../../components/Graph/TableStatus';

const MainDashboard = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const productTransaction = useSelector((state) => state.productTransaction);
  const product = useSelector((state) => state.product);
  const customer = useSelector((state) => state.customer);

  const name = '';
  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = useState(
    dayjs().startOf('month').toISOString(),
  );
  const [endDate, setEndDate] = useState(dayjs().endOf('month').toISOString());
  const [periodText, setPeriodText] = useState('ออก');
  const transactionType = periodText;

  // console.log('productTransaction', productTransaction);
  // console.log('product', product);
  // console.log('startDate', startDate);
  // console.log('endDate', endDate);
  // console.log('loading', loading);

  useEffect(() => {
    const fetchProductTransaction = async () => {
      try {
        await dispatch(
          actions.productTransactionAll({
            transactionType,
            startDate,
            endDate,
          }),
        );
        setLoading(true);
      } catch (error) {
        console.error(error);
      }
    };
    const fetchProduct = async () => {
      try {
        await dispatch(actions.productAll(''));
      } catch (error) {
        console.error(error);
      }
    };
    const fetchCustomer = async () => {
      try {
        await dispatch(actions.customerAll({ name }));
      } catch (error) {
        console.error(error);
      }
    };
    const fetchMe = async () => {
      try {
        await dispatch(actions.meGet(''));
      } catch (error) {
        console.error(error);
      }
    };

    fetchCustomer();
    fetchProduct();
    fetchMe();
    fetchProductTransaction();

    return () => {};
  }, [startDate, endDate, loading]);

  const handleChangePeriod = (value) => {
    if (value === 'เข้า') {
      // setStartDate(dayjs().startOf('week').toISOString());
      // setEndDate(dayjs().endOf('week').toISOString());
      setPeriodText('เข้า');
    } else if (value === 'ออก') {
      // setStartDate(dayjs().startOf('month').toISOString());
      // setEndDate(dayjs().endOf('month').toISOString());
      setPeriodText('ออก');
    }
    // } else if (value === 'year') {
    //   setStartDate(dayjs().startOf('year').toISOString());
    //   setEndDate(dayjs().endOf('year').toISOString());
    //   setPeriodText('ในปีล่าสุด');
  };

  const renderPeriod = () => (
    <>
      <div className="grid md:grid-cols-2 pb-2 gap-x-2 gap-y-2">
        <div className="bg-white">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="วันเริ่มต้น"
              value={startDate}
              minDate={new Date('2020-01-01')}
              onChange={(newValue) => {
                setStartDate(dayjs(newValue).toISOString());
                setLoading(false);
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </div>
        <div className="bg-white">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="วันสิ้นสุด"
              value={endDate}
              minDate={new Date('2020-01-01')}
              onChange={(newValue) => {
                setEndDate(dayjs(newValue).toISOString());
                setLoading(false);
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </div>
      </div>
    </>
  );

  const renderAllStatus = () => (
    <div>
      <div className="grid  md:grid-cols-2  gap-x-2 gap-y-2 pb-2">
        <div>
          <StatusCards
            title={'รายได้'}
            startDate={startDate}
            endDate={endDate}
            icon={'fas fa-coins fa-2x'}
            product={product?.rows}
            productTransaction={productTransaction}
            customer={customer}
            loading={loading}
            setLoading={setLoading}
          />
        </div>
        <div>
          <StatusCards
            title={'จำนวนสินค้าที่ขาย'}
            startDate={startDate}
            endDate={endDate}
            icon={'fas fa-box-open fa-2x'}
            product={product?.rows}
            productTransaction={productTransaction}
            customer={customer}
            loading={loading}
            setLoading={setLoading}
          />
        </div>
        <div>
          <StatusCards
            title={'จำนวนลูกค้าทั้งหมด'}
            startDate={startDate}
            endDate={endDate}
            icon={'far fa-address-card fa-2x'}
            product={product?.rows}
            productTransaction={productTransaction}
            customer={customer}
            loading={loading}
            setLoading={setLoading}
          />
        </div>
        <div>
          <StatusCards
            title={'จำนวนออเดอร์'}
            startDate={startDate}
            endDate={endDate}
            icon={'far fa-clipboard fa-2x'}
            product={product?.rows}
            productTransaction={productTransaction}
            customer={customer}
            loading={loading}
            setLoading={setLoading}
          />
        </div>
      </div>
      {/* {renderAllGraph()} */}
    </div>
  );

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (me.isLoading) {
    return <Loading />;
  }
  if (!me.isLoading && me.isCompleted) {
    return (
      <div>
        กำลังพัฒนา
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={!loading}
          onClose={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>
        <div className="flex justify-start pb-4">
          <div className="bg-white">
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel id="type" size={'small'}>
                เลือกรายการ
              </InputLabel>
              <Select
                label="เลือกรายการ"
                size={'small'}
                fullWidth
                defaultValue="ออก"
                onChange={(e) => {
                  handleChangePeriod(e.target.value);
                  setLoading(false);
                }}
              >
                <MenuItem value="เข้า">รายการรับฝากสินค้า</MenuItem>
                <MenuItem value="ออก">รายการเบิกสินค้า</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        {renderPeriod()}
        {/* {loading && renderAllStatus()} */}
        <div className="grid  md:grid-cols-2  gap-x-2 gap-y-2 pb-2">
          {/* {loading && (
            <TableStatus
              product={product.rows}
              productTransaction={productTransaction}
              customer={customer.rows}
              startDate={startDate}
              endDate={endDate}
            />
          )} */}
        </div>
      </div>
    );
  }
  return <Error message={me?.message} />;
};

MainDashboard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MainDashboard.defaultProps = {
  title: '',
  subtitle: '',
};

export default MainDashboard;
