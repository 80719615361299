import AddProductDialog from './AddProductDialog';
import AddSerialNumberDialog from './AddSerialNumberDialog';
import IncreaseSerialNumberDialog from './IncreaseSerialNumberDialog';
import CreateCustomer from './CreateCustomer';
import CreateProduct from './CreateProduct';

export {
  AddProductDialog,
  AddSerialNumberDialog,
  IncreaseSerialNumberDialog,
  CreateCustomer,
  CreateProduct,
};
export default {
  AddProductDialog,
  AddSerialNumberDialog,
  IncreaseSerialNumberDialog,
  CreateCustomer,
  CreateProduct,
};
