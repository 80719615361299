import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Backdrop,
  Button,
  Card,
  Fade,
  Modal,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
} from '@mui/material';

import AddBoxIcon from '@mui/icons-material/AddBox';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import { ProductForm } from '../../components/Forms';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import ImageUpload from '../../components/ImageUpload/ImageUpload';
import { CSVUpload } from '../../components/CSVUpload';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

const defaultValue = {
  name: '',
  unit: '',
  descriptions: '',
  tag: '',
  images: '',
};

function Product({ title, subtitle }) {
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product);

  // const me = useSelector((state) => state.me);
  const productType = useSelector((state) => state.productType);
  const [selectProduct, setSelectProduct] = useState('');
  const history = useHistory();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const [uploadedImage, setUploadedImage] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [total, setTotal] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [csvUploadOpen, setCsvUploadOpen] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [checkTag, setCheckTag] = useState(true);
  const [checkWeightSelf, setCheckWeightSelf] = useState(false);

  const handleChangeCheckTag = (event) => {
    setCheckTag(event.target.checked);
  };

  const handleCheckWeightSelf = (event) => {
    setCheckWeightSelf(event.target.checked);
  };

  const handleCSVOpen = () => setCsvUploadOpen(true);
  const handleCSVClose = () => {
    setCsvUploadOpen(false);
    setCsvData([]);
  };

  // const [optionList, setOptionList] = useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // console.log('productType....', productType?.rows);
  // console.log('selectProduct', selectProduct);

  useEffect(() => {
    dispatch(actions.productAll({ name, page, size, selectProduct }));
    dispatch(actions.productTypeAll(''));
    dispatch(actions.meGet());
    return () => {};
  }, [name, page, size, selectProduct]);

  useEffect(() => {
    setTotal(product?.total);
    return () => {};
  }, [product]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 100);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    try {
      if (checkTag) {
        const findProductWithTag = _.find(
          product.rows,
          (eachProduct) => eachProduct.tag === data.tag,
        );

        if (findProductWithTag) {
          const confirmName = confirm(
            'มีรหัสสินค้านี้เเล้ว ยินยันที่จะเพิ่มหรือไม่?',
          );
          if (confirmName) {
            const payload = {
              ...data,
              weight_self: checkWeightSelf,
              weight: checkWeightSelf ? undefined : data.weight,
              type: data?.type?.id,
              images: _.map(uploadedImage, (image) => ({
                image: image.data_url,
              })),
            };
            console.log('payload + image', payload);

            await dispatch(actions.productCreate(payload));
            reset(defaultValue);
            setUploadedImage('');
            alert('สำเร็จ');
            handleClose();
            await dispatch(actions.productAll({ name, page, size }));
            dispatch(actions.meGet());
          }
        } else {
          const payload = {
            ...data,
            weight_self: checkWeightSelf,
            weight: checkWeightSelf ? undefined : data.weight,
            type: data?.type?.id,
            images: _.map(uploadedImage, (image) => ({
              image: image.data_url,
            })),
          };
          console.log('payload + image', payload);

          await dispatch(actions.productCreate(payload));
          reset(defaultValue);
          setUploadedImage('');
          alert('สำเร็จ');
          handleClose();
          await dispatch(actions.productAll({ name, page, size }));
          dispatch(actions.meGet());
        }
      } else {
        const findProductWithName = _.find(
          product.rows,
          (eachProduct) => eachProduct.name === data.name,
        );

        if (findProductWithName) {
          const confirmNameTag = confirm(
            'มีชื่อสินค้านี้เเล้ว ยินยันที่จะเพิ่มหรือไม่?',
          );
          if (confirmNameTag) {
            const payload = {
              ...data,
              tag: undefined,
              weight_self: checkWeightSelf,
              weight: checkWeightSelf ? undefined : data.weight,
              type: data?.type?.id,
              images: _.map(uploadedImage, (image) => ({
                image: image.data_url,
              })),
            };
            console.log('payload + image', payload);

            await dispatch(actions.productCreate(payload));
            reset(defaultValue);
            setUploadedImage('');
            alert('สำเร็จ');
            handleClose();
            await dispatch(actions.productAll({ name, page, size }));
            dispatch(actions.meGet());
          }
        } else {
          const payload = {
            ...data,
            tag: undefined,
            weight_self: checkWeightSelf,
            weight: checkWeightSelf ? undefined : data.weight,
            type: data?.type?.id,
            images: _.map(uploadedImage, (image) => ({
              image: image.data_url,
            })),
          };
          console.log('payload + image', payload);

          await dispatch(actions.productCreate(payload));
          reset(defaultValue);
          setUploadedImage('');
          alert('สำเร็จ');
          handleClose();
          await dispatch(actions.productAll({ name, page, size }));
          dispatch(actions.meGet());
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteProduct = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.productDelete(id));
        await dispatch(actions.productAll({ name, page, size }));
        dispatch(actions.meGet());
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handlePushToDetailProduct = (id) => {
    history.push(`/wms/product/${id}`);
  };

  // const handlePushToEditProduct = async (id) => {
  //   history.push(`product/edit/${id}`);
  // };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeType = (event) => {
    console.log('type product', event.target);
    setSelectProduct(event.target.value);
  };

  const handleAddFromFile = async () => {
    console.log('csvData', csvData);

    const NewCsvData = _.map(csvData, (eachArray) => {
      const data = {
        ...eachArray,
        tag: eachArray.tag === '' ? undefined : eachArray?.tag,
        weight: eachArray.weight === '' ? undefined : eachArray?.weight,
        weight_self: eachArray.weight === '',
      };
      return data;
    });

    console.log('NewCsvData', NewCsvData);

    if (!_.isEmpty(NewCsvData)) {
      try {
        await dispatch(actions.productCreate({ arr: NewCsvData }));
        alert('สำเร็จ');
        handleCSVClose();
        await dispatch(actions.productAll({ name, page, size }));
      } catch (error) {
        alert('เพิ่มวัตถุดิบไม่สำเร็จ');
        console.error(error);
      }
    } else {
      alert('ไม่สามารถอ่านไฟล์รายการวัตถุดิบได้');
    }
  };

  const renderAddButton = () => (
    <div className="flex flex-row justify-end pb-4 gap-1">
      <Button
        startIcon={<AddBoxIcon />}
        variant="contained"
        onClick={handleOpen}
      >
        เพิ่ม
      </Button>
      <Button
        variant="contained"
        onClick={handleCSVOpen}
        startIcon={<FileUploadIcon />}
      >
        อัพโหลด
      </Button>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 md:flex md:flex-row ">
        <div className="w-full md:w-1/2 pt-2 md:pt-1">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full md:w-1/2 pt-2 md:pt-1">
          <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
            <InputLabel id="type" size={'small'}>
              ประเภทสินค้า
            </InputLabel>
            <Select
              label="ประเภทสินค้า"
              size={'small'}
              value={selectProduct}
              onChange={handleChangeType}
              fullWidth
            >
              <MenuItem value={''}>แสดงทั้งหมด</MenuItem>
              {_.size(productType.rows) ? (
                _.map(productType.rows, (row) => (
                  <MenuItem key={row.id} value={row.name}>
                    {row.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem>
                  <MenuItem disabled value="">
                    <em>ไม่มีข้อมูล</em>
                  </MenuItem>
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </div>
      </div>
    </Card>
  );

  const renderModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card sx={style} className="max-w-4xl  ">
          <div className="max-h-screen overflow-y-auto">
            <div className="py-2 text-xl">เพิ่มข้อมูล</div>
            <div className="py-2 ">
              <form onSubmit={handleSubmit(onSubmit)}>
                <ProductForm
                  control={control}
                  Controller={Controller}
                  errors={errors}
                  product={product}
                  productType={productType}
                  handleChangeCheckTag={handleChangeCheckTag}
                  checkTag={checkTag}
                  handleCheckWeightSelf={handleCheckWeightSelf}
                  checkWeightSelf={checkWeightSelf}
                />
                <div className="px-1">
                  <ImageUpload
                    images={uploadedImage}
                    setImages={setUploadedImage}
                    preview_size="250"
                    maxNumber={10}
                  />
                </div>
                <div className="flex flex-row justify-end gap-1 py-4">
                  <Button
                    variant="contained"
                    type="submit"
                    startIcon={<SaveIcon />}
                  >
                    บันทึก
                  </Button>
                  <Button variant="outlined" onClick={handleClose}>
                    ยกเลิก
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderCSVUploadModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={csvUploadOpen}
      onClose={handleCSVClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={csvUploadOpen}>
        <Card sx={style} className="max-w-4xl">
          <div className="max-h-screen overflow-y-auto my-10">
            <div className="pt-2 text-xl">อัพโหลดข้อมูล</div>
            <div className="py-2 font-system">
              อัพโหลดไฟล์ .csv โดยมีโครงสร้างตามไฟล์เทมเพลต แถวที่ 1-2
              และแถวสุดท้าย จะไม่ถูกนำมาคิดในระบบ <br />
              <Button variant="contained">
                <Link to="/filetemplate/Product.csv" target="_blank">
                  ดาวน์โหลดเทมเพลต
                </Link>
              </Button>
            </div>
            <div className="py-2">
              <div className="flex justify-center">
                <div>
                  <CSVUpload setCsvData={setCsvData} />
                  {!_.isEmpty(csvData) && (
                    <div>
                      <p className="font-system my-1">
                        พบข้อมูล {_.size(csvData)} รายการ
                      </p>
                      <Button
                        color="primary"
                        variant="contained"
                        type="button"
                        onClick={handleAddFromFile}
                      >
                        บันทึก
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="my-2">
              <div className="bg-blue-50 p-4 rounded-lg">
                <div className="pb-2">
                  * โปรดแทนที่ข้อมูลใน <strong>หมวดหมู่สินค้า</strong>{' '}
                  ด้วยรหัสดังต่อไปนี้
                </div>
                <div className="rounded-sm overflow-y-auto">
                  <Table
                    sx={{
                      '&:last-child td, &:last-child th': { border: 1 },
                    }}
                  >
                    <TableHead>
                      <TableCell>ลำดับ</TableCell>
                      <TableCell>ชื่อประเภทของสินค้า</TableCell>
                      <TableCell>รหัส</TableCell>
                    </TableHead>
                    <TableBody>
                      {_.map(productType?.rows, (_productType, index) => (
                        <TableRow key={index}>
                          <TableCell>{index + 1} </TableCell>
                          <TableCell>{_productType?.name} </TableCell>
                          <TableCell>{_productType?.id} </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderTable = () => (
    <div className="my-2 ">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">รหัสสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> น้ำหนักสินค้าต่อก้อน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> รูปภาพ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(product.rows) ? (
                product.rows.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">{`${row?.tag || ''}`}</div>
                      <div className="">{`${row?.id || ''}`}</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold text-lg">{`${row?.name}`}</div>
                      <div className="">{`( ${row?.type?.name} )`}</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">{`${
                        row?.weight || '-'
                      } กิโลกรัม`}</div>
                    </TableCell>
                    {_.isEmpty(row.images) ? (
                      <TableCell>
                        <img
                          key={index}
                          src="https://storage.googleapis.com/mydev_eonlineshop/other/thumbnails/1645339249181_800x800.png"
                          width="100"
                          height="100"
                          className="border-2"
                        ></img>
                      </TableCell>
                    ) : (
                      <TableCell>
                        <img
                          key={index}
                          src={`${row?.images[0]?.url}`}
                          width="100"
                          height="100"
                          className="border-2"
                        ></img>
                      </TableCell>
                    )}
                    <TableCell>
                      <div className="flex flex-row flex-wrap gap-1">
                        <Button
                          variant="contained"
                          startIcon={<DescriptionIcon />}
                          color={'info'}
                          size={'small'}
                          onClick={() => handlePushToDetailProduct(row.id)}
                        >
                          รายละเอียด
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<DeleteIcon />}
                          color={'error'}
                          size={'small'}
                          onClick={() => {
                            handleDeleteProduct(row?.id);
                          }}
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูลที่ต้องการ</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (product.isLoading) {
    return <Loading />;
  }
  if (!product.isLoading && product.isCompleted) {
    return (
      <div>
        {renderModal()}
        {renderCSVUploadModal()}
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="mt-6">{renderAddButton()}</div>
        </div>
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error message={product?.message} />;
}

Product.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Product.defaultProps = {
  title: '',
  subtitle: '',
};

export default Product;
