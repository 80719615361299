import React from 'react';
import ReactExport from 'react-export-excel';
import _ from 'lodash';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

const { ExcelFile } = ReactExport;
const { ExcelSheet, ExcelColumn } = ReactExport.ExcelFile;

export default function ExcelSheetGenerator({
  dataSets,
  sheetName = 'Material',
  columnList = [],
  spreadedColumn = '',
}) {
  let spreadedDataSet = [];
  if (spreadedColumn !== '') {
    _.map(dataSets, (eachData) => {
      _.map(eachData?.[spreadedColumn], (eachColumn) => {
        spreadedDataSet.push({ ...eachData, ...eachColumn });
      });
    });
  } else {
    spreadedDataSet = dataSets;
  }

  const dataSetWithIndex = _.map(spreadedDataSet, (eachData, index) => ({
    index: index + 1,
    ...eachData,
  }));
  console.log('dataSets', dataSetWithIndex);
  return (
    <ExcelFile
      hideElement={true}
      filename={`${sheetName}-${dayjs().format('YYYYMMDD')}`}
    >
      <ExcelSheet data={dataSetWithIndex} name={sheetName}>
        {_.map(columnList, (eachElement, index) => (
          <ExcelColumn
            label={eachElement.label}
            value={eachElement?.value}
            key={index}
          />
        ))}
      </ExcelSheet>
    </ExcelFile>
  );
}

ExcelSheetGenerator.propTypes = {
  dataSets: PropTypes.arrayOf(PropTypes.object),
  columnList: PropTypes.arrayOf(PropTypes.object),
  sheetName: PropTypes.string,
  spreadedColumn: PropTypes.string,
};
