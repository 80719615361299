import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import {
  Employees,
  EditEmployee,
  Departments,
  EditDepartment,
  RoleType,
  EditRoleType,
  Users,
  EditUser,
} from '../views/HRMS';
import { HomeNavbar } from '../components/Navbars';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import { Loading } from '../components/Loading';
import { NotAuthorized } from '../components/Error';
import accessRight from '../utils/functions/accessRight';
import * as actions from '../redux/actions';

export function HRMS() {
  const module = 'HRMS';
  const prefix = '/hrms';
  const name = 'ทรัพยากรบุคคล';

  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const availableModule = useSelector((state) => state.availableModule);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };
  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    console.log('Layout : HRMS');
    dispatch(actions.meGet());
    dispatch(actions.availableModuleAll());
    return () => {};
  }, []);

  if (!me?.userData) {
    return <Loading />;
  }

  if (accessRight(me, module, 0, availableModule)) {
    return (
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <div className="relative lg:ml-64  min-h-screen  pt-16 px-8 ">
          <div className="py-4">
            <Switch>
              <Redirect exact from={`${prefix}`} to={`${prefix}/employee`} />
              <Route exact path={`${prefix}/employee`}>
                <Employees title="จัดการข้อมูลพนักงาน" subtitle={name} />
              </Route>

              {accessRight(me, module, 1, availableModule) && (
                <Switch>
                  <Route exact path={`${prefix}/department`}>
                    <Departments title="จัดการแผนก" subtitle={name} />
                  </Route>
                  <Route exact path={`${prefix}/department/edit/:id`}>
                    <EditDepartment title="แก้ไขแผนก" subtitle={name} />
                  </Route>
                  <Route exact path={`${prefix}/user`}>
                    <Users title="จัดการข้อมูลผู้ใช้" subtitle={name} />
                  </Route>
                  <Route exact path={`${prefix}/employee/edit/:id`}>
                    <EditEmployee title="แก้ไขข้อมูลพนักงาน" subtitle={name} />
                  </Route>
                  <Route exact path={`${prefix}/user/edit/:id`}>
                    <EditUser title="แก้ไขข้อมูลผู้ใช้" subtitle={name} />
                  </Route>
                  {accessRight(me, module, 2, availableModule) && (
                    <Switch>
                      <Route exact path={`${prefix}/role-types`}>
                        <RoleType title="จัดการบทบาท" subtitle={name} />
                      </Route>
                      <Route exact path={`${prefix}/role-types/edit/:id`}>
                        <EditRoleType title="แก้ไขบทบาท" subtitle={name} />
                      </Route>
                      <Route path="*">
                        <NotAuthorized />
                      </Route>
                    </Switch>
                  )}
                  <Route path="*">
                    <NotAuthorized />
                  </Route>
                </Switch>
              )}
              <Route path="*">
                <NotAuthorized />
              </Route>
            </Switch>
          </div>
        </div>
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <NotAuthorized />
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    </div>
  );
}

export default HRMS;
