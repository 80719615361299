/* eslint-disable arrow-body-style */
/* eslint-disable operator-linebreak */
import React, { useEffect, useState } from 'react';
import { TextField, Button, Tooltip, Autocomplete } from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useForm } from 'react-hook-form';
import CreateProduct from '../../Modal/CreateProduct';

import { AddSerialNumberDialog } from '../../Dialogs';
import { SPM } from '../../../utils/Proptypes';

const resetValues = {
  product: '',
  price: '',
  inventory: '',
  selectSerial: [],
  price1: '',
};

export const AddProductForm = ({
  errors,
  order,
  Controller,
  product,
  setSelectedProducts,
  filterProduct,
  setFilterProduct,
  productList,
  setProductList,
  setReload,
  reload,
  selectSerial,
  setSelectSerial,
}) => {
  const [isModalCreateProductOpen, setIsModalCreateProductOpen] = useState(
    false,
  );
  const handleOpen = () => setIsModalCreateProductOpen(true);
  const handleClose = () => {
    setIsModalCreateProductOpen(false);
  };
  const [isProductDialogOpen, setIsProductDialogOpen] = useState(false);
  const { getValues, control, reset } = useForm();
  console.log('productList in add product form', productList);
  console.log('selectSerial', selectSerial);

  const checkProduct = (data) => {
    // eslint-disable-next-line no-underscore-dangle
    const findproduct = _.find(product, { id: data._id });
    setFilterProduct(findproduct);
  };
  useEffect(() => () => {}, [reload]);
  useEffect(() => {
    setSelectedProducts(order?.product_of_order);
    return () => {};
  }, [order]);

  const handleCloseDialog = () => {
    try {
      setIsProductDialogOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddProduct = () => {
    if (!filterProduct?.serial_type) {
      if (getValues('inventory') === '' || getValues('price') === '') {
        alert('กรุณาใส่ข้อมูลให้ครบถ้วน');
      } else {
        const data = {
          product: filterProduct,
          inventory: getValues('inventory'),
          price: getValues('price'),
        };
        console.log(data);
        productList.push(data);
        setProductList(productList);
        reset(resetValues);
        setReload(!reload);
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (getValues('price1') === '' || selectSerial.length === 0) {
        alert('กรุณาใส่ข้อมูลให้ครบถ้วน');
      } else {
        const data = {
          product: filterProduct,
          inventory: selectSerial.length,
          serials: selectSerial,
          price: getValues('price1'),
        };
        console.log(data);
        productList.push(data);
        setProductList(productList);
        setSelectSerial([]);
        reset(resetValues);
        setReload(!reload);
      }
    }
  };

  return (
    <div>
      <CreateProduct
        isOpen={isModalCreateProductOpen}
        handleOnClose={handleClose}
      />
      <div className="flex flex-row flex-wrap">
        <div className="w-full px-1 py-2 flex ">
          <div className="flex-grow">
            <Controller
              name={'product'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  size={'small'}
                  disableClearable
                  {...field}
                  onChange={(e, newValue) => {
                    field.onChange(newValue);
                    checkProduct(newValue);
                    setReload(!reload);
                  }}
                  options={product}
                  getOptionLabel={(option) => `${option?.name}`}
                  required
                  renderInput={(params) => (
                    <TextField {...params} label="สินค้า" required />
                  )}
                />
              )}
            />
          </div>
          <div className="my-auto pl-1  flex-none">
            <Tooltip
              title="เพิ่มสินค้ากรณีที่ยังไม่มีข้อมูลในระบบ"
              placement="bottom"
            >
              <Button variant="outlined" onClick={handleOpen}>
                เพิ่มสินค้า
              </Button>
            </Tooltip>
          </div>
        </div>
        {!filterProduct?.serial_type === true ? (
          <>
            <div className="w-full md:w-1/2 px-1 py-2">
              <Controller
                name={'price'}
                control={control}
                defaultValue={''}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="ราคาต่อหน่วย"
                    fullWidth
                    type="number"
                    size={'small'}
                    // eslint-disable-next-line react/prop-types
                    helperText={errors.price && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div>
            <div className="w-full md:w-1/2 px-1 py-2">
              <Controller
                name={'inventory'}
                control={control}
                rules={{ required: true }}
                defaultValue={''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="จำนวน"
                    fullWidth
                    type="number"
                    size={'small'}
                    // eslint-disable-next-line react/prop-types
                    helperText={errors.inventory && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div>
            <div className="w-full px-1 py-2">
              <Button
                variant="outlined"
                className="w-full"
                onClick={() => handleAddProduct()}
              >
                เพิ่ม
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="w-full md:w-1/2 px-1 py-2">
              <Controller
                name={'price1'}
                control={control}
                defaultValue={''}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="ราคาต่อหน่วย"
                    fullWidth
                    type="number"
                    size={'small'}
                    // eslint-disable-next-line react/prop-types
                    helperText={errors.price && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div>
            <div className="w-full md:w-1/2 px-1 py-2">
              <div className="w-full h-full">
                <Button
                  variant="contained"
                  className="w-full h-full"
                  onClick={() => setIsProductDialogOpen(true)}
                >
                  <p className="bg-white p-0.5 px-2 font-bold rounded-full text-gray-800">
                    {selectSerial.length}
                  </p>
                  <p className="pl-2">เพิ่ม SN</p>
                </Button>
              </div>
            </div>
            <AddSerialNumberDialog
              open={isProductDialogOpen}
              handleClose={handleCloseDialog}
              selectSerial={selectSerial}
              setSelectSerial={setSelectSerial}
              // selectedProduct={selectedProducts}
              // handleAddProduct={handleAddProductToOrder}
              // handleRemoveProduct={handleRemoveProduct}
            />
            <div className="w-full h-full px-1 py-2">
              <Button
                variant="outlined"
                className="w-full h-full"
                onClick={() => handleAddProduct()}
              >
                เพิ่ม
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

AddProductForm.propTypes = {
  errors: PropTypes.shape({
    product_transaction_type: PropTypes.object,
    orderNumber: PropTypes.billNumber,
    customer: PropTypes.array,
    source: PropTypes.object,
    quantity: PropTypes.object,
    price: PropTypes.object,
    product_stock_lots: PropTypes.object,
    warehouse: PropTypes.object,
    product_type: PropTypes.array,
    productionDate: PropTypes.object,
    expirationDate: PropTypes.object,
    receiptDate: PropTypes.object,
    sellDate: PropTypes.object,
    remark: PropTypes.object,
    recipientName: PropTypes.object,
    sellName: PropTypes.object,
    trackingNumber: PropTypes.object,
    sellPrice: PropTypes.object,
    shippingCost: PropTypes.object,
    discount: PropTypes.object,
    payment: PropTypes.payment,
  }),
  product: PropTypes.array,
  productType: PropTypes.array,
  productTransactionType: PropTypes.array,
  order: PropTypes.oneOf([SPM.Order, SPM.OrderArray]),
  Controller: PropTypes.func,
  control: PropTypes.object,
  type: PropTypes.object,
  setType: PropTypes.func,
  selectProduct: PropTypes.array,
  setSelectProduct: PropTypes.func,
  filterProduct: PropTypes.object,
  setFilterProduct: PropTypes.func,
  customers: PropTypes.array,
  departments: PropTypes.object,
  watch: PropTypes.func.isRequired,
  products: PropTypes.object,
  selectedProducts: PropTypes.array,
  setSelectedProducts: PropTypes.func,
  employees: PropTypes.array,
  productTypes: PropTypes.object,
  productList: PropTypes.array,
  setProductList: PropTypes.func,
  reload: PropTypes.bool,
  setReload: PropTypes.func,
  selectSerial: PropTypes.array,
  setSelectSerial: PropTypes.func,
};

AddProductForm.defaultProps = {
  product: null,
  productType: null,
  productTransactionType: null,
  customer: null,
  department: null,
  me: null,
  type: null,
  selectProduct: null,
  filterProduct: null,
};

export default AddProductForm;
