/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
  Button,
  Card,
  TextField,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { Trash2 as Delete } from 'react-feather';
import _ from 'lodash';
import PropTypes from 'prop-types';
import CreateCustomer from '../../components/Dialogs/CreateCustomer';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { BackButton } from '../../components/Button';
import { ViewTitle } from '../../components/ViewTitle';

const filter = createFilterOptions();

function EditConsignmentInvoice({ title, subtitle }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [filterProduct, setFilterProduct] = useState(id);

  const consignmentInvoice = useSelector((state) => state.consignmentInvoice);
  const customer = useSelector((state) => state.customer);
  const productStockLot = useSelector((state) => state.productStockLot);
  const me = useSelector((state) => state.me);
  const product = useSelector((state) => state.product);
  const [consignmentInvoiceType, setConsignmentInvoiceType] = useState(
    consignmentInvoice?.consignment_invoice_type,
  );
  const [customerData, setCustomerData] = useState(
    consignmentInvoice?.customer,
  );
  const [openAddCustomer, toggleOpenAddCustomer] = useState(false);

  // console.log('consignmentInvoice', consignmentInvoice);
  console.log('productStockLot', productStockLot?.rows);
  console.log('product', product?.rows);

  const newProductStockLot = _.map(productStockLot?.rows, (each) => {
    const dataProduct = each?.product_type;
    return dataProduct;
  });

  console.log('newProductStockLot', newProductStockLot);

  const [dialogCustomer, setDialogCustomer] = useState({
    name: '',
  });

  const handleCloseAddCustomer = () => {
    setDialogCustomer({
      name: '',
    });
    toggleOpenAddCustomer(false);
  };

  // console.log('customerData', customerData);

  const handleChangeConsignmentInvoiceType = (event) => {
    setConsignmentInvoiceType(event.target.value);
  };

  const { register, control, handleSubmit, setValue } = useForm({
    mode: 'onBlur',
  });
  const { fields, append } = useFieldArray({
    control,
    name: 'itemList',
  });

  useEffect(() => {
    setFilterProduct(id);
    dispatch(actions.customerAll({}));
    dispatch(actions.productStockLotAll({ filterProduct }));
    dispatch(actions.consignmentInvoiceGet(id));
    dispatch(actions.productAllStock());
    dispatch(actions.meGet());
    return () => {};
  }, []);

  useEffect(() => {
    if (!productStockLot.isLoading) {
      setValue('itemList', productStockLot?.rows);
    }
    setConsignmentInvoiceType(consignmentInvoice?.consignment_invoice_type);
    setCustomerData(consignmentInvoice?.customer);
    return () => {};
  }, [product, customer, consignmentInvoice]);

  const handleAddItem = () => {
    append({
      product: product.rows[0].id,
      quantity: 100,
    });
  };

  const handleDeleteItem = async (item) => {
    // console.log('item', item);
    const confirm = window.confirm('ยืนยันการลบสินค้า');
    if (confirm) {
      try {
        if (item.product_in_pallet_inventory !== 0) {
          alert('ไม่สามารถลบได้เนื่องจากสินค้าถูกบรรจุลงพาเลทเเล้ว');
        } else {
          await dispatch(actions.productStockLotDelete(item.id));
          await dispatch(actions.customerAll({}));
          await dispatch(actions.productStockLotAll({ filterProduct }));
          await dispatch(actions.consignmentInvoiceGet(id));
          await dispatch(actions.productAllStock());
          await dispatch(actions.meGet());
        }
      } catch (error) {
        console.error(error);
      }
      // console.log('indexId', indexId);
    }
  };

  const onSubmit = async (data) => {
    try {
      const newList = _.map(data?.itemList, (item) => {
        const newItem = { ...item, quantity: parseFloat(item.quantity) };
        return newItem;
      });

      // for await (const eachProduct of newList) {
      //   if (eachProduct._id) {
      //     console.log('eachProduct have id', eachProduct);
      //   } else {
      //     console.log('eachProduct not have id', eachProduct);
      //   }
      // }

      const DataOfInvoice = {
        ...data,
        name: data.name,
        description: data.description,
        productList: newList,
        consignment_invoice_type: consignmentInvoiceType,
        employee: me?.userData?.id,
        customer: customerData?._id,
      };

      console.log('DataOfInvoice', DataOfInvoice);
      console.log('data', data);

      await dispatch(actions.consignmentInvoicePut(id, DataOfInvoice));
      alert('สำเร็จ');
      history.push('/doc/consignment-invoice');
    } catch (error) {
      console.error(error);
    }
  };

  const rendInfomationTextField = () => (
    <div>
      <div className="my-2 w-full">
        <div className="my-2 w-full">
          <TextField
            size="small"
            fullWidth
            required
            label="เลขใบรับฝาก"
            defaultValue={consignmentInvoice ? consignmentInvoice?.name : ''}
            {...register('name', { required: true })}
          />
        </div>
        <div className="my-2 w-full">
          <Autocomplete
            onChange={(event, newValue) => {
              if (typeof newValue === 'string') {
                // timeout to avoid instant validation of the dialog's form.
                setTimeout(() => {
                  toggleOpenAddCustomer(true);
                  setDialogCustomer({
                    name: newValue,
                  });
                });
              } else if (newValue && newValue?.inputValue) {
                toggleOpenAddCustomer(true);
                setDialogCustomer({
                  name: newValue?.inputValue,
                });
              } else {
                setCustomerData(newValue);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              if (params?.inputValue !== '') {
                filtered.push({
                  inputValue: params?.inputValue,
                  name: `เพิ่ม "${params?.inputValue}"  ลงในระบบ`,
                });
              }

              return filtered;
            }}
            handleHomeEndKeys
            value={customerData}
            options={customer?.rows}
            defaultValue={
              consignmentInvoice ? consignmentInvoice?.customer : ' '
            }
            getOptionLabel={(option) => {
              // e.g value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              if (option?.inputValue) {
                return option?.inputValue;
              }
              return option?.name;
            }}
            required
            renderOption={(props, option) => <li {...props}>{option?.name}</li>}
            renderInput={(params) => (
              <TextField {...params} label="ชื่อลูกค้า" />
            )}
          />
        </div>
        <div className="my-2 w-full">
          <TextField
            size="small"
            fullWidth
            label="ทะเบียนรถ"
            defaultValue={
              consignmentInvoice ? consignmentInvoice?.car_registration : ''
            }
            {...register('car_registration', { required: false })}
          />
        </div>

        <FormLabel>สำหรับ</FormLabel>
        <div className="my-2 w-full px-4">
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={consignmentInvoiceType}
            defaultValue={
              consignmentInvoice
                ? consignmentInvoice?.consignment_invoice_type
                : ''
            }
            onChange={handleChangeConsignmentInvoiceType}
          >
            <FormControlLabel value="freeze" control={<Radio />} label="ฟรีซ" />
            <FormControlLabel value="keep" control={<Radio />} label="เก็บ" />
            <FormControlLabel
              value="other"
              control={<Radio />}
              label="อื่นๆ โปรดระบุในหมายเหตุ"
            />
          </RadioGroup>
        </div>
        <div className="my-2 w-full">
          <TextField
            label="หมายเหตุ"
            fullWidth
            multiline
            required={consignmentInvoiceType === 'other'}
            rows={2}
            defaultValue={
              consignmentInvoice ? consignmentInvoice?.description : ''
            }
            {...register('description')}
          />
        </div>
      </div>
    </div>
  );

  const getOpObj = (option) => {
    if (!option) {
      // eslint-disable-next-line no-param-reassign
      option = _.find(product.rows, (op) => op.id === option);
    }
    return option;
  };

  const renderTable = () => (
    <div>
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size={'small'}>
            <colgroup>
              <col width="10%" />
              <col width="45%" />
              <col width="30%" />
              <col width="15%" />
            </colgroup>
            <TableHead>
              <TableRow className="bg-green-100">
                <TableCell>
                  <div className="font-bold py-1">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ชื่อสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">จำนวน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(fields) ? (
                _.map(fields, (item, index) => (
                  <TableRow
                    key={item.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="py-1">{`${index + 1}`}</div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="py-1">
                        <Controller
                          name={`itemList.${index}.product`}
                          control={control}
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              onChange={(event, newValue) => {
                                field.onChange(newValue?.id);
                              }}
                              value={getOpObj(field.value)?.id}
                              disableClearable
                              options={product?.rows}
                              defaultValue={
                                newProductStockLot
                                  ? newProductStockLot[index]
                                  : ' '
                              }
                              getOptionLabel={(option) =>
                                `${option?.name} ( ก้อนละ ${option?.weight} กิโลกรัม ) `
                              }
                              required
                              renderInput={(params) => (
                                <TextField {...params} label="สินค้า" />
                              )}
                            />
                          )}
                        />
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="py-1">
                        <Controller
                          name={`itemList.${index}.quantity`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              size="small"
                              fullWidth
                              required
                              type="number"
                              label="จำนวน"
                              {...field}
                            />
                          )}
                        />
                      </div>
                    </TableCell>

                    <TableCell component="th" scope="row">
                      <Button
                        size="sm"
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          handleDeleteItem(item);
                        }}
                      >
                        <Delete size={16} color="red" />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
  const renderAddButton = () => (
    <Button
      size="small"
      variant="outlined"
      color="success"
      onClick={() => {
        handleAddItem();
      }}
    >
      เพิ่ม
    </Button>
  );

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  // console.log('selectCustomer', selectCustomer);

  if (
    consignmentInvoice.isLoading ||
    product.isLoading ||
    customer.isLoading ||
    me.isLoading ||
    productStockLot.isLoading
  ) {
    return <Loading />;
  }
  if (
    !consignmentInvoice.isLoading &&
    consignmentInvoice.isCompleted &&
    !product.isLoading &&
    product.isCompleted &&
    !customer.isLoading &&
    customer.isCompleted &&
    !me.isLoading &&
    me.isCompleted &&
    !productStockLot.isLoading &&
    productStockLot.isCompleted
  ) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <CreateCustomer
          openAddCustomer={openAddCustomer}
          handleCloseAddCustomer={handleCloseAddCustomer}
          dialogCustomer={dialogCustomer}
          setCustomerData={setCustomerData}
          setDialogCustomer={setDialogCustomer}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-wrap gap-y-2">
            <div className="w-full px-1 min-h-full">
              <Card className="p-4 ">
                <div className="flex flex-wrap">
                  <div className="w-full">{rendInfomationTextField()}</div>
                </div>
              </Card>
            </div>
          </div>
          <div className="flex flex-wrap my-2">
            <div className="w-full  px-1 min-h-full">
              <div className="flex flex-wrap">
                <div className="w-full">{renderTable()}</div>
              </div>
            </div>
          </div>
          <div className="m-1 flex justify-between">
            {renderAddButton()}
            <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    );
  }
  return <Error />;
}

EditConsignmentInvoice.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditConsignmentInvoice.defaultProps = {
  title: '',
  subtitle: '',
};

export default EditConsignmentInvoice;
