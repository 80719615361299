/* eslint-disable implicit-arrow-linebreak */
import pdfMake from 'addthaifont-pdfmake/build/pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import _ from 'lodash';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

import qrcode from 'qrcode';

// import currencyFormatterTH from '../../utils/functions/currencyFormatterTH';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};

const genProduct = (data) =>
  _.map(data, (_data) => [
    {
      alignment: 'left',
      margin: [0, 2, 0, 2],
      text: `${_data?.product_type?.name || '-'} ${_data?.product?.tag || '-'}`,
      fontSize: '12',
    },
    {
      alignment: 'left',
      margin: [0, 2, 0, 2],
      text: `${_data?.inventory / _data?.product?.weight || '-'} x ${
        _data?.product?.weight || '-'
      }`,
      fontSize: '12',
    },
    {
      alignment: 'left',
      margin: [0, 2, 0, 2],
      text: `${_data?.customer?.name || '-'}`,
      fontSize: '12',
    },
    {
      alignment: 'left',
      margin: [0, 2, 0, 2],
      text: `${_data?.consignment_invoice?.name || '-'} / ${
        _data?.consignment_invoice?.car_registration || '-'
      }`,
      fontSize: '12',
    },
  ]);

// eslint-disable-next-line import/prefer-default-export
export const PalletInvoice = (locationData, palletData, meData, payload) => {
  const productData = genProduct(palletData?.products);

  const opts = {
    errorCorrectionLevel: 'H',
    type: 'image/jpeg',
    mode: 'Alphanumeric',
    quality: 0.8,
    margin: 1,
    width: 250,
  };

  const genQR = (data) => {
    let resQR;
    qrcode.toDataURL(data, opts, (err, res) => {
      if (err) throw err;
      resQR = res;
    });
    return resQR;
  };

  const docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [20, 30, 40, 20],
    defaultStyle: {
      font: 'Sarabun',
    },
    info: {
      title: 'ใบสำหรับติดพาเลท',
    },
    content: [
      {
        columns: [
          [
            {
              alignment: 'center',
              margin: [0, 10, 0, 12],
              text: 'ใบปะพาเลท',
              fontSize: '16',
              bold: true,
            },
            {
              style: 'tableExample',
              table: {
                widths: ['*', '*'],
                heights: 40,
                body: [
                  [
                    {
                      alignment: 'left',
                      margin: [0, 10, 0, 3],
                      text: 'เลขที่พาเลท',
                      fontSize: '14',
                    },
                    {
                      alignment: 'left',
                      margin: [0, 10, 0, 3],
                      text: `${palletData?.name_running || '-'}`,
                      fontSize: '14',
                    },
                  ],
                  [
                    {
                      alignment: 'left',
                      margin: [0, 10, 0, 3],
                      text: 'ตำแหน่ง',
                      fontSize: '14',
                    },
                    {
                      alignment: 'left',
                      margin: [0, 10, 0, 3],
                      text: `${locationData?.warehouse || '-'}  แถว ${
                        locationData?.row || '-'
                      }`,
                      fontSize: '14',
                    },
                  ],
                  [
                    {
                      alignment: 'left',
                      margin: [0, 10, 0, 3],
                      text: 'วันที่เข้าคลัง',
                      fontSize: '14',
                    },
                    {
                      alignment: 'left',
                      margin: [0, 10, 0, 3],
                      text: `${
                        dayjs(new Date(palletData?.date_warehouse))
                          .locale('th')
                          .format('DD MMM BBBB HH:mm') || '-'
                      }`,
                      fontSize: '14',
                    },
                  ],
                  [
                    {
                      alignment: 'left',
                      margin: [0, 10, 0, 3],
                      text: 'วันที่ออก',
                      fontSize: '14',
                    },
                    {
                      alignment: 'left',
                      margin: [0, 10, 0, 3],
                      text: '-',
                      fontSize: '14',
                    },
                  ],
                  [
                    {
                      alignment: 'left',
                      margin: [0, 10, 0, 3],
                      text: 'ผู้ดำเนินการ',
                      fontSize: '14',
                    },
                    {
                      alignment: 'left',
                      margin: [0, 10, 0, 3],
                      text: `${meData?.userData?.firstname || '-'} `,
                      fontSize: '14',
                    },
                  ],
                ],
              },
            },
            {
              alignment: 'center',
              margin: [0, 10, 0, 12],
              text: 'รายการสินค้าในพาเลท',
              fontSize: '16',
              bold: true,
            },
            {
              style: 'tableExample',
              table: {
                widths: ['*', '*', '*', '*'],
                heights: 40,
                body: [
                  [
                    {
                      alignment: 'left',
                      margin: [0, 2, 0, 2],
                      text: 'ชื่อสินค้า',
                      fontSize: '12',
                    },
                    {
                      alignment: 'left',
                      margin: [0, 2, 0, 2],
                      text: 'จำนวน',
                      fontSize: '12',
                    },
                    {
                      alignment: 'left',
                      margin: [0, 2, 0, 2],
                      text: 'ลูกค้า',
                      fontSize: '12',
                    },
                    {
                      alignment: 'left',
                      margin: [0, 2, 0, 2],
                      text: 'เลขใบรับฝาก / ทะเบียนรถ',
                      fontSize: '12',
                    },
                  ],
                  ...productData,
                ],
              },
            },
          ],
          [
            {
              alignment: 'center',
              margin: [0, 5, 0, 10],
              text: 'QR CODE',
              fontSize: '18',
              bold: true,
            },
            {
              alignment: 'center',
              margin: [0, 8, 0, 20],
              text: 'แสกนเพื่อดูข้อมูลรายการสินค้าในพาเลท',
              fontSize: '18',
              bold: true,
            },
            {
              alignment: 'center',
              image: 'QR',
              width: 200,
              opacity: 0.8,
              margin: [0, 0, 0, 0],
            },
            // {
            //   images: {
            //     building: genQR(payload?.url || '1234'),
            //   },
            // },
          ],
        ],
      },
    ],
    images: { QR: genQR(payload?.url || '1234') },
  };
  pdfMake.createPdf(docDefinition).open();
};
