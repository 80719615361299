import React from 'react';
import { Button } from '@mui/material';
import { useHistory } from 'react-router';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default function BackButton() {
  const history = useHistory();
  const handlePushBack = () => {
    history.goBack();
  };
  return (
    <Button
      variant="outlined"
      startIcon={<ArrowBackIosIcon />}
      onClick={handlePushBack}
    >
      กลับ
    </Button>
  );
}
