import { TextField } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

export function PalletTypeForm({ errors, palletType, Controller, control }) {
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={palletType ? palletType.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อประเภทพาเลท"
              fullWidth
              required
              size={'small'}
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'description'}
          control={control}
          defaultValue={palletType ? palletType.description : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รายละเอียด"
              fullWidth
              size={'small'}
              helperText={errors.description && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-1/2 px-1 py-2">
        <Controller
          name={'capacity'}
          control={control}
          defaultValue={palletType ? palletType.capacity : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ความจุสูงสุดของพาเลท"
              fullWidth
              required
              type="number"
              size={'small'}
            />
          )}
        />
      </div>
      <div className="w-1/2 px-1 py-2">
        <Controller
          name={'capacity_unit'}
          control={control}
          defaultValue={'กิโลกรัม'}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              disabled
              label="หน่วยของความจุ"
              fullWidth
              required
              size={'small'}
              multiline={true}
            />
          )}
        />
      </div>
    </div>
  );
}

PalletTypeForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    capacity: PropTypes.number,
    capacity_unit: PropTypes.string,
  }),
  palletType: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
};

PalletTypeForm.defaultProps = {
  palletType: null,
};

export default PalletTypeForm;
