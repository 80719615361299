import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { WMS } from '../../utils/Proptypes';

const resetValues = {
  name: '',
};

const AddSerialNumberDialog = ({
  open,
  handleClose,
  selectSerial,
  setSelectSerial,
}) => {
  const {
    control,
    formState: { errors },
    getValues,
    reset,
  } = useForm();
  const [reload, setReload] = useState(false);
  useEffect(() => () => {}, [reload]);

  const handleAddSerial = () => {
    if (getValues('name') === '') {
      alert('กรุณาใส่ข้อมูลให้ครบถ้วน');
    } else {
      const data = {
        name: getValues('name'),
      };
      // eslint-disable-next-line react/prop-types
      selectSerial.push(data);
      setSelectSerial(selectSerial);
      reset(resetValues);
      setReload(!reload);
    }
  };

  const handleRemoveOption = (index) => {
    // eslint-disable-next-line react/prop-types
    selectSerial.splice(index, 1);
    setSelectSerial(selectSerial);
    setReload(!reload);
  };

  // Show Product list to search and add

  // Show Product that already added
  const renderAddedSerialShow = () => (
    <TableContainer>
      <Table size="small" className="border-lg">
        <TableHead className="bg-yellow-100">
          <TableRow>
            <TableCell>
              <div className="font-bold">ลำดับที่</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> เลข S/N</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ดำเนินการ</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!_.isEmpty(selectSerial) ? (
            selectSerial.map((_option, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{_option?.name}</TableCell>
                <TableCell>
                  <Button
                    color={'error'}
                    variant="contained"
                    size={'small'}
                    onClick={() => handleRemoveOption(index)}
                  >
                    ลบ
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow key={''}>
              <TableCell colSpan={6}>
                <div className="text-center">ยังไม่มี S/N</div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">
        <div className="flex justify-between">
          <div>เพิ่ม Serial Number</div>
          <Button onClick={handleClose}>ปิด</Button>
        </div>
      </DialogTitle>

      <DialogContent>
        {renderAddedSerialShow()}
        <div className="w-full px-1 pb-5 flex">
          <div className="w-full">
            <Controller
              name={'name'}
              control={control}
              defaultValue={''}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="เลข S/N"
                  fullWidth
                  size={'small'}
                  helperText={errors.name && 'กรุณาใส่ข้อมูล'}
                  onKeyPress={(ev) => {
                    console.log(`Pressed keyCode ${ev.key}`);
                    if (ev.key === 'Enter') {
                      handleAddSerial();
                      ev.preventDefault();
                    }
                  }}
                />
              )}
            />
          </div>
          <div className="px-2">
            <Button variant="contained" onClick={() => handleAddSerial()}>
              เพิ่ม
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddSerialNumberDialog;

AddSerialNumberDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  selectedProduct: PropTypes.arrayOf(
    PropTypes.shape({
      product: PropTypes.oneOfType([WMS.Product, PropTypes.string]),
      amount: PropTypes.number,
    }),
  ),
  handleAddProduct: PropTypes.func,
  handleRemoveProduct: PropTypes.func,
  selectSerial: PropTypes.array,
  setSelectSerial: PropTypes.func,
};

AddSerialNumberDialog.defaultProps = {
  open: false,
};
