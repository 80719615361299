import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Card, Button, TextField } from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';
import PivotTableChartIcon from '@mui/icons-material/PivotTableChart';

import { useParams, useHistory } from 'react-router';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { WarehouseForm } from '../../components/Forms';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

export default function EditWarehouse({ title, subtitle }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const warehouse = useSelector((state) => state.warehouse);
  const [showWarehouseDiagram, setShowWarehouseDiagram] = useState(false);
  const [board, setBoard] = useState([]);

  console.log('warehouse', !_.isEmpty(warehouse?.pallet_in_warehouse));

  const {
    formState: { errors },
    handleSubmit,
    control,
    getValues,
  } = useForm({
    defaultValues: {
      name: warehouse.name,
      address: warehouse.address,
      capacity: warehouse.capacity,
      capacity_unit: warehouse.capacity_unit,
    },
  });

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    try {
      console.log(data);
      await dispatch(actions.warehousePut(id, data));
      alert('สำเร็จ');
      await dispatch(actions.warehouseGet(id));
      history.goBack();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(actions.warehouseGet(id));
    return () => {};
  }, []);

  const createWarehouseDiagram = (position) => {
    const elementCol = [];
    // eslint-disable-next-line no-plusplus
    for (let indexI = 0; indexI < position.col; indexI++) {
      const elementRow = [];
      // eslint-disable-next-line no-plusplus
      for (let indexJ = 0; indexJ < position.row; indexJ++) {
        elementRow.push(0);
      }
      elementCol.push(elementRow);
    }

    console.log('elementCol', elementCol);

    setBoard(elementCol);
  };

  const showDiagram = () => {
    if (
      getValues('row') === '' ||
      getValues('col') === '' ||
      getValues('height') === ''
    ) {
      alert('กรุณาใส่ข้อมูลให้ครบถ้วน');
    } else {
      const position = {
        row: parseInt(getValues('row'), 10),
        col: parseInt(getValues('col'), 10),
        height: parseInt(getValues('height'), 10),
      };
      createWarehouseDiagram(position);
      setShowWarehouseDiagram(true);
    }
  };

  if (warehouse.isLoading || warehouse.rows) {
    return <Loading />;
  }
  if (!warehouse.isLoading && warehouse.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
          <div className="text-lg font-semibold ml-6">แก้ไขข้อมูลสถานที่</div>
        </div>
        <div>
          <Card className="p-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <WarehouseForm
                errors={errors}
                warehouse={warehouse}
                control={control}
                Controller={Controller}
              />
              {/* <div className="py-2">ตั้งค่าความจุของคลัง</div>
              <div className="grid grid-cols-4 gap-1">
                <div className="">
                  <Controller
                    name={'col'}
                    control={control}
                    rules={{ required: true }}
                    defaultValue={warehouse ? warehouse?.col : ''}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="จำนวนแถว"
                        fullWidth
                        type="number"
                        size={'small'}
                        disabled={!_.isEmpty(warehouse?.pallet_in_warehouse)}
                        required
                      />
                    )}
                  />
                </div>
                <div className="">
                  <Controller
                    name={'row'}
                    control={control}
                    rules={{ required: true }}
                    defaultValue={warehouse ? warehouse?.row : ''}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="ความลึก"
                        fullWidth
                        type="number"
                        size={'small'}
                        disabled={!_.isEmpty(warehouse?.pallet_in_warehouse)}
                        required
                      />
                    )}
                  />
                </div>
                <div className="">
                  <Controller
                    name={'height'}
                    control={control}
                    rules={{ required: true }}
                    defaultValue={warehouse ? warehouse?.height : ''}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="ความสูง"
                        fullWidth
                        type="number"
                        size={'small'}
                        disabled={!_.isEmpty(warehouse?.pallet_in_warehouse)}
                        required
                      />
                    )}
                  />
                </div>
                <div className="my-auto ">
                  <Button
                    startIcon={<PivotTableChartIcon />}
                    variant="outlined"
                    fullWidth
                    onClick={() => {
                      showDiagram();
                    }}
                  >
                    แสดงแผนภาพ
                  </Button>
                </div>
              </div>

              {showWarehouseDiagram && (
                <>
                  <div className="pt-4 text-center text-xl">แผนภาพคลัง</div>
                  <div className="flex justify-center pt-2 overflow-x-auto">
                    <div className="overflow-auto">
                      {board.map((row, i) => (
                        <div key={i} className="flex flex-nowrap">
                          {row.map((col, j) => (
                            <Button key={j} variant="outlined" size={'small'}>
                              {i * _.size(row) + j + 1}
                            </Button>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )} */}
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<SaveIcon />}
                >
                  บันทึก
                </Button>
              </div>
            </form>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

EditWarehouse.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditWarehouse.defaultProps = {
  title: '',
  subtitle: '',
};
