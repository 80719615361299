import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  CreateOrder,
  Orders,
  DetailOrder,
  CreateExport,
  Exports,
  DetailExport,
  Onlines,
  DetailOnline,
} from '../views/SPM';
import { HomeNavbar } from '../components/Navbars';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import { Loading } from '../components/Loading';
import { NotFound, NotAuthorized } from '../components/Error';
import accessRight from '../utils/functions/accessRight';
import * as actions from '../redux/actions';
// Sales and Purchase Management
export const SPM = () => {
  const module = 'SPM';
  const prefix = '/spm';
  const name = 'จัดการคำสั่งซื้อ';

  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const availableModule = useSelector((state) => state.availableModule);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };

  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    console.log('Layout: SPM');
    dispatch(actions.meGet());
    dispatch(actions.availableModuleAll());
    return () => {};
  }, []);

  if (!me?.userData) {
    return <Loading />;
  }

  if (accessRight(me, module, 0, availableModule)) {
    return (
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <div className="lg:ml-64  min-h-screen  pt-16 px-8 ">
          <div className="py-4">
            <Switch>
              <Redirect exact from={`${prefix}`} to={`${prefix}/online`} />
              <Route exact path={`${prefix}/online`}>
                <Onlines title="ขายออนไลน์" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/online/:id`}>
                <DetailOnline title="รายละเอียดการขายออนไลน์" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/orders`}>
                <Orders title="การนำเข้าสินค้า" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/orders/create`}>
                <CreateOrder title="เพิ่มการนำเข้า" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/orders/:id`}>
                <DetailOrder title="รายละเอียดการนำเข้า" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/exports`}>
                <Exports title="การส่งออกสินค้า" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/exports/create`}>
                <CreateExport title="เพิ่มการส่งออก" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/exports/:id`}>
                <DetailExport title="รายละเอียดการส่งออก" subtitle={name} />
              </Route>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </div>
        </div>
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <NotAuthorized />
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    </div>
  );
};

export default SPM;
