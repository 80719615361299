// common type
export const ME_GET = 'ME_GET';
export const ME_RESET = 'ME_RESET';

export const USER_ALL = 'USER_ALL';
export const USER_GET = 'USER_GET';
export const USER_POST = 'USER_POST';
export const USER_PUT = 'USER_PUT';
export const USER_DEL = 'USER_DEL';
export const USER_CREATE = 'USER_CREATE';
export const USER_LOADING = 'USER_LOADING';
export const USER_ERROR = 'USER_ERROR';

// feature type
export const INFO_GET = 'INFO_GET';
export const INFO_CREATE = 'INFO_CREATE';
export const INFO_PUT = 'INFO_PUT';
export const INFO_RESET = 'INFO_RESET';
export const INFO_LOADING = 'INFO_LOADING';
export const INFO_ERROR = 'INFO_ERROR';

export const CUSTOMER_ALL = 'CUSTOMER_ALL';
export const CUSTOMER_GET = 'CUSTOMER_GET';
export const CUSTOMER_POST = 'CUSTOMER_POST';
export const CUSTOMER_PUT = 'CUSTOMER_PUT';
export const CUSTOMER_DEL = 'CUSTOMER_DEL';
export const CUSTOMER_RESET = 'CUSTOMER_RESET';
export const CUSTOMER_LOADING = 'CUSTOMER_LOADING';
export const CUSTOMER_ERROR = 'CUSTOMER_ERROR';

export const CUSTOMER_TYPE_ALL = 'CUSTOMER_TYPE_ALL';
export const CUSTOMER_TYPE_GET = 'CUSTOMER_TYPE_GET';
export const CUSTOMER_TYPE_POST = 'CUSTOMER_TYPE_POST';
export const CUSTOMER_TYPE_PUT = 'CUSTOMER_TYPE_PUT';
export const CUSTOMER_TYPE_DEL = 'CUSTOMER_TYPE_DEL';
export const CUSTOMER_TYPE_LOADING = 'CUSTOMER_TYPE_LOADING';
export const CUSTOMER_TYPE_ERROR = 'CUSTOMER_TYPE_ERROR';

export const DEPARTMENT_ALL = 'DEPARTMENT_ALL';
export const DEPARTMENT_GET = 'DEPARTMENT_GET';
export const DEPARTMENT_POST = 'DEPARTMENT_POST';
export const DEPARTMENT_PUT = 'DEPARTMENT_PUT';
export const DEPARTMENT_DEL = 'DEPARTMENT_DEL';
export const DEPARTMENT_LOADING = 'DEPARTMENT_LOADING';
export const DEPARTMENT_ERROR = 'DEPARTMENT_ERROR';

export const ROLETYPES_ALL = 'ROLETYPES_ALL';
export const ROLETYPES_GET = 'ROLETYPES_GET';
export const ROLETYPES_POST = 'ROLETYPES_POST';
export const ROLETYPES_PUT = 'ROLETYPES_PUT';
export const ROLETYPES_DEL = 'ROLETYPES_DEL';
export const ROLETYPES_LOADING = 'ROLETYPES_LOADING';
export const ROLETYPES_ERROR = 'ROLETYPES_ERROR';

export const EMPLOYEE_ALL = 'EMPLOYEE_ALL';
export const EMPLOYEE_GET = 'EMPLOYEE_GET';
export const EMPLOYEE_POST = 'EMPLOYEE_POST';
export const EMPLOYEE_PUT = 'EMPLOYEE_PUT';
export const EMPLOYEE_DEL = 'EMPLOYEE_DEL';
export const EMPLOYEE_RESET = 'EMPLOYEE_RESET';
export const EMPLOYEE_LOADING = 'EMPLOYEE_LOADING';
export const EMPLOYEE_ERROR = 'EMPLOYEE_ERROR';

export const MATERIALTYPES_ALL = 'MATERIALTYPES_ALL';
export const MATERIALTYPES_GET = 'MATERIALTYPES_GET';
export const MATERIALTYPES_POST = 'MATERIALTYPES_POST';
export const MATERIALTYPES_PUT = 'MATERIALTYPES_PUT';
export const MATERIALTYPES_DEL = 'MATERIALTYPES_DEL';
export const MATERIALTYPES_LOADING = 'MATERIALTYPES_LOADING';
export const MATERIALTYPES_ERROR = 'MATERIALTYPES_ERROR';

export const MATERIAL_ALL = 'MATERIAL_ALL';
export const MATERIAL_GET = 'MATERIAL_GET';
export const MATERIAL_GET_BY_TYPE = 'MATERIAL_GET_BY_TYPE';
export const MATERIAL_POST = 'MATERIAL_POST';
export const MATERIAL_PUT = 'MATERIAL_PUT';
export const MATERIAL_DEL = 'MATERIAL_DEL';
export const MATERIAL_LOADING = 'MATERIAL_LOADING';
export const MATERIAL_ERROR = 'MATERIAL_ERROR';

export const MATERIAL_TRANSACTION_TYPES_ALL = 'MATERIAL_TRANSACTION_TYPES_ALL';
export const MATERIAL_TRANSACTION_TYPES_GET = 'MATERIAL_TRANSACTION_TYPES_GET';
export const MATERIAL_TRANSACTION_TYPES_POST =
  'MATERIAL_TRANSACTION_TYPES_POST';
export const MATERIAL_TRANSACTION_TYPES_PUT = 'MATERIAL_TRANSACTION_TYPES_PUT';
export const MATERIAL_TRANSACTION_TYPES_DEL = 'MATERIAL_TRANSACTION_TYPES_DEL';
export const MATERIAL_TRANSACTION_TYPES_LOADING =
  'MATERIAL_TRANSACTION_TYPES_LOADING';
export const MATERIAL_TRANSACTION_TYPES_ERROR =
  'MATERIAL_TRANSACTION_TYPES_ERROR';

export const PLACE_ALL = 'PLACE_ALL';
export const PLACE_GET = 'PLACE_GET';
export const PLACE_POST = 'PLACE_POST';
export const PLACE_PUT = 'PLACE_PUT';
export const PLACE_DEL = 'PLACE_DEL';
export const PLACE_LOADING = 'PLACE_LOADING';
export const PLACE_ERROR = 'PLACE_ERROR';

export const PRODUCT_TRANSACTION_ALL = 'PRODUCT_TRANSACTION_ALL';
export const PRODUCT_TRANSACTION_GET = 'PRODUCT_TRANSACTION_GET';
export const PRODUCT_TRANSACTION_POST = 'PRODUCT_TRANSACTION_POST';
export const PRODUCT_TRANSACTION_PUT = 'PRODUCT_TRANSACTION_PUT';
export const PRODUCT_TRANSACTION_DEL = 'PRODUCT_TRANSACTION_DEL';
export const PRODUCT_TRANSACTION_LOADING = 'PRODUCT_TRANSACTION_LOADING';
export const PRODUCT_TRANSACTION_ERROR = 'PRODUCT_TRANSACTION_ERROR';

export const MATERIAL_STOCK_LOT_ALL = 'MATERIAL_STOCK_LOT_ALL';
export const MATERIAL_STOCK_LOT_GET = 'MATERIAL_STOCK_LOT_GET';
export const MATERIAL_STOCK_LOT_POST = 'MATERIAL_STOCK_LOT_POST';
export const MATERIAL_STOCK_LOT_PUT = 'MATERIAL_STOCK_LOT_PUT';
export const MATERIAL_STOCK_LOT_DEL = 'MATERIAL_STOCK_LOT_DEL';
export const MATERIAL_STOCK_LOT_LOADING = 'MATERIAL_STOCK_LOT_LOADING';
export const MATERIAL_STOCK_LOT_ERROR = 'MATERIAL_STOCK_LOT_ERROR';

export const ORDER_ALL = 'ORDER_ALL';
export const ORDER_GET = 'ORDER_GET';
export const ORDER_POST = 'ORDER_POST';
export const ORDER_PUT = 'ORDER_PUT';
export const ORDER_DEL = 'ORDER_DEL';
export const ORDER_LOADING = 'ORDER_LOADING';
export const ORDER_ERROR = 'ORDER_ERROR';

export const PROCESS_TEMPLATE_ALL = 'PROCESS_TEMPLATE_ALL';
export const PROCESS_TEMPLATE_GET = 'PROCESS_TEMPLATE_GET';
export const PROCESS_TEMPLATE_POST = 'PROCESS_TEMPLATE_POST';
export const PROCESS_TEMPLATE_PUT = 'PROCESS_TEMPLATE_PUT';
export const PROCESS_TEMPLATE_DEL = 'PROCESS_TEMPLATE_DEL';
export const PROCESS_TEMPLATE_LOADING = 'PROCESS_TEMPLATE_LOADING';
export const PROCESS_TEMPLATE_ERROR = 'PROCESS_TEMPLATE_ERROR';

export const PROCESS_OF_ORDER_ALL = 'PROCESS_OF_ORDER_ALL';
export const PROCESS_OF_ORDER_GET = 'PROCESS_OF_ORDER_GET';
export const PROCESS_OF_ORDER_POST = 'PROCESS_OF_ORDER_POST';
export const PROCESS_OF_ORDER_PUT = 'PROCESS_OF_ORDER_PUT';
export const PROCESS_OF_ORDER_DEL = 'PROCESS_OF_ORDER_DEL';
export const PROCESS_OF_ORDER_LOADING = 'PROCESS_OF_ORDER_LOADING';
export const PROCESS_OF_ORDER_ERROR = 'PROCESS_OF_ORDER_ERROR';

export const WAREHOUSE_ALL = 'WAREHOUSE_ALL';
export const WAREHOUSE_GET = 'WAREHOUSE_GET';
export const WAREHOUSE_POST = 'WAREHOUSE_POST';
export const WAREHOUSE_PUT = 'WAREHOUSE_PUT';
export const WAREHOUSE_DEL = 'WAREHOUSE_DEL';
export const WAREHOUSE_LOADING = 'WAREHOUSE_LOADING';
export const WAREHOUSE_ERROR = 'WAREHOUSE_ERROR';

export const PRODUCTTYPES_ALL = 'PRODUCTTYPES_ALL';
export const PRODUCTTYPES_GET = 'PRODUCTTYPES_GET';
export const PRODUCTTYPES_POST = 'PRODUCTTYPES_POST';
export const PRODUCTTYPES_PUT = 'PRODUCTTYPES_PUT';
export const PRODUCTTYPES_DEL = 'PRODUCTTYPES_DEL';
export const PRODUCTTYPES_LOADING = 'PRODUCTTYPES_LOADING';
export const PRODUCTTYPES_ERROR = 'MATERIALTYPES_ERROR';

export const PRODUCT_ALL = 'PRODUCT_ALL';
export const PRODUCT_GET = 'PRODUCT_GET';
export const PRODUCT_GET_BY_TYPE = 'PRODUCT_GET_BY_TYPE';
export const PRODUCT_POST = 'PRODUCT_POST';
export const PRODUCT_PUT = 'PRODUCT_PUT';
export const PRODUCT_DEL = 'PRODUCT_DEL';
export const PRODUCT_LOADING = 'PRODUCT_LOADING';
export const PRODUCT_ERROR = 'PRODUCT_ERROR';

export const PRODUCT_TRANSACTION_TYPES_ALL = 'PRODUCT_TRANSACTION_TYPES_ALL';
export const PRODUCT_TRANSACTION_TYPES_GET = 'PRODUCT_TRANSACTION_TYPES_GET';
export const PRODUCT_TRANSACTION_TYPES_POST = 'PRODUCT_TRANSACTION_TYPES_POST';
export const PRODUCT_TRANSACTION_TYPES_PUT = 'PRODUCT_TRANSACTION_TYPES_PUT';
export const PRODUCT_TRANSACTION_TYPES_DEL = 'PRODUCT_TRANSACTION_TYPES_DEL';
export const PRODUCT_TRANSACTION_TYPES_LOADING =
  'PRODUCT_TRANSACTION_TYPES_LOADING';
export const PRODUCT_TRANSACTION_TYPES_ERROR =
  'PRODUCT_TRANSACTION_TYPES_ERROR';

export const PRODUCT_STOCK_LOT_ALL = 'PRODUCT_STOCK_LOT_ALL';
export const PRODUCT_STOCK_LOT_GET = 'PRODUCT_STOCK_LOT_GET';
export const PRODUCT_STOCK_LOT_POST = 'PRODUCT_STOCK_LOT_POST';
export const PRODUCT_STOCK_LOT_PUT = 'PRODUCT_STOCK_LOT_PUT';
export const PRODUCT_STOCK_LOT_DEL = 'PRODUCT_STOCK_LOT_DEL';
export const PRODUCT_STOCK_LOT_LOADING = 'PRODUCT_STOCK_LOT_LOADING';
export const PRODUCT_STOCK_LOT_ERROR = 'PRODUCT_STOCK_LOT_ERROR';

export const LOG_ALL = 'LOG_ALL';
export const LOG_GET = 'LOG_GET';
export const LOG_CREATE = 'LOG_CREATE';
export const LOG_UPDATE = 'LOG_UPDATE';
export const LOG_DELETE = 'LOG_DELETE';
export const LOG_ERROR = 'LOG_ERROR';

export const LOG_MOVE_PALLET_ALL = 'LOG_MOVE_PALLET_ALL';
export const LOG_MOVE_PALLET_GET = 'LOG_MOVE_PALLET_GET';
export const LOG_MOVE_PALLET_CREATE = 'LOG_MOVE_PALLET_CREATE';
export const LOG_MOVE_PALLET_UPDATE = 'LOG_MOVE_PALLET_UPDATE';
export const LOG_MOVE_PALLET_DELETE = 'LOG_MOVE_PALLET_DELETE';
export const LOG_MOVE_PALLET_ERROR = 'LOG_MOVE_PALLET_ERROR';
export const LOG_MOVE_PALLET_LOADING = 'LOG_MOVE_PALLET_LOADING';

export const LOG_PRODUCT_STOCK_LOT_ALL = 'LOG_PRODUCT_STOCK_LOT_ALL';
export const LOG_PRODUCT_STOCK_LOT_GET = 'LOG_PRODUCT_STOCK_LOT_GET';
export const LOG_PRODUCT_STOCK_LOT_CREATE = 'LOG_PRODUCT_STOCK_LOT_CREATE';
export const LOG_PRODUCT_STOCK_LOT_UPDATE = 'LOG_PRODUCT_STOCK_LOT_UPDATE';
export const LOG_PRODUCT_STOCK_LOT_DELETE = 'LOG_PRODUCT_STOCK_LOT_DELETE';
export const LOG_PRODUCT_STOCK_LOT_ERROR = 'LOG_PRODUCT_STOCK_LOT_ERROR';
export const LOG_PRODUCT_STOCK_LOT_LOADING = 'LOG_PRODUCT_STOCK_LOT_LOADING';

export const SERIAL_ALL = 'SERIAL_ALL';
export const SERIAL_GET = 'SERIAL_GET';
export const SERIAL_CREATE = 'SERIAL_CREATE';
export const SERIAL_UPDATE = 'SERIAL_UPDATE';
export const SERIAL_DELETE = 'SERIAL_DELETE';
export const SERIAL_ERROR = 'SERIAL_ERROR';

export const AVAILABLE_MODULE_ALL = 'AVAILABLE_MODULE_ALL';
export const AVAILABLE_MODULE_ERROR = 'AVAILABLE_MODULE_ERROR';
export const AVAILABLE_MODULE_LOADING = 'AVAILABLE_MODULE_LOADING';
export const AVAILABLE_MODULE_PUT = 'AVAILABLE_MODULE_PUT';

export const PAYMENT_TRANSACTION_ALL = 'PAYMENT_TRANSACTION_ALL';
export const PAYMENT_TRANSACTION_GET = 'PAYMENT_TRANSACTION_GET';
export const PAYMENT_TRANSACTION_PUT = 'PAYMENT_TRANSACTION_PUT';
export const PAYMENT_TRANSACTION_DEL = 'PAYMENT_TRANSACTION_DEL';
export const PAYMENT_TRANSACTION_LOADING = 'PAYMENT_TRANSACTION_LOADING';
export const PAYMENT_TRANSACTION_ERROR = 'PAYMENT_TRANSACTION_ERROR';

export const CONSIGNMENT_INVOICE_ALL = 'CONSIGNMENT_INVOICE_ALL';
export const CONSIGNMENT_INVOICE_GET = 'CONSIGNMENT_INVOICE_GET';
export const CONSIGNMENT_INVOICE_POST = 'CONSIGNMENT_INVOICE_POST';
export const CONSIGNMENT_INVOICE_PUT = 'CONSIGNMENT_INVOICE_PUT';
export const CONSIGNMENT_INVOICE_DEL = 'CONSIGNMENT_INVOICE_DEL';
export const CONSIGNMENT_INVOICE_RESET = 'CONSIGNMENT_INVOICE_RESET';
export const CONSIGNMENT_INVOICE_LOADING = 'CONSIGNMENT_INVOICE_LOADING';
export const CONSIGNMENT_INVOICE_ERROR = 'CONSIGNMENT_INVOICE_ERROR';

export const STOCKTAKING_INVOICE_ALL = 'STOCKTAKING_INVOICE_ALL';
export const STOCKTAKING_INVOICE_GET = 'STOCKTAKING_INVOICE_GET';
export const STOCKTAKING_INVOICE_POST = 'STOCKTAKING_INVOICE_POST';
export const STOCKTAKING_INVOICE_PUT = 'STOCKTAKING_INVOICE_PUT';
export const STOCKTAKING_INVOICE_DEL = 'STOCKTAKING_INVOICE_DEL';
export const STOCKTAKING_INVOICE_RESET = 'STOCKTAKING_INVOICE_RESET';
export const STOCKTAKING_INVOICE_LOADING = 'STOCKTAKING_INVOICE_LOADING';
export const STOCKTAKING_INVOICE_ERROR = 'STOCKTAKING_INVOICE_ERROR';

export const PALLET_TYPES_ALL = 'PALLET_TYPES_ALL';
export const PALLET_TYPES_GET = 'PALLET_TYPES_GET';
export const PALLET_TYPES_POST = 'PALLET_TYPES_POST';
export const PALLET_TYPES_PUT = 'PALLET_TYPES_PUT';
export const PALLET_TYPES_DEL = 'PALLET_TYPES_DEL';
export const PALLET_TYPES_LOADING = 'PALLET_TYPES_LOADING';
export const PALLET_TYPES_ERROR = 'PALLET_TYPES_ERROR';

export const PALLET_ALL = 'PALLET_ALL';
export const PALLET_GET = 'PALLET_GET';
export const PALLET_POST = 'PALLET_POST';
export const PALLET_PUT = 'PALLET_PUT';
export const PALLET_DEL = 'PALLET_DEL';
export const PALLET_LOADING = 'PALLET_LOADING';
export const PALLET_ERROR = 'PALLET_ERROR';

export const SETTING_ALL = 'SETTING_ALL';
export const SETTING_PUT = 'SETTING_PUT';
export const SETTING_LOADING = 'SETTING_LOADING';
export const SETTING_ERROR = 'SETTING_ERROR';

export const POSITION_ALL = 'POSITION_ALL';
export const POSITION_PUT = 'POSITION_PUT';
export const POSITION_LOADING = 'POSITION_LOADING';
export const POSITION_ERROR = 'POSITION_ERROR';

export const POSITION_SIMPLE_ALL = 'POSITION_SIMPLE_ALL';
export const POSITION_SIMPLE_GET = 'POSITION_SIMPLE_GET';
export const POSITION_SIMPLE_POST = 'POSITION_SIMPLE_POST';
export const POSITION_SIMPLE_PUT = 'POSITION_SIMPLE_PUT';
export const POSITION_SIMPLE_DEL = 'POSITION_SIMPLE_DEL';
export const POSITION_SIMPLE_LOADING = 'POSITION_SIMPLE_LOADING';
export const POSITION_SIMPLE_ERROR = 'POSITION_SIMPLE_ERROR';
