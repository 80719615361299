/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
  Button,
  Card,
  TextField,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Autocomplete,
  Backdrop,
  Modal,
  Fade,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Zoom,
  Box,
  Typography,
} from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';
import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';

import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';

import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { Trash2 as Delete } from 'react-feather';
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { BackButton } from '../../components/Button';
import { ViewTitle } from '../../components/ViewTitle';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';

import { styled } from '@mui/material/styles';

dayjs.locale('th');
dayjs.extend(buddhistEra);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 12,
  borderRadius: 6,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 6,
    // backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

function EditPallet({ title, subtitle }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const productStockLot = useSelector((state) => state.productStockLot);
  const me = useSelector((state) => state.me);
  const product = useSelector((state) => state.product);
  const pallet = useSelector((state) => state.pallet);
  const warehouse = useSelector((state) => state.warehouse);

  const [openModal, setOpenModal] = useState(false);
  const [selectWarehouse, setSelectWarehouse] = useState();
  const [selectFloor, setSelectFloor] = useState();
  const [selectFloorAndPosition, setSelectFloorAndPosition] = useState();
  const [selectPosition, setSelectPosition] = useState();
  const [selectPositionSimple, setSelectPositionSimple] = useState();
  const [positionOfPallet, setPositionOfPallet] = useState();
  const [arrayPalletOnSelect, setArrayPalletOnSelect] = useState([]);
  const [openTooltipArrayPallet, setOpenTooltipArrayPallet] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleCloseTooltipArrayPallet = () => setOpenTooltipArrayPallet(false);

  console.log('selectPositionSimple', selectPositionSimple);
  // console.log('selectWarehouse', selectWarehouse);
  // console.log('selectFloor', selectFloor);
  // console.log('selectFloorAndPosition', selectFloorAndPosition);
  // console.log('selectPosition', selectPosition);
  // console.log('positionOfPallet', positionOfPallet);

  const newProductStockLot = _.map(productStockLot?.rows, (each) => {
    const dataProduct = each?.product_type;
    return each;
  });

  console.log('newProductStockLot', newProductStockLot);
  console.log('product ', product?.rows);

  const { register, control, handleSubmit, setValue } = useForm({
    mode: 'onBlur',
  });
  const { fields, append } = useFieldArray({
    control,
    name: 'itemList',
  });

  useEffect(() => {
    dispatch(actions.palletGet(id));
    dispatch(actions.productStockLotAll({}));
    dispatch(actions.productAllStock());
    dispatch(actions.warehouseAll({}));
    dispatch(actions.meGet());
    return () => {};
  }, []);

  useEffect(() => {
    const thisWarehouse = _.find(
      warehouse?.rows,
      (each) => each?._id === pallet?.warehouse?._id,
    );
    setSelectWarehouse(thisWarehouse);
    return () => {};
  }, [openModal]);

  useEffect(() => {
    console.log('ทำฟังก์ชั่น find pallet');
    console.log('pallet?.rows', pallet?.rows);
    console.log(
      'selectPositionSimple?.pallet_id',
      selectPositionSimple?.pallet_id,
    );

    const newArrayPallet = _.map(
      selectPositionSimple?.pallet_id,
      (eachPalletId) => {
        const findPallet = _.find(
          pallet?.rows,
          (eachPallet) => eachPallet?.id === eachPalletId?.pallet,
        );
        return findPallet;
      },
    );
    console.log('newArrayPallet', newArrayPallet);
    setArrayPalletOnSelect(newArrayPallet);
    return () => {};
  }, [selectPositionSimple]);

  useEffect(() => {
    const positionOfBenz = _.groupBy(selectWarehouse?.positions, 'floor');
    const arrayOfPosition = _.values(positionOfBenz);
    const positionOfWarehouse = _.map(arrayOfPosition, (eachArray, index) => ({
      floor: index + 1,
      available_position: eachArray,
    }));

    setSelectFloor(positionOfWarehouse);

    const findPosition = _.find(
      selectWarehouse?.positions,
      (each) => each?.pallet_id === pallet?._id,
    );

    // console.log('findPosition', findPosition);
    setPositionOfPallet(findPosition);

    return () => {};
  }, [selectWarehouse, openModal]);

  useEffect(() => {
    if (!pallet.isLoading) {
      setValue(
        'itemList',
        _.map(pallet?.products, (eachProduct) => {
          const NewArray = {
            ...eachProduct,
            inventory:
              eachProduct?.inventory /
              (eachProduct?.product_type?.weight ||
                eachProduct?.product?.weight),
          };
          return NewArray;
        }),
      );
    }
    return () => {};
  }, [product, pallet]);

  const handleAddItem = () => {
    append({
      product: newProductStockLot[0]._id,
      inventory: 20,
    });
  };

  const handleDeleteItem = async (item) => {
    // console.log('item', item);
    const confirm = window.confirm('ยืนยันการลบสินค้า');
    if (confirm) {
      try {
        if (item.product_in_pallet_inventory !== 0) {
          alert('ไม่สามารถลบได้เนื่องจากสินค้าถูกบรรจุลงพาเลทเเล้ว');
        } else {
          await dispatch(actions.productStockLotDelete(item.id));
          await dispatch(actions.productStockLotAll({}));
          await dispatch(actions.productAllStock());
          await dispatch(actions.meGet());
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const onSubmit = async (data) => {
    try {
      const newList = _.map(data?.itemList, (item) => {
        const newItem = { ...item, quantity: parseFloat(item.quantity) };
        return newItem;
      });

      const DataOfInvoice = {
        // ...data,
        product: newList,
      };

      console.log('DataOfInvoice', DataOfInvoice);
      console.log('data', data);

      // await dispatch(actions.palletPut(id, DataOfInvoice));
      // alert('สำเร็จ');
      // history.goBack();
    } catch (error) {
      console.error(error);
    }
  };

  const getOpObj = (option) => {
    if (!option) {
      // eslint-disable-next-line no-param-reassign
      option = _.find(product.rows, (op) => op.id === option);
    }
    return option;
  };

  const onSubmitChangePosition = async (data) => {
    console.log('data in submit change', data);
    console.log('selectPosition in submit change', selectPosition);
    console.log('pallet in submit change', pallet);
    const payload = {
      location: {
        col: selectPosition?.col,
        row: selectPosition?.row,
        height: selectPosition?.floor,
      },
      warehouse: data?.warehouse?.id,
      date_warehouse: pallet?.date_warehouse,
    };
    console.log('payload in submit change', payload);

    // alert('สำเร็จ');
    // await dispatch(actions.palletPut(id, payload));
    // history.goBack();
    // await dispatch(actions.palletGet(id));
    // await dispatch(actions.productStockLotAll({}));
    // await dispatch(actions.productAllStock());
    // await dispatch(actions.warehouseAll({}));
    // await dispatch(actions.meGet());
  };

  const progressBarValue = (value, maxValue) => {
    const NewValue = (value * 100) / maxValue;
    return NewValue;
  };

  const messageArrayPalletOnSelect = (
    <div>
      {_.isEmpty(arrayPalletOnSelect) ? (
        <div className="text-base">ไม่มีพาเลทในแถว</div>
      ) : (
        <>
          <div className="text-center text-xl underline">
            แถวที่ {selectPositionSimple?.row}
          </div>
          {_.map(arrayPalletOnSelect, (each, index) => (
            <div className="text-lg">
              {index + 1} : {each?.name || each?.prefix + each?.running_number}{' '}
              ( ความจุ {each?.used_capacity} / {each?.pallet_type?.capacity})
            </div>
          ))}
        </>
      )}
    </div>
  );

  const LinearProgressWithLabel = (value, maxValue) => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <BorderLinearProgress
          variant="determinate"
          value={progressBarValue(value, maxValue)}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">
          {value}/{maxValue}
        </Typography>
      </Box>
    </Box>
  );

  const renderModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModal}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <Card sx={style} className="max-w-2xl max-h-screen">
          <div className="py-2">โปรดระบุตำแหน่งการย้ายพาเลท</div>
          <div className="py-2">
            <form onSubmit={handleSubmit(onSubmitChangePosition)}>
              <div className="flex flex-row flex-wrap">
                <div className="w-full lg:w-1/2 px-1 py-2">
                  <Controller
                    name={'warehouse'}
                    control={control}
                    rules={{ required: true }}
                    defaultValue={selectWarehouse}
                    render={({ field }) => (
                      <Autocomplete
                        size={'small'}
                        {...field}
                        onChange={(e, newValue) => {
                          field.onChange(newValue);
                          setSelectWarehouse(newValue);
                        }}
                        options={warehouse?.rows}
                        getOptionLabel={(type) => `${type?.name}`}
                        required
                        renderInput={(params) => (
                          <TextField {...params} label="คลังสินค้า" required />
                        )}
                      />
                    )}
                  />
                </div>
                {/* {selectWarehouse && (
                  <div className="w-full lg:w-1/2 px-1 py-2">
                    <Controller
                      name={'floor'}
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Autocomplete
                          size={'small'}
                          {...field}
                          onChange={(e, newValue) => {
                            field.onChange(newValue);
                            setSelectFloorAndPosition(newValue);
                          }}
                          options={selectFloor}
                          getOptionLabel={(type) => `${type?.floor}`}
                          required
                          renderInput={(params) => (
                            <TextField {...params} label="เลือกชั้น" required />
                          )}
                        />
                      )}
                    />
                  </div>
                )} */}

                {selectWarehouse?.type === 'simple' && (
                  <div className="w-1/2 text-lg pl-2 my-auto">
                    เลือกแถวที่ต้องการ
                  </div>
                )}
              </div>

              {selectWarehouse?.type === 'simple' && (
                <>
                  <HtmlTooltip
                    open={openTooltipArrayPallet}
                    onClose={handleCloseTooltipArrayPallet}
                    placement="right"
                    TransitionComponent={Zoom}
                    title={messageArrayPalletOnSelect}
                  >
                    <div className="w-full rounded-lg border-2 max-h-screen overflow-y-auto">
                      <div className="text-center text-xl p-2 pt-4">
                        ทางเข้า
                      </div>
                      <div
                        className={`grid grid-flow-col grid-cols-2 grid-rows-${parseInt(
                          selectWarehouse?.row / 2,
                          10,
                        )} gap-x-8 gap-y-1 p-2`}
                      >
                        {_.map(
                          selectWarehouse?.positionsimples,
                          (eachPosition, index) => (
                            <div
                              className={
                                // eslint-disable-next-line no-underscore-dangle
                                selectPositionSimple?._id === eachPosition?._id
                                  ? 'rounded-md border-4 border-gray-500 p-2 cursor-pointer text-white bg-green-400'
                                  : 'rounded-md border-4 border-gray-500 p-2 cursor-pointer'
                              }
                              onClick={() => {
                                setSelectPositionSimple(eachPosition);
                                setOpenTooltipArrayPallet(true);
                              }}
                            >
                              <div className="text-center text-lg">
                                แถว {index + 1}
                              </div>
                              <div>
                                {LinearProgressWithLabel(
                                  eachPosition?.pallet_id.length,
                                  eachPosition?.max_capacity,
                                )}
                              </div>
                            </div>
                          ),
                        )}
                      </div>
                    </div>
                  </HtmlTooltip>
                </>
              )}

              <div className="flex justify-center mt-4 border py-2">
                <div className="w-full my-auto">
                  <div className="text-center">ตำแหน่งเดิม</div>
                  <div className="text-center">
                    {`${pallet?.warehouse?.name} แถว ${pallet?.row}`}{' '}
                  </div>
                </div>
                <div className="my-auto">
                  <ArrowForwardTwoToneIcon fontSize="large" />
                </div>

                <div className="w-full my-auto">
                  <div className="text-center">ตำแหน่งใหม่</div>
                  {selectPosition ? (
                    <div className="text-center">
                      {`${selectWarehouse?.name} แถว ${selectPosition?.row}`}{' '}
                    </div>
                  ) : (
                    <div className="text-center">-</div>
                  )}
                </div>
              </div>

              <div className="flex flex-row justify-end gap-1 py-4">
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<SaveIcon />}
                >
                  บันทึก
                </Button>
                <Button variant="outlined" onClick={handleCloseModal}>
                  ยกเลิก
                </Button>
              </div>
            </form>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderTable = () => (
    <div>
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size={'small'}>
            <colgroup>
              <col width="10%" />
              <col width="45%" />
              <col width="30%" />
              <col width="15%" />
            </colgroup>
            <TableHead>
              <TableRow className="bg-green-100">
                <TableCell>
                  <div className="font-bold py-1">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ชื่อสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">จำนวน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(fields) ? (
                _.map(fields, (item, index) => (
                  <TableRow
                    key={item.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="py-1">{`${index + 1}`}</div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="py-1">
                        <Controller
                          name={`itemList.${index}.product`}
                          control={control}
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              onChange={(event, newValue) => {
                                field.onChange(newValue?.id);
                              }}
                              value={getOpObj(field.value)?.id}
                              disableClearable
                              options={pallet?.products}
                              defaultValue={
                                pallet?.products ? pallet?.products[index] : ' '
                              }
                              getOptionLabel={(option) =>
                                `${
                                  option?.tag || option?.product_type?.tag
                                } : ${option?.product_type?.name} ( ก้อนละ ${
                                  option?.product_type?.weight || option?.weight
                                } กิโลกรัม )`
                              }
                              required
                              // disabled
                              renderInput={(params) => (
                                <TextField {...params} label="สินค้า" />
                              )}
                            />
                          )}
                        />
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="py-1">
                        <Controller
                          name={`itemList.${index}.inventory`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              size="small"
                              fullWidth
                              required
                              type="number"
                              label="จำนวน (ก้อน)"
                              {...field}
                            />
                          )}
                        />
                      </div>
                    </TableCell>

                    <TableCell component="th" scope="row">
                      <Button
                        size="sm"
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          handleDeleteItem(item);
                        }}
                      >
                        <Delete size={16} color="red" />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
  const renderAddButton = () => (
    <Button
      size="small"
      variant="outlined"
      color="success"
      onClick={() => {
        handleAddItem();
      }}
    >
      เพิ่ม
    </Button>
  );

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  // console.log('selectCustomer', selectCustomer);

  if (
    product.isLoading ||
    pallet.isLoading ||
    me.isLoading ||
    productStockLot.isLoading
  ) {
    return <Loading />;
  }
  if (
    !product.isLoading &&
    product.isCompleted &&
    !pallet.isLoading &&
    pallet.isCompleted &&
    !me.isLoading &&
    me.isCompleted &&
    !productStockLot.isLoading &&
    productStockLot.isCompleted
  ) {
    return (
      <div>
        {renderTitle()}
        {renderModal()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-wrap gap-y-2">
            <div className="pb-2">
              <Card className="p-4 ">
                <div className="flex flex-wrap divide-y">
                  <div className="w-full py-4 text-lg font-semibold ">
                    {'รายละเอียด'}
                  </div>
                  <div className="w-1/2 py-4 px-2 font-semibold ">
                    {'ชื่อ/รหัสพาเลท'}
                  </div>
                  <div className="w-1/2 py-4 ">
                    <p>{`${pallet?.name || pallet?.name_running}`}</p>
                    <p>( {`${pallet?.pallet_type?.name}`} )</p>
                  </div>
                  <div className="w-1/2 py-4 px-2 font-semibold ">
                    {'วันที่สร้างพาเลท'}
                  </div>
                  <div className="w-1/2 py-4 ">{`${dayjs(
                    pallet?.createdAt,
                  ).format('D MMM BBBB HH:mm')}`}</div>
                  <div className="w-1/2 py-4 px-2 font-semibold ">
                    {'สถานะพาเลท'}
                  </div>
                  <div className="w-1/2 py-4 ">
                    {pallet?.warehouse_status ? (
                      <p className="text-green-500">ลงคลังเรียบร้อย</p>
                    ) : (
                      <p className="text-red-500">ยังไม่ลงคลัง</p>
                    )}
                  </div>
                  <div className="w-1/2 py-4 px-2 font-semibold ">
                    {'ตำแหน่ง'}
                  </div>
                  <div className="w-1/2 py-4 flex">
                    {pallet?.warehouse_status ? (
                      <>
                        <p className="text-green-500 my-auto pr-4">
                          คลัง{' '}
                          {`${pallet?.warehouse?.name} แถวที่ ${pallet?.row}`}{' '}
                        </p>
                        {/* <Button variant="contained" onClick={handleOpenModal}>
                          เปลี่ยนตำแหน่ง
                        </Button> */}
                      </>
                    ) : (
                      <p className="text-red-500 my-auto pr-4">-</p>
                    )}
                  </div>
                  <div className="w-1/2 py-4 px-2 font-semibold ">
                    {'วันที่เข้าคลัง'}
                  </div>
                  <div className="w-1/2 py-4 ">
                    {pallet?.warehouse_status ? (
                      <p className="text-green-500">{`${dayjs(
                        pallet?.date_warehouse,
                      ).format('D MMM BBBB HH:mm')}`}</p>
                    ) : (
                      <p className="text-red-500">-</p>
                    )}
                  </div>
                </div>
              </Card>
            </div>
          </div>
          <div className="flex flex-wrap my-2">
            <div className="w-full  px-1 min-h-full">
              <div className="flex flex-wrap">
                <div className="w-full">{renderTable()}</div>
              </div>
            </div>
          </div>
          <div className="m-1 flex justify-between">
            {renderAddButton()}
            <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    );
  }
  return <Error />;
}

EditPallet.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditPallet.defaultProps = {
  title: '',
  subtitle: '',
};

export default EditPallet;
