/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
  Button,
  Card,
  TextField,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Checkbox,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import dayjs from 'dayjs';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { Trash2 as Delete } from 'react-feather';
import _ from 'lodash';
import PropTypes from 'prop-types';
import CreateCustomer from '../../components/Dialogs/CreateCustomer';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { BackButton } from '../../components/Button';
import { ViewTitle } from '../../components/ViewTitle';

const filter = createFilterOptions();

function EditConsignmentInvoice({ title, subtitle }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [filterProduct, setFilterProduct] = useState(id);

  const consignmentInvoice = useSelector((state) => state.consignmentInvoice);
  const customer = useSelector((state) => state.customer);
  const productStockLot = useSelector((state) => state.productStockLot);
  const me = useSelector((state) => state.me);
  const product = useSelector((state) => state.product);
  const [consignmentInvoiceType, setConsignmentInvoiceType] = useState(
    consignmentInvoice?.consignment_invoice_type,
  );
  const [customerData, setCustomerData] = useState(
    consignmentInvoice?.customer,
  );
  const [openAddCustomer, toggleOpenAddCustomer] = useState(false);
  const [depositStart, setDepositStart] = useState(
    consignmentInvoice?.deposit_start ? true : false,
  );
  const [depositEnd, setDepositEnd] = useState(
    consignmentInvoice?.deposit_end ? true : false,
  );
  const [loading, setLoading] = useState(false);

  // console.log('consignmentInvoice', consignmentInvoice);
  // console.log('productStockLot', productStockLot?.rows);
  // console.log('product', product?.rows);

  const NewProduct = _.map(product?.rows, (eachProduct, index) => ({
    ...eachProduct,
    index: index + 1,
  }));

  // const newProductStockLot = _.map(productStockLot?.rows, (each) => {
  //   const dataProduct = each?.product_type;
  //   return dataProduct;
  // });

  const newArrayProduct = _.map(product?.rows, (item) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    _.merge(
      { ...item, array_product_stock_lot: {} },
      {
        array_product_stock_lot: _.find(
          productStockLot?.rows,
          (each) => item?.id === each?.product_type?._id,
        ),
      },
    ),
  );

  console.log('newArrayProduct', newArrayProduct);

  const [dialogCustomer, setDialogCustomer] = useState({
    name: '',
  });

  const handleChangeDepositStart = (event) => {
    setDepositStart(event.target.checked);
  };

  const handleChangeDepositEnd = (event) => {
    setDepositEnd(event.target.checked);
  };

  const handleCloseAddCustomer = () => {
    setDialogCustomer({
      name: '',
    });
    toggleOpenAddCustomer(false);
  };

  // console.log('customerData', customerData);

  const handleChangeConsignmentInvoiceType = (event) => {
    setConsignmentInvoiceType(event.target.value);
  };

  const { register, control, handleSubmit, setValue } = useForm({
    mode: 'onBlur',
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'itemList',
  });

  useEffect(() => {
    setFilterProduct(id);
    dispatch(actions.consignmentInvoiceReset());
    dispatch(actions.customerAll({}));
    dispatch(actions.productStockLotAll({ filterProduct }));
    dispatch(actions.consignmentInvoiceGet(id));
    dispatch(actions.productAllStock());
    dispatch(actions.meGet());
    return () => {};
  }, []);

  useEffect(() => {
    if (!productStockLot.isLoading) {
      setValue('itemList', productStockLot?.rows);
      setValue('name', consignmentInvoice?.name);
      setValue('car_registration', consignmentInvoice?.car_registration);
      setValue('description', consignmentInvoice?.description);
      setValue(
        'deposit_start',
        dayjs(new Date(consignmentInvoice?.deposit_start))
          .locale('th')
          .format('YYYY-MM-DD'),
      );
      setValue(
        'deposit_end',
        dayjs(new Date(consignmentInvoice?.deposit_end))
          .locale('th')
          .format('YYYY-MM-DD'),
      );
    }

    setConsignmentInvoiceType(consignmentInvoice?.consignment_invoice_type);
    setCustomerData(consignmentInvoice?.customer);
    setDepositEnd(consignmentInvoice?.deposit_end ? true : false);
    setDepositStart(consignmentInvoice?.deposit_start ? true : false);
    return () => {};
  }, [customer, consignmentInvoice, productStockLot]);

  const handleAddItem = () => {
    append({
      product: productStockLot.rows[0].id,
      quantity: 100,
    });
  };

  const handleDeleteItem = async (item, index) => {
    console.log('item', item);
    if (item._id) {
      const confirm = window.confirm('ยืนยันการลบสินค้า');
      if (confirm) {
        try {
          if (item.product_in_pallet_inventory !== 0) {
            alert('ไม่สามารถลบได้เนื่องจากสินค้าถูกบรรจุลงพาเลทเเล้ว');
          } else {
            const payload = {
              type: 'delete',
              employee: consignmentInvoice?.employee?._id,
              customer: consignmentInvoice?.customer?._id,
              product: item?.product_type?._id,
            };
            console.log('payload', payload);
            await dispatch(actions.productStockLotPut(item?.id, payload));
            alert('สำเร็จ');
            setLoading(true);
            await dispatch(actions.productStockLotAll({ filterProduct }));
            setLoading(false);
            // await dispatch(actions.consignmentInvoiceGet(id));
          }
        } catch (error) {
          console.error(error);
        }
      }
    } else {
      remove(index);
    }
  };

  const onSubmit = async (data) => {
    try {
      console.log('data itemList', data?.itemList);
      const newList = _.map(data?.itemList, (item) => {
        if (item._id) {
          const newItem = {
            _id: item._id,
            status: item.status,
            scrap: item.scrap,
            weight: parseFloat(item.weight),
            tag: item.tag,
            createdAt: item.createdAt,
            updatedAt: item.updatedAt,
            __v: item.__v,
            quantity: parseFloat(item.quantity),
            consignment_invoice:
              item?.consignment_invoice?._id || consignmentInvoice?._id,
            product_type: item?.product_type?._id,
          };
          return newItem;
        }
        const newItem2 = {
          status: item.status,
          scrap: item.scrap,
          weight: parseFloat(item.weight),
          tag: item.tag,
          quantity: parseFloat(item.quantity),
          consignment_invoice: consignmentInvoice?._id,
          product_type: item.product,
        };
        return newItem2;
      });

      const DataOfInvoice = {
        ...data,
        name: data.name,
        description: data.description,
        productList: newList,
        consignment_invoice_type: consignmentInvoiceType,
        employee: me?.userData?.id,
        customer: customerData?._id,
        deposit_start: depositStart ? data?.deposit_start : undefined,
        deposit_end: depositEnd ? data?.deposit_end : undefined,
      };

      console.log('DataOfInvoice', DataOfInvoice);
      console.log('data', data);

      await dispatch(actions.consignmentInvoicePut(id, DataOfInvoice));
      alert('สำเร็จ');
      history.push('/doc/consignment-invoice');
    } catch (error) {
      console.error(error);
    }
  };

  const rendInfomationTextField = () => (
    <div>
      <div className="my-2 w-full">
        <div className="my-2 w-full">
          <TextField
            size="small"
            fullWidth
            required
            label="เลขใบรับฝาก"
            defaultValue={consignmentInvoice ? consignmentInvoice?.name : ''}
            {...register('name', { required: true })}
          />
        </div>
        <div className="my-2 w-full">
          {!_.isEmpty(customer?.rows) && (
            <Autocomplete
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  // timeout to avoid instant validation of the dialog's form.
                  setTimeout(() => {
                    toggleOpenAddCustomer(true);
                    setDialogCustomer({
                      name: newValue,
                    });
                  });
                } else if (newValue && newValue?.inputValue) {
                  toggleOpenAddCustomer(true);
                  setDialogCustomer({
                    name: newValue?.inputValue,
                  });
                } else {
                  setCustomerData(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (params?.inputValue !== '') {
                  filtered.push({
                    inputValue: params?.inputValue,
                    name: `เพิ่ม "${params?.inputValue}"  ลงในระบบ`,
                  });
                }

                return filtered;
              }}
              handleHomeEndKeys
              value={customerData}
              options={customer?.rows}
              defaultValue={
                consignmentInvoice ? consignmentInvoice?.customer : ' '
              }
              getOptionLabel={(option) => {
                // e.g value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                if (option?.inputValue) {
                  return option?.inputValue;
                }
                return option?.name;
              }}
              required
              renderOption={(props, option) => (
                <li {...props}>{option?.name}</li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="ชื่อลูกค้า" />
              )}
            />
          )}
        </div>
        <div className="my-2 w-full">
          <TextField
            size="small"
            fullWidth
            label="ทะเบียนรถ"
            defaultValue={
              consignmentInvoice ? consignmentInvoice?.car_registration : ''
            }
            {...register('car_registration', { required: false })}
          />
        </div>

        <FormLabel>สำหรับ</FormLabel>
        <div className="md:flex">
          <div className="my-2 w-full md:w-1/2 px-4">
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={consignmentInvoiceType}
              defaultValue={
                consignmentInvoice
                  ? consignmentInvoice?.consignment_invoice_type
                  : ''
              }
              onChange={handleChangeConsignmentInvoiceType}
            >
              <FormControlLabel
                value="freeze"
                control={<Radio />}
                label="ฟรีซ"
              />
              <FormControlLabel value="keep" control={<Radio />} label="เก็บ" />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label="อื่นๆ โปรดระบุในหมายเหตุ"
              />
            </RadioGroup>
          </div>
          <div className="my-2 w-full md:w-1/2 px-4">
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={depositStart}
                    onChange={handleChangeDepositStart}
                  />
                }
                label="วันที่ฝาก"
              />
            </div>
            {depositStart && (
              <div>
                <TextField
                  type="date"
                  fullWidth
                  defaultValue={consignmentInvoice?.deposit_start}
                  required={depositStart}
                  size={'small'}
                  {...register('deposit_start')}
                />
              </div>
            )}
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={depositEnd}
                    onChange={handleChangeDepositEnd}
                  />
                }
                label="วันที่ครบกำหนด"
              />
            </div>
            {depositEnd && (
              <div>
                <TextField
                  type="date"
                  fullWidth
                  defaultValue={consignmentInvoice?.deposit_end}
                  required={depositEnd}
                  size={'small'}
                  {...register('deposit_end')}
                />
              </div>
            )}
          </div>
        </div>
        <div className="my-2 w-full">
          <TextField
            label="หมายเหตุ"
            fullWidth
            multiline
            required={consignmentInvoiceType === 'other'}
            rows={2}
            defaultValue={
              consignmentInvoice ? consignmentInvoice?.description : ''
            }
            {...register('description')}
          />
        </div>
      </div>
    </div>
  );

  const getOpObj = (option) => {
    if (!option) {
      // eslint-disable-next-line no-param-reassign
      option = _.find(productStockLot.rows, (op) => op.id === option);
    }
    return option;
  };

  console.log('fields', fields);

  const renderTable = () => (
    <div>
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size={'small'}>
            <colgroup>
              <col width="8%" />
              <col width="20%" />
              <col width="32%" />
              <col width="15%" />
              <col width="15%" />
              <col width="10%" />
            </colgroup>
            <TableHead>
              <TableRow className="bg-green-100">
                <TableCell>
                  <div className="font-bold py-1">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">รหัสสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ชื่อสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">น้ำหนักต่อชิ้น</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">จำนวน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">เศษ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(fields) ? (
                _.map(fields, (item, index) => {
                  if (item._id) {
                    return (
                      <TableRow
                        key={item.id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <div className="py-1">{`${index + 1}`}</div>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <div className="py-1">
                            <Controller
                              name={`itemList.${index}.tag`}
                              control={control}
                              // defaultValue={
                              //   NewProduct ? NewProduct[0]?.weight : ' '
                              // }
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  size="small"
                                  fullWidth
                                  defaultValue={
                                    productStockLot?.rows
                                      ? productStockLot?.rows[index]?.tag
                                      : ' '
                                  }
                                  required
                                  label="รหัสสินค้า"
                                />
                              )}
                            />
                          </div>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <div className="py-1">
                            <Controller
                              name={`itemList.${index}.product`}
                              control={control}
                              render={({ field }) => (
                                <>
                                  {!_.isEmpty(productStockLot?.rows) && (
                                    <Autocomplete
                                      {...field}
                                      size="small"
                                      onChange={(event, newValue) => {
                                        field.onChange(newValue?.id);
                                      }}
                                      value={getOpObj(field.value)?.id}
                                      disableClearable
                                      options={productStockLot?.rows}
                                      defaultValue={
                                        productStockLot?.rows
                                          ? productStockLot?.rows[index]
                                          : ' '
                                      }
                                      getOptionLabel={(option) =>
                                        `${
                                          option?.product_type?.name
                                        } ( ก้อนละ ${
                                          option?.product_type?.weight ||
                                          option?.weight
                                        } กิโลกรัม ) `
                                      }
                                      disabled={
                                        item.product_in_pallet_inventory !==
                                          0 &&
                                        item.product_in_pallet_inventory &&
                                        item.status !== 'UNPACKED_STATUS'
                                      }
                                      required
                                      renderInput={(params) => (
                                        <TextField {...params} label="สินค้า" />
                                      )}
                                    />
                                  )}
                                </>
                              )}
                            />
                          </div>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <div className="py-1">
                            <Controller
                              name={`itemList.${index}.weight`}
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  size="small"
                                  fullWidth
                                  defaultValue={
                                    productStockLot?.rows
                                      ? productStockLot?.rows[index]?.weight
                                      : ' '
                                  }
                                  required
                                  label="น้ำหนักต่อก้อน"
                                />
                              )}
                            />
                          </div>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <div className="py-1">
                            <Controller
                              name={`itemList.${index}.quantity`}
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  size="small"
                                  fullWidth
                                  required
                                  disabled={
                                    item.product_in_pallet_inventory !== 0 &&
                                    item.product_in_pallet_inventory
                                  }
                                  type="number"
                                  label="จำนวน"
                                  {...field}
                                />
                              )}
                            />
                          </div>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <div className="py-1">
                            <Controller
                              render={({ field }) => (
                                <Checkbox
                                  size="small"
                                  {...field}
                                  disabled={
                                    item.product_in_pallet_inventory !== 0 &&
                                    item.product_in_pallet_inventory &&
                                    item.status !== 'UNPACKED_STATUS'
                                  }
                                />
                              )}
                              name={`itemList.${index}.scrap`}
                              control={control}
                            />
                          </div>
                        </TableCell>

                        <TableCell component="th" scope="row">
                          <Button
                            size="sm"
                            variant="outlined"
                            color="error"
                            onClick={() => {
                              handleDeleteItem(item, index);
                            }}
                          >
                            <Delete size={16} color="red" />
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  }
                  return (
                    <TableRow
                      key={item.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <div className="py-1">{`${index + 1}`} new</div>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <div className="py-1">
                          <Controller
                            name={`itemList.${index}.tag`}
                            control={control}
                            // defaultValue={
                            //   NewProduct ? NewProduct[0]?.weight : ' '
                            // }
                            render={({ field }) => (
                              <TextField
                                {...field}
                                size="small"
                                fullWidth
                                defaultValue={
                                  productStockLot?.rows
                                    ? productStockLot?.rows[index]?.tag
                                    : ' '
                                }
                                required
                                label="รหัสสินค้า"
                              />
                            )}
                          />
                        </div>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <div className="py-1">
                          <Controller
                            name={`itemList.${index}.product`}
                            control={control}
                            render={({ field }) => (
                              <>
                                {!_.isEmpty(NewProduct) && (
                                  <Autocomplete
                                    {...field}
                                    size="small"
                                    onChange={(event, newValue) => {
                                      field.onChange(newValue?.id);
                                      setValue(
                                        `itemList.${index}.weight`,
                                        newValue?.weight || '',
                                      );
                                      setValue(
                                        `itemList.${index}.tag`,
                                        newValue?.tag || '',
                                      );
                                      if (typeof newValue === 'string') {
                                        // timeout to avoid instant validation of the dialog's form.
                                        setTimeout(() => {
                                          toggleOpenAddProduct(true);
                                          setDialogProduct({
                                            name: newValue,
                                          });
                                        });
                                      } else if (
                                        newValue &&
                                        newValue?.inputValue
                                      ) {
                                        toggleOpenAddProduct(true);
                                        setDialogProduct({
                                          name: newValue?.inputValue,
                                        });
                                      } else {
                                      }
                                    }}
                                    filterOptions={(options, params) => {
                                      const filtered = filter(options, params);
                                      if (params?.inputValue !== '') {
                                        filtered.push({
                                          inputValue: params?.inputValue,
                                          name: `เพิ่ม "${params?.inputValue}"  ลงในระบบ`,
                                        });
                                      }
                                      return filtered;
                                    }}
                                    value={getOpObj(field.value)?.id}
                                    disableClearable
                                    options={NewProduct}
                                    defaultValue={
                                      NewProduct ? NewProduct[0] : ' '
                                    }
                                    getOptionLabel={(option) => {
                                      // e.g value selected with enter, right from the input
                                      if (typeof option === 'string') {
                                        return option;
                                      }
                                      if (option?.inputValue) {
                                        return option?.inputValue;
                                      }
                                      return option?.name;
                                    }}
                                    required
                                    renderOption={(props, option) => (
                                      <li {...props} key={option.index}>
                                        {option?.index} : {option?.name}{' '}
                                        {option?.weight && (
                                          <>( {option?.weight} kg )</>
                                        )}
                                      </li>
                                    )}
                                    renderInput={(params) => (
                                      <TextField {...params} label="สินค้า" />
                                    )}
                                  />
                                )}
                              </>
                            )}
                          />
                        </div>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <div className="py-1">
                          <Controller
                            name={`itemList.${index}.weight`}
                            control={control}
                            // defaultValue={
                            //   NewProduct ? NewProduct[0]?.weight : ' '
                            // }
                            render={({ field }) => (
                              <TextField
                                {...field}
                                size="small"
                                fullWidth
                                required
                                label="น้ำหนักต่อก้อน"
                              />
                            )}
                          />
                        </div>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <div className="py-1">
                          <Controller
                            name={`itemList.${index}.quantity`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                fullWidth
                                required
                                disabled={
                                  item.product_in_pallet_inventory !== 0 &&
                                  item.product_in_pallet_inventory
                                }
                                type="number"
                                label="จำนวน"
                                {...field}
                              />
                            )}
                          />
                        </div>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <div className="py-1">
                          <Controller
                            render={({ field }) => (
                              <Checkbox
                                size="small"
                                {...field}
                                disabled={
                                  item.product_in_pallet_inventory !== 0 &&
                                  item.product_in_pallet_inventory &&
                                  item.status !== 'UNPACKED_STATUS'
                                }
                              />
                            )}
                            name={`itemList.${index}.scrap`}
                            control={control}
                          />
                        </div>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <Button
                          size="sm"
                          variant="outlined"
                          color="error"
                          onClick={() => {
                            handleDeleteItem(item, index);
                          }}
                        >
                          <Delete size={16} color="red" />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );

  const renderAddButton = () => (
    <Button
      size="small"
      variant="outlined"
      color="success"
      onClick={() => {
        handleAddItem();
      }}
    >
      เพิ่ม
    </Button>
  );

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  // console.log('selectCustomer', selectCustomer);

  if (
    consignmentInvoice.isLoading ||
    customer.isLoading ||
    me.isLoading ||
    productStockLot.isLoading ||
    product.isLoading ||
    loading
  ) {
    return <Loading />;
  }
  if (
    !consignmentInvoice.isLoading &&
    consignmentInvoice.isCompleted &&
    !customer.isLoading &&
    customer.isCompleted &&
    !me.isLoading &&
    me.isCompleted &&
    !productStockLot.isLoading &&
    productStockLot.isCompleted &&
    !product.isLoading &&
    product.isCompleted &&
    !loading
  ) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <CreateCustomer
          openAddCustomer={openAddCustomer}
          handleCloseAddCustomer={handleCloseAddCustomer}
          dialogCustomer={dialogCustomer}
          setCustomerData={setCustomerData}
          setDialogCustomer={setDialogCustomer}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-wrap gap-y-2">
            <div className="w-full px-1 min-h-full">
              <Card className="p-4 ">
                <div className="flex flex-wrap">
                  <div className="w-full">{rendInfomationTextField()}</div>
                </div>
              </Card>
            </div>
          </div>
          <div className="flex flex-wrap my-2">
            <div className="w-full  px-1 min-h-full">
              <div className="flex flex-wrap">
                <div className="w-full">{renderTable()}</div>
              </div>
            </div>
          </div>
          <div className="m-1 flex justify-between">
            {renderAddButton()}
            <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    );
  }
  return <Error />;
}

EditConsignmentInvoice.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditConsignmentInvoice.defaultProps = {
  title: '',
  subtitle: '',
};

export default EditConsignmentInvoice;
