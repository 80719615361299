/* eslint-disable implicit-arrow-linebreak */
import pdfMake from 'addthaifont-pdfmake/build/pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import _ from 'lodash';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { BarcodeGenerator } from '../Others';
// import currencyFormatterTH from '../../utils/functions/currencyFormatterTH';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};

// eslint-disable-next-line import/prefer-default-export
export const BarcodeStickerReport = ({ palletRows, info }) => {
  console.log('palletRows in print', palletRows, info);
  const genProduct = (data) =>
    _.map(data, (_data) => [
      {
        alignment: 'left',
        margin: [0, 2, 0, 2],
        text: `${_data?.product_type?.name || '-'} ${
          _data?.product?.tag || '-'
        }`,
        fontSize: 10,
      },
      {
        alignment: 'left',
        margin: [0, 2, 0, 2],
        text: `${_data?.inventory / _data?.product?.weight || '-'} x ${
          _data?.product?.weight || '-'
        }`,
        fontSize: 10,
      },
      {
        alignment: 'left',
        margin: [0, 2, 0, 2],
        text: `${_data?.customer?.name || '-'}`,
        fontSize: 10,
      },
      {
        alignment: 'left',
        margin: [0, 2, 0, 2],
        text: `${_data?.consignment_invoice?.name || '-'}`,
        fontSize: 10,
      },
    ]);

  const barcodeImage = (word) => {
    const barcodeSVGWird = BarcodeGenerator(word);
    return {
      svg: barcodeSVGWird,
      fit: [150, 100],
    };
  };

  const palletData = _.map(palletRows, (eachRow, index) => [
    {
      height: 184,
      stack: [
        {
          alignment: 'left',
          margin: [0, 5, 0, 2],
          text: `${info?.owner?.name} \n`,
          fontSize: 8,
        },
        {
          alignment: 'left',
          margin: [0, 2, 0, 3],
          text: 'เลขที่พาเลท',
          fontSize: 10,
        },
        {
          alignment: 'left',
          margin: [0, 0, 0, 3],
          text: `${eachRow?.prefix}${eachRow?.running_number}`,
          bold: true,
          fontSize: 16,
        },
        {
          ...barcodeImage(`${eachRow?.prefix}${eachRow?.running_number}`),
        },
        {
          alignment: 'left',
          margin: [0, 5, 0, 17],
          text: eachRow?.warehouse_status
            ? `วันที่เข้าคลัง ${dayjs(eachRow?.date_warehouse).format(
                'D MMM BBBB',
              )}`
            : '-',
          fontSize: 8,
        },
      ],
    },
    {
      style: 'tableExample',
      height: 184,
      table: {
        widths: ['30%', '20%', '25%', '25%'],
        body: [
          [
            {
              alignment: 'left',
              margin: [0, 2, 0, 2],
              text: 'ชื่อสินค้า',
              fontSize: '10',
            },
            {
              alignment: 'left',
              margin: [0, 2, 0, 2],
              text: 'จำนวน',
              fontSize: '10',
            },
            {
              alignment: 'left',
              margin: [0, 2, 0, 2],
              text: 'ลูกค้า',
              fontSize: '10',
            },
            {
              alignment: 'left',
              margin: [0, 2, 0, 2],
              text: 'เลขใบรับฝาก',
              fontSize: '10',
            },
          ],
          ...genProduct(eachRow?.products),
        ],
      },
    },
  ]);

  const docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [20, 30, 40, 50],
    defaultStyle: {
      font: 'Sarabun',
    },
    info: {
      title: 'ใบสติ๊กเกอร์สำหรับติดพาเลท',
    },
    content: [
      {
        alignment: 'center',
        margin: [0, 10, 0, 12],
        text: 'ใบสติ๊กเกอร์สำหรับติดพาเลท',
        fontSize: '14',
        bold: true,
      },
      {
        style: 'tableExample',
        table: {
          widths: ['30%', '70%'],
          dontBreakRows: true,
          body: [
            // [{ text: 'header', colSpan: 2, alignment: 'center' }, {}],
            ...palletData,
          ],
        },
      },
    ],
  };
  pdfMake.createPdf(docDefinition).open();
};
