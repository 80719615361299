/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';

import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';

export default function CreateCustomer({
  handleCloseAddCustomer,
  openAddCustomer,
  dialogCustomer,
  setDialogCustomer,
}) {
  const dispatch = useDispatch();
  // const customer = useSelector((state) => state.customer);

  useEffect(() => {
    dispatch(actions.customerAll({}));
    return () => {};
  }, []);

  const handleAddCustomer = async (event) => {
    // console.log('dialogCustomer', dialogCustomer);
    event.preventDefault();
    const newCustomerData = await dispatch(
      actions.customerCreate({
        name: dialogCustomer.name,
      }),
    );

    console.log('respon customer', newCustomerData);
    // setCustomerData(newCustomerData);
    await dispatch(actions.customerAll({}));

    handleCloseAddCustomer();
  };

  return (
    <Dialog open={openAddCustomer} onClose={handleCloseAddCustomer}>
      <form onSubmit={handleAddCustomer}>
        <DialogTitle>เพิ่มลูกค้าลงในระบบ</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            value={dialogCustomer.name}
            onChange={(event) =>
              setDialogCustomer({
                ...dialogCustomer,
                name: event.target.value,
              })
            }
            label="ชื่อลูกค้า"
            type="text"
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddCustomer}>ยกเลิก</Button>
          <Button type="submit" startIcon={<SaveIcon />}>
            บันทึก
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

CreateCustomer.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  openAddCustomer: PropTypes.bool,
  handleCloseAddCustomer: PropTypes.func,
  dialogCustomer: PropTypes.object,
  setCustomerData: PropTypes.func,
  setDialogCustomer: PropTypes.func,
};

CreateCustomer.defaultProps = {
  name: '',
};
