/*eslint-disable*/
import React from 'react';
import { Link } from 'react-router-dom';
export default function Navbar() {
  return (
    <>
      <nav className="top-0 fixed z-10 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow-xl">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start font-sans">
            <div className="flex gap-2">
              <Link
                to="/"
                className="text-gray-600 text-base font-bold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap"
              >
                ระบบจัดการคลังสินค้า
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
