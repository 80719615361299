import { combineReducers } from 'redux';

// Common
import UserReducers from './common/UserReducers';
import MeReducers from './common/MeReducers';
import AvailableModuleReducer from './common/AvailableModuleReducer';
import SettingReducer from './common/SettingReducer';

// Feature
import CustomerReducer from './features/CustomerReducer';
import CustomerTypeReducer from './features/CustomerTypeReducer';
import DepartmentReducer from './features/DepartmentReducer';
import RoleTypesReducer from './features/RoleTypesReducer';
import EmployeeReducer from './features/EmployeeReducer';

// คลังวัตถุดิบ
import MaterialTypeReducer from './features/MaterialTypeReducer';
import MaterialReducer from './features/MaterialReducer';
import MaterialTransactionTypeReducer from './features/MaterialTransactionTypeReducer';
import PlaceReducer from './features/PlaceReducer';
import MaterialStockLotReducer from './features/MaterialStockLotReducer';

// คลังสินค้า
import WarehouseReducer from './features/WarehouseReducer';
import ProductTypeReducer from './features/ProductTypeReducer';
import ProductReducer from './features/ProductReducer';
import ProductTransactionTypeReducer from './features/ProductTransactionTypeReducer';
import ProductTransactionReducer from './features/ProductTransactionReducer';
import ProductStockLotReducer from './features/ProductStockLotReducer';

// การผลิต
import OrderReducer from './features/OrderReducer';
import ProcessTemplateReducer from './features/ProcessTemplateReducer';
import ProcessOfOrderReducer from './features/ProcessOfOrderReducer';

// จัดการหน้าเว็บ
import InfoReducers from './features/InfoReducers';

import LogReducers from './features/LogReducers';
import LogMovePalletReducers from './features/LogMovePalletReducers';
import LogProductStockLotReducers from './features/LogProductStockLotReducers';

// serial Number
import SerialReducers from './features/SerialReducers';

// payment
import PaymentTransactionReducer from './features/PaymentTransactionReducer';

// invoice
import ConsignmentInvoiceReducer from './features/ConsignmentInvoiceReducer';
import StocktakingInvoiceReducer from './features/StocktakingInvoiceReducer';

import PalletTypeReducer from './features/PalletTypeReducer';
import PalletReducer from './features/PalletReducer';

import PositionReducer from './features/PositionReducer';

import PositionSimpleReducer from './features/PositionSimpleReducer';

const rootReducer = combineReducers({
  me: MeReducers,
  setting: SettingReducer,
  user: UserReducers,
  customer: CustomerReducer,
  customerType: CustomerTypeReducer,
  department: DepartmentReducer,
  roletype: RoleTypesReducer,
  employee: EmployeeReducer,
  materialType: MaterialTypeReducer,
  material: MaterialReducer,
  materialTransactionType: MaterialTransactionTypeReducer,
  place: PlaceReducer,
  materialStockLot: MaterialStockLotReducer,
  order: OrderReducer,
  processOfOrderReducer: ProcessOfOrderReducer,
  processTemplate: ProcessTemplateReducer,
  warehouse: WarehouseReducer,
  logs: LogReducers,
  logMovePallet: LogMovePalletReducers,
  logProductStockLot: LogProductStockLotReducers,
  productType: ProductTypeReducer,
  product: ProductReducer,
  productTransactionType: ProductTransactionTypeReducer,
  productTransaction: ProductTransactionReducer,
  paymentTransaction: PaymentTransactionReducer,
  productStockLot: ProductStockLotReducer,
  information: InfoReducers,
  serialNumber: SerialReducers,
  availableModule: AvailableModuleReducer,
  consignmentInvoice: ConsignmentInvoiceReducer,
  stocktakingInvoice: StocktakingInvoiceReducer,
  palletType: PalletTypeReducer,
  pallet: PalletReducer,
  position: PositionReducer,
  positionSimple: PositionSimpleReducer,
});
export default rootReducer;
