import api from '../../../utils/functions/api';

import {
  LOG_MOVE_PALLET_ALL,
  LOG_MOVE_PALLET_CREATE,
  LOG_MOVE_PALLET_GET,
  LOG_MOVE_PALLET_UPDATE,
  LOG_MOVE_PALLET_DELETE,
  LOG_MOVE_PALLET_ERROR,
  LOG_MOVE_PALLET_LOADING,
} from '../types';

// faker.locale = "th"

export const getAllLogMovePallet = (params) => async (dispatch) => {
  try {
    const {
      nameHistoryMovePallet = '',
      sizeHistoryMovePallet = '',
      pageHistoryMovePallet = 1,
    } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/log-move-pallet?name=${nameHistoryMovePallet}&size=${sizeHistoryMovePallet}&page=${pageHistoryMovePallet}`,
    );
    if (status === 200) {
      dispatch({ type: LOG_MOVE_PALLET_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: LOG_MOVE_PALLET_ERROR });
    throw new Error(error);
  }
};

export const getOneLogMovePallet = (id) => async (dispatch) => {
  await api
    .get(`${process.env.REACT_APP_API_URL}/log-move-pallet/${id}`)
    .then((res) => {
      console.log('Request Server to Get One log');
      if (res.data) {
        dispatch({ type: LOG_MOVE_PALLET_GET, payload: res.data });
      } else {
        dispatch({ type: LOG_MOVE_PALLET_GET, payload: null });
      }
    });
};
export const createOneLogMovePallet = (payload) => async (dispatch) => {
  await api
    .post(`${process.env.REACT_APP_API_URL}/log-move-pallet/`, payload)
    .then(() => {
      console.log('Request Server to Create New log');
      dispatch({ type: LOG_MOVE_PALLET_CREATE });
    });
};

export const editOneLogMovePallet = (id, payload) => async (dispatch) => {
  await api
    .put(`${process.env.REACT_APP_API_URL}/log-move-pallet/${id}`, payload)
    .then(() => {
      console.log('Request Server to edit log');
      dispatch({ type: LOG_MOVE_PALLET_UPDATE });
    });
};

export const deleteOneLogMovePallet = (id) => async (dispatch) => {
  await api
    .delete(`${process.env.REACT_APP_API_URL}/log-move-pallet/${id}`)
    .then(() => {
      console.log('Request Server to Delete One Log');
      dispatch({ type: LOG_MOVE_PALLET_DELETE, payload: null });
    });
};
