/* eslint-disable import/extensions */
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
// import EditOptionForm from './EditOptionForm';
import ImageUpload from '../../ImageUpload/ImageUpload';
// eslint-disable-next-line import/no-unresolved
import RichtextEditor from '../../Richtext/RichtextEditor';

export function EditProductForm({
  errors,
  product,
  Controller,
  control,
  productType,
  addOption,
  setAddOption,
  // selectOption,
  // setSelectOption,
  // option,
  // serialType,
  uploadedImage,
  setUploadedImage,
  productDescribe,
  setProductDescribe,
}) {
  console.log('product', product);
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'tag'}
          control={control}
          defaultValue={product ? product.tag : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รหัสสินค้า"
              fullWidth
              size={'small'}
              helperText={errors.type_code && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={product ? product.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อสินค้า"
              fullWidth
              size={'small'}
              required
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'unit'}
          control={control}
          defaultValue={product ? product.unit : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="หน่วยสินค้า"
              fullWidth
              size={'small'}
              required
              helperText={errors.unit && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'product_type'}
          control={control}
          // eslint-disable-next-line no-underscore-dangle
          defaultValue={product ? product?.type?._id : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
              <InputLabel id="type" size={'small'}>
                ประเภทสินค้า
              </InputLabel>
              <Select
                {...field}
                label="ประเภทสินค้า"
                size={'small'}
                fullWidth
                required
              >
                {_.size(productType?.rows) ? (
                  _.map(productType.rows, (row) => (
                    <MenuItem key={row.id} value={row.id}>
                      {row.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="w-full px-1 pb-20">
        <div className="pb-2">รายละเอียดสินค้า</div>
        <RichtextEditor value={productDescribe} setValue={setProductDescribe} />
      </div>

      {addOption != null ? (
        <div className="w-full px-1 py-1">
          <FormControlLabel
            value="end"
            defaultValue={addOption}
            control={<Checkbox color="primary" />}
            label="เพิ่มออฟชั่นเสริมของสินค้า"
            labelPlacement="end"
            name="addUser"
            onChange={() => {
              setAddOption(!addOption);
            }}
          />
        </div>
      ) : (
        <></>
      )}

      {product.images ? (
        <>
          <div className="px-1 w-full pt-2">
            <div className="text-center py-2">รูปภาพ</div>
            <ImageUpload
              images={uploadedImage}
              setImages={setUploadedImage}
              preview_size="250"
              maxNumber={10}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

EditProductForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    unit: PropTypes.object,
    packing_size: PropTypes.object,
    packing_unit: PropTypes.object,
    inventory: PropTypes.object,
    price: PropTypes.object,
    unit_net: PropTypes.object,
    detail: PropTypes.object,
    type_code: PropTypes.object,
    option: PropTypes.object,
  }),
  product: PropTypes.object,
  productType: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
  serialType: PropTypes.bool,
  setSerialType: PropTypes.func,
  addOption: PropTypes.bool,
  setAddOption: PropTypes.func,
  selectOption: PropTypes.array,
  setSelectOption: PropTypes.func,
  option: PropTypes.array,
  uploadedImage: PropTypes.array,
  setUploadedImage: PropTypes.func,
  productDescribe: PropTypes.string,
  setProductDescribe: PropTypes.func,
  privatePrice: PropTypes.bool,
  setPrivatePrice: PropTypes.func,
};

EditProductForm.defaultProps = {
  product: null,
  productType: null,
  addOption: null,
  setAddOption: null,
  option: null,
};

export default EditProductForm;
