/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardContent } from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';

function PersonalProfile({ title, subtitle }) {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  console.log('In Personal Profile');

  useEffect(() => {
    dispatch(actions.meGet());
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderEditButton = () => (
    <div className="flex flex-row justify-end pb-4">
      <Link to="/profile/edit">
        <Button variant="contained" color="warning">
          แก้ไข
        </Button>
      </Link>
    </div>
  );

  const renderSetting = () => (
    <div className="my-2">
      <Card>
        <CardContent>
          <div className="font-semibold font-display  ">การตั้งค่า</div>
          <div className="flex flex-wrap w-full my-2">
            <div className="w-1/2 py-4 px-2 self-center">รหัสผ่าน</div>
            <div className="w-1/2 py-4 px-2  flex justify-end">
              <Link to="/profile/edit-password">
                <Button variant="contained" color="inherit">
                  เปลี่ยนรหัสผ่าน
                </Button>
              </Link>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );

  const renderDetail = () => {
    const {
      firstname = ' ',
      lastname = ' ',
      phone_number = '',
      department: { name: department_name = '' },
      image = '',
    } = me.userData;
    return (
      <div className="w-full">
        <Card>
          <div className="flex flex-wrap p-4">
            <div className="w-full">
              <div className="flex justify-center">
                <img src={image.url} className="w-60 rounded-lg" />
              </div>
            </div>

            <div className="w-full text-center">
              <div className="text-2xl font-semibold text-green-500 py-2 mt-2">
                {firstname} {lastname}
              </div>
              <div className="py-2">เบอร์โทรศัพท์ : {phone_number}</div>
              <div className="py-2">แผนก : {department_name}</div>
            </div>
          </div>
        </Card>
      </div>
    );
  };

  if (me.isLoading) {
    return <Loading />;
  }
  if (!me.isLoading && me.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          {renderTitle()}
          <div className="mt-6">{renderEditButton()}</div>
        </div>

        {renderDetail()}
        {renderSetting()}
      </div>
    );
  }
  return <Error />;
}
PersonalProfile.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

PersonalProfile.defaultProps = {
  title: '',
  subtitle: '',
};

export default PersonalProfile;
