import {
  POSITION_SIMPLE_ALL,
  POSITION_SIMPLE_GET,
  POSITION_SIMPLE_DEL,
  POSITION_SIMPLE_PUT,
  POSITION_SIMPLE_POST,
  POSITION_SIMPLE_LOADING,
  POSITION_SIMPLE_ERROR,
} from '../types';

import api from '../../../utils/functions/api';

export const positionSimpleCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: POSITION_SIMPLE_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/position-simple`,
      {
        ...payload,
      },
    );
    dispatch({ type: POSITION_SIMPLE_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: POSITION_SIMPLE_ERROR });
    throw new Error(error);
  }
};

export const positionSimpleAll = (params) => async (dispatch) => {
  try {
    const { filterByWarehouse = '' } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/position-simple?filterByWarehouse=${filterByWarehouse}`,
    );
    if (status === 200) {
      dispatch({ type: POSITION_SIMPLE_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: POSITION_SIMPLE_ERROR });
    throw new Error(error);
  }
};

export const positionSimpleGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/position-simple/${id}`,
    );
    if (status === 200) {
      dispatch({ type: POSITION_SIMPLE_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: POSITION_SIMPLE_ERROR });
    throw new Error(error);
  }
};

export const positionSimplePut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: POSITION_SIMPLE_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/position-simple/${id}`,
      payload,
    );
    dispatch({ type: POSITION_SIMPLE_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: POSITION_SIMPLE_ERROR });
    throw new Error(error);
  }
};
export const positionSimplePutManyPallets = (payload) => async (dispatch) => {
  try {
    dispatch({ type: POSITION_SIMPLE_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/position-simple/many-pallets/`,
      payload,
    );
    dispatch({ type: POSITION_SIMPLE_PUT, payload: data });
  } catch (error) {
    console.error(error?.response?.data?.error?.message);
    dispatch({ type: POSITION_SIMPLE_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};
export const positionSimpleDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: POSITION_SIMPLE_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/position-simple/${id}`,
    );
    dispatch({ type: POSITION_SIMPLE_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: POSITION_SIMPLE_ERROR });
    throw new Error(error);
  }
};

export const positionSimpleAddByPalletNumber = (id, payload) => async (
  dispatch,
) => {
  try {
    dispatch({ type: POSITION_SIMPLE_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/position-simple/pallet-number/${id}`,
      payload,
    );
    dispatch({ type: POSITION_SIMPLE_PUT, payload: data });
  } catch (error) {
    console.error(error?.response?.data?.error?.message);
    dispatch({ type: POSITION_SIMPLE_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const postionSimpleReset = () => async (dispatch) => {
  dispatch({ type: POSITION_SIMPLE_LOADING });
};
