import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
// eslint-disable-next-line object-curly-newline
import { useForm, Controller } from 'react-hook-form';
import { Button, Card, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

const ServiceSite = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);

  useEffect(() => {
    dispatch(actions.getInformation());
    return () => {};
  }, []);

  console.log('Information', information);
  // Setting React hook form
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data, event) => {
    try {
      const preprocessedData = {
        composite: {
          service_header: data.service_header,
          service_description: data.service_description,
          product_header: information?.composite?.product_header,
          product_description: information?.composite?.product_description,
        },
      };
      console.log(preprocessedData);
      event.preventDefault();
      await dispatch(
        actions.editOneInformation(information?.id, preprocessedData),
      );

      alert('สำเร็จ');
      await dispatch(actions.getInformation());
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className="flex justify-between">
        <div>{renderTitle()}</div>
        {/* <div className="mt-6">{renderAddButton()}</div> */}
      </div>
      <Card className="p-6">
        <div className="py-2">ข้อมูลหน้าแสดงบริการ</div>

        <form onSubmit={handleSubmit(onSubmit)}>
          {/* <ServiceSiteForm /> */}
          <div className="flex flex-row flex-wrap">
            <div className="w-full  px-1 py-2">
              <Controller
                name={'service_header'}
                control={control}
                defaultValue={information?.composite?.service_header || ''}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="ชื่อหัวข้อ"
                    fullWidth
                    size={'small'}
                    helperText={errors.name && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div>
            <div className="w-full  px-1 py-2">
              <Controller
                name={'service_description'}
                control={control}
                defaultValue={information?.composite?.service_description || ''}
                rules={{ required: false }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="คำอธิบายหน้าแสดงบริการ"
                    rows={3}
                    multiline={true}
                    fullWidth
                    size={'small'}
                    helperText={errors.name && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div>
          </div>
          <div className="flex flex-row justify-end gap-1 py-4">
            <Button variant="contained" type="submit" startIcon={<SaveIcon />}>
              บันทึก
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );
};

ServiceSite.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ServiceSite.defaultProps = {
  title: '',
  subtitle: '',
};

export default ServiceSite;
