import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Backdrop,
  Button,
  Card,
  Fade,
  Modal,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';

import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import { PalletTypeForm } from '../../components/Forms';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

const defaultValue = { name: '', description: '' };

function PalletType({ title, subtitle }) {
  const dispatch = useDispatch();
  const palletType = useSelector((state) => state.palletType);
  const history = useHistory();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [total, setTotal] = useState(undefined);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  console.log('palletType', palletType);

  useEffect(() => {
    dispatch(actions.palletTypeAll({ name, page, size }));
    return () => {};
  }, [name, page, size]);

  useEffect(() => {
    setTotal(palletType?.total);
    return () => {};
  }, [palletType]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    try {
      await dispatch(actions.palletTypeCreate(data));
      reset(defaultValue);
      alert('สำเร็จ');
      handleClose();
      await dispatch(actions.palletTypeAll({ name, page, size }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeletePalletType = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.palletTypeDelete(id));
        await dispatch(actions.palletTypeAll({ name, page, size }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handlePushToDetailPalletType = async (id) => {
    history.push(`/wms/pallet-type/${id}`);
  };

  const handlePushToEditPalletType = async (id) => {
    history.push(`/wms/pallet-type/edit/${id}`);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const renderAddButtom = () => (
    <div className="flex flex-row justify-end pb-4">
      <div>
        <Button
          startIcon={<AddBoxIcon />}
          variant="contained"
          onClick={handleOpen}
        >
          เพิ่ม
        </Button>
      </div>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card sx={style} className="max-w-4xl">
          <div className="py-2">เพิ่มข้อมูล</div>
          <div className="py-2">
            <form onSubmit={handleSubmit(onSubmit)}>
              <PalletTypeForm
                control={control}
                Controller={Controller}
                errors={errors}
                palletType={palletType?.rows}
              />
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<SaveIcon />}
                >
                  บันทึก
                </Button>
                <Button variant="outlined" onClick={handleClose}>
                  ยกเลิก
                </Button>
              </div>
            </form>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold"> ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> รหัสพาเลท</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> รายละเอียด</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ความจุ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(palletType.rows) ? (
                palletType.rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>{`${row.name}`}</TableCell>
                    <TableCell>{`${row.description}`}</TableCell>
                    <TableCell>
                      {`${row.capacity || '-'}`} {`${row.capacity_unit || '-'}`}
                    </TableCell>
                    <TableCell>
                      <div className="flex flex-row flex-wrap gap-1">
                        <Button
                          variant="contained"
                          startIcon={<DescriptionIcon />}
                          color={'info'}
                          size={'small'}
                          onClick={() => handlePushToDetailPalletType(row.id)}
                        >
                          รายละเอียด
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<EditIcon />}
                          color={'warning'}
                          size={'small'}
                          onClick={() => handlePushToEditPalletType(row.id)}
                        >
                          แก้ไข
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<DeleteIcon />}
                          color={'error'}
                          size={'small'}
                          onClick={() => {
                            handleDeletePalletType(row?.id);
                          }}
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (palletType.isLoading) {
    return <Loading />;
  }
  if (!palletType.isLoading && palletType.isCompleted) {
    return (
      <div>
        {renderModal()}
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="mt-6">{renderAddButtom()}</div>
        </div>{' '}
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error message={palletType?.message} />;
}

PalletType.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

PalletType.defaultProps = {
  title: '',
  subtitle: '',
};

export default PalletType;
