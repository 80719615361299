/* eslint-disable no-confusing-arrow */
import dayjs from 'dayjs';

// import { converseMaterialOrProductUnit } from '../functions/converseMaterialOrProductUnit';
import { PRODUCT_TRANSACTION_TYPE } from '../constants';

const columns = [
  {
    label: 'ลำดับที่',
    value: 'index',
  },
  {
    label: 'รหัสสินค้า',
    value: (row) => row?.product?.tag || row?.product_stock_lot?.tag,
  },
  {
    label: 'สินค้า',
    value: (row) => row?.product?.name,
  },
  {
    label: 'ใบรับฝาก/ใบเบิก',
    value: (row) => (row?.type === 'in' ? 'เข้า' : 'ออก'),
  },

  {
    label: 'ลูกค้า',
    value: (row) => row?.customer?.name,
  },
  {
    label: 'วันที่ดำเนินการ',
    value: (row) =>
      `${dayjs(row?.createdAt)?.format('D/MM/BBBB เวลา HH:mm น. ')}`,
  },
  {
    label: 'จำนวน',
    value: (row) => row?.quantity,
  },
  {
    label: 'น้ำหนัก',
    value: (row) => row?.weight,
  },

  {
    label: 'ดำเนินการโดย',
    value: (row) => row?.employee?.firstname + row?.employee?.lastname,
  },
];

export default { columns };
