import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card } from '@mui/material';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

export default function DetailCustomer({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const customer = useSelector((state) => state.customer);

  useEffect(() => {
    dispatch(actions.customerGet(id));
    dispatch(actions.customerTypeAll(''));
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (customer.isLoading || customer.rows) {
    return <Loading />;
  }
  if (!customer.isLoading && customer.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
          <div className="text-lg font-semibold ml-6">ข้อมูลลูกค้า</div>
        </div>
        <div>
          <Card className="p-4 ">
            <div className="flex flex-wrap divide-y">
              <div className="w-full py-4 text-lg font-semibold ">
                {'รายละเอียด'}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold ">{'ประเภท'}</div>
              <div className="w-1/2 py-4 ">{`${
                customer.type?.name || '-'
              }`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">{'ชื่อ'}</div>
              <div className="w-1/2 py-4 ">{`${customer.name}`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'ประเภทองค์กร'}
              </div>
              <div className="w-1/2 py-4 ">{`${
                customer.organization_type || '-'
              }`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">{'ที่อยู่'}</div>
              <div className="w-1/2 py-4 ">{`${customer.address || '-'}`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'เลขที่ผู้เสียภาษี'}
              </div>
              <div className="w-1/2 py-4 ">{`${customer.taxes || '-'}`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'เบอร์โทรศัพท์'}
              </div>
              <div className="w-1/2 py-4 ">{`${
                customer.phone_number || '-'
              }`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">{'อีเมล'}</div>
              <div className="w-1/2 py-4 ">{`${customer.email || '-'}`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">{'แฟกซ์'}</div>
              <div className="w-1/2 py-4 ">{`${customer.fax || '-'}`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'ประเภทการชำระเงิน'}
              </div>
              <div className="w-1/2 py-4 ">{`${customer.payment || '-'}`}</div>
              {customer.supplier ? (
                <>
                  <div className="w-1/2 py-4 px-2 font-semibold ">
                    {'ข้อมูลบัญชีธุรกรรมการเงิน'}
                  </div>
                  <div className="w-1/2 py-4 ">
                    <div className="w-1/2">
                      {'ชื่อธนาคาร'} {' : '}
                      {`${customer.bank_name}`}
                    </div>
                    <div className="w-1/2">
                      {'ชื่อบัญชี'} {' : '}
                      {`${customer.bank_account_name}`}
                    </div>
                    <div className="w-1/2">
                      {'หมายเลขบัญชี'} {' : '}
                      {`${customer.bank_account_number}`}
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailCustomer.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailCustomer.defaultProps = {
  title: '',
  subtitle: '',
};
