import { TextField, Autocomplete } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

export function PalletForm({ errors, palletType, Controller, control }) {
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={palletType ? palletType.name : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อ/รหัสพาเลท"
              fullWidth
              size={'small'}
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'pallet_type'}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Autocomplete
              {...field}
              onChange={(event, newValue) => {
                field.onChange(newValue);
              }}
              disableClearable
              options={palletType}
              getOptionLabel={(option) => `${option?.name}`}
              required
              renderInput={(params) => (
                <TextField {...params} label="ประเภทพาเลท" />
              )}
            />
          )}
        />
      </div>
    </div>
  );
}

PalletForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.string,
    pallet_type: PropTypes.object,
  }),
  palletType: PropTypes.array,
  Controller: PropTypes.func,
  control: PropTypes.object,
};

PalletForm.defaultProps = {
  palletType: null,
};

export default PalletForm;
