import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import {
  Button,
  Card,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Backdrop,
  Modal,
  Fade,
  Autocomplete,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  ToggleButton,
  Box,
  Typography,
  Zoom,
  IconButton,
  Dialog,
  DialogContent,
  ButtonGroup,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  // Select,
  // FormControl,
  // MenuItem,
  // InputLabel,
} from '@mui/material';

import qrcode from 'qrcode';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';

import { styled } from '@mui/material/styles';

import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import DescriptionIcon from '@mui/icons-material/Description';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import CheckIcon from '@mui/icons-material/Check';
import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';
import HistoryIcon from '@mui/icons-material/History';
import { ArrowDown, ChevronDown, Grid as GridIcon } from 'react-feather';
import _, { set } from 'lodash';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { BarcodeGenerator } from '../../components/Others';
import api from '../../utils/functions/api';
import {
  PalletInvoice,
  PalletInvoiceSingleProduct,
  BarcodeStickerReport,
} from '../../components/Report/index';
import { ExportExcelContainer } from '../../components/ExcelDownload';
import { PalletTransection } from '../../utils/modelutils';

dayjs.locale('th');
dayjs.extend(buddhistEra);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 12,
  borderRadius: 6,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 6,
    // backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

function PalletTransactionInvoice({ title, subtitle }) {
  const dispatch = useDispatch();
  const pallet = useSelector((state) => state.pallet);
  const logMovePallet = useSelector((state) => state.logMovePallet);
  const warehouse = useSelector((state) => state.warehouse);
  const me = useSelector((state) => state.me);
  const customer = useSelector((state) => state.customer);
  const setting = useSelector((state) => state.setting);
  // const anchorRef = useRef(null);

  const NewCustomer = _.map(customer?.rows, (row, index) => ({
    index: index + 1,
    ...row,
  }));

  console.log('pallet', pallet);

  const history = useHistory();
  const {
    handleSubmit,
    reset,
    // setValue,
  } = useForm({
    mode: 'onBlur',
  });

  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);
  const [total, setTotal] = useState(undefined);
  const [
    searchTermHistoryMovePallet,
    setSearchTermHistoryMovePallet,
  ] = useState('');
  const [nameHistoryMovePallet, setNameHistoryMovePallet] = useState('');
  const [pageHistoryMovePallet, setPageHistoryMovePallet] = useState(1);
  const [sizeHistoryMovePallet, setSizeHistoryMovePallet] = useState(10);
  const [totalHistoryMovePallet, setTotalHistoryMovePallet] = useState(
    undefined,
  );
  const [openPrint, setOpenPrint] = useState(false);
  const [openMovePallet, setOpenMovePallet] = useState(false);
  const [openHistoryMovePallet, setOpenHistoryMovePallet] = useState(false);

  const [selectPallet, setSelectPallet] = useState();
  const [selectMovePallet, setSelectMovePallet] = useState();

  const [selectStatus, setSelectStatus] = useState('');
  const [filterWarehouse, setFilterWarehouse] = useState();

  const [selectWarehouse, setSelectWarehouse] = useState();
  const [selectFloor, setSelectFloor] = useState();
  const [selectFloorAndPosition, setSelectFloorAndPosition] = useState();
  const [selectPosition, setSelectPosition] = useState();
  const [selectPositionSimple, setSelectPositionSimple] = useState();
  const [selectPositionMovePallet, setSelectPositionMovePallet] = useState();
  const [arrayPalletOnSelect, setArrayPalletOnSelect] = useState([]);
  const [openTooltipArrayPallet, setOpenTooltipArrayPallet] = useState(false);
  const [selectCustomer, setSelectCustomer] = useState('');
  const [selectedBarcode, setSelectedBarcode] = useState('');
  const [barcodeOpen, setBarcodeOpen] = useState(false);
  const [selectedQrcode, setSelectedQrcode] = useState('');
  const [qrcodeOpen, setQrcodeOpen] = useState(false);
  const [buttonGroupOpen, setButtonGroupOpen] = useState(false);

  const handleOpenPrint = () => setOpenPrint(true);
  const handleClosePrint = () => setOpenPrint(false);

  const handleOpenMovePallet = () => setOpenMovePallet(true);
  const handleCloseMovePallet = () => setOpenMovePallet(false);

  const handleOpenHistoryMovePallet = () => setOpenHistoryMovePallet(true);
  const handleCloseHistoryMovePallet = () => setOpenHistoryMovePallet(false);

  const handleCloseTooltipArrayPallet = () => setOpenTooltipArrayPallet(false);

  useEffect(() => {
    dispatch(actions.consignmentInvoiceAll({ name, page, size }));
    dispatch(actions.productStockLotAll({ name, page, size }));
    dispatch(
      actions.palletAll({
        name,
        page,
        size,
        selectStatus,
        selectCustomer,
        filterWarehouse,
      }),
    );

    dispatch(
      actions.getAllLogMovePallet({
        nameHistoryMovePallet,
        pageHistoryMovePallet,
        sizeHistoryMovePallet,
      }),
    );
    dispatch(actions.positionSimpleAll({}));
    dispatch(actions.meGet());
    return () => {};
  }, [
    name,
    page,
    size,
    nameHistoryMovePallet,
    pageHistoryMovePallet,
    sizeHistoryMovePallet,
    selectStatus,
    selectCustomer,
    filterWarehouse,
  ]);

  useEffect(() => {
    dispatch(actions.customerAll({}));
    dispatch(actions.warehouseAll({}));
    return () => {};
  }, []);

  console.log('selectPositionSimple', selectPositionSimple);
  console.log('arrayPalletOnSelect', arrayPalletOnSelect);

  console.log('openTooltipArrayPallet', openTooltipArrayPallet);

  // console.log('selectFloorAndPosition', selectFloorAndPosition);
  // console.log('positionSimple', positionSimple);

  useEffect(() => {
    const positionOfBenz = _.groupBy(selectWarehouse?.positions, 'floor');
    const arrayOfPosition = _.values(positionOfBenz);
    const positionOfWarehouse = _.map(arrayOfPosition, (eachArray, index) => ({
      floor: index + 1,
      available_position: eachArray,
    }));

    setSelectFloor(positionOfWarehouse);

    return () => {};
  }, [selectWarehouse]);

  useEffect(() => {
    setTotal(pallet?.total);
    return () => {};
  }, [pallet]);

  useEffect(() => {
    setTotalHistoryMovePallet(logMovePallet?.total);
    return () => {};
  }, [logMovePallet]);

  useEffect(() => {
    console.log('ทำฟังก์ชั่น find pallet');
    const newArrayPallet = _.map(
      selectPositionSimple?.pallet_id,
      (eachPalletId) => {
        const findPallet = _.find(
          pallet?.rows,
          (eachPallet) => eachPallet?.id === eachPalletId?.pallet,
        );
        return findPallet;
      },
    );
    console.log('newArrayPallet', newArrayPallet);
    setArrayPalletOnSelect(newArrayPallet);
    return () => {};
  }, [selectPositionSimple]);

  console.log('selectPositionMovePallet', selectPositionMovePallet);

  useEffect(() => {
    console.log('ทำฟังก์ชั่น find pallet');
    const newArrayPallet = _.map(
      selectPositionMovePallet?.pallet_id,
      (eachPalletId) => {
        const findPallet = _.find(
          pallet?.rows,
          (eachPallet) => eachPallet?.id === eachPalletId?.pallet,
        );
        return findPallet;
      },
    );
    console.log('newArrayPallet', newArrayPallet);
    setArrayPalletOnSelect(newArrayPallet);
    return () => {};
  }, [selectPositionMovePallet]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleGetAllPalletAPI = async () => {
    const searchParams = new URLSearchParams({
      name: name || '',
      page: 1,
      size: 10000,
      selectStatus: selectStatus || '',
      selectCustomer: selectCustomer || '',
      filterWarehouse: selectWarehouse || '',
    });

    try {
      const { data } = await api.get(
        `${process.env.REACT_APP_API_URL}/pallet?${searchParams.toString()}`,
      );
      return data;
    } catch (error) {
      alert(`ไม่สามารถดึงข้อมูลได้ ${error?.message}`);
      throw new Error(error);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const calPalletInRow = (rowNumber) => {
    const NewPalletInWarehouseEach = _.filter(
      selectWarehouse?.pallet_in_warehouse,
      (piw) => piw?.row === rowNumber,
    );
    return _.size(NewPalletInWarehouseEach);
  };
  // const handleDeleteConsignmentInvoice = async (id) => {
  //   const confirm = window.confirm('ยืนยันการลบข้อมูล');
  //   if (confirm) {
  //     try {
  //       await dispatch(actions.consignmentInvoiceDelete(id));
  //       await dispatch(actions.consignmentInvoiceAll({ name, page, size }));
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }
  // };

  const handleChangeStatus = (event) => {
    setSelectStatus(event.target.value);
  };

  const handleChangeFilterWarehouse = (event) => {
    setFilterWarehouse(event.target.value);
  };

  // const handlePushToDetailPallet = async (id) => {
  //   history.push(`/doc/pallet-transaction/${id}`);
  // };

  // const handlePushToDetailWarehouse = async (id) => {
  //   history.push(`/wms/warehouses/${id}`);
  // };

  // const handlePushToEditConsignmentInvoice = async (id) => {
  //   history.push(`consignment-invoice/edit/${id}`);
  // };

  // const handlePushToCreateTransactionInvoice = async () => {
  //   history.push('create');
  // };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPageHistoryMovePallet = (event) => {
    setSizeHistoryMovePallet(event.target.value);
    setPageHistoryMovePallet(1);
  };

  const handleChangePageHistoryMovePallet = (event, newPage) => {
    setPageHistoryMovePallet(newPage + 1);
  };

  const handleGenerateBarcode = (barcodeNo) => {
    const tempBarcode = BarcodeGenerator(barcodeNo);
    setSelectedBarcode(tempBarcode);
    setBarcodeOpen(true);
  };

  const handleGenerateQrcode = (qrcodeNo) => {
    const opts = {
      errorCorrectionLevel: 'H',
      type: 'image/jpeg',
      mode: 'Alphanumeric',
      quality: 0.8,
      margin: 1,
      width: 150,
    };

    const genQR = (data) => {
      let resQR;
      qrcode.toDataURL(data, opts, (err, res) => {
        if (err) throw err;
        resQR = res;
      });
      return resQR;
    };

    setSelectedQrcode(genQR(qrcodeNo));
    setQrcodeOpen(true);
  };

  const defaultValue = {
    warehouse: warehouse?.rows?.[0],
    location: { x: '' },
  };

  const onSubmitMovePallet = async (data) => {
    console.log('movePallet.....');
    console.log('selectMovePallet', selectMovePallet);
    console.log('selectWarehouse', selectWarehouse);
    console.log('selectPositionMovePallet', selectPositionMovePallet);

    const payload = {
      update_type: 'movePallet',
      pallet_id: selectMovePallet?.id,
      new_position_id: selectPositionMovePallet?._id,
      old_position_id: selectMovePallet?.position,
      new_row: selectPositionMovePallet?.row,
      old_row: selectMovePallet?.row,
      new_warehouse_id: selectWarehouse?.id,
      old_warehouse_id: selectMovePallet?.warehouse?._id,
      employee_id: me?.userData?.id,
    };

    console.log('payload on move pallet', payload);

    await dispatch(actions.palletPut(selectMovePallet?.id, payload));

    handleCloseMovePallet();
    alert('สำเร็จ');
    setSelectWarehouse();
    setSelectMovePallet();
    setSelectPositionMovePallet();

    await dispatch(actions.consignmentInvoiceAll({ name, page, size }));
    await dispatch(actions.productStockLotAll({ name, page, size }));
    await dispatch(actions.palletAll({ name, page, size, selectStatus }));
    await dispatch(actions.warehouseAll({}));
    await dispatch(actions.positionSimpleAll({}));
    await dispatch(actions.meGet());
    await dispatch(
      actions.getAllLogMovePallet({
        nameHistoryMovePallet,
        pageHistoryMovePallet,
        sizeHistoryMovePallet,
      }),
    );
  };

  const onSubmitPrint = async (data) => {
    // console.log('data on sumit print', data);
    // console.log('selectPallet', selectPallet);
    // console.log('me', me);
    // console.log('productStockLot', productStockLot?.rows);

    if (selectWarehouse?.type === 'complex') {
      const payload = {
        location: {
          row: selectPosition?.row,
          col: selectPosition?.col,
          height: selectPosition?.floor,
        },
        pallet: selectPallet?.id,
        warehouse: data?.warehouse?.id,
        date_warehouse: new Date(),
        url: `${window.location.href}${selectPallet?.id}`,
      };

      console.log('selectPallet', selectPallet);

      const payloadOfPosition = {
        ...selectPosition,
        available: false,
        pallet_id: selectPallet?.id,
      };

      await dispatch(actions.palletPut(selectPallet?.id, payload));
      await dispatch(
        actions.positionPut(selectPosition?._id, payloadOfPosition),
      );

      PalletInvoice(
        data,
        {
          ...selectPallet,
          date_warehouse: dayjs(payload.date_warehouse).format(),
        },
        me,
        {
          ...payload,
          date_warehouse: dayjs(payload.date_warehouse).format(),
        },
      );

      handleClosePrint();

      await dispatch(actions.palletAll({ name, page, size }));

      setSelectWarehouse();
      setSelectFloor([]);
      setSelectFloorAndPosition();
      setSelectPosition();
      setSelectPositionSimple();
      reset(defaultValue);
    } else if (!selectPositionSimple) {
      alert('simple เลือกแถวก่อน');
    } else {
      console.log('selectPositionSimple', selectPositionSimple);
      console.log('selectWarehouse', selectWarehouse);
      console.log('selectPallet', selectPallet);

      const payload = {
        pallet_id: selectPallet?._id,
        warehouse_id: selectWarehouse?._id,
      };
      const payload2 = {
        date_warehouse: dayjs(new Date()).format(),
        warehouse_status: true,
        warehouse: selectWarehouse?._id,
        row: selectPositionSimple?.row,
        position: selectPositionSimple?._id,
      };
      console.log('payload', payload);
      console.log('payload2', payload2);

      await dispatch(actions.palletPut(selectPallet?.id, payload2));
      await dispatch(
        actions.positionSimplePut(selectPositionSimple?._id, payload),
      );
      handleClosePrint();
      alert('สำเร็จ');
      setSelectWarehouse();
      setSelectPositionSimple();

      await dispatch(actions.consignmentInvoiceAll({ name, page, size }));
      await dispatch(actions.productStockLotAll({ name, page, size }));
      await dispatch(actions.palletAll({ name, page, size, selectStatus }));
      await dispatch(actions.warehouseAll({}));
      await dispatch(actions.positionSimpleAll({}));
      await dispatch(actions.meGet());
      await dispatch(
        actions.getAllLogMovePallet({
          nameHistoryMovePallet,
          pageHistoryMovePallet,
          sizeHistoryMovePallet,
        }),
      );
    }
  };

  const printPalletInvoice = (data) => {
    console.log('data in print pallet', data);

    const locationData = {
      row: data?.row,
      warehouse: data?.warehouse?.name,
    };

    const payload = {
      warehouse: data?.warehouse?.id,
      date_warehouse: dayjs(new Date()).format(),
      url: `${window.location.href}${data?.id}`,
    };

    console.log('payload in print pallet', payload);

    PalletInvoiceSingleProduct(locationData, data, me, payload);
    // PalletInvoice(locationData, data, me, payload);
  };

  const handleDeletePallet = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.palletDelete(id));
        await dispatch(actions.palletAll({ name, page, size, selectStatus }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handlePrintActiveBarcodeList = async () => {
    try {
      const activeList = await handleGetAllPalletAPI();
      BarcodeStickerReport({ palletRows: activeList?.rows, info: setting });
    } catch (error) {
      console.error(error?.message);
    }
  };

  const handlePrintAllBarcodeList = async () => {
    // TODO:not implement
    try {
      const activeList = await handleGetAllPalletAPI();
      console.log('activeList', activeList);
      // BarcodeStickerReport({ palletRows: activeList?.rows, info: setting });
    } catch (error) {
      console.error(error?.message);
    }
  };

  const progressBarValue = (value, maxValue) => {
    const NewValue = (value * 100) / maxValue;
    return NewValue;
  };

  const messageArrayPalletOnSelect = (
    <div>
      {_.isEmpty(arrayPalletOnSelect) ? (
        <div className="text-base">ไม่มีพาเลทในแถว</div>
      ) : (
        <>
          <div className="text-center text-xl underline">
            แถวที่ {selectPositionSimple?.row || selectPositionMovePallet?.row}
          </div>
          {_.map(arrayPalletOnSelect, (each, index) => (
            <div className="text-lg">
              {index + 1} : {each?.name || each?.prefix + each?.running_number}{' '}
              ( ความจุ {each?.used_capacity} / {each?.pallet_type?.capacity})
            </div>
          ))}
        </>
      )}
    </div>
  );

  const LinearProgressWithLabel = (value, maxValue) => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <BorderLinearProgress
          variant="determinate"
          value={progressBarValue(value, maxValue)}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">
          {value}/{maxValue}
        </Typography>
      </Box>
    </Box>
  );

  const renderSearch = () => (
    <Card>
      <div className="pt-4 px-4 w-full">
        <div className="w-full">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
      <div className="px-4 pb-4 pt-2 flex flex-row gap-1">
        <div className="w-full md:w-3/10">
          <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
            <InputLabel id="type" size={'small'}>
              เลือกคลัง
            </InputLabel>
            <Select
              label="เลือกคลัง"
              size={'small'}
              value={filterWarehouse}
              onChange={handleChangeFilterWarehouse}
              fullWidth
            >
              <MenuItem value={''}>แสดงทั้งหมด</MenuItem>
              {_.map(warehouse?.rows, (e, index) => (
                <MenuItem key={index} value={e?._id}>
                  {e?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="w-full md:w-3/10 md:pl-1 md:pt-0">
          <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
            <InputLabel id="type" size={'small'}>
              สถานะพาเลท
            </InputLabel>
            <Select
              label="ประเภทสินค้า"
              size={'small'}
              value={selectStatus}
              onChange={handleChangeStatus}
              fullWidth
            >
              <MenuItem value={''}>แสดงทั้งหมด</MenuItem>
              <MenuItem value={true}>ลงคลังเรียบร้อย</MenuItem>
              <MenuItem value={false}>ยังไม่ลงคลัง</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="w-full md:w-2/10 md:pl-1 md:pt-0">
          <Autocomplete
            size={'small'}
            onChange={(e, newValue) => {
              setSelectCustomer(newValue?.id);
            }}
            options={NewCustomer}
            getOptionLabel={(type) => `${type?.index} : ${type?.name}`}
            fullWidth
            renderInput={(params) => <TextField {...params} label="ลูกค้า" />}
          />
        </div>
        <div className="w-2/10">
          <Tooltip title="ประวัติการย้ายพาเลท">
            <IconButton
              color="primary"
              onClick={() => {
                handleOpenHistoryMovePallet();
              }}
            >
              <HistoryIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </Card>
  );

  const renderBarcodeDialog = () => (
    <Dialog
      open={barcodeOpen}
      onClose={() => {
        setBarcodeOpen(false);
        setSelectedBarcode('');
      }}
    >
      <DialogContent>
        <div
          dangerouslySetInnerHTML={{
            __html: selectedBarcode,
          }}
        ></div>
      </DialogContent>
    </Dialog>
  );

  const renderQrcodeDialog = () => (
    <Dialog
      open={qrcodeOpen}
      onClose={() => {
        setQrcodeOpen(false);
        setSelectedQrcode('');
      }}
    >
      <DialogContent>
        <div>
          <img src={selectedQrcode} />
        </div>
      </DialogContent>
    </Dialog>
  );

  const [printSelect, setPrintSelect] = useState('');

  const handlePrintSelect = (event) => {
    setPrintSelect(event.target.value);
  };
  const renderBottonGroup = () => (
    <Card className="p-2">
      <Box sx={{ minWidth: 200 }}>
        <FormControl fullWidth>
          <InputLabel>พิมพ์บาร์โค้ดติดพาเลท</InputLabel>
          <Select
            value={printSelect}
            label="พิมพ์บาร์โค้ดติดพาเลท"
            onChange={handlePrintSelect}
            size="small"
          >
            <MenuItem
              value="พิมพ์เฉพาะที่ยังไม่ลงคลัง"
              onClick={() => handlePrintActiveBarcodeList()}
            >
              พิมพ์เฉพาะที่ยังไม่ลงคลัง
            </MenuItem>
            <MenuItem
              value="พิมพ์ทั้งหมด"
              onClick={() => handlePrintAllBarcodeList()}
            >
              พิมพ์ทั้งหมด
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Card>
  );
  const renderTableHistoryMovePallet = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow className="bg-green-100">
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">พาเลท</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ตำแหน่งเดิม</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ตำแหน่งใหม่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">วันที่ย้าย</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ย้ายโดย</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(logMovePallet?.rows) ? (
                logMovePallet?.rows.map((row, index) => (
                  <TableRow
                    key={row?.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell component="th" scope="row">
                      {(pageHistoryMovePallet - 1) * sizeHistoryMovePallet +
                        index +
                        1}
                    </TableCell>
                    <TableCell>
                      <div>
                        {row?.pallet?.name ||
                          row?.pallet?.prefix + row?.pallet?.running_number}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div>คลัง {row?.old_warehouse?.name}</div>
                      <div>แถวที่ {row?.old_row}</div>
                    </TableCell>
                    <TableCell>
                      <div>คลัง {row?.new_warehouse?.name}</div>
                      <div>แถวที่ {row?.new_row}</div>
                    </TableCell>
                    <TableCell>{`${dayjs(row?.createdAt).format(
                      'D MMM BBBB HH:mm',
                    )}`}</TableCell>
                    <TableCell>
                      <div>
                        {row?.employee?.firstname} {row?.employee?.lastname}
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100]}
          onRowsPerPageChange={handleChangeRowsPerPageHistoryMovePallet}
          page={pageHistoryMovePallet - 1}
          count={totalHistoryMovePallet || 1}
          rowsPerPage={sizeHistoryMovePallet}
          onPageChange={handleChangePageHistoryMovePallet}
        />
      </Paper>
    </div>
  );

  const renderModalHistoryMovePallet = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openHistoryMovePallet}
      onClose={handleCloseHistoryMovePallet}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openHistoryMovePallet}>
        <Card sx={style} className="max-w-5xl my-4">
          <div className=" max-h-screen overflow-y-auto">
            <div className="flex w-full justify-between">
              <div className="py-2 text-2xl text-center underline">
                ประวัติการย้ายพาเลท
              </div>
              <div className="w-full md:w-1/4 flex justify-end">
                <ExportExcelContainer
                  columnList={PalletTransection?.columns}
                  currentData={logMovePallet?.rows}
                  dataAPIEndPoint="log-move-pallet"
                  dataQuery={''}
                  sheetName="PalletTransections"
                  spreadedColumn=""
                />
              </div>
            </div>
            <div>{renderTableHistoryMovePallet()}</div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderModalMovePallet = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openMovePallet}
      onClose={handleCloseMovePallet}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openMovePallet}>
        <Card sx={style} className="max-w-2xl my-4">
          <div className=" max-h-screen overflow-y-auto">
            <div className="py-2 text-lg">โปรดระบุตำแหน่งที่ต้องการย้าย1</div>
            <div className="py-2">
              <form onSubmit={handleSubmit(onSubmitMovePallet)}>
                <div className="flex flex-row flex-wrap">
                  {!_.isEmpty(warehouse?.rows) && (
                    <>
                      <div className="w-full lg:w-1/2 px-1 py-2">
                        <Autocomplete
                          size={'small'}
                          onChange={(e, newValue) => {
                            setSelectWarehouse(newValue);
                          }}
                          defaultValue={selectWarehouse}
                          options={warehouse?.rows}
                          getOptionLabel={(type) => `${type?.name}`}
                          required
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="คลังสินค้า"
                              required
                            />
                          )}
                        />
                      </div>
                    </>
                  )}

                  {selectWarehouse?.type === 'simple' && (
                    <div className="w-1/2 text-lg pl-2 my-auto">
                      เลือกแถวที่ต้องการ
                    </div>
                  )}

                  <div className="w-full flex justify-center border py-2">
                    <div className="w-full my-auto">
                      <div className="text-center">ตำแหน่งเดิม</div>
                      <div className="text-center">
                        {`${selectMovePallet?.warehouse?.name} แถวที่ ${selectMovePallet?.row}`}{' '}
                      </div>
                    </div>
                    <div className="my-auto">
                      <ArrowForwardTwoToneIcon fontSize="large" />
                    </div>

                    <div className="w-full my-auto">
                      <div className="text-center">ตำแหน่งใหม่</div>
                      {selectPositionMovePallet ? (
                        <div className="text-center">
                          {`${selectWarehouse?.name} แถวที่ ${selectPositionMovePallet?.row}`}{' '}
                        </div>
                      ) : (
                        <div className="text-center">-</div>
                      )}
                    </div>
                  </div>

                  {selectWarehouse?.type === 'simple' && (
                    <>
                      <HtmlTooltip
                        open={openTooltipArrayPallet}
                        onClose={handleCloseTooltipArrayPallet}
                        placement="right"
                        TransitionComponent={Zoom}
                        title={messageArrayPalletOnSelect}
                      >
                        <div className="w-full rounded-lg border-2 max-h-screen overflow-y-auto">
                          <div className="text-center text-xl p-2 pt-4">
                            ทางเข้า
                          </div>
                          <div
                            className={`grid grid-flow-col grid-cols-2 grid-rows-${parseInt(
                              selectWarehouse?.row / 2,
                              10,
                            )} gap-x-8 gap-y-1 p-2`}
                          >
                            {_.map(
                              selectWarehouse?.positionsimples,
                              (eachPosition, index) => (
                                <div
                                  className={
                                    // eslint-disable-next-line no-underscore-dangle
                                    selectPositionMovePallet?._id ===
                                    eachPosition?._id
                                      ? 'rounded-md border-4 border-gray-500 p-2 cursor-pointer text-white bg-green-400'
                                      : 'rounded-md border-4 border-gray-500 p-2 cursor-pointer'
                                  }
                                  onClick={() => {
                                    if (
                                      selectMovePallet?.row !==
                                      eachPosition?.row
                                    ) {
                                      setSelectPositionMovePallet(eachPosition);
                                    } else alert('คุณเลือกตำแหน่งเดิม');

                                    setOpenTooltipArrayPallet(true);
                                  }}
                                >
                                  <div className="text-center text-lg">
                                    แถว {index + 1}
                                  </div>
                                  <div>
                                    {LinearProgressWithLabel(
                                      calPalletInRow(eachPosition?.row),
                                      eachPosition?.max_capacity,
                                    )}
                                  </div>
                                </div>
                              ),
                            )}
                          </div>
                        </div>
                      </HtmlTooltip>
                    </>
                  )}
                </div>

                <div className="flex flex-row justify-end gap-1 py-4">
                  <Button
                    variant="contained"
                    type="submit"
                    startIcon={<SaveIcon />}
                  >
                    บันทึก
                  </Button>
                  <Button variant="outlined" onClick={handleCloseMovePallet}>
                    ยกเลิก
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderModalPrint = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openPrint}
      onClose={handleClosePrint}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openPrint}>
        <Card sx={style} className="max-w-2xl my-4">
          <div className=" max-h-screen overflow-y-auto">
            <div className="py-2 text-lg">โปรดระบุตำแหน่งการวางพาเลท</div>
            <div className="py-2">
              <form onSubmit={handleSubmit(onSubmitPrint)}>
                <div className="flex flex-row flex-wrap">
                  {!_.isEmpty(warehouse?.rows) && (
                    <>
                      <div className="w-full lg:w-1/2 px-1 py-2">
                        <Autocomplete
                          size={'small'}
                          onChange={(e, newValue) => {
                            setSelectWarehouse(newValue);
                          }}
                          defaultValue={selectWarehouse}
                          options={warehouse?.rows}
                          getOptionLabel={(type) => `${type?.name}`}
                          required
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="คลังสินค้า"
                              required
                            />
                          )}
                        />
                      </div>
                    </>
                  )}

                  {selectWarehouse?.type === 'simple' && (
                    <div className="w-1/2 text-lg pl-2 my-auto">
                      เลือกแถวที่ต้องการ
                    </div>
                  )}

                  {selectWarehouse?.type === 'simple' && (
                    <>
                      <HtmlTooltip
                        open={openTooltipArrayPallet}
                        onClose={handleCloseTooltipArrayPallet}
                        placement="right"
                        TransitionComponent={Zoom}
                        title={messageArrayPalletOnSelect}
                      >
                        <div className="w-full rounded-lg border-2 max-h-96 overflow-y-auto">
                          <div className="text-center text-xl p-2 pt-4">
                            ทางเข้า
                          </div>
                          <div
                            className={`grid grid-flow-col grid-cols-2 grid-rows-${parseInt(
                              selectWarehouse?.row / 2,
                              10,
                            )} gap-x-8 gap-y-1 p-2`}
                          >
                            {_.map(
                              selectWarehouse?.positionsimples,
                              (eachPosition, index) => (
                                <div
                                  className={
                                    // eslint-disable-next-line no-underscore-dangle
                                    selectPositionSimple?._id ===
                                    eachPosition?._id
                                      ? 'rounded-md border-4 border-gray-500 p-2 cursor-pointer text-white bg-green-400'
                                      : 'rounded-md border-4 border-gray-500 p-2 cursor-pointer'
                                  }
                                  onClick={() => {
                                    if (
                                      eachPosition?.pallet_id.length ===
                                      eachPosition?.max_capacity
                                    ) {
                                      alert(
                                        'ไม่สามารถเพิ่มพาเลทในแถวนี้ได้เเล้ว',
                                      );
                                    } else {
                                      setSelectPositionSimple(eachPosition);
                                    }

                                    // setOpenTooltipArrayPallet(true);
                                  }}
                                >
                                  <div className="text-center text-lg">
                                    แถว {index + 1}
                                  </div>
                                  <div>
                                    {LinearProgressWithLabel(
                                      eachPosition?.pallet_id.length,
                                      eachPosition?.max_capacity,
                                    )}
                                  </div>
                                </div>
                              ),
                            )}
                          </div>
                        </div>
                      </HtmlTooltip>
                    </>
                  )}
                </div>

                <div className="flex flex-row justify-end gap-1 py-4">
                  <Button
                    variant="contained"
                    type="submit"
                    startIcon={<SaveIcon />}
                  >
                    บันทึก
                  </Button>
                  <Button variant="outlined" onClick={handleClosePrint}>
                    ยกเลิก
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อ/รหัสพาเลท</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> รายการสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ความจุ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> สถานะ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(pallet?.rows) ? (
                pallet?.rows.map((row, index) => (
                  <TableRow
                    key={row?.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>
                      <p className="font-bold">{`${row?.name_running}`}</p>
                      {row?.name && <p>( {row?.name} )</p>}
                      <p>{`${row?.pallet_type?.name}`}</p>
                    </TableCell>
                    <TableCell>
                      <ul className="list-disc">
                        {_.map(row?.products, (eachProduct, index) => (
                          <li key={index}>
                            {eachProduct?.product_type?.name} ({' '}
                            {eachProduct?.product?.tag || '-'} )
                          </li>
                        ))}
                      </ul>
                    </TableCell>
                    <TableCell>
                      {/* {row?.used_capacity} / {row?.pallet_type?.capacity}{' '}
                      {row?.pallet_type?.capacity_unit} */}
                      <div className="list-disc">
                        {_.map(row?.products, (eachProduct, index) => (
                          <div key={index}>
                            {eachProduct?.inventory}{' '}
                            {row?.pallet_type?.capacity_unit}
                            {eachProduct?.inventory <
                              row?.pallet_type?.capacity && (
                              <div className="text-yellow-500 text-sm">
                                (ยังไม่เต็มพาเลท)
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </TableCell>
                    <TableCell>
                      {row?.warehouse_status ? (
                        <>
                          <p className="text-green-500">ลงคลังเรียบร้อย</p>
                          <Link to={`/wms/warehouses/${row?.warehouse?._id}`}>
                            <div className="text-lg cursor-pointer hover:underline">
                              <a className="mr-1">{row?.warehouse?.name}</a>
                              <a>แถว {row?.row}</a>
                            </div>
                          </Link>
                        </>
                      ) : (
                        <p className="text-red-500">ยังไม่ลงคลัง</p>
                      )}
                    </TableCell>
                    <TableCell>
                      <div>
                        <div className="flex gap-1">
                          {row?.warehouse_status ? (
                            <div className="w-1/2">
                              <Button
                                sx={{
                                  color: '#ffffff',
                                  backgroundColor: '#279e03',
                                }}
                                variant="contained"
                                // color={'success'}
                                size={'small'}
                                fullWidth
                                startIcon={<PrintOutlinedIcon />}
                                onClick={() => {
                                  setSelectPallet(row);
                                  printPalletInvoice(row);
                                }}
                              >
                                พิมพ์
                              </Button>
                            </div>
                          ) : (
                            <div className="w-1/2">
                              <Button
                                sx={{
                                  color: '#ffffff',
                                  backgroundColor: '#279e03',
                                }}
                                variant="contained"
                                // color={'success'}
                                disabled={row?.used_capacity === 0}
                                size={'small'}
                                fullWidth
                                startIcon={<PrintOutlinedIcon />}
                                onClick={() => {
                                  handleOpenPrint();
                                  setSelectPallet(row);
                                }}
                              >
                                ใส่ลงคลัง
                              </Button>
                            </div>
                          )}
                          <div className="w-1/2">
                            <Button
                              variant="contained"
                              color={'secondary'}
                              size={'small'}
                              fullWidth
                              startIcon={<SwapHorizontalCircleIcon />}
                              onClick={() => {
                                handleOpenMovePallet();
                                setSelectMovePallet(row);
                              }}
                              disabled={!row?.warehouse_status}
                            >
                              ย้ายพาเลท
                            </Button>
                          </div>
                        </div>
                        <div className="flex gap-1 mt-1">
                          <div className="w-1/2">
                            <Link to={`/doc/pallet-transaction/${row?.id}`}>
                              <Button
                                variant="contained"
                                sx={{
                                  color: '#ffffff',
                                  backgroundColor: '#ebd10c',
                                }}
                                size={'small'}
                                fullWidth
                                startIcon={<DescriptionIcon />}
                                // onClick={() =>
                                //   handlePushToDetailPallet(row?.id)
                                // }
                              >
                                รายละเอียด
                              </Button>
                            </Link>
                          </div>
                          <div className="w-1/2">
                            <Button
                              variant="contained"
                              startIcon={<DeleteIcon />}
                              color={'error'}
                              size={'small'}
                              fullWidth
                              disabled={row?.used_capacity !== 0}
                              onClick={() => {
                                handleDeletePallet(row?.id);
                              }}
                            >
                              ลบ
                            </Button>
                          </div>
                        </div>
                        <div className="flex gap-1 mt-1">
                          <div className="w-1/2">
                            <Button
                              variant="contained"
                              color="inherit"
                              size="small"
                              fullWidth
                              startIcon={<i className="fas fa-barcode" />}
                              onClick={() => {
                                handleGenerateBarcode(
                                  `${row?.prefix}${row?.running_number}`,
                                );
                              }}
                            >
                              บาร์โค้ด
                            </Button>
                          </div>
                          <div className="w-1/2">
                            <Button
                              variant="contained"
                              color="inherit"
                              size="small"
                              fullWidth
                              startIcon={<i className="fas fa-qrcode" />}
                              onClick={() => {
                                handleGenerateQrcode(
                                  `${row?.prefix}${row?.running_number}`,
                                );
                              }}
                            >
                              คิวอาร์โค้ด
                            </Button>
                          </div>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (pallet.isLoading) {
    return <Loading />;
  }
  if (!pallet.isLoading && pallet.isCompleted) {
    return (
      <div>
        {renderModalPrint()}
        {renderModalMovePallet()}
        {renderModalHistoryMovePallet()}
        {renderBarcodeDialog()}
        {renderQrcodeDialog()}
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="self-center flex gap-2">
            <Link to="/doc/pallet-transaction/group-view">
              <Button variant="contained" color="secondary">
                <GridIcon />
                <p className="my-auto pl-1">มุมมองแบบจัดกลุ่ม</p>
              </Button>
            </Link>{' '}
            {renderBottonGroup()}
          </div>
        </div>
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error />;
}

PalletTransactionInvoice.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

PalletTransactionInvoice.defaultProps = {
  title: '',
  subtitle: '',
};

export default PalletTransactionInvoice;
