import {
  STOCKTAKING_INVOICE_ALL,
  STOCKTAKING_INVOICE_GET,
  STOCKTAKING_INVOICE_DEL,
  STOCKTAKING_INVOICE_PUT,
  STOCKTAKING_INVOICE_POST,
  STOCKTAKING_INVOICE_LOADING,
  STOCKTAKING_INVOICE_ERROR,
  STOCKTAKING_INVOICE_RESET,
} from '../types';

import api from '../../../utils/functions/api';

export const stocktakingInvoiceCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: STOCKTAKING_INVOICE_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/stocktaking-invoice`,
      payload,
    );
    dispatch({ type: STOCKTAKING_INVOICE_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: STOCKTAKING_INVOICE_ERROR });
    throw new Error(error);
  }
};

export const stocktakingInvoiceAll = (params) => async (dispatch) => {
  try {
    const {
      name = '',
      size = '',
      page = 1,
      startDate = '',
      endDate = '',
      orderByField = 'date',
      orderBy = 'asc',
      selectYear = '',
      selectEndYear = '',
    } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/stocktaking-invoice?name=${name}&size=${size}&page=${page}&startDate=${startDate}&endDate=${endDate}&orderByField=${orderByField}&orderBy=${orderBy}&selectYear=${selectYear}&selectEndYear=${selectEndYear}`,
    );
    if (status === 200) {
      dispatch({ type: STOCKTAKING_INVOICE_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: STOCKTAKING_INVOICE_ERROR });
    throw new Error(error);
  }
};

export const stocktakingInvoiceGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/stocktaking-invoice/${id}`,
    );
    if (status === 200) {
      dispatch({ type: STOCKTAKING_INVOICE_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: STOCKTAKING_INVOICE_ERROR });
    throw new Error(error);
  }
};

export const stocktakingInvoicePut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: STOCKTAKING_INVOICE_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/stocktaking-invoice/${id}`,
      payload,
    );
    dispatch({ type: STOCKTAKING_INVOICE_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: STOCKTAKING_INVOICE_ERROR });
    throw new Error(error);
  }
};
export const stocktakingInvoiceDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: STOCKTAKING_INVOICE_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/stocktaking-invoice/${id}`,
    );
    dispatch({ type: STOCKTAKING_INVOICE_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: STOCKTAKING_INVOICE_ERROR });
    throw new Error(error);
  }
};

export const stocktakingInvoiceReset = () => async (dispatch) => {
  try {
    dispatch({ type: STOCKTAKING_INVOICE_RESET });
  } catch (error) {
    console.error(error);
    dispatch({ type: STOCKTAKING_INVOICE_ERROR });
    throw new Error(error);
  }
};
