import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import {
  Button,
  Card,
  TextField,
  Autocomplete,
  Box,
  Typography,
  Zoom,
  CardContent,
  IconButton,
} from '@mui/material';
import { Check, XCircle } from 'react-feather';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';

import { styled } from '@mui/material/styles';

import SaveIcon from '@mui/icons-material/Save';

import _ from 'lodash';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { BackButton } from '../../components/Button';

import {
  PalletInvoice,
  PalletInvoiceSingleProduct,
} from '../../components/Report/index';

dayjs.locale('th');
dayjs.extend(buddhistEra);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 12,
  borderRadius: 6,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 6,
    // backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const PalletBox = ({
  palletIndex,
  pallet,
  onHold,
  onWarehousePlaced,
  pagePalletStatus,
  setPagePalletStatus,
  rerender,
  setRerender,
}) => {
  const handleClick = () => {
    if (pallet?.warehouse_status === true) {
      console.log('มีเจ้าของแล้ว');
    } else if (onWarehousePlaced) {
      console.log('Cannot click it on placed in warehouse');
    } else if (onHold) {
      const onHoldStatus = pagePalletStatus?.onHold;
      const selectedPallet = _.findIndex(
        onHoldStatus,
        (each) => each === pallet?._id,
      );
      // eslint-disable-next-line no-param-reassign
      delete pagePalletStatus.onHold[selectedPallet];
      setPagePalletStatus(pagePalletStatus);
    } else {
      pagePalletStatus.onHold.push(pallet?._id);
      setPagePalletStatus(pagePalletStatus);
    }
    setRerender(!rerender);
  };
  if (onWarehousePlaced) {
    return <div></div>;
  }
  return (
    <div key={`pallet-${palletIndex}`} className="p-2">
      <div
        className={`border rounded-md border-gray-300 p-2 ${
          onWarehousePlaced || pallet?.warehouse_status === true
            ? 'cursor-not-allowed'
            : 'cursor-pointer'
        }
        ${onHold ? 'border-gray-500 bg-gray-200 ' : ''}
        ${
          pallet?.warehouse_status === true
            ? 'border-green-200 bg-green-100'
            : ''
        }
        `}
        onClick={handleClick}
        style={{ borderWidth: '4px' }}
      >
        <h5 className="font-semibold">{pallet?.name_running}</h5>
        <div>
          {_.map(pallet?.products, (eachProduct, productIndex) => (
            <div key={`product-${productIndex}`} className="my-2">
              <div>
                {eachProduct?.product_type?.name} {eachProduct?.product?.tag}
              </div>
              <div className="text-sm">
                จำนวน {eachProduct?.inventory} {eachProduct?.product_type?.unit}
              </div>
            </div>
          ))}
        </div>
        {pallet?.warehouse_status && (
          <div className="flex gap-2 border p-2 border-green-500 text-green-800 ">
            <Check size={20} />
            <div className="text-sm">
              ลงคลังแล้ว {pallet?.warehouse?.name} แถว {pallet?.warehouse?.row}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

function PalletGroupArrangement({ title, subtitle }) {
  const dispatch = useDispatch();
  const pallet = useSelector((state) => state.pallet);
  const warehouse = useSelector((state) => state.warehouse);
  const me = useSelector((state) => state.me);

  const initialPageStatus = {
    onHold: [],
    onWarehousePlaced: [],
  };

  const history = useHistory();
  const params = useParams();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    // setValue,
  } = useForm({
    mode: 'onBlur',
  });

  const [selectWarehouse, setSelectWarehouse] = useState();
  const [rerender, setRerender] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectPositionSimple, setSelectPositionSimple] = useState();
  const [arrayPalletOnSelect, setArrayPalletOnSelect] = useState([]);
  const [openTooltipArrayPallet, setOpenTooltipArrayPallet] = useState(false);
  const [pagePalletStatus, setPagePalletStatus] = useState(initialPageStatus);
  const handleCloseTooltipArrayPallet = () => setOpenTooltipArrayPallet(false);

  const palletAll = () => {
    dispatch(
      actions.palletAll({
        groupView: true,
        page: 1,
        size: 1000,
        product: params.id,
      }),
    );
  };

  useEffect(() => {
    palletAll();
    dispatch(actions.warehouseAll({}));
    return () => {};
  }, []);

  console.log('selectPositionSimple', selectPositionSimple);
  console.log('arrayPalletOnSelect', arrayPalletOnSelect);
  console.log('openTooltipArrayPallet', openTooltipArrayPallet);

  useEffect(() => {
    console.log('ทำฟังก์ชั่น find pallet');
    const newArrayPallet = _.map(
      selectPositionSimple?.pallet_id,
      (eachPalletId) => {
        const findPallet = _.find(
          pallet?.rows,
          (eachPallet) => eachPallet?.id === eachPalletId?.pallet,
        );
        return findPallet;
      },
    );
    console.log('newArrayPallet', newArrayPallet);
    setArrayPalletOnSelect(newArrayPallet);
    return () => {};
  }, [selectPositionSimple]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmitArrangement = async (data) => {
    setLoading(true);
    console.log('selectWarehouse', selectWarehouse);

    try {
      const payload = {
        warehouse: selectWarehouse?._id,
        positions: selectWarehouse?.positionsimples,
      };
      console.log('payload', payload);
      await dispatch(actions.positionSimplePutManyPallets(payload));
      alert('สำเร็จ');
      setSelectWarehouse();
      setLoading(false);
      history.goBack();
    } catch (error) {
      console.error(error);
      alert(`ใส่พาเลทลงคลังไม่สำเร็จ ${error?.message}`);
    }
  };

  // const printPalletInvoice = (data) => {
  //   console.log('data in print pallet', data);

  //   const locationData = {
  //     row: data?.row,
  //     warehouse: data?.warehouse?.name,
  //   };

  //   const payload = {
  //     warehouse: data?.warehouse?.id,
  //     date_warehouse: dayjs(new Date()).format(),
  //     url: `${window.location.href}${data?.id}`,
  //   };

  //   console.log('payload in print pallet', payload);

  //   PalletInvoiceSingleProduct(locationData, data, me, payload);
  //   // PalletInvoice(locationData, data, me, payload);
  // };

  const progressBarValue = (value, maxValue) => {
    const NewValue = (value * 100) / maxValue;
    return NewValue;
  };

  const messageArrayPalletOnSelect = (
    <div>
      {_.isEmpty(arrayPalletOnSelect) ? (
        <div className="text-base">ไม่มีพาเลทในแถว</div>
      ) : (
        <>
          <div className="text-center text-xl underline">
            แถวที่ {selectPositionSimple?.row}
          </div>
          {_.map(arrayPalletOnSelect, (each, index) => (
            <div className="text-lg">
              {index + 1} : {each?.name || each?.prefix + each?.running_number}{' '}
              ( ความจุ {each?.used_capacity} / {each?.pallet_type?.capacity})
            </div>
          ))}
        </>
      )}
    </div>
  );

  const LinearProgressWithLabel = (value, maxValue) => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <BorderLinearProgress
          variant="determinate"
          value={progressBarValue(value, maxValue)}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">
          {value}/{maxValue}
        </Typography>
      </Box>
    </Box>
  );

  const renderMapingLayout = () => (
    <Card>
      <CardContent>
        <div className=" max-h-screen overflow-y-auto">
          <div className="py-2 text-lg">โปรดระบุตำแหน่งการวางพาเลท</div>
          <div className="py-2">
            <form onSubmit={handleSubmit(onSubmitArrangement)}>
              <div className="flex flex-row flex-wrap">
                {!_.isEmpty(warehouse?.rows) && (
                  <>
                    <div className="w-full lg:w-1/2 px-1 py-2">
                      <Autocomplete
                        size={'small'}
                        onChange={(e, newValue) => {
                          setSelectWarehouse(newValue);
                        }}
                        defaultValue={selectWarehouse}
                        options={warehouse?.rows}
                        getOptionLabel={(type) => `${type?.name}`}
                        required
                        renderInput={(fieldParam) => (
                          <TextField
                            {...fieldParam}
                            label="คลังสินค้า"
                            required
                          />
                        )}
                      />
                    </div>
                  </>
                )}

                {selectWarehouse?.type === 'simple' && (
                  <div className="w-1/2 text-lg pl-2 my-auto">
                    เลือกแถวที่ต้องการ
                  </div>
                )}

                {selectWarehouse?.type === 'simple' && (
                  <>
                    <HtmlTooltip
                      open={openTooltipArrayPallet}
                      onClose={handleCloseTooltipArrayPallet}
                      placement="right"
                      TransitionComponent={Zoom}
                      title={messageArrayPalletOnSelect}
                    >
                      <div className="w-full rounded-lg border-2 max-h-96 overflow-y-auto">
                        <div className="text-center text-xl p-2 pt-4">
                          ทางเข้า
                        </div>
                        <div
                          className={`grid grid-flow-col grid-cols-2 grid-rows-${parseInt(
                            selectWarehouse?.row / 2,
                            10,
                          )} gap-x-8 gap-y-1 p-2`}
                        >
                          {_.map(
                            selectWarehouse?.positionsimples,
                            (eachPosition, index) => (
                              <div
                                className={
                                  // eslint-disable-next-line no-underscore-dangle
                                  selectPositionSimple?._id ===
                                  eachPosition?._id
                                    ? 'rounded-md border-4 border-gray-500 p-2 cursor-pointer text-white bg-green-400'
                                    : 'rounded-md border-4 border-gray-500 p-2 cursor-pointer'
                                }
                                onClick={() => {
                                  if (
                                    eachPosition?.pallet_id.length +
                                      _.size(pagePalletStatus.onHold) >=
                                    eachPosition?.max_capacity
                                  ) {
                                    alert(
                                      'ไม่สามารถเพิ่มพาเลทในแถวนี้ได้เเล้ว',
                                    );
                                  } else if (
                                    _.isEmpty(pagePalletStatus.onHold)
                                  ) {
                                    // alert(
                                    //   'ยังไม่ได้เลือกพาเลทที่ต้องการจะเพิ่ม',
                                    // );
                                  } else {
                                    selectWarehouse.positionsimples[
                                      index
                                    ].temperaryMembers = [
                                      ...pagePalletStatus.onHold,
                                      ...(selectWarehouse.positionsimples[index]
                                        .temperaryMembers || []),
                                    ];
                                    setSelectWarehouse(selectWarehouse);
                                    pagePalletStatus.onWarehousePlaced = [
                                      ...pagePalletStatus.onHold,
                                      ...pagePalletStatus.onWarehousePlaced,
                                    ];
                                    pagePalletStatus.onHold = [];
                                    setPagePalletStatus(pagePalletStatus);
                                    setRerender(!rerender);
                                  }
                                }}
                              >
                                <div className="text-center text-lg">
                                  แถว {index + 1}
                                </div>
                                <div>
                                  {LinearProgressWithLabel(
                                    eachPosition?.pallet_id.length +
                                      _.size(
                                        _.filter(
                                          eachPosition?.temperaryMembers,
                                          (each) => !_.isEmpty(each),
                                        ),
                                      ),
                                    eachPosition?.max_capacity,
                                  )}
                                </div>
                                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                                  {_.map(
                                    _.filter(
                                      eachPosition?.temperaryMembers,
                                      (each) => !_.isEmpty(each),
                                    ),
                                    (eachMember, memberIndex) => {
                                      const foundedPallet = _.find(
                                        pallet?.rows?.[0]?.pallets,
                                        (each) => each?._id === eachMember,
                                      );
                                      const handleDelete = () => {
                                        // In Row
                                        const indexInRow = _.findIndex(
                                          selectWarehouse.positionsimples[index]
                                            .temperaryMembers,
                                          (each) => each === eachMember,
                                        );
                                        delete selectWarehouse.positionsimples[
                                          index
                                        ].temperaryMembers[indexInRow];
                                        setSelectWarehouse(selectWarehouse);
                                        // In Placed Array
                                        const indexInPlacedArray = _.findIndex(
                                          pagePalletStatus?.onWarehousePlaced,
                                          (each) => each === eachMember,
                                        );
                                        delete pagePalletStatus
                                          .onWarehousePlaced[
                                          indexInPlacedArray
                                        ];
                                        setPagePalletStatus(pagePalletStatus);
                                        setRerender(!rerender);
                                      };
                                      return (
                                        <div
                                          key={`member-${memberIndex}`}
                                          className="p-2  m-1 border-2 rounded-md flex justify-between"
                                        >
                                          <div>
                                            {foundedPallet?.name_running}
                                          </div>
                                          <IconButton
                                            color="error"
                                            size="small"
                                            onClick={handleDelete}
                                          >
                                            <XCircle />
                                          </IconButton>
                                        </div>
                                      );
                                    },
                                  )}
                                </div>
                              </div>
                            ),
                          )}
                        </div>
                      </div>
                    </HtmlTooltip>
                  </>
                )}
              </div>

              <div className="flex flex-row justify-end gap-1 py-4">
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<SaveIcon />}
                >
                  บันทึก
                </Button>
              </div>
            </form>
          </div>
        </div>
      </CardContent>
    </Card>
  );

  const renderDisplayPallets = () => (
    <div className="my-2">
      {_.map(pallet?.rows, (palletGroup, groupIndex) => (
        <Card key={groupIndex}>
          <CardContent>
            <h3 className="font-semibold">กลุ่มที่ {groupIndex + 1}</h3>
            <div className="my-2 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
              {_.map(palletGroup?.pallets, (eachPallet, palletIndex) => (
                <PalletBox
                  pallet={eachPallet}
                  palletIndex={palletIndex}
                  key={`pallet-${palletIndex}`}
                  onHold={_.includes(pagePalletStatus.onHold, eachPallet?._id)}
                  onWarehousePlaced={_.includes(
                    pagePalletStatus.onWarehousePlaced,
                    eachPallet?._id,
                  )}
                  setPagePalletStatus={setPagePalletStatus}
                  pagePalletStatus={pagePalletStatus}
                  rerender={rerender}
                  setRerender={setRerender}
                />
              ))}
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );

  if (pallet.isLoading || loading) {
    return <Loading />;
  }

  if (!pallet.isLoading && pallet.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="self-center"></div>
        </div>
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
          <div className="text-lg font-semibold ml-6">ข้อมูลกลุ่มของพาเลท</div>
        </div>
        <div>{renderDisplayPallets()}</div>
        <div>{renderMapingLayout()}</div>
      </div>
    );
  }
  return <Error />;
}

PalletGroupArrangement.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

PalletGroupArrangement.defaultProps = {
  title: '',
  subtitle: '',
};

PalletBox.propTypes = {
  palletIndex: PropTypes.number,
  pallet: PropTypes.object,
  onHold: PropTypes.bool,
  onWarehousePlaced: PropTypes.bool,
  pagePalletStatus: PropTypes.shape({
    onHold: PropTypes.arrayOf(PropTypes.string),
    onWarehousePlaced: PropTypes.arrayOf(PropTypes.string),
  }),
  setPagePalletStatus: PropTypes.func,
  rerender: PropTypes.bool,
  setRerender: PropTypes.func,
};

export default PalletGroupArrangement;
