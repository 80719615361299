/* eslint-disable import/no-unresolved */
import {
  TextField,
  Autocomplete,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

export function ProductForm({
  errors,
  product,
  Controller,
  control,
  productType,
  handleChangeCheckTag,
  checkTag,
  handleCheckWeightSelf,
  checkWeightSelf,
}) {
  const [reload, setReload] = useState(false);

  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1">
        <FormControlLabel
          control={
            <Checkbox checked={checkTag} onChange={handleChangeCheckTag} />
          }
          label="มีรหัส/แท็ก"
        />
      </div>

      {checkTag && (
        <div className="w-full px-1 py-2">
          <Controller
            name={'tag'}
            control={control}
            defaultValue={product ? product.type_code : ''}
            rules={{ required: checkTag }}
            render={({ field }) => (
              <TextField
                {...field}
                label="รหัสสินค้า"
                fullWidth
                size={'small'}
                required={checkTag}
                helperText={errors.tag && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
      )}

      <div className="w-full px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={product ? product.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อสินค้า"
              required
              fullWidth
              size={'small'}
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>

      <div className="w-full px-1">
        <FormControlLabel
          control={
            <Checkbox
              checked={checkWeightSelf}
              onChange={handleCheckWeightSelf}
            />
          }
          label="ใส่น้ำหนักตอนรับสินค้าเข้า"
        />
      </div>

      {!checkWeightSelf && (
        <div className="w-full  px-1 py-2">
          <Controller
            name={'weight'}
            control={control}
            defaultValue={product ? product.weight : ''}
            rules={{ required: !checkWeightSelf }}
            render={({ field }) => (
              <TextField
                {...field}
                label="น้ำหนักสินค้าต่อก้อน ( กิโลกรัม )"
                required={!checkWeightSelf}
                fullWidth
                size={'small'}
                helperText={errors.weight && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
      )}

      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'type'}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Autocomplete
              size={'small'}
              disableClearable
              {...field}
              onChange={(e, newValue) => {
                field.onChange(newValue);
                setReload(!reload);
              }}
              options={productType?.rows}
              getOptionLabel={(type) => `${type?.name}`}
              required
              renderInput={(params) => (
                <TextField {...params} label="ประเภทสินค้า" required />
              )}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'unit'}
          control={control}
          defaultValue={product ? product.unit : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="หน่วยสินค้า"
              required
              fullWidth
              size={'small'}
              helperText={errors.unit && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'descriptions'}
          control={control}
          defaultValue={product ? product.descriptions : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รายละเอียดเพิ่มเติม"
              fullWidth
              rows={3}
              multiline={true}
              size={'small'}
              helperText={errors.descriptions && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
    </div>
  );
}

ProductForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.string,
    unit: PropTypes.string,
    tag: PropTypes.string,
    descriptions: PropTypes.string,
    weight: PropTypes.number,
  }),
  product: PropTypes.object,
  productType: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
  handleChangeCheckTag: PropTypes.func,
  handleCheckWeightSelf: PropTypes.func,
  checkTag: PropTypes.bool,
  checkWeightSelf: PropTypes.bool,
};

ProductForm.defaultProps = {
  product: null,
  productType: null,
};

export default ProductForm;
