import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';
import { InformationForm } from '../../components/Forms';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';

function Information({ title, subtitle }) {
  const dispatch = useDispatch();
  const setting = useSelector((state) => state.setting);
  const [logoImage, setLogoImage] = useState([]);

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  useEffect(() => {
    dispatch(actions.settingAll());
    return () => {};
  }, []);

  const onSubmit = async (data, event) => {
    try {
      event.preventDefault();
      if (!_.isEmpty(logoImage)) {
        await dispatch(
          actions.settingPut(setting.id, {
            ...data,
            logo: {
              image: logoImage[0]?.data_url,
              imageType: 'logo',
              alt: '',
            },
          }),
        );
        setLogoImage([]);
      } else {
        await dispatch(actions.settingPut(setting.id, data));
      }
      alert('สำเร็จ');
      await dispatch(actions.settingAll());
    } catch (error) {
      console.error(error);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderForm = () => (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InformationForm
        Controller={Controller}
        control={control}
        errors={errors}
        information={setting}
        selectedImage={logoImage}
        setSelectedImage={setLogoImage}
      />
      <div className="flex flex-row justify-end gap-1 py-4">
        <Button variant="contained" type="submit" startIcon={<SaveIcon />}>
          บันทึก
        </Button>
      </div>
    </form>
  );

  if (setting.isLoading) {
    return <Loading />;
  }
  if (!setting.isLoading && setting.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderForm()}
      </div>
    );
  }
  return <Error />;
}
Information.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Information.defaultProps = {
  title: '',
  subtitle: '',
};

export default Information;
