/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Button,
  Card,
  TextField,
  Checkbox,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import dayjs from 'dayjs';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { Trash2 as Delete } from 'react-feather';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { CreateCustomer, CreateProduct } from '../../components/Dialogs';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { BackButton } from '../../components/Button';
import { ViewTitle } from '../../components/ViewTitle';

const filter = createFilterOptions();

function CreateConsignmentInvoice({ title, subtitle }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const consignmentInvoice = useSelector((state) => state.consignmentInvoice);
  const customer = useSelector((state) => state.customer);
  // const customerType = useSelector((state) => state.customerType);
  const me = useSelector((state) => state.me);
  const product = useSelector((state) => state.product);
  const [consignmentInvoiceType, setConsignmentInvoiceType] = useState('');
  const [customerData, setCustomerData] = useState(customer?.rows?.[0]);
  const [productData, setProductData] = useState(product?.rows?.[0]);

  const [openAddCustomer, toggleOpenAddCustomer] = useState(false);
  const [openAddProduct, toggleOpenAddProduct] = useState(false);
  const [depositStart, setDepositStart] = useState(false);
  const [depositEnd, setDepositEnd] = useState(false);
  const [dialogCustomer, setDialogCustomer] = useState({
    name: '',
  });
  const [dialogProduct, setDialogProduct] = useState({
    name: '',
    // tag: '',
    unit: '',
  });

  const NewProduct = _.map(product?.rows, (eachProduct, index) => ({
    ...eachProduct,
    index: index + 1,
  }));

  const NewCustomer = _.map(customer?.rows, (eachCustomer, index) => ({
    ...eachCustomer,
    index: index + 1,
  }));

  console.log('NewProduct', NewProduct);

  const handleChangeDepositStart = (event) => {
    setDepositStart(event.target.checked);
  };

  const handleChangeDepositEnd = (event) => {
    setDepositEnd(event.target.checked);
  };
  // console.log('NewProduct', NewProduct);

  const handleCloseAddCustomer = () => {
    setDialogCustomer({
      name: '',
    });
    toggleOpenAddCustomer(false);
  };

  const handleCloseAddProduct = () => {
    setDialogProduct({
      name: '',
      // tag: '',
      unit: '',
    });
    toggleOpenAddProduct(false);
  };

  // console.log('customerData', customerData);

  const handleChangeConsignmentInvoiceType = (event) => {
    setConsignmentInvoiceType(event.target.value);
  };

  const { register, control, handleSubmit, setValue } = useForm({
    mode: 'onBlur',
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'itemList',
  });

  useEffect(() => {
    dispatch(actions.customerAll({}));
    dispatch(actions.consignmentInvoiceAll({}));
    dispatch(actions.productAllStock());
    dispatch(actions.meGet());
    return () => {};
  }, []);

  useEffect(() => {
    if (!product.isLoading) {
      setValue('itemList', [
        {
          product: product.rows[0]?.id,
          tag: product.rows[0]?.tag,
          weight: product.rows[0]?.weight || '',
        },
      ]);
    }
    setCustomerData(customer?.rows?.[0]);
    return () => {};
  }, [customer]);

  const handleAddItem = () => {
    append({
      product: product.rows[0].id,
      tag: product.rows[0]?.tag,
      weight: product.rows[0]?.weight || '',
    });
  };

  const handleDeleteItem = (index) => {
    remove(index);
  };

  const onSubmit = async (data) => {
    try {
      const newList = _.map(data?.itemList, (item) => item);

      const DataOfInvoice = {
        ...data,
        name: data.name,
        description: data.description,
        productList: newList,
        consignment_invoice_type: consignmentInvoiceType,
        employee: me?.userData?.id,
        customer: customerData?.id,
        deposit_end: depositEnd ? data.deposit_end : undefined,
        deposit_start: depositStart ? data.deposit_start : undefined,
      };

      console.log('DataOfInvoice', DataOfInvoice);
      console.log('newList', newList);
      await dispatch(actions.consignmentInvoiceCreate(DataOfInvoice));
      alert('สำเร็จ');
      history.push('/doc/consignment-invoice');
    } catch (error) {
      console.error(error);
    }
  };

  const rendInfomationTextField = () => (
    <div>
      <div className="my-2 w-full">
        <div className="my-2 w-full">
          <TextField
            size="small"
            fullWidth
            required
            label="เลขใบรับฝาก"
            defaultValue=""
            {...register('name', { required: true })}
          />
        </div>
        <div className="my-2 w-full">
          <Autocomplete
            onChange={(event, newValue) => {
              if (typeof newValue === 'string') {
                // timeout to avoid instant validation of the dialog's form.
                setTimeout(() => {
                  toggleOpenAddCustomer(true);
                  setDialogCustomer({
                    name: newValue,
                  });
                });
              } else if (newValue && newValue?.inputValue) {
                toggleOpenAddCustomer(true);
                setDialogCustomer({
                  name: newValue?.inputValue,
                });
              } else {
                setCustomerData(newValue);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              if (params?.inputValue !== '') {
                filtered.push({
                  inputValue: params?.inputValue,
                  name: `เพิ่ม "${params?.inputValue}"  ลงในระบบ`,
                });
              }

              return filtered;
            }}
            handleHomeEndKeys
            value={customerData}
            options={NewCustomer}
            defaultValue={customer?.rows ? customer?.rows?.[0] : ' '}
            getOptionLabel={(option) => {
              // e.g value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              if (option?.inputValue) {
                return option?.inputValue;
              }
              return option?.name;
            }}
            required
            renderOption={(props, option) => (
              <li {...props}>
                {option?.index} : {option?.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="ชื่อลูกค้า" />
            )}
          />
        </div>
        <div className="my-2 w-full">
          <TextField
            size="small"
            fullWidth
            label="ทะเบียนรถ"
            defaultValue=""
            {...register('car_registration', { required: false })}
          />
        </div>

        <FormLabel>สำหรับ</FormLabel>
        <div className="md:flex">
          <div className="my-2 w-full md:w-1/2 px-4">
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={consignmentInvoiceType}
              onChange={handleChangeConsignmentInvoiceType}
            >
              <FormControlLabel
                value="freeze"
                control={<Radio />}
                label="ฟรีซ"
              />
              <FormControlLabel value="keep" control={<Radio />} label="เก็บ" />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label="อื่นๆ โปรดระบุในหมายเหตุ"
              />
            </RadioGroup>
          </div>
          <div className="my-2 w-full md:w-1/2 px-4">
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={depositStart}
                    onChange={handleChangeDepositStart}
                  />
                }
                label="วันที่ฝาก"
              />
            </div>
            {depositStart && (
              <div>
                <Controller
                  name={'deposit_start'}
                  control={control}
                  defaultValue={dayjs(new Date())
                    .locale('th')
                    .format('YYYY-MM-DD')}
                  rules={{ required: depositStart }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="date"
                      fullWidth
                      required={depositStart}
                      size={'small'}
                    />
                  )}
                />
              </div>
            )}

            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={depositEnd}
                    onChange={handleChangeDepositEnd}
                  />
                }
                label="วันที่ครบกำหนด"
              />
            </div>
            {depositEnd && (
              <div>
                <Controller
                  name={'deposit_end'}
                  control={control}
                  defaultValue={dayjs(new Date())
                    .add(1, 'day')
                    .locale('th')
                    .format('YYYY-MM-DD')}
                  rules={{ required: depositEnd }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="date"
                      fullWidth
                      required={depositEnd}
                      size={'small'}
                    />
                  )}
                />
              </div>
            )}
          </div>
        </div>

        <div className="my-2 w-full">
          <TextField
            label="หมายเหตุ"
            fullWidth
            multiline
            required={consignmentInvoiceType === 'other'}
            rows={2}
            defaultValue=""
            {...register('description')}
          />
        </div>
      </div>
    </div>
  );

  const getOpObj = (option) => {
    if (!option) {
      // eslint-disable-next-line no-param-reassign
      option = _.find(product.rows, (op) => op.id === option);
    }
    return option;
  };

  const renderTable = () => (
    <div>
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size={'small'}>
            <colgroup>
              <col width="8%" />
              <col width="20%" />
              <col width="32%" />
              <col width="15%" />
              <col width="15%" />
              <col width="10%" />
            </colgroup>
            <TableHead>
              <TableRow className="bg-green-100">
                <TableCell>
                  <div className="font-bold py-1">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">รหัสสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ชื่อสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">น้ำหนักต่อชิ้น</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">จำนวน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">เศษ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(fields) ? (
                _.map(fields, (item, index) => (
                  <TableRow
                    key={item.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="py-1">{`${index + 1}`}</div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="py-1">
                        <Controller
                          name={`itemList.${index}.tag`}
                          control={control}
                          // defaultValue={
                          //   NewProduct ? NewProduct[0]?.weight : ' '
                          // }
                          render={({ field }) => (
                            <TextField
                              {...field}
                              size="small"
                              fullWidth
                              required
                              label="รหัสสินค้า"
                            />
                          )}
                        />
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="py-1">
                        <Controller
                          name={`itemList.${index}.product`}
                          control={control}
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              size="small"
                              onChange={(event, newValue) => {
                                field.onChange(newValue?.id);
                                setValue(
                                  `itemList.${index}.weight`,
                                  newValue?.weight || '',
                                );
                                setValue(
                                  `itemList.${index}.tag`,
                                  newValue?.tag || '',
                                );
                                if (typeof newValue === 'string') {
                                  // timeout to avoid instant validation of the dialog's form.
                                  setTimeout(() => {
                                    toggleOpenAddProduct(true);
                                    setDialogProduct({
                                      name: newValue,
                                    });
                                  });
                                } else if (newValue && newValue?.inputValue) {
                                  toggleOpenAddProduct(true);
                                  setDialogProduct({
                                    name: newValue?.inputValue,
                                  });
                                } else {
                                  setProductData(newValue);
                                }
                              }}
                              filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                if (params?.inputValue !== '') {
                                  filtered.push({
                                    inputValue: params?.inputValue,
                                    name: `เพิ่ม "${params?.inputValue}"  ลงในระบบ`,
                                  });
                                }
                                return filtered;
                              }}
                              value={getOpObj(field.value)?.id}
                              disableClearable
                              options={NewProduct}
                              defaultValue={NewProduct ? NewProduct[0] : ' '}
                              getOptionLabel={(option) => {
                                // e.g value selected with enter, right from the input
                                if (typeof option === 'string') {
                                  return option;
                                }
                                if (option?.inputValue) {
                                  return option?.inputValue;
                                }
                                return option?.name;
                              }}
                              required
                              renderOption={(props, option) => (
                                <li {...props} key={option.index}>
                                  {option?.index} : {option?.name}{' '}
                                  {option?.weight && (
                                    <>( {option?.weight} kg )</>
                                  )}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField {...params} label="สินค้า" />
                              )}
                            />
                          )}
                        />
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="py-1">
                        <Controller
                          name={`itemList.${index}.weight`}
                          control={control}
                          // defaultValue={
                          //   NewProduct ? NewProduct[0]?.weight : ' '
                          // }
                          render={({ field }) => (
                            <TextField
                              {...field}
                              size="small"
                              fullWidth
                              required
                              label="น้ำหนักต่อก้อน"
                            />
                          )}
                        />
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="py-1">
                        <Controller
                          name={`itemList.${index}.quantity`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              size="small"
                              fullWidth
                              required
                              type="number"
                              label="จำนวนก้อน"
                              {...field}
                            />
                          )}
                        />
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="py-1">
                        <Controller
                          render={({ field }) => (
                            <Checkbox size="small" {...field} />
                          )}
                          name={`itemList.${index}.scrap`}
                          control={control}
                        />
                      </div>
                    </TableCell>

                    <TableCell component="th" scope="row">
                      <Button
                        size="sm"
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          handleDeleteItem(index);
                        }}
                      >
                        <Delete size={16} color="red" />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
  const renderAddButton = () => (
    <Button
      size="small"
      variant="outlined"
      color="success"
      onClick={() => {
        handleAddItem();
      }}
    >
      เพิ่ม
    </Button>
  );

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  // console.log('selectCustomer', selectCustomer);

  if (consignmentInvoice.isLoading || customer.isLoading || me.isLoading) {
    return <Loading />;
  }
  if (
    !consignmentInvoice.isLoading &&
    consignmentInvoice.isCompleted &&
    !customer.isLoading &&
    customer.isCompleted &&
    !me.isLoading &&
    me.isCompleted
  ) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <CreateCustomer
          openAddCustomer={openAddCustomer}
          handleCloseAddCustomer={handleCloseAddCustomer}
          dialogCustomer={dialogCustomer}
          setCustomerData={setCustomerData}
          setDialogCustomer={setDialogCustomer}
        />
        <CreateProduct
          openAddProduct={openAddProduct}
          handleCloseAddProduct={handleCloseAddProduct}
          dialogProduct={dialogProduct}
          setProductData={setProductData}
          setDialogProduct={setDialogProduct}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-wrap gap-y-2">
            <div className="w-full px-1 min-h-full">
              <Card className="p-4 ">
                <div className="flex flex-wrap">
                  <div className="w-full">{rendInfomationTextField()}</div>
                </div>
              </Card>
            </div>
          </div>
          <div className="flex flex-wrap my-2">
            <div className="w-full  px-1 min-h-full">
              <div className="flex flex-wrap">
                <div className="w-full">{renderTable()}</div>
              </div>
            </div>
          </div>
          <div className="m-1 flex justify-between">
            {renderAddButton()}
            <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    );
  }
  return <Error />;
}

CreateConsignmentInvoice.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateConsignmentInvoice.defaultProps = {
  title: '',
  subtitle: '',
};

export default CreateConsignmentInvoice;
