import {
  CONSIGNMENT_INVOICE_ALL,
  CONSIGNMENT_INVOICE_GET,
  CONSIGNMENT_INVOICE_PUT,
  CONSIGNMENT_INVOICE_DEL,
  CONSIGNMENT_INVOICE_POST,
  CONSIGNMENT_INVOICE_RESET,
  CONSIGNMENT_INVOICE_LOADING,
  CONSIGNMENT_INVOICE_ERROR,
} from '../../actions/types';

const initialState = {
  customer: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case CONSIGNMENT_INVOICE_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case CONSIGNMENT_INVOICE_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case CONSIGNMENT_INVOICE_POST:
      return { isLoading: false, isCompleted: true };
    case CONSIGNMENT_INVOICE_PUT:
      return { isLoading: false, isCompleted: true };
    case CONSIGNMENT_INVOICE_DEL:
      return { isLoading: false, isCompleted: true };
    case CONSIGNMENT_INVOICE_RESET:
      return { ...action.payload, isLoading: true, isCompleted: false };
    case CONSIGNMENT_INVOICE_LOADING:
      return { isLoading: true, isCompleted: true };
    case CONSIGNMENT_INVOICE_ERROR:
      return { isLoading: false, isCompleted: false };
    default:
      return state;
  }
}
