export const currencyFormatter = new Intl.NumberFormat('th', {
  style: 'decimal',
  currency: 'THB',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export const integerCurrencyFormatter = new Intl.NumberFormat('th', {
  style: 'decimal',
  currency: 'THB',
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

export default currencyFormatter;
