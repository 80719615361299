import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Button,
  Card,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  IconButton,
  Tooltip,
  Radio,
  RadioGroup,
  FormControlLabel,
  // Select,
  // FormControl,
  // MenuItem,
  // InputLabel,
} from '@mui/material';

import AddBoxIcon from '@mui/icons-material/AddBox';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

import thLocale from 'date-fns/locale/th';
const localeMap = {
  th: thLocale,
};
const maskMap = {
  th: '__/__/____',
};

import _ from 'lodash';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

dayjs.locale('th');
dayjs.extend(buddhistEra);

function stocktakingInvoice({ title, subtitle }) {
  const dispatch = useDispatch();
  const stocktakingInvoice = useSelector((state) => state.stocktakingInvoice);
  const history = useHistory();

  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [orderByDir, setOrderByDir] = useState('asc');
  const [orderByField, setOrderByField] = useState('date');

  useEffect(() => {
    dispatch(
      actions.stocktakingInvoiceAll({
        name,
        page,
        size,
        startDate,
        endDate,
        orderBy: orderByDir,
        orderByField,
      }),
    );
    return () => {};
  }, [name, page, size, startDate, endDate, orderByDir, orderByField]);

  console.log('stocktakingInvoice', stocktakingInvoice?.rows);

  useEffect(() => {
    setTotal(stocktakingInvoice?.total);
    return () => {};
  }, [stocktakingInvoice, startDate, endDate, orderByDir, orderByField]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleDeleteStocktakingInvoice = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.stocktakingInvoiceDelete(id));
        await dispatch(
          actions.stocktakingInvoiceAll({
            name,
            page,
            size,
            startDate,
            endDate,
            orderBy: orderByDir,
            orderByField,
          }),
        );
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handlePushToDetailStocktakingInvoice = async (id) => {
    history.push(`/doc/stocktaking-invoice/${id}`);
  };

  const handlePushToEditStocktakingInvoice = async (id) => {
    history.push(`/doc/stocktaking-invoice/edit/${id}`);
  };

  const handlePushToCreateStocktakingInvoice = async () => {
    history.push('/doc/stocktaking-invoice/create');
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const chevronDisplay = (field) => {
    if (field === orderByField) {
      if (orderByDir === 'asc') {
        return (
          <div className="self-center  mx-3 ">
            <i className="fas fa-chevron-up text-sm" />
          </div>
        );
      }
      return (
        <div className="self-center  mx-3 ">
          <i className="fas fa-chevron-down text-sm" />
        </div>
      );
    }
    return (
      <div className="self-center  mx-3 ">
        <i className="fas fa-chevron-down text-gray-400 text-sm" />
      </div>
    );
  };

  const setSelectedField = (field) => {
    if (orderByField === field) {
      if (orderByDir === 'asc') {
        setOrderByDir('desc');
      } else {
        setOrderByDir('asc');
      }
    } else {
      setOrderByField(field);
      setOrderByDir('desc');
    }
  };

  const renderAddButton = () => (
    <div className="flex flex-row justify-end pb-4">
      <div>
        <Button
          variant="contained"
          onClick={() => handlePushToCreateStocktakingInvoice()}
          startIcon={<AddBoxIcon />}
        >
          เพิ่ม
        </Button>
      </div>
    </div>
  );

  const renderPeriod = () => (
    <>
      <div className="grid md:grid-cols-2 pb-4 px-4 pt-4 gap-x-2 gap-y-2">
        <div className="bg-white flex">
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            locale={localeMap['th']}
          >
            <DesktopDatePicker
              label="วันเริ่มต้น"
              value={startDate}
              mask={maskMap['th']}
              minDate={new Date('2020-01-01')}
              onChange={(newValue) => {
                setStartDate(dayjs(newValue).toISOString());
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </div>
        <div className="bg-white flex">
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            locale={localeMap['th']}
          >
            <DesktopDatePicker
              label="วันสิ้นสุด"
              value={endDate}
              mask={maskMap['th']}
              minDate={new Date('2020-01-01')}
              onChange={(newValue) => {
                setEndDate(dayjs(newValue).toISOString());
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
          <div className="my-auto ml-1">
            <Tooltip
              title="รีเช็ตการค้นหา"
              onClick={() => {
                setStartDate('');
                setEndDate('');
              }}
            >
              <IconButton color="primary">
                <RestartAltIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  );

  const renderSearch = () => (
    <>
      <div className="pt-4 px-4 flex flex-row">
        <div className="w-full">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">เลขใบเบิก</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ชื่อลูกค้า /</div>
                  <div className="font-bold">ทะเบียนรถ</div>
                </TableCell>
                <TableCell
                  onClick={() => setSelectedField('date')}
                  className="cursor-pointer"
                >
                  <div className="flex">
                    <div className="font-bold">วันที่ดำเนินการ</div>
                    {chevronDisplay('date')}
                  </div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ผู้ดำเนินการ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(stocktakingInvoice?.rows) ? (
                stocktakingInvoice?.rows.map((row, index) => (
                  <TableRow
                    key={row?.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>
                      <p className="font-bold">{`${row?.name}`}</p>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">{`${row?.customer?.name}`}</div>
                      {row?.car_registration && (
                        <div>( {`${row?.car_registration}`} )</div>
                      )}
                    </TableCell>
                    <TableCell>{`${dayjs(row?.date).format(
                      'D MMM BBBB',
                    )}`}</TableCell>
                    <TableCell>{`${row?.employee?.firstname} ${row?.employee?.lastname}`}</TableCell>
                    <TableCell>
                      <div className="my-1">
                        <Button
                          variant="contained"
                          color={'info'}
                          size={'small'}
                          fullWidth
                          startIcon={<DescriptionIcon />}
                          onClick={() =>
                            // eslint-disable-next-line implicit-arrow-linebreak
                            handlePushToDetailStocktakingInvoice(row?.id)
                          }
                        >
                          รายละเอียด
                        </Button>
                      </div>
                      <div className="my-1">
                        <Button
                          variant="contained"
                          color={'warning'}
                          size={'small'}
                          fullWidth
                          startIcon={<EditIcon />}
                          disabled
                          onClick={() =>
                            // eslint-disable-next-line implicit-arrow-linebreak
                            handlePushToEditStocktakingInvoice(row?.id)
                          }
                        >
                          แก้ไข
                        </Button>
                      </div>
                      <div>
                        <Button
                          variant="contained"
                          color={'error'}
                          size={'small'}
                          fullWidth
                          startIcon={<DeleteIcon />}
                          disabled
                          onClick={() => {
                            handleDeleteStocktakingInvoice(row?.id);
                          }}
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (stocktakingInvoice.isLoading) {
    return <Loading />;
  }
  if (!stocktakingInvoice.isLoading && stocktakingInvoice.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="mt-6">{renderAddButton()}</div>
        </div>
        <Card>
          {renderSearch()}
          {renderPeriod()}
        </Card>
        {renderTable()}
      </div>
    );
  }
  return <Error />;
}

stocktakingInvoice.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

stocktakingInvoice.defaultProps = {
  title: '',
  subtitle: '',
};

export default stocktakingInvoice;
