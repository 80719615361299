/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

// eslint-disable-next-line object-curly-newline
import {
  Backdrop,
  Button,
  Card,
  Fade,
  Modal,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  IconButton,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
} from '@mui/material';

import {
  ReportDairyConsignment,
  ReportDairyStockTacking,
} from '../../components/Report/index';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

import { ArrowLeft, ArrowRight } from 'react-feather';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';

import thLocale from 'date-fns/locale/th';
const localeMap = {
  th: thLocale,
};
const maskMap = {
  th: '__/__/____',
};

import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import AddBoxIcon from '@mui/icons-material/AddBox';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

import { Link } from 'react-router-dom';
import buddhistEra from 'dayjs/plugin/buddhistEra';

dayjs.locale('th');
dayjs.extend(buddhistEra);

const MainReportDairy = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const consignmentInvoice = useSelector((state) => state.consignmentInvoice);
  const stocktakingInvoice = useSelector((state) => state.stocktakingInvoice);

  const [selectYear, setSelectYear] = useState(
    dayjs().startOf('month').toISOString(),
  );
  const [selectEndYear, setSelectEndYear] = useState(
    dayjs().endOf('month').toISOString(),
  );
  const [reportType, setReportType] = useState('consignment');

  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [total, setTotal] = useState(undefined);

  console.log('reportType', reportType);

  const NewConsignmentInvoice = _.chain(consignmentInvoice?.rows)
    .groupBy('deposit_start')
    .map((value, key) => ({ day: key, consignment_invoice: value }))
    .value();

  const NewStocktakingInvoice = _.chain(stocktakingInvoice?.rows)
    .groupBy('date')
    .map((value, key) => ({ day: key, consignment_invoice: value }))
    .value();

  console.log('NewConsignmentInvoice', NewConsignmentInvoice);
  console.log('NewStocktakingInvoice', NewStocktakingInvoice);

  useEffect(() => {
    dispatch(
      actions.consignmentInvoiceAll({
        name,
        page,
        size,
        selectYear,
        selectEndYear,
      }),
    );

    dispatch(
      actions.stocktakingInvoiceAll({
        name,
        page,
        size,
        selectYear,
        selectEndYear,
      }),
    );
    return () => {};
  }, [name, page, size, selectYear, reportType]);

  useEffect(() => {
    dispatch(actions.meGet());
    return () => {};
  }, [name, page, size]);

  useEffect(() => {
    if (reportType === 'consignment') {
      setTotal(consignmentInvoice?.total);
    }
    setTotal(stocktakingInvoice?.total);
    return () => {};
  }, [consignmentInvoice, stocktakingInvoice]);

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeReportType = (event) => {
    setReportType(event.target.value);
  };

  const printReportDairyConsignment = (data) => {
    console.log('data in print report', data);
    ReportDairyConsignment(data, me);
    // PalletInvoice(locationData, data, me, payload);
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderSelectYear = () => (
    <Card>
      <div className="flex flex-row justify-end p-3">
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          locale={localeMap['th']}
        >
          <DesktopDatePicker
            label="เลือกปี"
            inputFormat="yyyy"
            views={['year']}
            value={selectYear}
            mask={maskMap['th']}
            minDate={new Date('2010-01-01')}
            maxDate={new Date('2030-01-01')}
            onChange={(newValue) => {
              setSelectYear(dayjs(newValue).toISOString());
              setSelectEndYear(dayjs(newValue).endOf('month').toISOString());
            }}
            renderInput={(params) => (
              <TextField fullWidth {...params} size={'small'} />
            )}
          />
        </LocalizationProvider>
      </div>
    </Card>
  );

  const renderReportType = () => (
    <Card>
      <div className="flex flex-row justify-end p-3">
        <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
          <InputLabel id="type" size={'small'}>
            เลือกใบรายงาน
          </InputLabel>
          <Select
            label="เลือกใบรายงาน"
            size={'small'}
            value={reportType}
            onChange={handleChangeReportType}
            fullWidth
          >
            <MenuItem value={'consignment'}>ใบรับฝาก</MenuItem>
            <MenuItem value={'stocktaking'}>ใบเบิก</MenuItem>
          </Select>
        </FormControl>
      </div>
    </Card>
  );

  const minusOneMonth = () => {
    const previousMonth = dayjs(selectYear)
      .subtract(1, 'month')
      .startOf('month');
    const endOfPreviousMonth = dayjs(selectEndYear)
      .subtract(1, 'month')
      .endOf('month');
    setSelectYear(previousMonth.toISOString());
    setSelectEndYear(endOfPreviousMonth.toISOString());
  };

  const addOneMonth = () => {
    const previousMonth = dayjs(selectYear).add(1, 'month').startOf('month');
    const endOfPreviousMonth = dayjs(selectEndYear)
      .add(1, 'month')
      .endOf('month');
    setSelectYear(previousMonth.toISOString());
    setSelectEndYear(endOfPreviousMonth.toISOString());
  };

  const renderSearch = () => (
    <Card>
      <div className="hidden p-4 md:flex justify-between">
        <div>
          <Button
            variant="outlined"
            startIcon={<ArrowLeft />}
            onClick={minusOneMonth}
          >
            ก่อนหน้า
          </Button>
        </div>
        <div className="my-auto text-bold text-xl">
          {dayjs(selectYear).format('MMMM')}
        </div>
        <div>
          <Button
            variant="outlined"
            endIcon={<ArrowRight />}
            onClick={addOneMonth}
          >
            ถัดไป
          </Button>
        </div>
      </div>

      <div className="md:hidden p-4 justify-between">
        <div className="my-auto text-bold text-xl text-center w-full">
          {dayjs(selectYear).format('MMMM')}
        </div>
        <div className="w-full flex justify-between gap-4 mt-2">
          <div className="w-1/2">
            <Button
              fullWidth
              variant="outlined"
              startIcon={<ArrowLeft />}
              onClick={minusOneMonth}
            >
              ก่อนหน้า
            </Button>
          </div>
          <div className="w-1/2">
            <Button
              fullWidth
              variant="outlined"
              endIcon={<ArrowRight />}
              onClick={addOneMonth}
            >
              ถัดไป
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-2 hidden md:block">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">วันที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">จำนวนใบรับฝาก</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">จำนวนสินค้า ( กิโลกรัม )</div>
                </TableCell>
              </TableRow>
            </TableHead>
            {_.map(
              reportType === 'consignment'
                ? NewConsignmentInvoice
                : NewStocktakingInvoice,
              (row, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  className={index % 2 === 0 ? 'bg-gray-100' : ''}
                >
                  <TableCell component="th" scope="row">
                    <div className="flex md:justify-between w-full xl:w-1/2">
                      <div className="my-auto">
                        {row?.day === 'undefined' ? (
                          'ไม่ระบุวันฝาก'
                        ) : (
                          <>{dayjs(row?.day).format('DD MMMM YYYY')}</>
                        )}
                      </div>
                      <div>
                        <IconButton
                          color="primary"
                          onClick={() => {
                            printReportDairyConsignment(row);
                          }}
                        >
                          <PrintOutlinedIcon />
                        </IconButton>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold cursor-pointer hover:underline flex">
                      <div className="text-xl pr-2 my-auto">
                        {row?.consignment_invoice?.length}
                      </div>
                      <div className="my-auto">ใบรับฝาก</div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="w-1/2 flex justify-end">
                      {_.sumBy(
                        row?.consignment_invoice,
                        (e) => e?.total_weight,
                      ).toLocaleString(undefined)}
                    </div>
                  </TableCell>
                </TableRow>
              ),
            )}
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  const renderModule = () => (
    <div className="my-2 md:hidden block">
      <div className="w-full">
        {_.map(
          reportType === 'consignment'
            ? NewConsignmentInvoice
            : NewStocktakingInvoice,
          (row, index) => (
            <Card className="my-2 p-4">
              <div className="w-full flex" key={index}>
                <div className="w-4/5 border-r-2">
                  <div className="text-sm">
                    {row?.day === 'undefined' ? (
                      <p className="text-red-500">ไม่ระบุวันฝาก</p>
                    ) : (
                      <>วันที่ {dayjs(row?.day).format('DD MMMM YYYY')}</>
                    )}
                  </div>
                  <div className="flex mt-1">
                    <div className="w-1/2 text-center">
                      <div>จำนวนใบรับฝาก</div>
                      <div className="font-bold">
                        {row?.consignment_invoice?.length}
                      </div>
                    </div>
                    <div className="w-1/2 text-center">
                      <div>น้ำหนักรวม</div>
                      <div className="font-bold">
                        {_.sumBy(
                          row?.consignment_invoice,
                          (e) => e?.total_weight,
                        ).toLocaleString(undefined)}{' '}
                        kg
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-1/5 flex justify-center">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      printReportDairyConsignment(row);
                    }}
                  >
                    <PrintOutlinedIcon />
                  </IconButton>
                </div>
              </div>
            </Card>
          ),
        )}
      </div>
    </div>
  );

  if (me.isLoading) {
    return <Loading />;
  }
  if (!me.isLoading && me.isCompleted) {
    return (
      <div>
        {/* <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={!loading}
          onClose={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop> */}
        <div className="flex justify-between">
          <div>{renderTitle()}</div>

          <div className="hidden md:flex gap-1 my-auto">
            {renderReportType()}
            {renderSelectYear()}
          </div>
        </div>
        <div className="flex md:hidden gap-1 my-auto mb-2">
          <div className="w-1/2">{renderReportType()}</div>
          <div className="w-1/2">{renderSelectYear()}</div>
        </div>
        {renderSearch()}
        {renderTable()}
        {renderModule()}
      </div>
    );
  }
  return <Error message={me?.message} />;
};

MainReportDairy.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MainReportDairy.defaultProps = {
  title: '',
  subtitle: '',
};

export default MainReportDairy;
