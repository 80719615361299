import React, { useState } from 'react';
import PropTypes, { number } from 'prop-types';
import { useFieldArray, Controller, useForm } from 'react-hook-form';
import {
  Autocomplete,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { PlusSquare, Trash, Package as PackageIcon, Save } from 'react-feather';

import _ from 'lodash';
import { integerCurrencyFormatter } from '../../../utils/functions/currencyFormatterTH';

function AddOneProductToManyPalletFormElement({
  eachStockLot,
  index,
  control,
  palletTypes,
  watch,
  errors,
  setValue,
}) {
  const [rerender, setRerender] = useState(false);
  const { append, remove, fields } = useFieldArray({
    control,
    name: `product[${index}].pallets`,
  });

  const arragementAmountCalculate = () =>
    _.sum(
      _.map(
        watch(`product[${index}].pallets`),
        (eachField) => eachField?.filled_capacity * eachField?.quantity,
      ),
    ) || 0;

  const remainingAmountCalculate = () =>
    eachStockLot?.total_weight -
    eachStockLot?.product_in_pallet_inventory -
    arragementAmountCalculate();

  // Function When Clicking Button "หมด"
  const handleAllOfQuantity = (
    boxCapacity,
    boxIndex,
    currentQuantity,
    palletType,
  ) => {
    const restingAmount =
      remainingAmountCalculate() + currentQuantity * boxCapacity;
    const numberOfPallet = Math.floor(restingAmount / boxCapacity);
    console.log('numberOfPallet', numberOfPallet);
    setValue(`product[${index}].pallets[${boxIndex}].quantity`, numberOfPallet);
    const remaining = restingAmount - numberOfPallet * boxCapacity;
    append({
      pallet_type: palletType,
      filled_capacity: remaining,
      quantity: 1,
    });
    setTimeout(() => {
      setRerender(!rerender);
    }, 500);
  };

  return (
    <div>
      <div key={index}>
        <div className=" rounded-md p-4 m-2 bg-gray-200 my-2">
          <h5 className="text-lg font-semibold">
            {eachStockLot?.product_type?.name}
          </h5>
          <div className="flex flex-wrap ">
            <div className="w-full lg:w-1/3">
              <b>รหัสสินค้า:</b> {eachStockLot?.tag}
            </div>
            <div className="w-full lg:w-1/3">
              <b>เลขที่ใบรับฝาก:</b> {eachStockLot?.consignment_invoice?.name}
            </div>
            <div className="w-full lg:w-1/3">
              <b>ลูกค้า:</b> {eachStockLot?.consignment_invoice?.customer?.name}
            </div>
            <div className="w-full lg:w-1/3">
              <b>จำนวน:</b>{' '}
              {integerCurrencyFormatter?.format(eachStockLot?.quantity || 0)}{' '}
              ก้อน
            </div>
            <div className="w-full lg:w-1/3">
              <b>ก้อนละ:</b> {eachStockLot?.weight} กิโลกรัม
            </div>
            <div className="w-full lg:w-1/3">
              <b>รวม:</b>{' '}
              {integerCurrencyFormatter.format(eachStockLot?.total_weight || 0)}{' '}
              กิโลกรัม
            </div>
          </div>
        </div>
        <div className="p-2 px-4">
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/3">
              ลงพาเลทแล้ว{' '}
              <span className="text-xl font-semibold">
                {integerCurrencyFormatter.format(
                  eachStockLot?.product_in_pallet_inventory || 0,
                )}{' '}
              </span>
              กิโลกรัม
            </div>

            <div className="w-full md:w-1/3">
              จัดสรร{' '}
              <span className="text-xl font-semibold text-green-500">
                {integerCurrencyFormatter.format(arragementAmountCalculate())}{' '}
              </span>
              กิโลกรัม
            </div>
            <div className="w-full md:w-1/3">
              ต้องจัดอีก{' '}
              <span className="text-xl font-semibold text-red-500">
                {integerCurrencyFormatter.format(remainingAmountCalculate())}{' '}
              </span>
              กิโลกรัม
            </div>
          </div>
        </div>
        <div className="px-4 py-2">
          <Table>
            <colgroup>
              <col width="10%"></col>
              <col width="30%"></col>
              <col width="30%"></col>
              <col width="20%"></col>
              <col width="10%"></col>
            </colgroup>
            <TableHead>
              <TableCell>ลำดับที่</TableCell>
              <TableCell>รูปแบบ</TableCell>
              <TableCell>จำนวนที่จะใส่</TableCell>
              <TableCell>จำนวนพาเลท</TableCell>
              <TableCell>ลบ</TableCell>
            </TableHead>
            <TableBody>
              {_.map(fields, (eachField, fieldIndex) => (
                <TableRow key={eachField.id}>
                  <TableCell>{fieldIndex + 1}</TableCell>
                  <TableCell>
                    {!_.isEmpty(palletTypes) && (
                      <Controller
                        control={control}
                        name={`product[${index}].pallets[${fieldIndex}].pallet_type`}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            options={palletTypes}
                            fullWidth
                            size="small"
                            onChange={(e, newValue) => {
                              field.onChange(newValue);
                              setValue(
                                `product[${index}].pallets[${fieldIndex}].filled_capacity`,
                                newValue?.capacity || 0,
                              );
                              setRerender(!rerender);
                            }}
                            getOptionLabel={(option) =>
                              `${option?.name || ''} `
                            }
                            renderInput={(params) => (
                              <TextField {...params} label="รูปแบบพาเลท" />
                            )}
                          />
                        )}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <Controller
                      control={control}
                      name={`product[${index}].pallets[${fieldIndex}].filled_capacity`}
                      defaultValue={
                        watch(
                          `product[${index}].pallets[${fieldIndex}].pallet_type`,
                        ) || 0
                      }
                      rules={{
                        max: watch(
                          `product[${index}].pallets[${fieldIndex}].pallet_type`,
                        ),
                      }}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          label="ใส่จริง"
                          size="small"
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            setRerender(!rerender);
                          }}
                          helperText={
                            errors?.product?.[index]?.pallets?.[fieldIndex]
                              ?.filled_capacity &&
                            'ใส่ได้ไม่เกินขนาดของรูปแบบพาเลท'
                          }
                        />
                      )}
                    />
                    <div className="my-2">
                      ใส่ได้ไม่เกิน{' '}
                      {watch(
                        `product[${index}].pallets[${fieldIndex}].pallet_type`,
                      )?.capacity || '-'}{' '}
                      กิโลกรัม
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex">
                      <Controller
                        control={control}
                        defaultValue={1}
                        name={`product[${index}].pallets[${fieldIndex}].quantity`}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            onChange={(e) => {
                              field.onChange(e);
                              setRerender(!rerender);
                            }}
                            label="จำนวนพาเลท"
                            fullWidth
                            type="number"
                            size="small"
                          />
                        )}
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex flex-col gap-2">
                      <Button
                        size="small"
                        variant="contained"
                        startIcon={<PackageIcon />}
                        onClick={() =>
                          handleAllOfQuantity(
                            watch(
                              `product[${index}].pallets[${fieldIndex}].filled_capacity`,
                            ),
                            fieldIndex,
                            watch(
                              `product[${index}].pallets[${fieldIndex}].quantity`,
                            ),
                            watch(
                              `product[${index}].pallets[${fieldIndex}].pallet_type`,
                            ),
                          )
                        }
                      >
                        หมด
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        startIcon={<Trash />}
                        color="error"
                        onClick={() => remove(fieldIndex)}
                      >
                        ลบ
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <div className="px-4">
          <Button
            startIcon={<PlusSquare />}
            variant="outlined"
            onClick={() => append({})}
          >
            เพิ่มพาเลท
          </Button>
        </div>
      </div>
    </div>
  );
}

function AddProductToManyPalletForm({
  selectedProductLot,
  allProductStockLot,
  palletTypes,
  handleAddManyPallets,
}) {
  const selectedProductStockLotWithInfo = _.map(
    selectedProductLot,
    (eachProdLot) => {
      const selectedInfo = _.find(
        allProductStockLot,
        (eachDBProductStockLot) => eachDBProductStockLot?._id === eachProdLot,
      );
      return selectedInfo;
    },
  );

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  return (
    <div>
      <form onSubmit={handleSubmit(handleAddManyPallets)}>
        {_.map(selectedProductStockLotWithInfo, (eachStockLot, index) => (
          <AddOneProductToManyPalletFormElement
            eachStockLot={eachStockLot}
            index={index}
            control={control}
            palletTypes={palletTypes}
            watch={watch}
            errors={errors}
            setValue={setValue}
          />
        ))}
        <div className="flex justify-end">
          <Button variant="contained" startIcon={<Save />} type="submit">
            บันทึกการจัดเรียง
          </Button>
        </div>
      </form>
    </div>
  );
}

AddProductToManyPalletForm.propTypes = {
  selectedProductLot: PropTypes.arrayOf(PropTypes.string),
  allProductStockLot: PropTypes.arrayOf(PropTypes.object),
  palletTypes: PropTypes.arrayOf(PropTypes.object),
  handleAddManyPallets: PropTypes.func,
};

AddOneProductToManyPalletFormElement.propTypes = {
  eachStockLot: PropTypes.object,
  index: PropTypes.number,
  control: PropTypes.object,
  palletTypes: PropTypes.arrayOf(PropTypes.object),
  watch: PropTypes.func,
  errors: PropTypes.object,
  setValue: PropTypes.func,
};

export default AddProductToManyPalletForm;
