import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Card,
  CardContent,
  TextField,
  Table,
  TableContainer,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useForm, Controller } from 'react-hook-form';
import { Description } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { ViewTitle } from '../../components/ViewTitle';

export default function WarehouseInput({ title, subtitle }) {
  const dispatch = useDispatch();
  const { control, handleSubmit, setValue, reset } = useForm();

  const positionSimple = useSelector((state) => state.positionSimple);

  const [isAllowScanPosition, setIsAllowScanPosition] = useState(true);

  const handleGetWarehouse = async (data) => {
    try {
      await dispatch(actions.positionSimpleGet(data.positionSimple));
      setIsAllowScanPosition(false);
    } catch (error) {
      alert(`ไม่พบตำแหน่งที่ต้องการ ${error?.response?.data?.error?.message}`);
      reset({ positionSimple: '' });
    }
  };

  const handleAddPallet = async (data) => {
    const selectedPosition = positionSimple?._id;
    try {
      await dispatch(
        actions.positionSimpleAddByPalletNumber(positionSimple?._id, {
          pallet_number: data?.pallet_number,
          warehouse_id: positionSimple?.warehouse_id?._id,
        }),
      );
      await dispatch(actions.positionSimpleGet(selectedPosition));
      reset({ pallet_number: '' });
    } catch (error) {
      alert(`ไม่สามารถเพิ่มพาเลทได้ ${error?.message}`);
      await dispatch(actions.positionSimpleGet(selectedPosition));
      reset({ pallet_number: '' });
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  console.log('positionSimple', positionSimple);

  return (
    <div>
      {renderTitle()}
      <div className="flex flex-row justify-start pb-4">
        <div>
          <BackButton />
        </div>
      </div>
      <div>
        {isAllowScanPosition ? (
          <Card>
            <CardContent>
              <div className="justify-center text-xl text-center">
                สแกนตำแหน่งของคลังสินค้า
              </div>
              <form onSubmit={handleSubmit(handleGetWarehouse)}>
                <div className="w-full my-2">
                  <Controller
                    control={control}
                    name="positionSimple"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="medium"
                        fullWidth
                        autoFocus
                        label="รหัสตำแหน่งในคลังสินค้า"
                      />
                    )}
                  />
                </div>
              </form>
            </CardContent>
          </Card>
        ) : (
          <Card>
            <CardContent>
              <div className="text-2xl text-center">
                คลัง {positionSimple?.warehouse_id?.name}
              </div>
              <div className="text-4xl text-center">
                แถวที่ {positionSimple?.row}
              </div>
              <div className="text-4xl text-center text-red-500">
                {_.size(positionSimple?.pallet_id)} /{' '}
                {positionSimple?.max_capacity}
              </div>
              <div className="flex justify-end">
                <Button
                  size="large"
                  variant="contained"
                  onClick={() => {
                    dispatch(actions.postionSimpleReset());
                    setValue('positionSimple', '');
                    setIsAllowScanPosition(true);
                  }}
                >
                  เลือกแถวอื่น
                </Button>
              </div>
            </CardContent>
          </Card>
        )}

        {!isAllowScanPosition && (
          <div>
            <div className="mt-2">
              <Card>
                <CardContent>
                  <div>สแกนเพิ่มพาเลทลงในคลัง</div>
                  <form onSubmit={handleSubmit(handleAddPallet)}>
                    <div className="w-full my-2">
                      <Controller
                        control={control}
                        name="pallet_number"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            size="medium"
                            fullWidth
                            autoFocus
                            label="เลขพาเลท"
                          />
                        )}
                      />
                    </div>
                  </form>
                </CardContent>
              </Card>
            </div>
            <div className="my-2">
              <Paper>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <div className="font-bold">ลำดับที่</div>
                        </TableCell>
                        <TableCell>
                          <div className="font-bold"> ชื่อ/รหัสพาเลท</div>
                        </TableCell>
                        <TableCell>
                          <div className="font-bold"> ความจุ</div>
                        </TableCell>
                        <TableCell>
                          <div className="font-bold"> รายละเอียดสินค้า</div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!_.isEmpty(positionSimple?.pallet_id) ? (
                        positionSimple?.pallet_id.map((row, index) => (
                          <TableRow
                            key={row.id}
                            sx={{
                              '&:last-child td, &:last-child th': {
                                border: 0,
                              },
                            }}
                            className={index % 2 === 0 ? 'bg-gray-100' : ''}
                          >
                            <TableCell component="th" scope="row">
                              {index + 1}
                            </TableCell>
                            <TableCell>
                              <div className="font-bold">
                                <p>
                                  {row?.name ||
                                    row?.prefix + row?.running_number}
                                </p>
                              </div>
                            </TableCell>
                            <TableCell>
                              <p className="font-bold">{`${row?.used_capacity}`}</p>
                              <p>กิโลกรัม</p>
                            </TableCell>
                            <TableCell>
                              <div className="flex flex-row flex-wrap gap-1">
                                <Link
                                  to={`/doc/pallet-transaction/${row?._id}`}
                                ></Link>
                                <Button
                                  variant="contained"
                                  color={'info'}
                                  size={'small'}
                                  startIcon={<Description />}
                                >
                                  รายละเอียด
                                </Button>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={6}>
                            <div className="text-center">ไม่มีข้อมูลพาเลท</div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

WarehouseInput.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

WarehouseInput.defaultProps = {
  title: '',
  subtitle: '',
};
