import {
  Card,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export function TableStatus({
  product,
  productTransaction,
  customer,
  startDate,
  endDate,
}) {
  const [dataStack, setDataStack] = useState([]);
  const [customerStack, setCustomerStack] = useState([]);
  const [labelStack, setLabelStack] = useState([]);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [orderStack, setOderStack] = useState([]);
  const [totalPrice, setTotalPrice] = useState([]);
  const [loading, setLoading] = useState(false);

  const calculate = () => {
    // find labelStack
    if (
      _.isArray(product) &&
      isFirstRender &&
      _.isArray(productTransaction?.rows)
    ) {
      const tempLabelStack = _.map(product, (eachProduct) => eachProduct.name);
      setLabelStack(tempLabelStack);
      setIsFirstRender(false);

      // find order stack
      const stackData = orderStack;
      _.map(productTransaction.rows, (log) => {
        _.map(log.order, (order) => {
          const Data = {
            order,
            date: log.date,
          };
          stackData.push(Data);
        });
      });
      setOderStack(stackData);

      // find group of product
      const orderByProduct = _.groupBy(
        stackData,
        // eslint-disable-next-line no-underscore-dangle
        (order) => order.order.product._id,
      );
      // console.log('orderByProduct', orderByProduct);

      // find group of customer
      const orderByCustomer = _.groupBy(
        productTransaction.rows,
        // eslint-disable-next-line no-underscore-dangle
        (order) => order?.customer?._id,
      );
      //   console.log('orderByCustomer', orderByCustomer);

      // find sum price each customer
      const priceOfCustomer = _.map(customer, (eachCustomer) => {
        // eslint-disable-next-line no-underscore-dangle
        const orderOfCustomer = orderByCustomer[eachCustomer._id];
        // console.log('orderOfCustomer', orderOfCustomer);
        const sumAmount = {
          customer: eachCustomer,
          price: _.sum(
            _.map(orderOfCustomer, (order) => {
              const sumPrice = _.sum(
                _.map(
                  order.order,
                  (eachOrder) => eachOrder?.amount * eachOrder?.price,
                ),
              );
              //   console.log('sumPrice', sumPrice);
              return sumPrice;
            }) || 0,
          ),
        };
        // console.log('sumAmount', sumAmount);
        return sumAmount;
      });

      // find sum of amount
      const amountOfProduct = _.map(product, (eachProduct) => {
        // eslint-disable-next-line no-underscore-dangle
        const orderOfProduct = orderByProduct[eachProduct._id];
        // console.log('orderOfProduct', orderOfProduct);

        const sumAmount = {
          product: eachProduct,
          amount: _.sum(
            _.map(orderOfProduct, (order) => order?.order?.amount) || 0,
          ),
        };

        return sumAmount;
      });

      // find total price of product
      const totalPriceOfProduct = _.map(product, (eachProduct) => {
        // eslint-disable-next-line no-underscore-dangle
        const orderOfProduct = orderByProduct[eachProduct._id];
        // console.log('orderOfProduct', orderOfProduct);

        const sumPrice = _.sum(
          _.map(
            orderOfProduct,
            (order) => order?.order?.amount * order?.order?.price,
          ) || 0,
        );
        return sumPrice;
      });
      setCustomerStack(priceOfCustomer);
      setDataStack(amountOfProduct);
      setTotalPrice(totalPriceOfProduct);
    }
  };

  useEffect(() => {
    calculate();
    setLoading(true);
    return () => {};
  }, [startDate, endDate, product, productTransaction, customer]);

  calculate();

  const renderTableCustomer = () => (
    <div className="max-h-96 overflow-y-auto">
      <Paper>
        <TableContainer component={Paper}>
          <Table size="small" className="border-lg">
            <TableHead className="bg-yellow-300">
              <TableRow>
                <TableCell>
                  <div className="font-bold py-2">อันดับ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อลูกค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ยอดเงิน</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(
                _.sortBy(customerStack, ['customer', 'price']).reverse(),
                (EachCustomer, index) => (
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    // className={index % 2 === 0 ? 'bg-gray-100' : ''}
                    className="hover:bg-yellow-100 py-2"
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <div className="font-bold my-auto py-2">
                        {EachCustomer?.customer?.name}
                      </div>
                    </TableCell>
                    <TableCell>
                      {parseInt(EachCustomer?.price, 10).toLocaleString()} บาท
                    </TableCell>
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );

  const renderTopCustomers = () => (
    <Card className="p-6">
      <div className="">
        <div className="pb-2 text-xl">รายการลูกค้าดีเด่น</div>
        {renderTableCustomer()}
      </div>
    </Card>
  );

  const renderTableProduct = () => (
    <div className="max-h-96 overflow-y-auto">
      <Paper>
        <TableContainer component={Paper}>
          <Table size="small" className="border-lg">
            <TableHead className="bg-yellow-300">
              <TableRow>
                <TableCell>
                  <div className="font-bold py-2">อันดับ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ยอดขาย</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(
                _.sortBy(dataStack, ['product', 'amount']).reverse(),
                (EachProduct, index) => (
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    // className={index % 2 === 0 ? 'bg-gray-100' : ''}
                    className="hover:bg-yellow-100 py-2"
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <div className="font-bold my-auto py-2">
                        {EachProduct?.product?.name}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="grid grid-cols-2">
                        <p className="text-center">{EachProduct?.amount}</p>
                        <p>{EachProduct?.product?.unit}</p>
                      </div>
                    </TableCell>
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );

  const renderTopProduct = () => (
    <Card className="p-6">
      <div className="">
        <div className="pb-2 text-xl">รายการสินค้าขายดี</div>
        {renderTableProduct()}
      </div>
    </Card>
  );

  if (loading) {
    return (
      <>
        {renderTopCustomers()}
        {renderTopProduct()}
      </>
    );
  }
  return (
    <Card className="p-6">
      <div></div>
    </Card>
  );
}

TableStatus.propTypes = {
  product: PropTypes.array,
  productTransaction: PropTypes.object,
  customer: PropTypes.object,
  startDate: PropTypes.text,
  endDate: PropTypes.text,
};

TableStatus.defaultProps = {
  product: null,
  productTransaction: null,
  customer: null,
  startDate: null,
  endDate: null,
};

export default TableStatus;
