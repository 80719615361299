/* eslint-disable arrow-body-style */
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactQuill from 'react-quill';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-quill/dist/quill.snow.css';

// eslint-disable-next-line react/prop-types
const RichtextEditor = ({ value = '', setValue }) => {
  const modules = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['blockquote', 'code-block'],
        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ direction: 'rtl' }], // text direction

        [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, false] }],

        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        ['link'],
        ['clean'],
      ],
      // handlers: {
      //   image: () => {
      //     this.showImageUploadModal();
      //   },
      //   video: () => {
      //     this.showVideoUploadModal();
      //   },
      //   // insertImage: this.insertImage,
      // },
    },
  };

  const formats = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    'code-block',
  ];

  return (
    <div className="">
      <ReactQuill
        modules={modules}
        theme="snow"
        value={value}
        onChange={setValue}
        formats={formats}
        style={{ height: '200px' }}
      />
    </div>
  );
};

export default RichtextEditor;
