import { Card } from '@mui/material';
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export function StatusCards({
  title,
  icon,
  product,
  productTransaction,
  customer,
  startDate,
  endDate,
}) {
  const [dataStack, setDataStack] = useState([]);
  const [labelStack, setLabelStack] = useState([]);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [orderStack, setOderStack] = useState([]);
  const [totalPrice, setTotalPrice] = useState([]);
  const [loading, setLoading] = useState(false);

  // console.log('productTransaction', productTransaction);
  // console.log('product', product);

  // console.log('dataStack', dataStack);

  const calculate = () => {
    // console.log('productTransaction', productTransaction);
    // console.log('startDate', startDate);
    // console.log('endDate', endDate);
    // find labelStack
    // console.log('labelStack', labelStack);
    if (
      _.isArray(product) &&
      isFirstRender &&
      _.isArray(productTransaction?.rows)
    ) {
      const tempLabelStack = _.map(product, (eachProduct) => eachProduct.name);
      setLabelStack(tempLabelStack);
      setIsFirstRender(false);

      // find order stack
      const stackData = orderStack;
      _.map(productTransaction.rows, (log) => {
        _.map(log.order, (order) => {
          const Data = {
            order,
            date: log.date,
          };
          stackData.push(Data);
        });
      });
      setOderStack(stackData);

      // find group of product
      const orderByProduct = _.groupBy(
        stackData,
        // eslint-disable-next-line no-underscore-dangle
        (order) => order.order.product._id,
      );
      // console.log('orderByProduct', orderByProduct);

      // find sum of amount
      const amountOfProduct = _.map(product, (eachProduct) => {
        // eslint-disable-next-line no-underscore-dangle
        const orderOfProduct = orderByProduct[eachProduct._id];
        // console.log('orderOfProduct', orderOfProduct);

        const sumAmount = _.sum(
          _.map(orderOfProduct, (order) => order?.order?.amount) || 0,
        );
        return sumAmount;
      });

      // find total price of product
      const totalPriceOfProduct = _.map(product, (eachProduct) => {
        // eslint-disable-next-line no-underscore-dangle
        const orderOfProduct = orderByProduct[eachProduct._id];
        // console.log('orderOfProduct', orderOfProduct);

        const sumPrice = _.sum(
          _.map(
            orderOfProduct,
            (order) => order?.order?.amount * order?.order?.price,
          ) || 0,
        );
        return sumPrice;
      });
      setDataStack(amountOfProduct);
      setTotalPrice(totalPriceOfProduct);
    }
  };

  useEffect(() => {
    calculate();
    setLoading(true);
    return () => {};
  }, [startDate, endDate, product, productTransaction, customer]);

  calculate();

  if (loading) {
    if (title === 'รายได้') {
      return (
        <Card className="p-6">
          <div>
            <div className="pb-2 text-xl">จำนวนเงินที่ดำเนินการ</div>
            <div className="flex">
              <i className={icon} />
              <h1 className="pl-2 my-auto text-2xl">
                {' '}
                {`${(_.sum(totalPrice) || 0).toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}`}{' '}
                {'บาท'}
              </h1>
            </div>
          </div>
        </Card>
      );
    }
    if (title === 'จำนวนสินค้าที่ขาย') {
      return (
        <Card className="p-6">
          <div>
            <div className="pb-2 text-xl">จำนวนสินค้าที่ดำเนินการ</div>
            <div className="flex">
              <i className={icon} />
              <h1 className="pl-2 my-auto text-2xl">{_.sum(dataStack)} </h1>
            </div>
          </div>
        </Card>
      );
    }
    if (title === 'จำนวนลูกค้าทั้งหมด') {
      return (
        <Card className="p-6">
          <div>
            <div className="pb-2 text-xl">{title}</div>
            <div className="flex">
              <i className={icon} />
              <h1 className="pl-2 my-auto text-2xl">
                {customer?.rows?.length}
              </h1>
            </div>
          </div>
        </Card>
      );
    }
    if (title === 'จำนวนออเดอร์') {
      return (
        <Card className="p-6">
          <div>
            <div className="pb-2 text-xl">จำนวนออเดอร์ทั้งหมด</div>
            <div className="flex">
              <i className={icon} />
              <h1 className="pl-2 my-auto text-2xl">
                {productTransaction?.rows?.length}
              </h1>
            </div>
          </div>
        </Card>
      );
    }
  }

  return (
    <Card className="p-6">
      <div></div>
    </Card>
  );
}

StatusCards.propTypes = {
  product: PropTypes.array,
  productTransaction: PropTypes.object,
  title: PropTypes.string,
  count: PropTypes.number,
  icon: PropTypes.string,
  customer: PropTypes.object,
  startDate: PropTypes.text,
  endDate: PropTypes.text,
};

StatusCards.defaultProps = {
  product: null,
  productTransaction: null,
  title: null,
  count: null,
  icon: null,
  customer: null,
  startDate: null,
  endDate: null,
};

export default StatusCards;
