import { TextField } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

export function ProductForm({ errors, product, Controller, control }) {
  return (
    <div className="flex flex-row flex-wrap">
      <div>{product.name}</div>
      <div className="w-full  px-1 py-2">
        <Controller
          name={'inventory'}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="จำนวน"
              fullWidth
              type="number"
              size={'small'}
              defaultValue={product?.inventory}
              // eslint-disable-next-line react/prop-types
              helperText={errors.price && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
    </div>
  );
}

ProductForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    unit: PropTypes.object,
    packing_size: PropTypes.object,
    packing_unit: PropTypes.object,
    inventory: PropTypes.object,
    price: PropTypes.object,
    unit_net: PropTypes.object,
    detail: PropTypes.object,
    type_code: PropTypes.object,
  }),
  product: PropTypes.object,
  productType: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
};

ProductForm.defaultProps = {
  product: null,
  productType: null,
};

export default ProductForm;
