import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';

// eslint-disable-next-line import/no-duplicates
import { useHistory } from 'react-router';
// eslint-disable-next-line import/no-duplicates
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

export default function DetailProduct({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const product = useSelector((state) => state.product);
  const history = useHistory();

  useEffect(() => {
    dispatch(actions.productGet(id));
    return () => {};
  }, []);

  // eslint-disable-next-line no-shadow
  const handlePushToEditProduct = async (id) => {
    history.push(`/wms/product/edit/${id}`);
  };

  // eslint-disable-next-line no-underscore-dangle
  console.log('product', product);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (product.isLoading || product.rows) {
    return <Loading />;
  }
  if (!product.isLoading && product.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          {renderTitle()}
          <div className="mt-6">
            <div className="flex flex-row justify-end pb-4">
              <div>
                <Button
                  variant="contained"
                  startIcon={<EditIcon />}
                  onClick={() => {
                    // eslint-disable-next-line no-underscore-dangle
                    handlePushToEditProduct(product._id);
                  }}
                >
                  แก้ไข
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
          <div className="text-lg font-semibold ml-6">ข้อมูลสินค้า</div>
        </div>
        <div>
          <Card className="p-4 ">
            <div className="flex flex-wrap divide-y">
              <div className="w-full py-4 text-lg font-semibold ">
                {'รายละเอียด'}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'รหัสสินค้า'}
              </div>
              <div className="w-1/2 py-4 ">{`${product?.tag || ''}`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">{'ชื่อ'}</div>
              <div className="w-1/2 py-4 ">{`${product?.name}`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">{'น้ำหนัก'}</div>
              <div className="w-1/2 py-4 ">{`${
                product?.weight || '-'
              } กิโลกรัม`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">{'หน่วย'}</div>
              <div className="w-1/2 py-4 ">{`${product?.unit}`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'ประเภทสินค้า'}
              </div>
              <div className="w-1/2 py-4 ">{`${product?.type?.name}`}</div>
              {product?.images ? (
                <>
                  <div className="w-full py-4 px-2 font-semibold ">รูปภาพ</div>
                  <div className="w-full flex">
                    {_.map(product.images, (eachImage, index) => (
                      <div key={index} className="">
                        <img
                          key={index}
                          src={eachImage?.url}
                          className="w-64 my-auto "
                        />
                        <div className="my-4 flex justify-center  "></div>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailProduct.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailProduct.defaultProps = {
  title: '',
  subtitle: '',
};
