import { green, red, yellow } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  palette: {
    amber: {
      main: '#ffc107',
    },
    yellow: {
      main: '#FFC038',
    },
    primary: {
      main: '#1d7d10',
    },
    cyan: {
      main: '#00bcd4',
    },
    secondary: {
      main: '#3B82F6',
    },
    indigo: {
      main: '#141414',
    },
    error: {
      main: red.A400,
    },
    warning: {
      main: yellow[800],
    },
    success: {
      main: green[500],
    },
    background: {
      default: '#F4F5F7',
    },
  },
  typography: {
    fontFamily: ['Prompt'].join(','),
  },
});

export default theme;
