import React, { useContext, useEffect } from 'react';
import {
  Link as RouterLink,
  useLocation,
  useHistory,
  Link,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Avatar,
  Button,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
} from '@mui/material';
import {
  BarChart as BarChartIcon,
  Users as UsersIcon,
  // FileText as FileTextIcon,
  // Layout as LayoutIcon,
  Archive as ArchiveIcon,
  UserPlus as UserPlusIcon,
  FilePlus as FilePlusIcon,
  FileMinus as FileMinusIcon,
  Package as PackageIcon,
  Sliders as SlidersIcon,
  ArrowDownCircle,
  Repeat as RepeatIcon,
} from 'react-feather';

import SetMealIcon from '@mui/icons-material/SetMeal';

import NavItem from './NavItem';
import { PassportAuth } from '../../contexts/AuthContext';
import { getStorage } from '../../utils/functions/localstorage';
import accessRight from '../../utils/functions/accessRight';

const items = [
  {
    href: '/dashboard/report-dairy',
    icon: BarChartIcon,
    title: 'รายงานสรุป',
    name: 'DASHBOARD',
    sub: [
      // {
      //   href: '/dashboard/main',
      //   title: 'หน้าหลัก',
      //   level: 0,
      // },
      // {
      //   href: '/dashboard/graph',
      //   title: 'หน้าแสดงกราฟ',
      //   level: 0,
      // },
      {
        href: '/dashboard/report-dairy',
        title: 'ใบรับฝาก/ใบเบิก',
        level: 0,
      },
      {
        href: '/dashboard/product-stock',
        title: 'สินค้าคงเหลือ',
        level: 0,
      },
      {
        href: '/dashboard/product-stock-by-customer',
        title: 'สรุปรายการลูกค้า',
        level: 0,
      },
    ],
  },
  {
    href: '/doc/consignment-invoice',
    icon: FilePlusIcon,
    title: 'จัดการใบรับฝากสินค้า',
    name: 'DOC',
    sub: [],
  },
  {
    href: '/doc/stocktaking-invoice',
    icon: FileMinusIcon,
    title: 'จัดการใบรับเบิกสินค้า',
    name: 'DOC',
    sub: [],
  },
  {
    href: '/doc/product-transaction/',
    icon: SetMealIcon,
    title: 'จัดการสินค้า',
    name: 'DOC',
    sub: [],
  },
  {
    href: '/doc/pallet-transaction/group-view',
    icon: PackageIcon,
    title: 'จัดการพาเลท',
    name: 'DOC',
    sub: [],
  },
  {
    href: '/doc/warehouse-input/',
    icon: ArrowDownCircle,
    title: 'นำสินค้าเข้าคลัง',
    name: 'DOC',
    sub: [],
  },
  {
    href: '/doc/pallet-move/',
    icon: RepeatIcon,
    title: 'ย้ายพาเลท',
    name: 'DOC',
    sub: [],
  },
  {
    href: '/doc/product-move/',
    icon: RepeatIcon,
    title: 'ย้ายสินค้า',
    name: 'DOC',
    sub: [],
  },
  // {
  //   href: '/spm',
  //   icon: FileTextIcon,
  //   title: 'จัดการคำสั่งซื้อขาย',
  //   name: 'SPM',
  //   sub: [
  //     {
  //       href: '/spm/online',
  //       title: 'ขายออนไลน์',
  //       level: 0,
  //     },
  //     {
  //       href: '/spm/orders',
  //       title: 'การนำเข้าสินค้า',
  //       level: 0,
  //     },
  //     {
  //       href: '/spm/exports',
  //       title: 'การส่งออกสินค้า',
  //       level: 0,
  //     },
  //   ],
  // },
  // {
  //   href: '/mms',
  //   icon: FileTextIcon,
  //   name: 'MMS',
  //   title: 'การผลิต',
  //   sub: [
  //     {
  //       href: '/mms/works',
  //       title: 'รายการงาน',
  //       level: 0,
  //     },
  //     {
  //       href: '/mms/processes',
  //       title: 'ไลน์การผลิต',
  //       level: 0,
  //     },
  //     {
  //       href: '/mms/process-templates',
  //       title: 'รูปแบบไลน์การผลิต',
  //       level: 0,
  //     },
  //   ],
  // },
  // {
  //   href: '/ims',
  //   icon: FileTextIcon,
  //   title: 'คลังวัตถุดิบ',
  //   name: 'IMS',
  //   sub: [
  //     {
  //       href: '/ims/material-stock',
  //       title: 'จัดการสต็อกวัตถุดิบ',
  //       level: 0,
  //     },
  //     {
  //       href: '/ims/materials',
  //       title: 'จัดการวัตถุดิบ',
  //       level: 0,
  //     },
  //     {
  //       href: '/ims/material-types',
  //       title: 'ประเภทวัตถุดิบ',
  //       level: 0,
  //     },
  //     {
  //       href: '/ims/places',
  //       title: 'รายชื่อคลังวัตถุดิบ',
  //       level: 0,
  //     },
  //     {
  //       href: '/ims/transaction-types',
  //       title: 'ประเภทการดำเนินการ',
  //       level: 0,
  //     },
  //     {
  //       href: '/ims/history',
  //       title: 'ประวัติการจัดการวัตถุดิบ',
  //       level: 0,
  //     },
  //   ],
  // },
  {
    href: '/wms',
    icon: ArchiveIcon,
    title: 'คลังสินค้า',
    name: 'WMS',
    sub: [
      {
        href: '/wms/warehouses',
        title: 'จัดการคลังสินค้า',
        level: 0,
      },
      // {
      //   href: '/wms/products',
      //   title: 'จัดการสินค้า',
      //   level: 0,
      // },
      // {
      //   href: '/wms/product-stock',
      //   title: 'จัดการสต็อกสินค้า',
      //   level: 0,
      // },
      // {
      //   href: '/wms/product-types',
      //   title: 'ประเภทสินค้า',
      //   level: 0,
      // },
      // {
      //   href: '/wms/pallet-types',
      //   title: 'ประเภทพาเลท',
      //   level: 0,
      // },
      // {
      //   href: '/wms/transaction-types',
      //   title: 'ประเภทการดำเนินการ',
      //   level: 0,
      // },
      // {
      //   href: '/wms/history',
      //   title: 'ประวัติการจัดการสต๊อกสินค้า',
      //   level: 0,
      // },
    ],
  },

  {
    href: '/crm',
    icon: UsersIcon,
    title: 'ลูกค้าสัมพันธ์',
    name: 'CRM',
    sub: [
      {
        href: '/crm/customers',
        title: 'รายการลูกค้า',
        level: 0,
      },
      {
        href: '/crm/customer-types',
        title: 'ประเภทลูกค้า',
        level: 0,
      },
    ],
  },
  {
    href: '/hrms',
    icon: UserPlusIcon,
    title: 'ทรัพยากรบุคคล',
    name: 'HRMS',
    sub: [
      {
        href: '/hrms/employee',
        title: 'จัดการพนักงาน',
        level: 0,
      },
      {
        href: '/hrms/department',
        title: 'จัดการแผนก',
        level: 0,
      },
      {
        href: '/hrms/role-types',
        title: 'จัดการบทบาท',
        level: 0,
      },
      {
        href: '/hrms/user',
        title: 'จัดการผู้ใช้',
        level: 0,
      },
    ],
  },
  {
    href: '/setting',
    icon: SlidersIcon,
    title: 'ตั้งค่าระบบ',
    name: 'SETTING',
    sub: [
      {
        href: '/setting/system',
        title: 'การตั้งค่าทั่วไป',
        level: 0,
        name: 'SETTING',
        sub: [
          {
            href: '/setting/system',
            title: 'ตั้งค่าข้อมูลระบบ',
            level: 0,
          },
          {
            href: '/setting/modules',
            title: 'โมดูลของระบบ',
            level: 0,
          },
        ],
      },
      {
        href: '/setting/wms/products',
        icon: ArchiveIcon,
        title: 'ตั้งค่าสินค้าและพาเลท',
        name: 'WMS',
        sub: [
          {
            href: '/setting/wms/products',
            title: 'จัดการสินค้า',
            level: 0,
          },
          {
            href: '/setting/wms/product-types',
            title: 'ประเภทสินค้า',
            level: 0,
          },
          {
            href: '/setting/wms/pallet-types',
            title: 'ประเภทพาเลท',
            level: 0,
          },
        ],
      },
      {
        href: '/setting/doc/consignment-invoice/setting',
        icon: ArchiveIcon,
        title: 'ตั้งค่าเอกสาร',
        name: 'WMS',
        sub: [
          {
            href: '/setting/doc/consignment-invoice/setting',
            title: 'ตั้งค่าใบรับฝากสินค้า',
            level: 0,
          },
          {
            href: '/setting/doc/product-transaction/setting',
            title: 'ตั้งค่าเอกสารสินค้า',
            level: 0,
          },
          {
            href: '/setting/doc/pallet-transaction/setting',
            title: 'ตั้งค่าเอกสารพาเลท',
            level: 0,
          },
        ],
      },
      // {
      //   href: '/setting/wms/warehouses',
      //   icon: ArchiveIcon,
      //   title: 'ตั้งค่าคลังสินค้า',
      //   name: 'WMS',
      //   sub: [
      //     {
      //       href: '/setting/wms/warehouses',
      //       title: 'จัดการคลังสินค้า',
      //       level: 0,
      //     },
      //   ],
      // },
    ],
  },
  // {
  //   href: '/cms',
  //   icon: LayoutIcon,
  //   title: 'จัดการเว็บไซต์',
  //   name: 'CMS',
  //   sub: [
  //     {
  //       href: '/cms/main-site',
  //       title: 'หน้าหลักของเว็บไซต์',
  //       level: 0,
  //     },
  //     {
  //       href: '/cms/portfolio',
  //       title: 'ข้อมูลหน้าผลงาน',
  //       level: 0,
  //     },
  //     {
  //       href: '/cms/product-site',
  //       title: 'ข้อมูลหน้าสินค้า',
  //       level: 0,
  //     },
  //     {
  //       href: '/cms/contact-site',
  //       title: 'ข้อมูลการติดต่อ',
  //       level: 0,
  //     },
  //   ],
  // },
];

const DashboardSidebar = ({ me, onMobileClose, openMobile }) => {
  const {
    firstname = '',
    lastname = '',
    department: { name: departmentName = '' } = {},
  } = JSON.parse(getStorage('remember'));
  const availableModule = useSelector((state) => state.availableModule);
  const user = {
    avatar: '/static/images/avatars/avatar_6.png',
    jobTitle: departmentName,
    name: `${firstname} ${lastname}`,
  };

  const { handleSignout } = useContext(PassportAuth);
  const history = useHistory();

  const onSignOut = async () => {
    await handleSignout();
    history.push('/login');
  };

  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box className="p-6">
        <div className="p-2 rounded-xl flex flex-wrap bg-gray-50">
          <div className="w-full lg:w-1/3">
            <Avatar
              component={RouterLink}
              src={me?.userData?.image?.url || ''}
              sx={{
                cursor: 'pointer',
                width: 48,
                height: 48,
              }}
              to="/profile"
            />
          </div>
          <div className="w-full lg:w-2/3">
            <Typography color="textPrimary" variant="body2">
              {user.name}
            </Typography>
            <Typography color="palevioletred" variant="body2">
              {user.jobTitle}
            </Typography>
            <Link to="/profile">
              <Typography
                color="primary"
                variant="body2"
                className="pt-2 cursor-pointer hover:text-green-800"
              >
                <i className="fas fa-edit" /> แก้ไขโปรไฟล์
              </Typography>
            </Link>
          </div>
        </div>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => {
            if (accessRight(me, item.name, 0, availableModule)) {
              return (
                <div key={item.title}>
                  <NavItem
                    href={item.href}
                    title={item.title}
                    icon={item.icon}
                    sub={item.sub}
                    me={me}
                    moduleName={item.name}
                  />
                </div>
              );
            }
            return <></>;
          })}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Divider />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2,
        }}
      >
        <Button
          size="xs"
          startIcon={<i className="fas fa-sign-out-alt"></i>}
          variant="outlined"
          onClick={() => {
            onSignOut();
          }}
        >
          ออกจากระบบ
        </Button>
      </Box>
    </Box>
  );

  return (
    <div>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256,
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)',
              zIndex: 0,
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </div>
  );
};

DashboardSidebar.propTypes = {
  me: PropTypes.object,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default DashboardSidebar;
