/* eslint-disable no-confusing-arrow */
import dayjs from 'dayjs';

// import { converseMaterialOrProductUnit } from '../functions/converseMaterialOrProductUnit';
// import { PRODUCT_TRANSACTION_TYPE } from '../constants';

const columns = [
  {
    label: 'ลำดับที่',
    value: 'index',
  },
  {
    label: 'พาเลท',
    value: (row) =>
      row?.pallet?.name || row?.pallet?.prefix + row?.pallet?.running_number,
  },
  {
    label: 'ตำแหน่งเดิม',
    value: (row) => row?.old_warehouse?.name + ',' + row?.old_row,
  },
  {
    label: 'ตำแหน่งใหม่',
    value: (row) => row?.new_warehouse?.name + ',' + row?.new_row,
  },

  {
    label: 'วันที่ย้าย',
    value: (row) =>
      `${dayjs(row?.createdAt)?.format('D/MM/BBBB เวลา HH:mm น. ')}`,
  },
  {
    label: 'ย้ายโดย',
    value: (row) => row?.employee?.firstname + row?.employee?.lastname,
  },
];

export default { columns };
