export const PRODUCT_TRANSACTION_TYPE = [
  {
    name: 'เข้า',
    direction: 'add',
  },

  {
    name: 'ออก',
    direction: 'desc',
  },
  {
    name: 'in',
    direction: 'เข้า',
  },
  {
    name: 'out',
    direction: 'ออก',
  },
];
export default PRODUCT_TRANSACTION_TYPE;
