import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import {
  ConsignmentInvoice,
  CreateConsignmentInvoice,
  DetailConsignmentInvoice,
  TransactionInvoice,
  CreateTransactionInvoice,
  PalletTransactionInvoice,
  DetailPallet,
  CreatePalletTransactionInvoice,
  EditConsignmentInvoice,
  EditPallet,
  StockTakingInvoice,
  CreateStockTakingInvoice,
  EditStockTakingInvoice,
  DetailStockTakingInvoice,
  GroupViewPalletTransactionInvoice,
  PalletGroupArrangement,
  WarehouseInput,
  PalletMove,
  ProductMove,
} from '../views/DOC';
import { HomeNavbar } from '../components/Navbars';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import * as actions from '../redux/actions';
import { Loading } from '../components/Loading';
import { NotAuthorized } from '../components/Error';
import accessRight from '../utils/functions/accessRight';

export function DOC() {
  const module = 'DOC';
  const prefix = '/doc';
  const name = 'จัดการเอกสาร';
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const availableModule = useSelector((state) => state.availableModule);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };
  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    console.log('Layout : CRM');
    dispatch(actions.meGet());
    dispatch(actions.availableModuleAll());
    return () => {};
  }, []);

  if (!me?.userData) {
    return <Loading />;
  }

  if (accessRight(me, module, 0, availableModule)) {
    return (
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <div className="lg:ml-64  min-h-screen  pt-16 px-8 ">
          <div className="py-4">
            <Switch>
              <Redirect
                exact
                from={`${prefix}`}
                to={`${prefix}/consignment-invoice`}
              />
              <Route exact path={`${prefix}/consignment-invoice`}>
                <ConsignmentInvoice
                  title="จัดการใบรับฝากสินค้า"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/consignment-invoice/create`}>
                <CreateConsignmentInvoice
                  title="สร้างใบรับฝากสินค้า"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/consignment-invoice/:id`}>
                <DetailConsignmentInvoice
                  title="รายละเอียดใบรับฝาก"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/consignment-invoice/edit/:id`}>
                <EditConsignmentInvoice title="แก้ไขใบรับฝาก" subtitle={name} />
              </Route>
              <Redirect
                exact
                from={`${prefix}`}
                to={`${prefix}/stocktaking-invoice`}
              />
              <Route exact path={`${prefix}/stocktaking-invoice`}>
                <StockTakingInvoice
                  title="จัดการใบเบิกสินค้า"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/stocktaking-invoice/create`}>
                <CreateStockTakingInvoice
                  title="สร้างใบเบิกสินค้า"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/stocktaking-invoice/:id`}>
                <DetailStockTakingInvoice
                  title="รายละเอียดใบรับเบิก"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/stocktaking-invoice/edit/:id`}>
                <EditStockTakingInvoice
                  title="แก้ไขใบรับเบิก"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/product-transaction/`}>
                <TransactionInvoice
                  title="จัดการสินค้าเข้าพาเลท"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/product-transaction/create`}>
                <CreateTransactionInvoice
                  title="จัดการสินค้าเข้าพาเลท"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/pallet-transaction/`}>
                <PalletTransactionInvoice
                  title="จัดการพาเลทเข้าคลัง"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/pallet-transaction/group-view`}>
                <GroupViewPalletTransactionInvoice
                  title="จัดการพาเลทเข้าคลัง (แบบจัดกลุ่ม)"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/pallet-arrangement/:id`}>
                <PalletGroupArrangement
                  title="จัดการพาเลทเข้าคลังของแต่ละกลุ่ม"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/pallet-transaction/create`}>
                <CreatePalletTransactionInvoice
                  title="จัดการพาเลทเข้าพาเลท"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/pallet-transaction/:id`}>
                <DetailPallet title="รายละเอียดพาเลท" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/pallet-transaction/edit/:id`}>
                <EditPallet title="แก้ไขรายละเอียดพาเลท" subtitle={name} />
              </Route>{' '}
              <Route exact path={`${prefix}/warehouse-input`}>
                <WarehouseInput title="เพิ่มสินค้าลงคลัง" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/pallet-move`}>
                <PalletMove title="ย้ายพาเลท" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/product-move`}>
                <ProductMove title="ย้ายสินค้าในพาเลท" subtitle={name} />
              </Route>
              <Route path="*">
                <NotAuthorized />
              </Route>
            </Switch>
          </div>
        </div>
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <NotAuthorized />
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    </div>
  );
}

export default DOC;
