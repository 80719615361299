import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import {
  Button,
  Card,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  IconButton,
  Tooltip,
  Backdrop,
  Modal,
  Fade,
  Autocomplete,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { Download as DownloadIcon, ArrowLeft, ArrowRight } from 'react-feather';

import HistoryIcon from '@mui/icons-material/History';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import _ from 'lodash';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import thLocale from 'date-fns/locale/th';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

import * as constants from '../../utils/constants';
import { ExportExcelContainer } from '../../components/ExcelDownload';
import { ProducTransection } from '../../utils/modelutils';
import { AddProductToManyPalletForm } from '../../components/Forms';

const localeMap = {
  th: thLocale,
};
const maskMap = {
  th: '__/__/____',
};

dayjs.locale('th');
dayjs.extend(buddhistEra);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

function ProductTransactionInvoice({ title, subtitle }) {
  const dispatch = useDispatch();
  const consignmentInvoice = useSelector((state) => state.consignmentInvoice);
  const productStockLot = useSelector((state) => state.productStockLot);
  const customer = useSelector((state) => state.customer);
  const logProductStockLot = useSelector((state) => state.logProductStockLot);
  const positionSimple = useSelector((state) => state.positionSimple);
  const palletType = useSelector((state) => state.palletType);

  const history = useHistory();

  const NewCustomer = _.map(customer?.rows, (row, index) => ({
    index: index + 1,
    ...row,
  }));

  console.log('positionSimple', positionSimple);

  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);
  const [total, setTotal] = useState(undefined);
  const [selectStatus, setSelectStatus] = useState('');
  const [selectCustomer, setSelectCustomer] = useState('');
  const [pushProductToPallet, setPushProductToPallet] = useState(false);

  console.log('selectStatus', selectStatus);
  console.log('selectCustomer', selectCustomer);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectDate, setSelectDate] = useState('');
  const [orderByDir, setOrderByDir] = useState('desc');
  const [orderByField, setOrderByField] = useState('deposit_start');

  const [checkProductStockLot, setCheckProductStockLot] = useState([]);

  console.log('checkProductStockLot', checkProductStockLot);

  const handleToggleCheck = (value) => () => {
    const currentIndex = checkProductStockLot.indexOf(value);
    const newChecked = [...checkProductStockLot];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckProductStockLot(newChecked);
  };

  const handleChangeSelectDate = (event) => {
    setSelectDate(event.target.value);
    setStartDate(dayjs().startOf('month').toISOString());
    setEndDate(dayjs().endOf('month').toISOString());
  };

  const [nameHistoryMoveProduct, setNameHistoryMoveProduct] = useState('');
  const [pageHistoryMoveProduct, setPageHistoryMoveProduct] = useState(1);
  const [sizeHistoryMoveProduct, setSizeHistoryMoveProduct] = useState(10);
  const [totalHistoryMoveProduct, setTotalHistoryMoveProduct] = useState(
    undefined,
  );

  const [openHistoryMoveProduct, setOpenHistoryMoveProduct] = useState(false);
  const [
    openModalPushProductToPallets,
    setOpenModalPushProductToPallets,
  ] = useState(false);

  const handleOpenHistoryMoveProduct = () => setOpenHistoryMoveProduct(true);
  const handleCloseHistoryMoveProduct = () => setOpenHistoryMoveProduct(false);

  useEffect(() => {
    dispatch(
      actions.consignmentInvoiceAll({
        name,
        page,
        size,
      }),
    );
    dispatch(
      actions.productStockLotAll({
        name,
        page,
        size,
        selectStatus,
        selectCustomer,
        startDate,
        endDate,
        selectDate,
        orderBy: orderByDir,
        orderByField,
      }),
    );

    dispatch(
      actions.palletTypeAll({
        name: '',
        page: 1,
        size: 10000,
      }),
    );

    return () => {};
  }, [
    name,
    page,
    size,
    selectStatus,
    selectCustomer,
    startDate,
    endDate,
    orderByDir,
    orderByField,
    selectDate,
  ]);

  useEffect(() => {
    dispatch(
      actions.getAllLogProductStockLot({
        nameHistoryMoveProduct,
        pageHistoryMoveProduct,
        sizeHistoryMoveProduct,
      }),
    );
    dispatch(actions.positionSimpleAll({}));
    return () => {};
  }, [nameHistoryMoveProduct, pageHistoryMoveProduct, sizeHistoryMoveProduct]);

  useEffect(() => {
    dispatch(actions.customerAll({}));
    return () => {};
  }, []);

  console.log('logProductStockLot', logProductStockLot);
  // console.log('consignmentInvoice', consignmentInvoice.rows);

  useEffect(() => {
    setTotal(productStockLot?.total);
    return () => {};
  }, [
    productStockLot,
    startDate,
    endDate,
    orderByDir,
    orderByField,
    selectDate,
  ]);

  useEffect(() => {
    setTotalHistoryMoveProduct(logProductStockLot?.total);
    return () => {};
  }, [logProductStockLot]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleChangeStatus = (event) => {
    setSelectStatus(event.target.value);
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  // const handleDeleteConsignmentInvoice = async (id) => {
  //   const confirm = window.confirm('ยืนยันการลบข้อมูล');
  //   if (confirm) {
  //     try {
  //       await dispatch(actions.consignmentInvoiceDelete(id));
  //       await dispatch(actions.consignmentInvoiceAll({ name, page, size }));
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }
  // };

  // const handlePushToDetailConsignmentInvoice = async (id) => {
  //   history.push(`consignment-invoice/${id}`);
  // };

  // const handlePushToEditConsignmentInvoice = async (id) => {
  //   history.push(`consignment-invoice/edit/${id}`);
  // };

  const handlePushToCreateTransactionInvoice = async () => {
    history.push('/doc/product-transaction/create');
  };

  const handlePushToDetailPallet = async (id) => {
    history.push(`/doc/pallet-transaction/${id}`);
  };

  const handlePushProductToPallet = () => {
    setPushProductToPallet(!pushProductToPallet);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPageHistoryMoveProduct = (event) => {
    setSizeHistoryMoveProduct(event.target.value);
    setPageHistoryMoveProduct(1);
  };

  const handleChangePageHistoryMoveProduct = (event, newPage) => {
    setPageHistoryMoveProduct(newPage + 1);
  };

  const handleOpenPushProductToPallet = () => {
    if (_.isEmpty(checkProductStockLot)) {
      alert('กรุณาเลือกสินค้าที่จะลงพาเลทอย่างน้อย 1 ชั้น');
    } else {
      setOpenModalPushProductToPallets(true);
    }
  };

  const handleAddManyPallets = async (data) => {
    try {
      const mappedProductData = _.map(data?.product, (eachProduct, index) => ({
        ...eachProduct,
        product: checkProductStockLot[index],
      }));

      console.log('Data.arr ', mappedProductData);
      dispatch(actions.productStockLotLoading());
      await dispatch(actions.palletCreate({ arr: mappedProductData }));
      setOpenModalPushProductToPallets(false);
      setCheckProductStockLot([]);
      dispatch(
        actions.productStockLotAll({
          name,
          page,
          size,
          selectStatus,
          selectCustomer,
          startDate,
          endDate,
          selectDate,
          orderBy: orderByDir,
          orderByField,
        }),
      );
    } catch (error) {
      alert(`สร้างพาเลทไม่สำเร็จ ${error?.message}`);
    }
  };

  const checkStatusProduct = (status) => {
    if (status === constants.TRANSACTION_STATUS.UNPACKED_STATUS.status_code) {
      return (
        <p className="text-red-500">
          {constants.TRANSACTION_STATUS.UNPACKED_STATUS.description}
        </p>
      );
    }
    if (
      status ===
      constants.TRANSACTION_STATUS.PACKED_NOT_SUCCESS_STATUS.status_code
    ) {
      return (
        <p className="text-yellow-500">
          {constants.TRANSACTION_STATUS.PACKED_NOT_SUCCESS_STATUS.description}
        </p>
      );
    }
    return (
      <p className="text-green-500">
        {constants.TRANSACTION_STATUS.PACKED_SUCCESS_STATUS.description}
      </p>
    );
  };
  // console.log('selectCustomer', selectCustomer);

  // eslint-disable-next-line camelcase
  const checkPosition = (position_id) => {
    // eslint-disable-next-line consistent-return
    const findPosition = _.find(positionSimple?.rows, (e) => {
      // eslint-disable-next-line camelcase
      if (position_id === e?._id) {
        return e;
      }
    });
    return findPosition;
  };

  // const chevronDisplay = (field) => {
  //   if (field === orderByField) {
  //     if (orderByDir === 'asc') {
  //       return (
  //         <div className="self-center  mx-3 ">
  //           <i className="fas fa-chevron-up text-sm" />
  //         </div>
  //       );
  //     }
  //     return (
  //       <div className="self-center  mx-3 ">
  //         <i className="fas fa-chevron-down text-sm" />
  //       </div>
  //     );
  //   }
  //   return (
  //     <div className="self-center  mx-3 ">
  //       <i className="fas fa-chevron-down text-gray-400 text-sm" />
  //     </div>
  //   );
  // };

  // const setSelectedField = (field) => {
  //   if (orderByField === field) {
  //     if (orderByDir === 'asc') {
  //       setOrderByDir('desc');
  //     } else {
  //       setOrderByDir('asc');
  //     }
  //   } else {
  //     setOrderByField(field);
  //     setOrderByDir('desc');
  //   }
  // };

  const renderPeriod = () => (
    <>
      <div className="grid md:grid-cols-2 pb-4 px-4 pt-2 gap-x-2 gap-y-2">
        <div className="bg-white flex">
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            locale={localeMap.th}
          >
            <DesktopDatePicker
              label="วันเริ่มต้น"
              value={startDate}
              mask={maskMap.th}
              minDate={new Date('2020-01-01')}
              onChange={(newValue) => {
                setStartDate(dayjs(newValue).toISOString());
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </div>
        <div className="bg-white flex">
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            locale={localeMap.th}
          >
            <DesktopDatePicker
              label="วันสิ้นสุด"
              value={endDate}
              mask={maskMap.th}
              minDate={new Date('2020-01-01')}
              onChange={(newValue) => {
                setEndDate(dayjs(newValue).toISOString());
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
          <div className="my-auto ml-1">
            <Tooltip
              title="รีเช็ตการค้นหา"
              onClick={() => {
                setStartDate('');
                setEndDate('');
                setSelectDate('');
                setSelectCustomer('');
                setSelectStatus('');
                setName('');
              }}
            >
              <IconButton color="primary">
                <RestartAltIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  );

  const renderTableHistoryProduct = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow className="bg-green-100">
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">สินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ใบรับฝาก/ใบเบิก</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ลูกค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">วันที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">จำนวน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">น้ำหนัก</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ดำเนินการโดย</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(logProductStockLot?.rows) ? (
                logProductStockLot?.rows.map((row, index) => (
                  <TableRow
                    key={row?.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell component="th" scope="row">
                      {(pageHistoryMoveProduct - 1) * sizeHistoryMoveProduct +
                        index +
                        1}
                    </TableCell>
                    <TableCell>
                      <div>
                        {row?.product?.tag || row?.product_stock_lot?.tag}
                      </div>
                      <div>{row?.product?.name}</div>
                    </TableCell>
                    <TableCell>
                      {row?.type === 'in' ? <div>เข้า</div> : <div>ออก</div>}
                    </TableCell>
                    <TableCell>
                      <div>{row?.customer?.name}</div>
                    </TableCell>
                    <TableCell>
                      <div>{`${dayjs(row?.createdAt).format(
                        'D MMM BBBB',
                      )}`}</div>
                      <div>
                        เวลา {`${dayjs(row?.createdAt).format('HH:mm')}`} น.
                      </div>
                    </TableCell>
                    <TableCell>
                      <div>{row?.quantity}</div>
                    </TableCell>
                    <TableCell>
                      <div>{row?.quantity * row?.weight}</div>
                    </TableCell>
                    <TableCell>
                      <div>
                        {row?.employee?.firstname} {row?.employee?.lastname}
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100]}
          onRowsPerPageChange={handleChangeRowsPerPageHistoryMoveProduct}
          page={pageHistoryMoveProduct - 1}
          count={totalHistoryMoveProduct || 1}
          rowsPerPage={sizeHistoryMoveProduct}
          onPageChange={handleChangePageHistoryMoveProduct}
        />
      </Paper>
    </div>
  );

  const renderModalAddToPallets = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModalPushProductToPallets}
      onClose={() => {
        setOpenModalPushProductToPallets(false);
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModalPushProductToPallets}>
        <Card sx={style} className="max-w-5xl my-4 ">
          <div className=" max-h-screen overflow-y-auto pb-20">
            <div className="flex w-full justify-between">
              <div className="py-2 text-2xl text-center underline">
                เพิ่มสินค้าลงพาเลท
              </div>
            </div>
            <div>
              <AddProductToManyPalletForm
                allProductStockLot={productStockLot?.rows}
                selectedProductLot={checkProductStockLot}
                palletTypes={palletType?.rows}
                handleAddManyPallets={handleAddManyPallets}
              />
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const minusOneMonth = () => {
    const previousMonth = dayjs(startDate)
      .subtract(1, 'month')
      .startOf('month');
    const endOfPreviousMonth = dayjs(startDate)
      .subtract(1, 'month')
      .endOf('month');
    setStartDate(previousMonth);
    setEndDate(endOfPreviousMonth);
  };

  const addOneMonth = () => {
    const previousMonth = dayjs(startDate).add(1, 'month').startOf('month');
    const endOfPreviousMonth = dayjs(startDate).add(1, 'month').endOf('month');
    setStartDate(previousMonth);
    setEndDate(endOfPreviousMonth);
  };

  const renderSearch = () => (
    <Card>
      <div className="pt-4 px-4 flex flex-row gap-1">
        <div className="w-full md:w-3/10">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full md:w-3/10 md:pl-1 md:pt-0">
          <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
            <InputLabel id="type" size={'small'}>
              สถานะสินค้า
            </InputLabel>
            <Select
              label="ประเภทสินค้า"
              size={'small'}
              value={selectStatus}
              onChange={handleChangeStatus}
              fullWidth
            >
              <MenuItem value={''}>แสดงทั้งหมด</MenuItem>
              <MenuItem value={'PACKED_SUCCESS_STATUS'}>
                ลงพาเลทเรียบร้อย
              </MenuItem>
              <MenuItem value={'PACKED_NOT_SUCCESS_STATUS'}>
                ลงพาเลทยังไม่ครบ
              </MenuItem>
              <MenuItem value={'UNPACKED_STATUS'}>ยังไม่ลงพาเลท</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="w-full md:w-2/10 md:pl-1 md:pt-0">
          <Autocomplete
            size={'small'}
            onChange={(e, newValue) => {
              setSelectCustomer(newValue?.id);
            }}
            options={NewCustomer}
            getOptionLabel={(type) => `${type?.index} : ${type?.name}`}
            fullWidth
            renderInput={(params) => <TextField {...params} label="ลูกค้า" />}
          />
        </div>
        <div className="w-2/10">
          <Tooltip title="ประวัติการจัดการสินค้า">
            <IconButton
              color="primary"
              onClick={() => {
                handleOpenHistoryMoveProduct();
              }}
            >
              <HistoryIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {!productStockLot.isLoading && (
        <>
          <div className="w-full px-4 mt-2 flex my-2">
            <div className="w-1/2">
              <RadioGroup
                row
                value={selectDate}
                onChange={handleChangeSelectDate}
              >
                <FormControlLabel
                  value="start"
                  control={<Radio />}
                  label="วันรับฝาก"
                />
                <FormControlLabel
                  value="end"
                  control={<Radio />}
                  label="วันครบกำหนด"
                />
              </RadioGroup>
            </div>
            {(selectDate === 'start' || selectDate === 'end') && (
              <div className="w-1/2 flex gap-2">
                <Button
                  variant="outlined"
                  startIcon={<ArrowLeft />}
                  size={'small'}
                  onClick={minusOneMonth}
                >
                  กลับ 1 เดือน
                </Button>
                <Button
                  variant="outlined"
                  endIcon={<ArrowRight />}
                  size={'small'}
                  onClick={addOneMonth}
                >
                  เพิ่ม 1 เดือน
                </Button>
              </div>
            )}
          </div>
          {renderPeriod()}
        </>
      )}
    </Card>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                {pushProductToPallet && (
                  <TableCell>
                    <div className="font-bold">เลือก</div>
                  </TableCell>
                )}
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">รหัสสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อสินค้า/เลขใบรับฝาก</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> จำนวน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> น้ำหนักรวม</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> สถานะ</div>
                </TableCell>
                {/* <TableCell>
                  <div className="font-bold"> วันที่รับสินค้าเข้า</div>
                </TableCell> */}
                {/* <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(productStockLot?.rows) ? (
                productStockLot?.rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    {pushProductToPallet && (
                      <TableCell>
                        <Checkbox
                          onChange={handleToggleCheck(row?.id)}
                          // checked={checked.indexOf(value) !== -1}
                        />
                      </TableCell>
                    )}

                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>
                      <div>{`${
                        row?.product_type?.tag || row?.tag || '-'
                      }`}</div>
                      <div>( {row?.consignment_invoice?.customer?.name} )</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">{`${row?.product_type?.name}`}</div>
                      <div>{`( ${row?.consignment_invoice?.name} )`}</div>
                    </TableCell>
                    <TableCell>
                      <a className="font-bold">{`${row?.quantity} `}</a>
                      <a className="font-bold">x</a>
                      <a className="font-bold">{` ${
                        row?.weight || row?.product_type?.weight
                      }`}</a>
                      {/* <a>{` ${row?.product_type?.unit}`}</a> */}
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">
                        {row?.total_weight} กิโลกรัม
                      </div>
                      <div>{row?.scrap && '( เศษ )'}</div>
                      <div>หมายเหตุ {row?.descriptions || '-'}</div>
                    </TableCell>
                    <TableCell>
                      <p>{checkStatusProduct(row?.status)}</p>
                      <div className="max-h-40 overflow-y-auto">
                        {_.map(row?.product_in_pallet, (eachPallet) => (
                          <ul className="list-disc mt-1">
                            <Link
                              to={`/doc/pallet-transaction/${eachPallet?._id}`}
                              target="_blank"
                            >
                              <li className="cursor-pointer hover:underline flex">
                                <p className="pr-1">
                                  {eachPallet?.name ||
                                    eachPallet?.prefix +
                                      eachPallet?.running_number}
                                </p>
                                <p>({eachPallet?.used_capacity} กิโลกรัม) </p>
                                <p className="pl-2">
                                  {
                                    checkPosition(eachPallet?.position)
                                      ?.warehouse_id?.name
                                  }{' '}
                                  {checkPosition(eachPallet?.position)?.row
                                    ? `แถว ${
                                        checkPosition(eachPallet?.position)
                                          ?.row || ''
                                      }`
                                    : ''}
                                </p>
                              </li>
                            </Link>
                          </ul>
                        ))}
                      </div>
                    </TableCell>

                    {/* <TableCell>{`${dayjs(row?.createdAt).format(
                      'D MMM BBBB HH:mm',
                    )}`}</TableCell> */}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <div className="text-center">น้ำหนักรวมทั้งหมด</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold text-red-600 text-xl">
                    {`${(productStockLot?.totalWeight || 0).toLocaleString(
                      undefined,
                    )}`}{' '}
                    {'กิโลกรัม'}
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  const renderModalHistoryProduct = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openHistoryMoveProduct}
      onClose={handleCloseHistoryMoveProduct}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openHistoryMoveProduct}>
        <Card sx={style} className="max-w-5xl my-4">
          <div className=" max-h-screen overflow-y-auto">
            <div className="flex w-full justify-between">
              <div className="py-2 text-2xl text-center underline">
                ประวัติการจัดการสินค้า
              </div>
              <div className="w-full md:w-1/4 flex justify-end">
                <ExportExcelContainer
                  columnList={ProducTransection?.columns}
                  currentData={logProductStockLot?.rows}
                  dataAPIEndPoint="log-product-stock-lot"
                  dataQuery={''}
                  sheetName="ProductTransection"
                  spreadedColumn=""
                />
              </div>
            </div>
            <div>{renderTableHistoryProduct()}</div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  if (
    consignmentInvoice.isLoading &&
    productStockLot.isLoading &&
    positionSimple.isLoading
  ) {
    return <Loading />;
  }
  if (
    !consignmentInvoice.isLoading &&
    consignmentInvoice.isCompleted &&
    !productStockLot.isLoading &&
    productStockLot.isCompleted
  ) {
    return (
      <div>
        {renderModalHistoryProduct()}
        {renderModalAddToPallets()}
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="mt-6">
            <div className="w-full">
              <div className="flex justify-end gap-1">
                {!pushProductToPallet && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handlePushToCreateTransactionInvoice()}
                  >
                    <DownloadIcon />
                    <p className="my-auto pl-1">จัดสินค้าลงพาเลท (เดี่ยว)</p>
                  </Button>
                )}

                {pushProductToPallet && (
                  <Button
                    variant="contained"
                    onClick={handleOpenPushProductToPallet}
                  >
                    <DownloadIcon />
                    <p className="my-auto pl-1">ตกลง</p>
                  </Button>
                )}

                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    handlePushProductToPallet();
                    if (pushProductToPallet) {
                      setCheckProductStockLot([]);
                    }
                  }}
                >
                  {pushProductToPallet ? (
                    <>
                      <p className="my-auto pl-1">ยกเลิก</p>
                    </>
                  ) : (
                    <>
                      <DownloadIcon />
                      <p className="my-auto pl-1">จัดสินค้าลงพาเลท (กลุ่ม)</p>
                    </>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Loading />;
}

ProductTransactionInvoice.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ProductTransactionInvoice.defaultProps = {
  title: '',
  subtitle: '',
};

export default ProductTransactionInvoice;
