/* eslint-disable implicit-arrow-linebreak */
import pdfMake from 'addthaifont-pdfmake/build/pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import _ from 'lodash';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import qrcode from 'qrcode';
import { BarcodeGenerator } from '../Others';
// import currencyFormatterTH from '../../utils/functions/currencyFormatterTH';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};

// eslint-disable-next-line import/prefer-default-export
export const BarcodeWarehouseReport = ({ warehouse, info, typeCode }) => {
  const barcodeImage = (word) => {
    const barcodeSVGWird = BarcodeGenerator(word);
    return {
      svg: barcodeSVGWird,
      fit: [400, 300],
      alignment: 'center',
    };
  };

  const opts = {
    errorCorrectionLevel: 'H',
    type: 'image/jpeg',
    mode: 'Alphanumeric',
    quality: 0.8,
    margin: 1,
    width: 150,
  };

  const genQR = (data) => {
    let resQR;
    qrcode.toDataURL(data, opts, (err, res) => {
      if (err) throw err;
      resQR = res;
    });
    return resQR;
  };

  const warehouseRow = _.map(warehouse?.positionsimples, (eachRow, index) => ({
    style: 'tableExample',
    height: 300,
    table: {
      widths: typeCode === 'barcode' ? ['*'] : ['50%', '50%'],
      body:
        typeCode === 'barcode'
          ? [
              [
                {
                  alignment: 'center',
                  border: [true, true, true, false],
                  text: `คลัง ${warehouse?.name} แถว ${eachRow?.row || ''}`,
                  bold: true,
                  fontSize: 22,
                },
              ],
              [
                {
                  border: [true, false, true, false],
                  alignment: 'center',
                  text: `ความจุ ${eachRow?.max_capacity || ''} พาเลท`,
                  bold: true,
                  fontSize: 14,
                },
              ],
              [
                {
                  border: [true, false, true, true],
                  ...barcodeImage(eachRow?._id),
                },
                {
                  border: [true, false, true, true],
                  ...barcodeImage(eachRow?._id),
                },
              ],
            ]
          : [
              [
                {
                  alignment: 'center',
                  border: [true, true, true, false],
                  text: `คลัง ${warehouse?.name} แถว ${eachRow?.row || ''}`,
                  bold: true,
                  fontSize: 22,
                },
                {
                  alignment: 'center',
                  border: [true, true, true, false],
                  text: `คลัง ${warehouse?.name} แถว ${eachRow?.row || ''}`,
                  bold: true,
                  fontSize: 22,
                },
              ],
              [
                {
                  border: [true, false, true, false],
                  alignment: 'center',
                  text: `ความจุ ${eachRow?.max_capacity || ''} พาเลท`,
                  bold: true,
                  fontSize: 14,
                },
                {
                  border: [true, false, true, false],
                  alignment: 'center',
                  text: `ความจุ ${eachRow?.max_capacity || ''} พาเลท`,
                  bold: true,
                  fontSize: 14,
                },
              ],
              [
                {
                  border: [true, false, true, true],
                  image: genQR(eachRow?._id || '123456'),
                  width: 100,
                  alignment: 'center',
                  margin: [0, 10, 0, 12],
                },
                {
                  border: [true, false, true, true],
                  image: genQR(eachRow?._id || '123456'),
                  width: 100,
                  alignment: 'center',
                  margin: [0, 10, 0, 20],
                },
              ],
            ],
    },
  }));

  const docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [20, 30, 40, 20],
    defaultStyle: {
      font: 'Sarabun',
    },
    info: {
      title: `ใบสติ๊กเกอร์สำหรับคลังสินค้า ${warehouse?.name || ''}`,
    },
    header: [
      {
        alignment: 'center',
        margin: [0, 10, 0, 12],
        text: `ใบสติ๊กเกอร์สำหรับคลังสินค้า ${warehouse?.name || ''}`,
        fontSize: '14',
        bold: true,
      },
    ],
    content: [...warehouseRow],
    // images: { QR: genQR('1234') },
  };
  pdfMake.createPdf(docDefinition).open();
};
