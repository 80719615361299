/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
import pdfMake from 'addthaifont-pdfmake/build/pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import _ from 'lodash';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
  Kanit: {
    normal: 'Kanit-Light.ttf',
    bold: 'Kanit-Regular.ttf',
    italics: 'Kanit-LightItalic.ttf',
    bolditalics: 'Kanit-Italic.ttf',
  },
  Prompt: {
    normal: 'Prompt-Light.ttf',
    bold: 'Prompt-Regular.ttf',
    italics: 'Prompt-LightItalic.ttf',
    bolditalics: 'Prompt-Italic.ttf',
  },
};

export const ReportDairyConsignment = (data, me) => {
  const genProducts = (dataProduct) => {
    const dataArrayProduct = _.map(dataProduct, (_product) => ({
      text: `- ${_product?.product_type?.name}\n`,
    }));
    return dataArrayProduct;
  };

  const genTotalWeight = (dataProductWeight) => {
    const dataArrayProduct = _.map(dataProductWeight, (_product) => ({
      text: `${_product?.total_weight.toLocaleString(undefined)}\n`,
    }));
    return dataArrayProduct;
  };

  const genReport = (dataReport) => {
    return _.map(dataReport, (_report, index) => [
      {
        text: index + 1,
        border: [true, false, true, true],
        fontSize: 11,
        colSpan: 1,
        alignment: 'center',
      },
      {
        text: `${_report?.name || '-'}`,
        border: [true, false, true, true],
        fontSize: 11,
        colSpan: 1,
      },
      {
        text: genProducts(_report?.productstocklots),
        border: [true, false, true, true],
        fontSize: 11,
        colSpan: 1,
        alignment: 'center',
      },
      {
        text: genTotalWeight(_report?.productstocklots),
        border: [true, false, true, true],
        fontSize: 11,
        colSpan: 1,
        alignment: 'center',
      },
    ]);
  };

  const docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [20, 130, 20, 230],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '10',
    },
    info: {
      title: 'รายงานใบรับฝาก',
    },
    header: [
      {
        text: `${dayjs().format('DD/MM/YYYY')}`,
        alignment: 'right',
        margin: [10, 10, 10, 10],
        fontSize: '10',
      },
      {
        alignment: 'center',
        margin: [0, 0, 0, 12],
        text: [
          {
            text: 'ใบคุมงานรายวัน',
            fontSize: '18',
            bold: true,
          },
        ],
      },
      {
        alignment: 'center',
        margin: [0, 4, 0, 12],
        text: 'ใบรับฝาก',
        fontSize: '16',
        bold: true,
      },
      {
        margin: [20, 0, 0, 0],
        canvas: [{ type: 'line', x1: 0, y1: 0, x2: 550, y2: 0, lineWidth: 1 }],
      },
    ],

    content: [
      {
        style: 'tableExample',
        margin: [2, 0, 10, 2],
        table: {
          widths: ['25%', '30%', '15%', '30%'],
          body: [
            [
              {
                text: 'ประจำวันที่',
                colSpan: 1,
                bold: true,
                fontSize: 14,
                border: [false, false, false, false],
              },
              {
                text: `${dayjs(data?.day).format('DD MMMM YYYY')}`,
                colSpan: 1,
                fontSize: 14,
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        margin: [0, 10, 0, 0],
        style: 'tableExample',
        layout: {
          fillColor(rowIndex, node, columnIndex) {
            return rowIndex === 0 ? '#c5cae9' : null;
          },
        },
        table: {
          widths: ['15%', '30%', '*', '20%'],
          heights: [15],
          headerRows: 1,
          body: [
            [
              {
                text: 'ลำดับ',
                colSpan: 1,
                fontSize: 11,
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'เลขใบรับฝาก',
                colSpan: 1,
                fontSize: 11,
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'สินค้า',
                colSpan: 1,
                fontSize: 11,
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'จำนวนสินค้า (กิโลกรัม)',
                colSpan: 1,
                fontSize: 11,
                alignment: 'center',
                border: [true, true, true, true],
              },
            ],
            ...genReport(data?.consignment_invoice),
            [
              {
                text: 'น้ำหนักรวม',
                colSpan: 3,
                fontSize: 11,
                bold: true,
                alignment: 'center',
                border: [true, true, true, true],
              },
              {},
              {},
              {
                text: `${_.sumBy(
                  data?.consignment_invoice,
                  'total_weight',
                ).toLocaleString(undefined)}`,
                border: [true, true, true, true],
                fontSize: 11,
                colSpan: 1,
                alignment: 'center',
                bold: true,
              },
            ],
          ],
        },
      },
    ],
    footer: [
      {
        margin: [10, 30, 0, 0],
        style: 'tableExample',
        table: {
          widths: ['33.3%', '33.3%', '33.3%'],
          heights: [15],
          body: [
            [
              {
                text:
                  'ลงชื่อ ...................................... ผู้จัดการคลัง',
                colSpan: 1,
                // bold: true,
                fontSize: 11,
                alignment: 'center',
                border: [false, false, false, false],
              },
              {
                text:
                  'ลงชื่อ ...................................... ผู้ตรวจสอบคลัง',
                colSpan: 1,
                // bold: true,
                fontSize: 11,
                alignment: 'center',
                border: [false, false, false, false],
              },
              {
                text:
                  'ลงชื่อ ...................................... ผู้ตรวจสอบเอกสาร',
                colSpan: 1,
                // bold: true,
                fontSize: 11,
                alignment: 'center',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '(......................................)',
                colSpan: 1,
                // bold: true,
                fontSize: 11,
                alignment: 'center',
                border: [false, false, false, false],
              },
              {
                text: '(......................................)',
                colSpan: 1,
                // bold: true,
                fontSize: 11,
                alignment: 'center',
                border: [false, false, false, false],
              },
              {
                text: '(......................................)',
                colSpan: 1,
                // bold: true,
                fontSize: 11,
                alignment: 'center',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        margin: [20, 20, 0, 0],
        fontSize: 11,
        columns: [
          {
            text: 'ความคิดเห็น ',
          },
        ],
      },
      {
        margin: [20, 4, 0, 20],
        fontSize: 11,
        columns: [
          {
            text:
              '................................................................................................................................................................................................\n................................................................................................................................................................................................\n................................................................................................................................................................................................\n................................................................................................................................................................................................ ',
          },
        ],
      },
      {
        margin: [20, 0, 0, 20],
        fontSize: 11,
        columns: [
          {
            text: `ผู้ทำรายการ ${me?.userData?.firstname}  ${me?.userData?.lastname}`,
            bold: true,
          },
        ],
      },
    ],
  };
  pdfMake.createPdf(docDefinition).open();
};
