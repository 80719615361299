import api from '../../../utils/functions/api';

import { INFO_GET, INFO_PUT, INFO_CREATE } from '../types';

// faker.locale = "th"

export const getInformation = () => async (dispatch) => {
  await api.get(`${process.env.REACT_APP_API_URL}/information/`).then((res) => {
    console.log('Request Server to Get information');
    if (res.data) {
      dispatch({ type: INFO_GET, payload: res.data });
    } else {
      dispatch({ type: INFO_GET, payload: null });
    }
  });
};
export const createOneInformation = (payload) => async (dispatch) => {
  await api
    .post(`${process.env.REACT_APP_API_URL}/information/`, payload)
    .then(() => {
      console.log('Request Server to Create New Promotion');
      dispatch({ type: INFO_CREATE });
    });
};

export const editOneInformation = (id, payload) => async (dispatch) => {
  await api
    .put(`${process.env.REACT_APP_API_URL}/information/${id}`, payload)
    .then(() => {
      console.log('Request Server to edit Promotion');
      dispatch({ type: INFO_PUT });
    });
};
