import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Card,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';

// eslint-disable-next-line import/no-duplicates
import { useHistory } from 'react-router';
// eslint-disable-next-line import/no-duplicates
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import _ from 'lodash';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

dayjs.locale('th');
dayjs.extend(buddhistEra);

export default function DetailPallet({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  // const [filterProduct, setFilterProduct] = useState(id);
  const productStockLot = useSelector((state) => state.productStockLot);
  const pallet = useSelector((state) => state.pallet);

  console.log('pallet', pallet);
  console.log('productStockLot', productStockLot?.rows);

  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [searchTerm, setSearchTerm] = useState('');

  const history = useHistory();

  useEffect(() => {
    // setFilterProduct(id);
    dispatch(actions.palletGet(id));
    dispatch(actions.productStockLotAll({}));
    return () => {};
  }, [id]);

  // useEffect(() => {
  //   setTotal(pallet?.products?.length());
  //   return () => {};
  // }, [pallet]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  // eslint-disable-next-line no-shadow
  const handlePushToEditProduct = async (id) => {
    history.push(`/doc/pallet-transaction/edit/${id}`);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  // const checkProduct = (data) => {
  //   const findProduct = _.find(productStockLot?.rows, { id: data });
  //   // console.log('product ', data, findProduct);
  //   return findProduct;
  // };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2 my-auto">
          <div className="font-semibold  text-lg">รายการสินค้า</div>
        </div>
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">รหัสสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> จำนวน</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(pallet?.products) ? (
                pallet?.products.map((row, index) => (
                  <TableRow
                    key={row?.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>
                      {row?.product?.tag || row?.product_type?.tag || '-'}
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">{`${
                        row?.product_type?.name
                      } ( ${
                        row?.product_type?.weight || row?.product?.weight
                      } กิโลกรัม )`}</div>
                      <div className="">{`( ${row?.consignment_invoice?.name} )`}</div>
                    </TableCell>
                    <TableCell>
                      <p className="font-bold">
                        {`${row?.inventory / row?.product?.weight} x ${
                          row?.product?.weight
                        }`}{' '}
                      </p>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (pallet.isLoading || pallet.rows) {
    return <Loading />;
  }
  if (!pallet.isLoading && pallet.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          {renderTitle()}
          <div className="mt-6">
            <div className="flex flex-row justify-end pb-4">
              <div>
                <Button
                  startIcon={<EditIcon />}
                  variant="contained"
                  onClick={() => {
                    // eslint-disable-next-line no-underscore-dangle
                    handlePushToEditProduct(pallet?._id);
                  }}
                >
                  แก้ไข
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
          <div className="text-lg font-semibold ml-6">ข้อมูลพาเลท</div>
        </div>
        <div className="pb-2">
          <Card className="p-4 ">
            <div className="flex flex-wrap divide-y">
              <div className="w-full py-4 text-lg font-semibold ">
                {'รายละเอียด'}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'ชื่อ/รหัสพาเลท'}
              </div>
              <div className="w-1/2 py-4 ">
                <p>{`${pallet?.name || pallet?.name_running}`}</p>
                <p>( {`${pallet?.pallet_type?.name}`} )</p>
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'วันที่สร้างพาเลท'}
              </div>
              <div className="w-1/2 py-4 ">{`${dayjs(pallet?.createdAt).format(
                'D MMM BBBB HH:mm',
              )}`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'สถานะพาเลท'}
              </div>
              <div className="w-1/2 py-4 ">
                {pallet?.warehouse_status ? (
                  <p className="text-green-500">ลงคลังเรียบร้อย</p>
                ) : (
                  <p className="text-red-500">ยังไม่ลงคลัง</p>
                )}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold ">{'ตำแหน่ง'}</div>
              <div className="w-1/2 py-4 ">
                {pallet?.warehouse_status ? (
                  <>
                    {pallet?.warehouse?.type === 'simple' ? (
                      <p className="text-green-500">
                        {pallet?.warehouse?.name} แถว {pallet?.row}
                      </p>
                    ) : (
                      <p className="text-green-500">
                        {`${pallet?.warehouse?.name} แถว ${
                          pallet?.location?.col + 1
                        } ความลึก ${pallet?.location?.row + 1} ชั้น ${
                          pallet?.location?.height
                        }`}{' '}
                      </p>
                    )}
                  </>
                ) : (
                  <p className="text-red-500">-</p>
                )}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'วันที่เข้าคลัง'}
              </div>
              <div className="w-1/2 py-4 ">
                {pallet?.warehouse_status ? (
                  <p className="text-green-500">{`${dayjs(
                    pallet?.date_warehouse,
                  ).format('D MMM BBBB HH:mm')}`}</p>
                ) : (
                  <p className="text-red-500">-</p>
                )}
              </div>
            </div>
          </Card>
        </div>
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error />;
}

DetailPallet.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailPallet.defaultProps = {
  title: '',
  subtitle: '',
};
