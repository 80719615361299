import React, { useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../redux/actions';

import { PassportAuth } from '../../contexts/AuthContext';
import PlaceholderLogo from '../../assets/images/diprom.png';

export function HomeNavbar() {
  const dispatch = useDispatch();
  const setting = useSelector((state) => state.setting);
  // const history = useHistory();
  useEffect(() => {
    dispatch(actions.settingAll());
    return () => {};
  }, []);
  const { handleSignout } = useContext(PassportAuth);
  const history = useHistory();
  const onSignOut = async () => {
    await handleSignout();
    history.push('/login');
  };
  const [navbarOpen] = React.useState(false);

  return (
    <>
      <nav className="top-0 fixed z-10 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow-xl">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start font-sans">
            <div className="flex gap-2">
              <Link
                to="/"
                className="text-gray-600 text-base font-bold leading-relaxed mr-4 py-2 whitespace-no-wrap flex"
              >
                <img
                  src={setting?.logo?.url || PlaceholderLogo}
                  className="h-10 rounded-md"
                />
                <div className="my-auto pl-4">
                  {setting?.name || 'ระบบ E-Warehouse'}
                </div>
              </Link>
            </div>
            <div className="lg:hidden block py-2 px-3 text-gray-600">
              <Button
                size="xs"
                variant="outlined"
                onClick={() => {
                  onSignOut();
                }}
              >
                <i className="fas fa-sign-out-alt"></i>
              </Button>
            </div>
          </div>
          <div
            className={`lg:flex flex-grow items-center bg-white text-white lg:bg-transparent lg:shadow-none${
              navbarOpen ? ' block' : ' hidden'
            }`}
            id="example-navbar-warning"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="flex items-center">
                <Link to="/auth/login">
                  <Button
                    size="xs"
                    variant="outlined"
                    startIcon={<i className="fas fa-sign-out-alt"></i>}
                    onClick={() => {
                      onSignOut();
                    }}
                  >
                    ออกจากระบบ
                  </Button>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default HomeNavbar;
