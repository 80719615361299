import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Card,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
// eslint-disable-next-line import/no-duplicates
import { useHistory } from 'react-router';
// eslint-disable-next-line import/no-duplicates
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import _ from 'lodash';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

dayjs.locale('th');
dayjs.extend(buddhistEra);

export default function DetailConsignmentInvoice({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [filterProduct, setFilterProduct] = useState(id);
  const [filterLog, setFilterLog] = useState(id);
  const stocktakingInvoice = useSelector((state) => state.stocktakingInvoice);
  const productStockLot = useSelector((state) => state.productStockLot);
  const logProductStockLot = useSelector((state) => state.logProductStockLot);

  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [searchTerm, setSearchTerm] = useState('');

  console.log('stocktakingInvoice', stocktakingInvoice);

  const NewLogProductStockLot = _.filter(
    logProductStockLot?.rows,
    (each) => each?.stocktaking_invoice?._id === id,
  );

  console.log('logProductStockLot', logProductStockLot?.rows);
  console.log('NewLogProductStockLot', NewLogProductStockLot);

  const history = useHistory();

  useEffect(() => {
    setFilterProduct(id);
    dispatch(actions.stocktakingInvoiceGet(id));
    dispatch(actions.productStockLotAll({ name, page, size, filterProduct }));
    dispatch(actions.getAllLogProductStockLot({}));
    return () => {};
  }, [id, name, page, size]);

  useEffect(() => {
    setTotal(productStockLot?.total);
    return () => {};
  }, [productStockLot]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  // eslint-disable-next-line no-shadow
  const handlePushToEditProduct = async (id) => {
    history.push(`/doc/stocktaking-invoice/edit/${id}`);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  // eslint-disable-next-line no-underscore-dangle
  console.log('productStockLot', productStockLot);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2 my-auto">
          <div className="font-semibold  text-lg">รายการสินค้า</div>
        </div>
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow className="bg-green-100">
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> จำนวน</div>
                </TableCell>
                {/* <TableCell>
                  <div className="font-bold"> คงเหลือ</div>
                </TableCell> */}
                <TableCell>
                  <div className="font-bold"> วันที่เบิกสินค้า</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(NewLogProductStockLot) ? (
                NewLogProductStockLot.map((row, index) => (
                  <TableRow
                    key={row?.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>
                      <div className="w-full flex flex-wrap">
                        <div className="pr-2 font-bold">{`${
                          row?.product_stock_lot?.tag || row?.product?.tag
                        } ${row?.product?.name} `}</div>
                        <div>{` ( ก้อนละ ${row?.weight} กิโลกรัม )`}</div>
                      </div>
                      {row?.scrap && <div>( เศษ )</div>}
                    </TableCell>
                    <TableCell>
                      <a className="font-bold">{`${row?.quantity}`}</a>
                      <a>{` ${row?.product.unit}`}</a>
                    </TableCell>

                    <TableCell>{`${dayjs(row?.createdAt).format(
                      'D MMM BBBB HH:mm',
                    )}`}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (stocktakingInvoice.isLoading || stocktakingInvoice.rows) {
    return <Loading />;
  }
  if (!stocktakingInvoice.isLoading && stocktakingInvoice.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          {renderTitle()}
          {/* <div className="mt-6">
            <div className="flex flex-row justify-end pb-4">
              <div>
                <Button
                  variant="contained"
                  startIcon={<EditIcon />}
                  onClick={() => {
                    // eslint-disable-next-line no-underscore-dangle
                    handlePushToEditProduct(consignmentInvoice?._id);
                  }}
                >
                  แก้ไข
                </Button>
              </div>
            </div>
          </div> */}
        </div>
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
          <div className="text-lg font-semibold ml-6">ข้อมูลสินค้า</div>
        </div>
        <div className="pb-2">
          <Card className="p-4 ">
            <div className="flex flex-wrap divide-y">
              <div className="w-full py-4 text-lg font-semibold ">
                {'รายละเอียด'}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'เลขที่ใบเบิก'}
              </div>
              <div className="w-1/2 py-4 ">{`${stocktakingInvoice?.name}`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'รายละเอียด'}
              </div>
              <div className="w-1/2 py-4 ">{`${
                stocktakingInvoice?.description || '-'
              }`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'ชื่อลูกค้า'}
              </div>
              <div className="w-1/2 py-4 ">{`${
                stocktakingInvoice?.customer?.name || '-'
              }`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'ทะเบียนรถ'}
              </div>
              <div className="w-1/2 py-4 ">{`${
                stocktakingInvoice?.car_registration || '-'
              }`}</div>
              {/* <div className="w-1/2 py-4 px-2 font-semibold ">{'สำหรับ'}</div>
              <div className="w-1/2 py-4 ">{`${
                stocktakingInvoice?.consignment_invoice_type || ''
              }${stocktakingInvoice?.description || ''}`}</div> */}
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'วันที่เบิก'}
              </div>
              <div className="w-1/2 py-4 ">{`${dayjs(
                stocktakingInvoice?.createdAt,
              ).format('D MMM BBBB HH:mm')}`}</div>
            </div>
          </Card>
        </div>
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error />;
}

DetailConsignmentInvoice.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailConsignmentInvoice.defaultProps = {
  title: '',
  subtitle: '',
};
