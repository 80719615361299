import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

import accessRight from '../../utils/functions/accessRight';
import { NotAuthorized } from '../../components/Error';
import {
  ProductType,
  EditProductType,
  DetailProductType,
  Product,
  PalletType,
  DetailPalletType,
  EditPalletType,
  Warehouse,
  DetailWarehouse,
  EditWarehouse,
} from '../../views/WMS';

export function SettingSPM() {
  const module = 'WMS';
  const prefix = '/setting/wms';
  const name = 'ตั้งค่าระบบ';
  const me = useSelector((state) => state.me);
  const availableModule = useSelector((state) => state.availableModule);

  if (accessRight(me, module, 1, availableModule)) {
    return (
      <div>
        <Route exact path={`${prefix}/products`}>
          <Product title="จัดการสินค้า" subtitle={name} />
        </Route>
        <Route exact path={`${prefix}/product-types`}>
          <ProductType title="จัดการประเภทสินค้า" subtitle={name} />
        </Route>
        <Route exact path={`${prefix}/product-types/edit/:id`}>
          <EditProductType title="แก้ไขประเภทสินค้า" subtitle={name} />
        </Route>
        <Route exact path={`${prefix}/product-type/:id`}>
          <DetailProductType title="รายละเอียดประเภทสินค้า" subtitle={name} />
        </Route>
        <Route exact path={`${prefix}/pallet-types`}>
          <PalletType title="จัดการประเภทพาเลท" subtitle={name} />
        </Route>
        <Route exact path={`${prefix}/pallet-types/:id`}>
          <DetailPalletType title="รายละเอียดพาเลท" subtitle={name} />
        </Route>
        <Route exact path={`${prefix}/pallet-types/edit/:id`}>
          <EditPalletType title="แก้ไขประเภทพาเลท" subtitle={name} />
        </Route>
        <Route exact path={`${prefix}/warehouses`}>
          <Warehouse title="จัดการคลังสินค้า" subtitle={name} />
        </Route>
        <Route exact path={`${prefix}/warehouses/:id`}>
          <DetailWarehouse title="รายละเอียดคลังสินค้า" subtitle={name} />
        </Route>
        <Route exact path={`${prefix}/warehouses/edit/:id`}>
          <EditWarehouse title="แก้ไขคลังสินค้า" subtitle={name} />
        </Route>
      </div>
    );
  }
  return (
    <div>
      <Route path="*">
        <NotAuthorized />
      </Route>
    </div>
  );
}

export default SettingSPM;
