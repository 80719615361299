import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  PersonalProfile,
  EditPersonalProfile,
  EditPassword,
} from '../views/Profile';
import { HomeNavbar } from '../components/Navbars';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import { Loading } from '../components/Loading';
import { NotFound, Error } from '../components/Error';
import * as actions from '../redux/actions';

export function Profile() {
  const prefix = '/profile';
  const name = 'โปรไฟล์';
  // const { pathname } = useLocation();
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };
  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    console.log('Layout: Profile');
    dispatch(actions.meGet());
    dispatch(actions.availableModuleAll());
    return () => {};
  }, []);

  if (!me?.userData || me?.isLoading) {
    return <Loading />;
  }

  if (!me.isLoading && !me.isCompleted) {
    return <Error message="ไม่สามารถโหลดข้อมูลได้" />;
  }

  return (
    <div className="min-h-screen">
      <MainSidebar
        onMobileClose={handleOnMobileNavClose}
        openMobile={isMobileNavOpen}
        me={me}
      />
      <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
      <div className="lg:ml-64  min-h-screen  pt-16 px-8 ">
        <div className="py-4">
          <Switch>
            <Redirect exact from={`${prefix}`} to={`${prefix}/main`} />
            {/* <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} /> */}
            <Route exact path={`${prefix}/main`}>
              <PersonalProfile title="โปรไฟล์" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/edit`}>
              <EditPersonalProfile title="แก้ไขโปรไฟล์" subtitle={name} />
            </Route>

            <Route exact path={`${prefix}/edit-password`}>
              <EditPassword title="แก้ไขรหัสผ่าน" subtitle={name} />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </div>
      </div>
      <div className="lg:ml-64">
        <MainFooter />
      </div>
    </div>
  );
}

export default Profile;
