import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
// eslint-disable-next-line object-curly-newline
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Card,
  TextField,
  InputAdornment,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

const ExportsManagement = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const productTransaction = useSelector((state) => state.productTransaction);
  const history = useHistory();
  // const product = useSelector((state) => state.product);
  console.log('productTransaction', productTransaction);

  // Setting React hook form

  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [total, setTotal] = useState(undefined);
  const transactionType = 'ออก';

  useEffect(() => {
    const fetchProductTransaction = async () => {
      try {
        await dispatch(
          actions.productTransactionAll({ name, page, size, transactionType }),
        );
        await dispatch(actions.productAll(''));
      } catch (error) {
        console.error(error);
      }
    };
    fetchProductTransaction();

    return () => {};
  }, [name, page, size]);

  useEffect(() => {
    setTotal(productTransaction?.total);
    return () => {};
  }, [productTransaction]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  // const handleDelete = async (id) => {
  //   const confirm = window.confirm('ยืนยันการลบข้อมูล');
  //   if (confirm) {
  //     try {
  //       await dispatch(actions.productTransactionDelete(id));
  //       await dispatch(actions.productTransactionAll({ name, page, size }));
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }
  // };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleToInfoPage = async (id) => {
    history.push(`exports/${id}`);
  };

  const renderAddButtom = () => (
    <div className="flex flex-row justify-end pb-4">
      <Link to="/spm/exports/create">
        <Button variant="contained">เพิ่ม</Button>
      </Link>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">คู่ค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">รายการสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">วันดำเนินการ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(productTransaction.rows) ? (
                productTransaction.rows.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">{`${row?.customer?.name}`}</div>
                      <div className="">{`${row?.customer?.type?.name}`}</div>
                    </TableCell>
                    <TableCell>
                      {row?.order.map((eachOrder, _index) => (
                        <div key={_index} className="w-full py-1 flex">
                          <p className="font-bold">
                            {_index + 1}
                            {' . '}
                            {eachOrder?.product?.name}
                          </p>
                          <p className="pl-2">
                            {eachOrder?.amount}
                            {'  '}
                            {eachOrder?.product?.unit}
                          </p>
                        </div>
                      ))}
                    </TableCell>
                    <TableCell>{`${dayjs(row?.date).format(
                      'D MMM BBBB ',
                    )}`}</TableCell>
                    <TableCell>
                      <div className="flex flex-row flex-wrap gap-1">
                        <Button
                          variant="contained"
                          color={'secondary'}
                          size={'small'}
                          onClick={() => handleToInfoPage(row.id)}
                        >
                          รายละเอียด
                        </Button>
                        {/* <Button
                          variant="contained"
                          color={'error'}
                          size={'small'}
                          // disabled
                          onClick={() => {
                            handleDelete(row?.id);
                          }}
                        >
                          ลบ
                        </Button> */}
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (productTransaction.isLoading) {
    return <Loading />;
  }
  if (!productTransaction.isLoading && productTransaction.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="mt-6">{renderAddButtom()}</div>
        </div>
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error message={productTransaction?.message} />;
};

ExportsManagement.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ExportsManagement.defaultProps = {
  title: '',
  subtitle: '',
};

export default ExportsManagement;
