import { TextField } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

export function WarehouseForm({
  errors,
  warehouse,
  Controller,
  control,
  // checkComplexWarehouse,
  // checkSimpleWarehouse,
  // handleCheckSimpleWarehouse,
  // handleCheckComplexWarehouse,
}) {
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={warehouse ? warehouse.name : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อ/รหัส คลัง"
              fullWidth
              required
              size={'small'}
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'address'}
          control={control}
          defaultValue={warehouse ? warehouse.address : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="ที่อยู่"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors.address && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      {/* <div className="w-1/2 px-1 py-2">
        <Controller
          name={'capacity'}
          control={control}
          defaultValue={warehouse ? warehouse.capacity : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ความจุสูงสุดของคลัง"
              fullWidth
              type="number"
              size={'small'}
            />
          )}
        />
      </div>
      <div className="w-1/2 px-1 py-2">
        <Controller
          name={'capacity_unit'}
          control={control}
          defaultValue={warehouse ? warehouse.capacity_unit : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="หน่วยของความจุ"
              fullWidth
              size={'small'}
              multiline={true}
            />
          )}
        />
      </div> */}
    </div>
  );
}

WarehouseForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    address: PropTypes.object,
  }),
  warehouse: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
  // checkComplexWarehouse: PropTypes.bool,
  // checkSimpleWarehouse: PropTypes.bool,
  // handleCheckSimpleWarehouse: PropTypes.func,
  // handleCheckComplexWarehouse: PropTypes.func,
};

WarehouseForm.defaultProps = {
  warehouse: null,
};

export default WarehouseForm;
