export const TRANSACTION_STATUS = {
  UNPACKED_STATUS: {
    status_code: 'UNPACKED_STATUS',
    description: 'สินค้ายังไม่ลงพาเลท',
    level: 0,
  },
  PACKED_NOT_SUCCESS_STATUS: {
    status_code: 'PACKED_NOT_SUCCESS_STATUS',
    description: 'สินค้ายังลงพาเลทไม่ครบ',
    level: 1,
  },
  PACKED_SUCCESS_STATUS: {
    status_code: 'PACKED_SUCCESS_STATUS',
    description: 'สินค้าลงพาเลทเรียบร้อย',
    level: 2,
  },
};
export default TRANSACTION_STATUS;
