import {
  PALLET_ALL,
  PALLET_GET,
  PALLET_DEL,
  PALLET_PUT,
  PALLET_POST,
  PALLET_LOADING,
  PALLET_ERROR,
} from '../types';

import api from '../../../utils/functions/api';

export const palletCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: PALLET_LOADING });
    const { data } = await api.post(`${process.env.REACT_APP_API_URL}/pallet`, {
      ...payload,
    });
    dispatch({ type: PALLET_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PALLET_ERROR });
    throw new Error(error);
  }
};

export const palletAll = (params) => async (dispatch) => {
  try {
    const {
      name = '',
      size = '',
      page = 1,
      selectStatus = '',
      selectCustomer = '',
      filterWarehouse = '',
      groupView = '',
      product = '',
    } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/pallet?name=${name}&size=${size}&page=${page}&selectStatus=${selectStatus}&selectCustomer=${selectCustomer}&filterWarehouse=${filterWarehouse}&groupView=${groupView}&product=${product}`,
    );
    if (status === 200) {
      dispatch({ type: PALLET_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PALLET_ERROR });
    throw new Error(error);
  }
};

export const palletGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/pallet/${id}`,
    );
    if (status === 200) {
      dispatch({ type: PALLET_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PALLET_ERROR });
    throw new Error(error);
  }
};

export const palletGetByPalletNumber = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/pallet/number/${id}`,
    );
    if (status === 200) {
      dispatch({ type: PALLET_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PALLET_ERROR });
    throw new Error(error);
  }
};

export const palletReset = () => async (dispatch) => {
  dispatch({ type: PALLET_LOADING });
};

export const palletPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: PALLET_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/pallet/${id}`,
      payload,
    );
    dispatch({ type: PALLET_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PALLET_ERROR });
    throw new Error(error);
  }
};

export const palletDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: PALLET_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/pallet/${id}`,
    );
    dispatch({ type: PALLET_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PALLET_ERROR });
    throw new Error(error);
  }
};
