import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Card, Collapse, IconButton, CardActionArea } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import { Archive as ArchiveIcon } from 'react-feather';
// eslint-disable-next-line import/no-duplicates
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

export default function CreatePalletTransactionInvoice({ title, subtitle }) {
  const dispatch = useDispatch();
  const pallet = useSelector((state) => state.pallet);
  const warehouse = useSelector((state) => state.warehouse);

  const [reload, setReload] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();

  console.log('warehouse', warehouse?.rows);
  console.log('pallet ', pallet?.rows);
  console.log('selectedProduct', selectedProduct);
  // const history = useHistory();

  useEffect(() => {
    dispatch(actions.palletTypeAll({}));
    dispatch(actions.palletAll({}));
    dispatch(actions.warehouseAll({}));
    setReload(true);
    return () => {};
  }, [reload]);

  const handleClickOpen = (palletClick) => {
    if (palletClick?.warehouse_status) {
      alert('พาเลทลงคลังเเล้ว');
    } else {
      setSelectedProduct(palletClick);
      if (!openCollapse || selectedProduct?.id === palletClick?.id) {
        setOpenCollapse(!openCollapse);
      }
    }
  };

  const AddPalletToWarehouse = (warehouseData, productData) => {
    // console.log('pallet on add product', palletData);
    // console.log('product on add product', productData);
    const data = { warehouseData, productData };
    console.log('data in add', data);
    // setPalletAndWarehouse(data);
    // console.log('palletAndWarehouse', palletAndWarehouse);
    if (
      warehouseData?.capacity ===
        warehouseData?.product_in_warehouse_inventory &&
      data?.productData?.used_capacity +
        warehouseData?.product_in_warehouse_inventory >
        data?.warehouseData?.capacity
    ) {
      alert('คลังสินค้าถูกบรรจุเต็มเเล้ว');
    } else {
      const confirm = window.confirm('ยืนยันการเพิ่มพาเลทลงคลัง');
      if (confirm) {
        // eslint-disable-next-line no-lone-blocks
        {
          // eslint-disable-next-line no-use-before-define
          onSubmitAddPallet(data);
        }
      }
    }
  };

  const onSubmitAddPallet = async (data) => {
    try {
      console.log(
        'data in submit',
        data?.productData?.id,
        data?.warehouseData?.id,
      );
      alert('สำเร็จ');
      setOpenCollapse(false);
      await dispatch(
        actions.palletPut(data?.productData?.id, {
          id_warehouse: data?.warehouseData?.id,
        }),
      );
      await dispatch(actions.palletAll({}));
      await dispatch(actions.productStockLotAll({}));
      await dispatch(actions.warehouseAll({}));
    } catch (error) {
      console.error(error);
    }
  };

  const manageWarehouse = () => (
    <div className="col-span-1 lg:col-span-2 xl:col-span-3">
      <Collapse in={openCollapse} timeout="auto" unmountOnExit>
        <Card
          className=""
          style={{
            backgroundColor: '#ffffff',
            border: 'solid',
          }}
        >
          <div className="flex justify-between">
            <div className="w-full p-4  text-lg font-semibold ">
              <p>
                จัดพาเลทลงคลัง ( {selectedProduct?.name}{' '}
                {selectedProduct?.pallet_type?.name} )
              </p>
              <div className="w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-1">
                {warehouse?.rows?.map((row, index) => (
                  <Card
                    key={index}
                    className="cursor-pointer"
                    style={{
                      backgroundColor: '#e8e8e8',
                      border: '',
                    }}
                    onClick={() =>
                      // eslint-disable-next-line implicit-arrow-linebreak
                      AddPalletToWarehouse(row, selectedProduct)
                    }
                  >
                    <CardActionArea>
                      <div
                        className="text-center px-4 pt-4"
                        // onClick={() => handlePushToDetailWarehouse(row.id)}
                      >
                        <div className="text-xl font-bold">{row?.name}</div>
                        <div className="text-base text-red-600 pt-1">
                          {row?.product_in_warehouse_inventory} /{' '}
                          {row?.capacity} {row?.capacity_unit}
                        </div>
                        <ArchiveIcon
                          className="text-gray-600 mx-auto"
                          size={80}
                        />
                      </div>
                    </CardActionArea>
                  </Card>
                ))}
              </div>
            </div>
            <div className="mt-1 mr-1">
              <IconButton
                aria-label="delete"
                size="large"
                onClick={() => setOpenCollapse(false)}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </Card>
      </Collapse>
    </div>
  );

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (pallet.isLoading || !pallet.rows) {
    return <Loading />;
  }
  if (!pallet.isLoading && pallet.isCompleted) {
    return (
      <>
        {/* {renderModalAddPalletToWarehouse()} */}
        <div className="flex justify-between">{renderTitle()}</div>
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
          <div className="text-lg font-semibold ml-6">ข้อมูลสินค้า</div>
        </div>
        {/* {renderHead()} */}
        <div className="w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-2 pt-2">
          {manageWarehouse()}
          {_.map(pallet?.rows, (row, index) => (
            <>
              <div key={index} className="">
                <Card
                  className="cursor-pointer"
                  onClick={() => handleClickOpen(row)}
                  style={{
                    backgroundColor: `${
                      selectedProduct?.id === row?.id && openCollapse
                        ? '#bfe6ac'
                        : '#ffffff'
                    }`,
                  }}
                >
                  <CardActionArea>
                    <div className="w-full flex justify-between">
                      <div>
                        <p className="pt-4 pl-4 text-lg">{row?.name}</p>
                        <p className="pl-4 text-lg font-semibold">
                          {row?.pallet_type?.name}
                        </p>
                        <p className="pl-4 text-lg">
                          {row?.used_capacity} / {row?.pallet_type?.capacity}{' '}
                          {row?.pallet_type?.capacity_unit}
                        </p>
                      </div>
                      <div className="pt-4 pr-2 text-center">
                        <p>
                          {row?.warehouse_status ? (
                            <p className="text-green-500">ลงคลังเเล้ว</p>
                          ) : (
                            <p className="text-red-500">ยังไม่ลงคลัง</p>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="flex justify-end p-1 px-2 text-gray-500">
                      <small>คลิกเพื่อเลือกพาเลทลงคลัง</small>
                    </div>
                  </CardActionArea>
                </Card>
              </div>
            </>
          ))}
        </div>
      </>
    );
  }
  return <Error />;
}

CreatePalletTransactionInvoice.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreatePalletTransactionInvoice.defaultProps = {
  title: '',
  subtitle: '',
};
