import {
  LOG_MOVE_PALLET_ALL,
  LOG_MOVE_PALLET_CREATE,
  LOG_MOVE_PALLET_GET,
  LOG_MOVE_PALLET_UPDATE,
  LOG_MOVE_PALLET_DELETE,
} from '../../actions/types';

const initialState = {
  logs: null,
  isLoading: false,
  isCompleted: true,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case LOG_MOVE_PALLET_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case LOG_MOVE_PALLET_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case LOG_MOVE_PALLET_CREATE:
      return { isLoading: false, isCompleted: true };
    case LOG_MOVE_PALLET_UPDATE:
      return { isLoading: false, isCompleted: true };
    case LOG_MOVE_PALLET_DELETE:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
