import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Button,
  Card,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  IconButton,
  Tooltip,
  Radio,
  RadioGroup,
  FormControlLabel,
  Autocomplete,
  // Select,
  // FormControl,
  // MenuItem,
  // InputLabel,
} from '@mui/material';

import { ArrowLeft, ArrowRight } from 'react-feather';

import AddBoxIcon from '@mui/icons-material/AddBox';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

import thLocale from 'date-fns/locale/th';

import _ from 'lodash';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

const localeMap = {
  th: thLocale,
};
const maskMap = {
  th: '__/__/____',
};

dayjs.locale('th');
dayjs.extend(buddhistEra);

function ConsignmentInvoice({ title, subtitle }) {
  const dispatch = useDispatch();
  const consignmentInvoice = useSelector((state) => state.consignmentInvoice);
  const history = useHistory();
  const product = useSelector((state) => state.product);

  const [selectProduct, setSelectProduct] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);

  const NewProduct = _.map(product?.rows, (row, index) => ({
    index: index + 1,
    ...row,
  }));

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectDate, setSelectDate] = useState('');
  const [orderByDir, setOrderByDir] = useState('asc');
  const [orderByField, setOrderByField] = useState('deposit_start');

  const handleChangeSelectDate = (event) => {
    setSelectDate(event.target.value);
    setStartDate(dayjs().startOf('month').toISOString());
    setEndDate(dayjs().endOf('month').toISOString());
  };

  console.log('orderByDir', orderByDir);

  useEffect(() => {
    dispatch(actions.productAll({}));
    return () => {};
  }, []);

  useEffect(() => {
    dispatch(
      actions.consignmentInvoiceAll({
        name,
        page,
        size,
        startDate,
        endDate,
        selectDate,
        orderBy: orderByDir,
        orderByField,
        selectProduct,
      }),
    );
    return () => {};
  }, [
    name,
    page,
    size,
    startDate,
    endDate,
    orderByDir,
    orderByField,
    selectDate,
    selectProduct,
  ]);

  console.log('selectDate', selectDate);

  useEffect(() => {
    setTotal(consignmentInvoice?.total);
    return () => {};
  }, [
    consignmentInvoice,
    startDate,
    endDate,
    selectDate,
    orderByDir,
    orderByField,
    selectProduct,
  ]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleDeleteConsignmentInvoice = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      console.log('ลบสำเร็จ', id);
      // try {
      //   await dispatch(actions.consignmentInvoiceDelete(id));
      //   await dispatch(
      //     actions.consignmentInvoiceAll({
      //       name,
      //       page,
      //       size,
      //       startDate,
      //       endDate,
      //       selectDate,
      //     }),
      //   );
      // } catch (error) {
      //   console.error(error);
      // }
    }
  };

  const handlePushToDetailConsignmentInvoice = async (id) => {
    history.push(`/doc/consignment-invoice/${id}`);
  };

  const handlePushToEditConsignmentInvoice = async (id) => {
    history.push(`/doc/consignment-invoice/edit/${id}`);
  };

  const handlePushToCreateConsignmentInvoice = async () => {
    history.push('/doc/consignment-invoice/create');
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const chevronDisplay = (field) => {
    if (field === orderByField) {
      if (orderByDir === 'asc') {
        return (
          <div className="self-center  mx-3 ">
            <i className="fas fa-chevron-up text-sm" />
          </div>
        );
      }
      return (
        <div className="self-center  mx-3 ">
          <i className="fas fa-chevron-down text-sm" />
        </div>
      );
    }
    return (
      <div className="self-center  mx-3 ">
        <i className="fas fa-chevron-down text-gray-400 text-sm" />
      </div>
    );
  };

  const setSelectedField = (field) => {
    if (orderByField === field) {
      if (orderByDir === 'asc') {
        setOrderByDir('desc');
      } else {
        setOrderByDir('asc');
      }
    } else {
      setOrderByField(field);
      setOrderByDir('desc');
    }
  };

  const renderPeriod = () => (
    <>
      <div className="grid md:grid-cols-2 pb-4 px-4 pt-2 gap-x-2 gap-y-2">
        <div className="bg-white flex">
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            locale={localeMap['th']}
          >
            <DesktopDatePicker
              label="วันเริ่มต้น"
              value={startDate}
              mask={maskMap['th']}
              minDate={new Date('2020-01-01')}
              onChange={(newValue) => {
                setStartDate(dayjs(newValue).toISOString());
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </div>
        <div className="bg-white flex">
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            locale={localeMap['th']}
          >
            <DesktopDatePicker
              label="วันสิ้นสุด"
              value={endDate}
              mask={maskMap['th']}
              minDate={new Date('2020-01-01')}
              onChange={(newValue) => {
                setEndDate(dayjs(newValue).toISOString());
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
          <div className="my-auto ml-1">
            <Tooltip
              title="รีเช็ตการค้นหา"
              onClick={() => {
                setStartDate('');
                setEndDate('');
                setSelectDate('');
              }}
            >
              <IconButton color="primary">
                <RestartAltIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  );

  // console.log('selectCustomer', selectCustomer);

  const renderAddButton = () => (
    <div className="flex flex-row justify-end pb-4">
      <div>
        <Button
          variant="contained"
          onClick={() => handlePushToCreateConsignmentInvoice()}
          startIcon={<AddBoxIcon />}
        >
          เพิ่ม
        </Button>
      </div>
    </div>
  );

  const renderSearch = () => (
    <>
      <div className="pt-4 px-4 flex flex-row gap-2">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full md:w-1/2">
          <Autocomplete
            size={'small'}
            onChange={(e, newValue) => {
              setSelectProduct(newValue?.id);
            }}
            options={NewProduct}
            getOptionLabel={(type) => `${type?.index} : ${type?.name}`}
            fullWidth
            renderInput={(params) => <TextField {...params} label="สินค้า" />}
          />
        </div>
      </div>
    </>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">เลขใบรับฝาก</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">
                    <div>ชื่อลูกค้า /</div>
                    <div>ทะเบียนรถ</div>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">สินค้า</div>
                </TableCell>
                <TableCell
                  onClick={() => setSelectedField('deposit_start')}
                  className="cursor-pointer"
                >
                  <div className="font-bold flex">
                    <div>วันที่รับฝาก</div>
                    {chevronDisplay('deposit_start')}
                  </div>
                </TableCell>
                <TableCell
                  onClick={() => setSelectedField('deposit_end')}
                  className="cursor-pointer"
                >
                  <div className="font-bold flex">
                    <div>วันครบกำหนด</div>
                    {chevronDisplay('deposit_end')}
                  </div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ผู้ดำเนินการ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(consignmentInvoice?.rows) ? (
                consignmentInvoice?.rows.map((row, index) => (
                  <TableRow
                    key={row?.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>
                      <p className="font-bold">{`${row?.name}`}</p>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">{`${row?.customer?.name}`}</div>
                      {row?.car_registration && (
                        <div>( {`${row?.car_registration}`} )</div>
                      )}
                      <div className="">
                        {row?.consignment_invoice_type === 'keep' && 'เก็บ'}
                        {row?.consignment_invoice_type === 'freeze' &&
                          'แช่แข็ง'}
                        {row?.consignment_invoice_type === 'other' && 'อื่นๆ'}
                      </div>
                      {row?.description && <div>( {row?.description} )</div>}
                    </TableCell>
                    <TableCell>
                      <ul className="list-disc">
                        {_.map(row?.productstocklots, (eachProduct, _index) => (
                          <li key={_index}>
                            {eachProduct?.product_type?.name} ({' '}
                            {eachProduct?.product_type?.tag ||
                              eachProduct?.tag ||
                              '-'}{' '}
                            )
                          </li>
                        ))}
                      </ul>
                    </TableCell>
                    {/* <TableCell>
                      <div className="">{`${row?.consignment_invoice_type}`}</div>
                      {row?.description && <div>( {row?.description} )</div>}
                    </TableCell> */}
                    <TableCell>
                      {row?.deposit_start ? (
                        <div>{`${dayjs(row?.deposit_start).format(
                          'D MMM BBBB',
                        )}`}</div>
                      ) : (
                        '-'
                      )}
                    </TableCell>
                    <TableCell>
                      {row?.deposit_end ? (
                        <div>{`${dayjs(row?.deposit_end).format(
                          'D MMM BBBB',
                        )}`}</div>
                      ) : (
                        '-'
                      )}
                    </TableCell>
                    <TableCell>{`${row?.employee?.firstname} ${row?.employee?.lastname}`}</TableCell>
                    <TableCell>
                      <div className="my-1">
                        <Button
                          variant="contained"
                          color={'info'}
                          size={'small'}
                          fullWidth
                          startIcon={<DescriptionIcon />}
                          onClick={() =>
                            // eslint-disable-next-line implicit-arrow-linebreak
                            handlePushToDetailConsignmentInvoice(row.id)
                          }
                        >
                          รายละเอียด
                        </Button>
                      </div>
                      <div className="my-1">
                        <Button
                          variant="contained"
                          color={'warning'}
                          size={'small'}
                          sx={{
                            color: '#ffffff',
                          }}
                          fullWidth
                          startIcon={<EditIcon />}
                          onClick={() =>
                            // eslint-disable-next-line implicit-arrow-linebreak
                            handlePushToEditConsignmentInvoice(row.id)
                          }
                        >
                          แก้ไข
                        </Button>
                      </div>
                      <div className="">
                        <Button
                          variant="contained"
                          color={'error'}
                          size={'small'}
                          fullWidth
                          startIcon={<DeleteIcon />}
                          disabled
                          onClick={() => {
                            // setSelectConsignment(row?.id);
                            handleDeleteConsignmentInvoice(row?.id);
                          }}
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  const minusOneMonth = () => {
    const previousMonth = dayjs(startDate)
      .subtract(1, 'month')
      .startOf('month');
    const endOfPreviousMonth = dayjs(startDate)
      .subtract(1, 'month')
      .endOf('month');
    setStartDate(previousMonth);
    setEndDate(endOfPreviousMonth);
  };

  const addOneMonth = () => {
    const previousMonth = dayjs(startDate).add(1, 'month').startOf('month');
    const endOfPreviousMonth = dayjs(startDate).add(1, 'month').endOf('month');
    setStartDate(previousMonth);
    setEndDate(endOfPreviousMonth);
  };

  if (consignmentInvoice.isLoading) {
    return <Loading />;
  }
  if (!consignmentInvoice.isLoading && consignmentInvoice.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>

          <div className="mt-6">{renderAddButton()}</div>
        </div>
        <Card>
          {renderSearch()}
          {!consignmentInvoice.isLoading && (
            <>
              <div className="w-full px-4 mt-2 flex my-2">
                <div className="w-1/2">
                  <RadioGroup
                    row
                    value={selectDate}
                    onChange={handleChangeSelectDate}
                  >
                    <FormControlLabel
                      value="start"
                      control={<Radio />}
                      label="วันรับฝาก"
                    />
                    <FormControlLabel
                      value="end"
                      control={<Radio />}
                      label="วันครบกำหนด"
                    />
                  </RadioGroup>
                </div>
                {(selectDate === 'start' || selectDate === 'end') && (
                  <div className="w-1/2 flex gap-2">
                    <Button
                      variant="outlined"
                      startIcon={<ArrowLeft />}
                      size={'small'}
                      onClick={minusOneMonth}
                    >
                      กลับ 1 เดือน
                    </Button>
                    <Button
                      variant="outlined"
                      endIcon={<ArrowRight />}
                      size={'small'}
                      onClick={addOneMonth}
                    >
                      เพิ่ม 1 เดือน
                    </Button>
                  </div>
                )}
              </div>
              {renderPeriod()}
            </>
          )}
        </Card>
        {renderTable()}
      </div>
    );
  }
  return <Error />;
}

ConsignmentInvoice.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ConsignmentInvoice.defaultProps = {
  title: '',
  subtitle: '',
};

export default ConsignmentInvoice;
