/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';
// eslint-disable-next-line object-curly-newline
import { useForm, Controller } from 'react-hook-form';
import { Button, Card, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/extensions
// eslint-disable-next-line import/no-unresolved
import RichtextEditor from '../../components/Richtext/RichtextEditor';
import ImageUpload from '../../components/ImageUpload/ImageUpload';

const MainSite = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);
  const [uploadedImage1, setUploadedImage1] = useState(information?.images);
  const [uploadedImage2, setUploadedImage2] = useState(information?.images2);
  // const history = useHistory();
  const [shopDescribe, setShopDescribe] = useState(information?.description1);

  console.log('information', information);

  useEffect(() => {
    dispatch(actions.getInformation());
    return () => {};
  }, []);

  useEffect(() => {
    setUploadedImage1(information?.images);
    setUploadedImage2(information?.images2);
    return () => {};
  }, [information]);

  // console.log('Information', information);

  // console.log('shopDescribe', shopDescribe);
  // Setting React hook form
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      name: information?.name,
      video: information?.video,
      description: information?.description1,
    },
  });

  useEffect(() => {
    if (information?.description1) {
      setShopDescribe(information?.description1);
    }
    return () => {};
  }, [information]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data, event) => {
    try {
      const preprocessedData = {
        ...data,
        description1: shopDescribe,
        images: _.map(uploadedImage1, (image) => ({
          // eslint-disable-next-line no-underscore-dangle
          id: image._id,
          image: image.data_url || image.url,
        })),

        images2: _.map(uploadedImage2, (image2) => ({
          // eslint-disable-next-line no-underscore-dangle
          id: image2._id,
          image: image2.data_url || image2.url,
        })),
      };
      console.log('preprocessedData', preprocessedData);
      event.preventDefault();
      await dispatch(
        // eslint-disable-next-line no-underscore-dangle
        actions.editOneInformation(information?._id, preprocessedData),
      );

      alert('สำเร็จ');
      await dispatch(actions.getInformation());
    } catch (error) {
      console.error(error);
    }
  };
  if (information.isLoading) {
    return <Loading />;
  }
  if (!information.isLoading && information.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          {/* <div className="mt-6">{renderAddButton()}</div> */}
        </div>
        <Card className="p-6">
          <div className="py-2 font-bold">ชื่อบริษัท</div>

          <form onSubmit={handleSubmit(onSubmit)}>
            {/* <MainSiteForm /> */}
            <div className="flex flex-row flex-wrap">
              <div className="w-full  px-1 py-2">
                <Controller
                  name={'name'}
                  control={control}
                  defaultValue={information?.name || ''}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="ชื่อบริษัท"
                      fullWidth
                      size={'small'}
                      helperText={errors.name && 'กรุณาใส่ข้อมูล'}
                    />
                  )}
                />
              </div>

              <div className="py-2 font-bold">
                ลิงค์วีดีโอ{' '}
                <small>
                  ( สามารถใส่ลิงค์วีดีโอได้ทั้ง Youtube และ Facebook )
                </small>
              </div>
              <div className="w-full  px-1 py-2">
                <Controller
                  name={'video'}
                  control={control}
                  defaultValue={information?.video || ''}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="ลิงค์วีดีโอ"
                      fullWidth
                      size={'small'}
                      helperText={errors.name && 'กรุณาใส่ข้อมูล'}
                    />
                  )}
                />
              </div>

              <div className="py-2 font-bold">
                สไลค์รูปภาพ 1 <small>( ใส่รูปภาพได้สูงสุด 10 รูป )</small>
              </div>
              <div className="w-full  px-1 border-4 rounded-xl">
                <ImageUpload
                  images={uploadedImage1}
                  setImages={setUploadedImage1}
                  preview_size="250"
                  maxNumber={10}
                />
              </div>

              <div className="py-2 font-bold">คำอธิบายบริษัท</div>
              <div className="w-full  px-1 py-2 pb-20">
                <RichtextEditor
                  value={shopDescribe}
                  setValue={setShopDescribe}
                />
              </div>

              <div className="py-2 font-bold">
                สไลค์รูปภาพ 2 <small>( ใส่รูปภาพได้สูงสุด 10 รูป )</small>
              </div>
              <div className="w-full  px-1 border-4 rounded-xl">
                <ImageUpload
                  images={uploadedImage2}
                  setImages={setUploadedImage2}
                  preview_size="250"
                  maxNumber={10}
                />
              </div>
              {/* <div className="w-full  px-1 py-2">
              <Controller
                name={'description1'}
                control={control}
                defaultValue={information?.description1 || ''}
                rules={{ required: false }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="คำอธิบายบริษัท 1"
                    rows={3}
                    multiline={true}
                    fullWidth
                    size={'small'}
                    helperText={errors.name && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div>
            <div className="w-full  px-1 py-2">
              <Controller
                name={'description2'}
                control={control}
                defaultValue={information?.description2 || ''}
                rules={{ required: false }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="คำอธิบายบริษัท 2"
                    rows={3}
                    multiline={true}
                    fullWidth
                    size={'small'}
                    helperText={errors.name && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div>
            <div className="w-full  px-1 py-2">
              <Controller
                name={'description3'}
                control={control}
                defaultValue={information?.description3 || ''}
                rules={{ required: false }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="คำอธิบายบริษัท 3"
                    rows={3}
                    multiline={true}
                    fullWidth
                    size={'small'}
                    helperText={errors.name && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div> */}
            </div>
            <div className="flex flex-row justify-end gap-1 py-4">
              <Button
                variant="contained"
                type="submit"
                startIcon={<SaveIcon />}
              >
                บันทึก
              </Button>
            </div>
          </form>
        </Card>
      </div>
    );
  }
  return <Error message={information?.message} />;
};

MainSite.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MainSite.defaultProps = {
  title: '',
  subtitle: '',
};

export default MainSite;
