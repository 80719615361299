/* eslint-disable no-sequences */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import QRCode from 'react-qr-code';
import {
  Backdrop,
  Button,
  Card,
  Fade,
  Modal,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  IconButton,
  Snackbar,
  Alert,
  FormControlLabel,
  Checkbox,
  Box,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import PivotTableChartIcon from '@mui/icons-material/PivotTableChart';

import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';

import { styled } from '@mui/material/styles';

import { Trash2 as Delete, Archive as ArchiveIcon } from 'react-feather';

import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import { useForm, Controller, useFieldArray } from 'react-hook-form';
import _ from 'lodash';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import qrcode from 'qrcode';
import * as actions from '../../redux/actions';
import { WarehouseForm } from '../../components/Forms';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { CSVUpload } from '../../components/CSVUpload';
import { BarcodeWarehouseReport } from '../../components/Report';

dayjs.extend(customParseFormat);

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    // backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

const defaultValue = { name: '', address: '', capacity: '', capacity_unit: '' };

function Warehouse({ title, subtitle }) {
  const dispatch = useDispatch();
  const warehouse = useSelector((state) => state.warehouse);
  const setting = useSelector((state) => state.setting);
  const me = useSelector((state) => state.me);
  const history = useHistory();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
  } = useForm();
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [total, setTotal] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [openQr, setOpenQr] = useState(false);
  const [openCopy, setOpenCopy] = useState(false);
  const [view, setView] = useState('module');
  const [warehouseData, setWarehouseData] = useState('');
  const [showWarehouseDiagram, setShowWarehouseDiagram] = useState(false);
  const [board, setBoard] = useState([]);

  const [checkSimpleWarehouse, setCheckSimpleWarehouse] = useState(false);
  const [checkComplexWarehouse, setCheckComplexWarehouse] = useState(false);
  const [csvUploadOpen, setCsvUploadOpen] = useState(false);
  const [csvData, setCsvData] = useState([]);

  // const opts = {
  //   errorCorrectionLevel: 'H',
  //   type: 'image/jpeg',
  //   mode: 'Alphanumeric',
  //   quality: 0.8,
  //   margin: 1,
  //   width: 250,
  // };

  // const genQR = (data) => {
  //   let resQR;
  //   qrcode.toDataURL(data, opts, (err, res) => {
  //     if (err) throw err;
  //     resQR = res;
  //   });
  //   return resQR;
  // };

  // console.log('qr code in warehouse', genQR('123456'));

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenQr = () => setOpenQr(true);
  const handleCloseQr = () => setOpenQr(false);
  const handleOpenCopy = () => setOpenCopy(true);
  const handleCloseCopy = () => setOpenCopy(false);

  const handleCheckSimpleWarehouse = (event) => {
    setCheckSimpleWarehouse(event.target.checked);
  };

  const handleCheckComplexWarehouse = (event) => {
    setCheckComplexWarehouse(event.target.checked);
  };

  const handleCSVOpen = () => setCsvUploadOpen(true);
  const handleCSVClose = () => {
    setCsvUploadOpen(false);
    setCsvData([]);
  };

  // console.log('warehouse', warehouse?.rows);
  // console.log(
  //   'warehouse map test',
  //   _.map(warehouse?.rows[0]?.positionsimples, (each) => each?.row),
  // );

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'itemList',
  });

  useEffect(() => {
    dispatch(actions.warehouseAll({ name, page, size }));
    dispatch(actions.meGet());
    return () => {};
  }, [name, page, size]);

  useEffect(() => {
    setTotal(warehouse?.total);
    return () => {};
  }, [warehouse]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleAddItem = () => {
    append({
      quantityPallet: '',
    });
  };

  const handleDeleteItem = (index) => {
    remove(index);
  };

  const handlePrintBarcode = (rowData, typeCode) => {
    try {
      BarcodeWarehouseReport({ warehouse: rowData, info: setting, typeCode });
    } catch (error) {
      alert(`ไม่สามารถพิมพ์บาร์โค้ดได้ ${error?.message}`);
    }
  };
  const handleAddFromFile = async () => {
    const NewCsvData = _.map(csvData, (eachArray) => {
      const data = {
        consignment_invoice_type: 'freeze',
        deposit_start:
          eachArray?.date === '-'
            ? null
            : dayjs(eachArray?.date).format('YYYY-MM-DD'),
        description: eachArray?.remark,
        car_registration: eachArray?.car,
        name: eachArray?.NO,
        employee: me?.userData?.id,
        customer: eachArray?.customer_id,
        weight: eachArray?.weight,
        warehouse: eachArray?.warehouse_id,
        quantity: eachArray?.quantity,
        row: parseInt(eachArray?.row, 10),
        product: eachArray?.product,
        inventory: parseInt(eachArray?.inventory, 10),
        product_id:
          eachArray?.product_id === '' ? undefined : eachArray?.product_id,
      };
      return data;
    });

    console.log('NewCsvData', NewCsvData);

    if (!_.isEmpty(NewCsvData)) {
      try {
        await dispatch(actions.warehouseCreate({ arr: NewCsvData }));
        alert('สำเร็จ');
        handleCSVClose();
        await dispatch(actions.warehouseAll({ name, page, size }));
      } catch (error) {
        alert('เพิ่มสินค้าไม่สำเร็จ');
        console.error(error);
      }
    } else {
      alert('ไม่สามารถอ่านไฟล์รายการสินค้าได้');
    }
  };

  const onSubmit = async (data) => {
    try {
      if (checkComplexWarehouse) {
        const payload = {
          ...data,
          address: data.address || '-',
          row: parseInt(data.row, 10),
          col: parseInt(data.col, 10),
          height: parseInt(data.height, 10),
        };
        const elementCol = [];
        // eslint-disable-next-line no-plusplus
        for (let indexI = 0; indexI < payload.col; indexI++) {
          // eslint-disable-next-line no-plusplus
          for (let indexJ = 0; indexJ < payload.row; indexJ++) {
            elementCol.push({ row: indexI, col: indexJ, available: true });
          }
        }

        const position = [];
        // eslint-disable-next-line no-plusplus
        for (let indexK = 0; indexK < payload.height; indexK++) {
          position.push({ floor: indexK + 1, available_position: elementCol });
        }

        // console.log('position', position);
        const newPayload = {
          ...data,
          address: data.address || '-',
          row: parseInt(data.row, 10),
          col: parseInt(data.col, 10),
          height: parseInt(data.height, 10),
          position,
          type: 'complex',
        };

        console.log('payload on sumit', newPayload);
        await dispatch(actions.warehouseCreate(newPayload));
        reset(defaultValue);
        alert('สำเร็จ');
        handleClose();
        setShowWarehouseDiagram(false);
        await dispatch(actions.warehouseAll({ name, page, size }));
      } else {
        const newList = _.map(data?.itemList, (item, index) => ({
          max_capacity: parseInt(item.quantityPallet, 10),
          row: index + 1,
        }));

        const newPayload = {
          ...data,
          address: data.address || '-',
          row: newList.length,
          position: newList,
          type: 'simple',
        };

        console.log('newPayload', newPayload);
        await dispatch(actions.warehouseCreate(newPayload));
        reset(defaultValue);
        alert('สำเร็จ');
        handleClose();
        setShowWarehouseDiagram(false);
        await dispatch(actions.warehouseAll({ name, page, size }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteWarehouse = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูลคลัง');
    if (confirm) {
      try {
        await dispatch(actions.warehouseDelete(id));
        await dispatch(actions.warehouseAll({ name, page, size }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handlePushToDetailWarehouse = (id) => {
    history.push(`/wms/warehouses/${id}`);
  };

  const handlePushToEditWarehouse = async (id) => {
    history.push(`/wms/warehouse/edit/${id}`);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeView = (event, nextView) => {
    setView(nextView);
  };

  const handleOpenModalQrCode = (data) => {
    handleOpenQr(), setWarehouseData(data);
  };

  const createWarehouseDiagram = (position) => {
    console.log('position', position);
    const elementCol = [];
    // eslint-disable-next-line no-plusplus
    for (let indexI = 0; indexI < position.col; indexI++) {
      // eslint-disable-next-line no-plusplus
      for (let indexJ = 0; indexJ < position.row; indexJ++) {
        elementCol.push(0);
      }
    }

    console.log('elementCol', elementCol);

    setBoard(elementCol);
  };

  const showDiagram = () => {
    console.log('row', getValues('row'));
    console.log('col', getValues('col'));
    if (
      getValues('row') === '' ||
      getValues('col') === '' ||
      getValues('height') === ''
    ) {
      alert('กรุณาใส่ข้อมูลให้ครบถ้วน');
    } else {
      const position = {
        row: parseInt(getValues('row'), 10),
        col: parseInt(getValues('col'), 10),
        height: parseInt(getValues('height'), 10),
      };
      createWarehouseDiagram(position);
      setShowWarehouseDiagram(true);
    }
  };

  const progressBarValue = (value, maxValue) => {
    const NewValue = (value * 100) / maxValue;
    return NewValue;
  };

  const LinearProgressWithLabel = (value, maxValue) => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mb: 1 }}>
        <BorderLinearProgress
          variant="determinate"
          value={progressBarValue(value, maxValue)}
        />
      </Box>
    </Box>
  );

  const renderAddButtom = () => (
    <div className="flex flex-row justify-end pb-4 gap-1">
      <Button
        variant="contained"
        onClick={handleOpen}
        startIcon={<AddBoxIcon />}
      >
        เพิ่ม
      </Button>
      <Button
        variant="contained"
        onClick={handleCSVOpen}
        startIcon={<FileUploadIcon />}
      >
        อัพโหลด excel
      </Button>
    </div>
  );

  const renderModalQR = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openQr}
      onClose={handleCloseQr}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openQr}>
        <Card sx={style} className="max-w-xl">
          <div className="py-2 text-center text-lg font-semibold">
            คิวอาร์โค๊ดสำหรับดูรายละเอียดคลัง
          </div>
          <div className="py-2 text-center text-2xl font-semibold">
            {warehouseData?.name}
          </div>
          <div className="grid md:grid-cols-3 pt-2">
            <QRCode
              className="col-start-2 col-span-1"
              value={`${window.location.href}/${warehouseData?.id}`}
              size={150}
            />
          </div>
          <div className="w-full pt-4">
            <TextField
              fullWidth
              readOnly
              value={`${window.location.href}/${warehouseData?.id}`}
            />
          </div>
          <div className="w-full flex justify-center pt-4">
            <Button
              variant="contained"
              color={'info'}
              onClick={async () => {
                await navigator.clipboard.writeText(
                  `${window.location.href}/${warehouseData?.id}`,
                );
                handleOpenCopy();
              }}
            >
              คัดลอก
            </Button>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 md:flex justify-between">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full md:w-1/2">
          <div className="flex justify-end">
            <p className=" my-auto pr-2">เลือกมุมมอง</p>
            <ToggleButtonGroup
              value={view}
              exclusive
              onChange={handleChangeView}
            >
              <ToggleButton value="list" aria-label="list">
                <ViewListIcon />
              </ToggleButton>
              <ToggleButton value="module" aria-label="module">
                <ViewModuleIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
      </div>
    </Card>
  );

  const renderCSVUploadModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={csvUploadOpen}
      onClose={handleCSVClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={csvUploadOpen}>
        <Card sx={style} className="max-w-4xl">
          <div className="max-h-screen overflow-y-auto my-10">
            <div className="text-2xl text-center mb-2">อัพโหลดสินค้าลงคลัง</div>
            <div className="py-2">
              <div className="flex justify-center">
                <div>
                  <CSVUpload setCsvData={setCsvData} />
                  {!_.isEmpty(csvData) && (
                    <div>
                      <p className="font-system my-1">
                        พบข้อมูล {_.size(csvData)} รายการ
                      </p>
                      <Button
                        color="primary"
                        variant="contained"
                        type="button"
                        onClick={handleAddFromFile}
                      >
                        บันทึก
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderTablePallet = () => (
    <div>
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size={'small'}>
            <colgroup>
              <col width="30%" />
              <col width="40%" />
              <col width="30%" />
            </colgroup>
            <TableHead>
              <TableRow className="bg-green-100">
                <TableCell>
                  <div className="font-bold py-1">แถวที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">จำนวน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(fields) ? (
                _.map(fields, (item, index) => (
                  <TableRow
                    key={item.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="py-1">{`${index + 1}`}</div>
                    </TableCell>

                    <TableCell component="th" scope="row">
                      <div className="py-1">
                        <Controller
                          name={`itemList.${index}.quantityPallet`}
                          control={control}
                          // defaultValue={
                          //   NewProduct ? NewProduct[0]?.weight : ' '
                          // }
                          render={({ field }) => (
                            <TextField
                              {...field}
                              size="small"
                              fullWidth
                              required
                              label="จำนวนพาเลท"
                            />
                          )}
                        />
                      </div>
                    </TableCell>

                    <TableCell component="th" scope="row">
                      <Button
                        size="sm"
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          handleDeleteItem(index);
                        }}
                      >
                        <Delete size={16} color="red" />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );

  const renderAddButton = () => (
    <Button
      size="small"
      variant="outlined"
      color="success"
      onClick={() => {
        handleAddItem();
      }}
    >
      เพิ่มแถว
    </Button>
  );

  const renderModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 100,
      }}
    >
      <Fade in={open}>
        <Card sx={style} className="max-w-4xl">
          <div className="max-h-screen overflow-y-auto overflow-x-auto">
            <div className="py-2">เพิ่มข้อมูล</div>
            <div className="py-2">
              <form onSubmit={handleSubmit(onSubmit)}>
                <WarehouseForm
                  control={control}
                  Controller={Controller}
                  errors={errors}
                  warehouse={warehouse}
                  checkComplexWarehouse={checkComplexWarehouse}
                  checkSimpleWarehouse={checkSimpleWarehouse}
                  handleCheckSimpleWarehouse={handleCheckSimpleWarehouse}
                  handleCheckComplexWarehouse={handleCheckComplexWarehouse}
                />
                <div className="py-2">ตั้งค่าความจุของคลัง</div>

                {!checkSimpleWarehouse && (
                  <div className="w-full px-1">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkComplexWarehouse}
                          onChange={handleCheckComplexWarehouse}
                          disabled
                        />
                      }
                      label="คลังแบบสามมิติ (สามารถดูตำแหน่งของพาเลทได้ละเอียดมากขึ้น)"
                    />
                  </div>
                )}

                {!checkComplexWarehouse && (
                  <div className="w-full px-1">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkSimpleWarehouse}
                          onChange={handleCheckSimpleWarehouse}
                        />
                      }
                      label="คลังแบบง่าย (ง่ายต่อการจัดการพาเลทมากขึ้น)"
                    />
                  </div>
                )}

                {/* <div>
                  ทำยูสฟิวอาเรย์ตรงนี้ โดยมี 2 ฟิลด์ คือ ลำดับแถว
                  และจำนวนพาเลทในแต่ละแถว
                </div> */}
                {checkSimpleWarehouse && (
                  <>
                    <div>{renderTablePallet()}</div>
                    <div className="mt-2">{renderAddButton()}</div>
                  </>
                )}

                {checkComplexWarehouse && (
                  <div className="grid grid-cols-4 gap-1">
                    <div className="">
                      <Controller
                        name={'col'}
                        control={control}
                        rules={{ required: true }}
                        defaultValue={''}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="จำนวนแถว"
                            fullWidth
                            type="number"
                            size={'small'}
                            required
                          />
                        )}
                      />
                    </div>
                    <div className="">
                      <Controller
                        name={'row'}
                        control={control}
                        rules={{ required: true }}
                        defaultValue={''}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="ความลึก"
                            fullWidth
                            type="number"
                            size={'small'}
                            required
                          />
                        )}
                      />
                    </div>
                    <div className="">
                      <Controller
                        name={'height'}
                        control={control}
                        rules={{ required: true }}
                        defaultValue={''}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="ความสูง"
                            fullWidth
                            type="number"
                            size={'small'}
                            required
                          />
                        )}
                      />
                    </div>
                    <div className="my-auto ">
                      <Button
                        startIcon={<PivotTableChartIcon />}
                        variant="outlined"
                        fullWidth
                        onClick={() => {
                          showDiagram();
                        }}
                      >
                        แสดงแผนภาพ
                      </Button>
                    </div>
                  </div>
                )}

                {showWarehouseDiagram && (
                  <>
                    <div className="pt-4 text-center text-xl">แผนภาพคลัง</div>
                    <div className="flex justify-center pt-2 overflow-x-auto">
                      <div className="overflow-auto">
                        <div
                          className={`grid grid-cols-${
                            parseInt(getValues('row'), 10) + 1
                          } grid-rows-${parseInt(getValues('col'), 10) + 1}`}
                        >
                          <div
                            className={`row-span-${
                              parseInt(getValues('col'), 10) + 1
                            } bg-green-500`}
                          >
                            {Array.from(
                              Array(parseInt(getValues('col') || 1, 10) + 1),
                              (e, i) => (
                                <div className="text-white text-center py-1">
                                  {i}
                                </div>
                              ),
                            )}
                          </div>
                          <div
                            className={`col-span-${getValues(
                              'row',
                            )} bg-green-500`}
                          >
                            <div
                              className={`grid grid-cols-${getValues(
                                'row',
                              )} text-gray-200`}
                            >
                              {Array.from(
                                Array(parseInt(getValues('row') || 1, 10)),
                                (e, i) => (
                                  <div className="text-white text-center">
                                    {i + 1}
                                  </div>
                                ),
                              )}
                            </div>
                          </div>
                          {_.map(board, (eachBoard, index) => (
                            <Button
                              key={index}
                              variant="outlined"
                              size={'small'}
                              disabled
                            >
                              <div className="pl-1">ว่าง</div>
                            </Button>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {(checkComplexWarehouse || checkSimpleWarehouse) && (
                  <div className="flex flex-row justify-end gap-1 pt-4">
                    <Button
                      variant="contained"
                      type="submit"
                      startIcon={<SaveIcon />}
                    >
                      บันทึก
                    </Button>
                    <Button variant="outlined" onClick={handleClose}>
                      ยกเลิก
                    </Button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderModules = () => (
    <>
      {!_.isEmpty(warehouse.rows) ? (
        <div className="my-2 grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-2">
          {warehouse.rows.map((row, index) => (
            <Card
              key={index}
              className="w-full  cursor-pointer duration-150 ease-in-out transform hover:-translate-y-1  hover:shadow-2xl"
            >
              <div
                className="text-center px-4 pt-4"
                onClick={() => handlePushToDetailWarehouse(row?.id)}
              >
                <div className="text-xl font-bold">{row?.name}</div>
                {row?.pallet_in_warehouse?.length === 0 ? (
                  <>
                    <div className="text-base text-red-600 pt-1">
                      ยังไม่มีพาเลท
                    </div>
                    <div className="text-gray-600 text-sm">
                      ความจุสูงสุด {row?.maximum_capacity} พาเลท
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-base text-green-500 pt-1">
                      พาเลทในคลัง {row?.pallet_in_warehouse?.length} พาเลท
                    </div>
                    <div className="text-gray-600 text-sm">
                      ความจุสูงสุด {row?.maximum_capacity} พาเลท
                    </div>
                  </>
                )}

                <ArchiveIcon className="text-gray-600 mx-auto" size={140} />
                <div className="pb-2">คลิกเพื่อดูรายละเอียดคลัง</div>
                <div>
                  {LinearProgressWithLabel(
                    row?.pallet_in_warehouse?.length,
                    row?.maximum_capacity,
                  )}
                </div>

                <div className="text-center">
                  น้ำหนักรวม{' '}
                  {(row?.product_in_warehouse_inventory || 0).toLocaleString(
                    undefined,
                  )}{' '}
                  กิโลกรัม
                </div>
              </div>
              <div className="border-t-2 grid grid-cols-2 text-white text-center">
                <div
                  className="bg-yellow-300 hover:bg-yellow-400 py-2"
                  onClick={() => handlePushToEditWarehouse(row?.id)}
                >
                  <EditIcon /> แก้ไข
                </div>
                {row?.pallet_in_warehouse?.length > 0 ? (
                  <div
                    className="bg-red-400  py-2"
                    onClick={() => {
                      alert('ไม่สามารถลบได้ เนื่องจากมีพาเลทในคลัง');
                    }}
                  >
                    <DeleteForeverIcon /> ลบ
                  </div>
                ) : (
                  <div
                    className="bg-red-400 hover:bg-red-500 py-2"
                    onClick={() => {
                      handleDeleteWarehouse(row?.id);
                    }}
                  >
                    <DeleteForeverIcon /> ลบ
                  </div>
                )}
              </div>
              <div className="absolute top-1 right-1">
                {/* <IconButton onClick={() => handleOpenModalQrCode(row)}>
                  <QrCodeScannerIcon />
                </IconButton> */}
                <IconButton onClick={() => handlePrintBarcode(row, 'qrcode')}>
                  <QrCodeScannerIcon />
                </IconButton>
                <IconButton onClick={() => handlePrintBarcode(row, 'barcode')}>
                  <i className="fas fa-barcode" />
                </IconButton>
              </div>
            </Card>
          ))}
        </div>
      ) : (
        <div className="text-2xl text-center pt-4">ไม่มีคลังที่ต้องการ</div>
      )}
    </>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อ/รหัส คลัง</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> จำนวนพาเลทในคลัง</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(warehouse.rows) ? (
                warehouse.rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>{`${row.name}`}</TableCell>
                    <TableCell>
                      {row?.pallet_in_warehouse?.length === 0 ? (
                        <div className=" text-red-600 pt-1">ยังไม่มีพาเลท</div>
                      ) : (
                        <div className=" text-green-500 pt-1">
                          พาเลทในคลัง {row?.pallet_in_warehouse?.length} พาเลท
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      <div className="flex flex-row flex-wrap gap-1">
                        <Button
                          variant="contained"
                          color={'secondary'}
                          size={'small'}
                          startIcon={<DescriptionIcon />}
                          onClick={() => handlePushToDetailWarehouse(row.id)}
                        >
                          รายละเอียด
                        </Button>
                        <Button
                          variant="contained"
                          color={'warning'}
                          size={'small'}
                          startIcon={<EditIcon />}
                          onClick={() => handlePushToEditWarehouse(row.id)}
                        >
                          แก้ไข
                        </Button>
                        <Button
                          variant="contained"
                          color={'error'}
                          size={'small'}
                          disabled={row?.pallet_in_warehouse?.length > 0}
                          startIcon={<DeleteIcon />}
                          onClick={() => {
                            handleDeleteWarehouse(row?.id);
                          }}
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (warehouse.isLoading) {
    return <Loading />;
  }
  if (!warehouse.isLoading && warehouse.isCompleted) {
    return (
      <div>
        {renderModal()}
        {renderModalQR()}
        {renderCSVUploadModal()}
        <Snackbar
          open={openCopy}
          autoHideDuration={2000}
          onClose={handleCloseCopy}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={handleCloseCopy}
            severity="success"
            sx={{ width: '100%' }}
          >
            คัดลอกเรียบร้อย
          </Alert>
        </Snackbar>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="mt-6">{renderAddButtom()}</div>
        </div>
        {renderSearch()}
        {view === 'list' ? renderTable() : renderModules()}
      </div>
    );
  }
  return <Error message={warehouse?.message} />;
}

Warehouse.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Warehouse.defaultProps = {
  title: '',
  subtitle: '',
};

export default Warehouse;
