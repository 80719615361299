import {
  LOG_PRODUCT_STOCK_LOT_ALL,
  LOG_PRODUCT_STOCK_LOT_CREATE,
  LOG_PRODUCT_STOCK_LOT_GET,
  LOG_PRODUCT_STOCK_LOT_UPDATE,
  LOG_PRODUCT_STOCK_LOT_DELETE,
} from '../../actions/types';

const initialState = {
  logs: null,
  isLoading: false,
  isCompleted: true,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case LOG_PRODUCT_STOCK_LOT_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case LOG_PRODUCT_STOCK_LOT_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case LOG_PRODUCT_STOCK_LOT_CREATE:
      return { isLoading: false, isCompleted: true };
    case LOG_PRODUCT_STOCK_LOT_UPDATE:
      return { isLoading: false, isCompleted: true };
    case LOG_PRODUCT_STOCK_LOT_DELETE:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
