/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

// eslint-disable-next-line object-curly-newline
import {
  Backdrop,
  Button,
  Card,
  Fade,
  Modal,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
} from '@mui/material';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import AddBoxIcon from '@mui/icons-material/AddBox';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

import { Link } from 'react-router-dom';

const MainProductStockByCustomer = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const product = useSelector((state) => state.product);
  const productType = useSelector((state) => state.productType);
  const productStockLot = useSelector((state) => state.productStockLot);
  const customer = useSelector((state) => state.customer);
  const customerType = useSelector((state) => state.customerType);

  const [selectProduct, setSelectProduct] = useState('');
  const [filterProduct, setFilterProduct] = useState(true);
  const [selectCustomer, setSelectCustomer] = useState('');

  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);
  const [total, setTotal] = useState(undefined);

  console.log('customer', customer?.rows);
  console.log('customerType', customerType?.rows);

  useEffect(() => {
    dispatch(actions.customerAll({ name, page, size, selectCustomer }));
    dispatch(actions.customerTypeAll(''));
    dispatch(
      actions.productAll({ name, page, size, selectProduct, filterProduct }),
    );
    dispatch(actions.productStockLotAll({}));
    dispatch(actions.productTypeAll(''));
    dispatch(actions.meGet());
    return () => {};
  }, [name, page, size, selectProduct, filterProduct]);

  useEffect(() => {
    setTotal(product?.total);
    return () => {};
  }, [product]);

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeType = (event) => {
    console.log('type product', event.target);
    setSelectProduct(event.target.value);
  };

  const handleChangeTypeCustomer = (event) => {
    setSelectCustomer(event.target.value);
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderSearch = () => (
    <Card>
      <div className="p-4 md:flex md:flex-row gap-2">
        <div className="w-full md:w-1/2 pt-2 md:pt-1">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full md:w-1/2 pt-2 md:pt-1">
          <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
            <InputLabel id="type" size={'small'}>
              หมวดหมู่ลูกค้า
            </InputLabel>
            <Select
              label="ประเภทสินค้า"
              size={'small'}
              value={selectCustomer}
              onChange={handleChangeTypeCustomer}
              fullWidth
            >
              <MenuItem value={''}>แสดงทั้งหมด</MenuItem>
              {_.size(customerType.rows) ? (
                _.map(customerType.rows, (row) => (
                  <MenuItem key={row.id} value={row.name}>
                    {row.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem>
                  <MenuItem disabled value="">
                    <em>ไม่มีข้อมูล</em>
                  </MenuItem>
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-2 ">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ชื่อลูกค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">รายการใบรับฝาก</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">รายการใบเบิก</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(customer?.rows) ? (
                customer?.rows.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>

                    <TableCell>
                      <div className="font-bold text-lg">{`${row?.name}`}</div>
                      {row?.type?.name && (
                        <div className="">{`( ${row?.type?.name} )`}</div>
                      )}
                    </TableCell>

                    <TableCell>
                      <div className="max-h-20 overflow-y-auto">
                        <ui className="list-disc">
                          {_.map(row?.consignmentinvoices, (each, index) => (
                            <Link to={`/doc/consignment-invoice/${each?._id}`}>
                              <li key={index} className="hover:underline">
                                {each?.name}
                              </li>
                            </Link>
                          ))}
                        </ui>
                      </div>
                    </TableCell>

                    <TableCell>
                      <div className="max-h-20 overflow-y-auto">
                        <ui className="list-disc">
                          {_.map(row?.stocktakinginvoices, (each, index) => (
                            <Link to={`/doc/stocktaking-invoice/${each?._id}`}>
                              <li key={index} className="hover:underline">
                                {each?.name}
                              </li>
                            </Link>
                          ))}
                        </ui>
                      </div>
                    </TableCell>

                    <TableCell>
                      <div></div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูลที่ต้องการ</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (me.isLoading && product?.isLoading && customer?.isLoading) {
    return <Loading />;
  }
  if (
    !me.isLoading &&
    me.isCompleted &&
    !customer.isLoading &&
    customer.isCompleted &&
    !product.isLoading &&
    product.isCompleted
  ) {
    return (
      <div>
        {/* <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={!loading}
          onClose={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop> */}
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error message={me?.message} />;
};

MainProductStockByCustomer.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MainProductStockByCustomer.defaultProps = {
  title: '',
  subtitle: '',
};

export default MainProductStockByCustomer;
