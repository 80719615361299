import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Card, Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { useParams, useHistory } from 'react-router';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { EditProductForm } from '../../components/Forms';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

export default function EditProduct({ title, subtitle }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const product = useSelector((state) => state.product);
  const productType = useSelector((state) => state.productType);
  const [selectOption, setSelectOption] = useState([]);
  const [serialType, setSerialType] = useState(product.serial_type);
  const [privatePrice, setPrivatePrice] = useState(product.private_price);
  const [uploadedImage, setUploadedImage] = useState(product?.images);
  const [productDescribe, setProductDescribe] = useState(product?.descriptions);

  console.log('productDescribe', productDescribe);

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      name: product.name,
      unit: product.unit,
      packing_size: product.packing_size,
      packing_unit: product.packing_unit,
      net: product.net,
      unit_net: product.net,
      descriptions: product.descriptions,
      tag: product.tag,
      option: product.option,
    },
  });

  useEffect(() => {
    dispatch(actions.productGet(id));
    dispatch(actions.productTypeAll(''));
    return () => {};
  }, []);
  useEffect(() => {
    setUploadedImage(product?.images);
    setProductDescribe(product?.descriptions);
    return () => {};
  }, [product]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    try {
      console.log('payload after submit', data);
      const payload = {
        tag: data.tag,
        name: data.name,
        descriptions: productDescribe,
        unit: data.unit,
        type: data.product_type,
        images: _.map(uploadedImage, (image) => ({
          // eslint-disable-next-line no-underscore-dangle
          id: image._id,
          image: image.data_url || image.url,
        })),
      };
      // console.log('data after submit', data);
      console.log('payload after submit', payload);
      await dispatch(actions.productPut(id, payload));
      await dispatch(actions.productGet(id));
      alert('สำเร็จ');
      history.goBack();
    } catch (error) {
      console.log(error);
    }
  };

  // console.log('product', product);
  if (product.isLoading || product.rows) {
    return <Loading />;
  }
  if (!product.isLoading && product.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
          <div className="text-lg font-semibold ml-6">แก้ไขข้อมูลสินค้า</div>
        </div>
        <div>
          <Card className="p-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <EditProductForm
                errors={errors}
                product={product}
                control={control}
                Controller={Controller}
                productType={productType}
                option={product.option}
                selectOption={selectOption}
                setSelectOption={setSelectOption}
                serialType={serialType}
                setSerialType={setSerialType}
                privatePrice={privatePrice}
                setPrivatePrice={setPrivatePrice}
                uploadedImage={uploadedImage}
                setUploadedImage={setUploadedImage}
                productDescribe={productDescribe}
                setProductDescribe={setProductDescribe}
              />
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<SaveIcon />}
                >
                  บันทึก
                </Button>
              </div>
            </form>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

EditProduct.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditProduct.defaultProps = {
  title: '',
  subtitle: '',
};
